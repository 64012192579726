import React, { useEffect, useState, useRef, Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet';

import { DomainContext } from '../../../../context/Context';

import * as requests from '../../../../requests/requests';
import * as helpers from '../../../../utils/helpers';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import RegisterForm from '../../../utilities/RegisterForm';
import IdentifiedComponent from '../../../utilities/IdentifiedComponent';
import ErrorBoundary, { IErrorProps } from '../../../ErrorBoundary';

import FaceRecogForm from './FaceRecogForm';
import FaceRecogConn, { IFaceRecogMessage } from './FaceRecogConn';

interface IUserData {
  name: string;
  email: string;
  score: number;
}

interface IProps {
  title: string;
  description?: string;
}

type IdentifyResult = 'success' | 'fail' | 'no-data' | 'no-result';

const FaceRecog = ({ title, description }: IProps) => {
  const domain = useContext(DomainContext);
  const isMounted = useRef<boolean>(false);
  const userKeyRef = useRef<string>('');

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [identifiedResult, setIdentifiedResult] = useState<IdentifyResult>(
    'no-result'
  );
  const [userData, setUserData] = useState<IUserData | null>(null);
  const [boundError, setBoundError] = useState<IErrorProps | null>(null);

  const connectUrl = `https://${domain}/api/vision/face/connect`;
  const checkUrl = `https://${domain}/api/vision/face/check`;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const gotResult = (result: IFaceRecogMessage) => {
    const { key, callId, score } = result;
    const readableScore = helpers.roundPercentage(score);

    userKeyRef.current = key;

    if (key && callId) {
      if (key !== callId) {
        console.log('%cIdentified', 'color: green');
        requests
          .checkKey(checkUrl, { key })
          .then(({ error, name, email }) => {
            if (isMounted.current) {
              if (error) {
                console.warn(error);
                setIdentifiedResult('no-data');
              } else {
                setUserData({
                  name,
                  email,
                  score: readableScore,
                });
                setIdentifiedResult('success');
              }
            }
          })
          .catch((error) => {
            console.warn('Identified', readableScore, 'but... without data');
            console.error(error);
            setIdentifiedResult('no-data');
          });
      } else {
        console.log('%cNo Identified', 'color: red');
        if (isMounted.current) {
          setIdentifiedResult('fail');
        }
      }
    } else {
      console.error('something went wrong');
      setBoundError({
        title: 'Face recognition',
        description: 'Something went wrong',
      });
      if (isMounted.current) {
        setIsConnected(false);
        setUserData(null);
        setIsWorking(false);
        setIdentifiedResult('no-result');
      }
    }
  };

  const form = (
    <FaceRecogForm
      status={isConnected}
      disabled={identifiedResult !== 'no-result' ? true : false}
      onConnect={({ host, port }) => {
        if (isMounted.current) {
          setUserData(null);
          if (!isConnected === true) {
            requests
              .faceRecognitionConnect(connectUrl, { host, port })
              .then(() => {
                setIsConnected(true);
                setBoundError(null);
              })
              .catch((error) => {
                console.error(error);
                setBoundError({
                  title: 'Face recognition',
                  description: 'Something went wrong',
                });
              });
          } else {
            setIsConnected(false);
          }
        }
      }}
      onError={(error) => {
        console.error(error);
        setBoundError({
          title: 'Face recogntion',
          description: error,
        });
        if (isMounted.current) {
          setIsConnected(false);
          setUserData(null);
          setIsWorking(false);
          setIdentifiedResult('no-result');
        }
      }}
    />
  );

  const conn = isConnected ? (
    <FaceRecogConn
      hiddenCamera={true}
      onResult={gotResult}
      onDisconnect={() => {
        if (isMounted.current) {
          setIsConnected(false);
          setIsWorking(false);
        }
      }}
      onError={(error: string) => {
        console.error(error);
        setBoundError({
          title: 'Face recognition',
          description: error,
        });
      }}
      onRecording={(status) => {
        if (isMounted.current) setIsWorking(status);
      }}
    />
  ) : null;

  const register =
    (identifiedResult === 'no-data' || identifiedResult === 'fail') &&
      !isWorking ? (
      <RegisterForm
        uuid={userKeyRef.current}
        details={identifiedResult}
        data={userData}
        onRegistered={() => {
          if (isMounted.current) setIdentifiedResult('no-result');
        }}
        onError={(error) => console.log(error)}
      />
    ) : null;

  const identified =
    identifiedResult === 'success' && userData && !isWorking ? (
      <IdentifiedComponent
        name={userData?.name}
        email={userData?.email}
        score={userData?.score}
        done={() => {
          if (isMounted.current) setIdentifiedResult('no-result');
        }}
      />
    ) : null;

  const errorBoundary = boundError ? (
    <ErrorBoundary
      title={boundError?.title || 'Error'}
      description={boundError?.description ? boundError.description : ''}
      reason={boundError?.reason ? boundError.reason : ''}
    />
  ) : null;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <SubTitle name={title} />
      <Description text={description ? description : 'Utopia.AI'} />
      {form}
      {conn}
      {register}
      {identified}
      {errorBoundary}
    </Fragment>
  );
};

export default FaceRecog;
