import React, { useState, useRef, useEffect, useContext } from 'react';

import { EnvContext, DomainContext } from '../../../../context/Context';
import { downsampleBuffer, getLinear16 } from '../../../../utils/audio';

import CustomRecorder from '../../../utilities/CustomRecorder';
import CustomSocket from '../../../utilities/CustomSocket';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export type Top = {
  scores: number[];
  uids: string[];
};

export interface IVoiceIdMessage {
  isUser: boolean | undefined;
  key: string;
  score: number;
  callId: string;
  top: Top;
}

interface IProps {
  uuid: string;
  threshold: number;
  onResult: (message: IVoiceIdMessage) => void;
  onDisconnect: () => void;
  onError: (error: string) => void;
  onRecording: (state: boolean) => void;
}

type BufferSize = 256 | 512 | 1024 | 2048 | 4096 | 8192 | 16384;

const VoiceIdConn = ({ uuid, threshold, defaultThresholdEnabled, onResult, onDisconnect, onError, onRecording }: IProps) => {
  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);
  const voiceIdWsPort = env.includes('prod') ? 8002 : 4002;
  const voiceIdWsUrl = `wss://${domain}/ws${voiceIdWsPort}`;

  const isMounted = useRef<boolean>(false);
  const wsRef = useRef<WebSocket | null>(null);
  const audioCtxRef = useRef<AudioContext | null>(null);
  const sourceStreamRef = useRef<MediaStreamAudioSourceNode | null>(null);

  const [isRecording, setIsRecording] = useState<boolean>(false);

  useEffect(() => {
    isMounted.current = true;

    if (!audioCtxRef.current || audioCtxRef.current.state === 'closed') {
      audioCtxRef.current = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
    }

    return () => {
      isMounted.current = false;
      // audioCtxRef.current && audioCtxRef.current.close();
      wsRef.current && wsRef.current.close();
      sourceStreamRef.current && sourceStreamRef.current.disconnect();
    };
  }, []);

  const streamHandler = async (stream: MediaStream) => {
    if (!audioCtxRef.current || audioCtxRef.current.state === 'closed') {
      audioCtxRef.current = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
    }

    sourceStreamRef.current = audioCtxRef.current!.createMediaStreamSource(
      stream as MediaStream
    );

    const sampleRate: number = audioCtxRef.current!.sampleRate;
    const targetSampleRate: number = 8000;
    const bufferSize: BufferSize = 4096; // Small numbers lower the latency, but large number may be necessary to avoid audio breakup and glitches.

    let scriptNode = audioCtxRef.current!.createScriptProcessor(
      bufferSize,
      1,
      1
    ); // buffersize 1024

    scriptNode.onaudioprocess = async (audio) => {
      try {
        const inputBuffer: AudioBuffer = audio.inputBuffer;
        const audioBuffer: Float32Array = inputBuffer.getChannelData(0);
        const bufferF32: Float32Array = new Float32Array(audioBuffer);
        const downsampledBuffer = downsampleBuffer(
          bufferF32,
          sampleRate,
          targetSampleRate
        );

        const raw: DataView = await getLinear16(downsampledBuffer);
        const bytes: ArrayBuffer = raw.buffer;

        if (
          wsRef.current?.readyState !== wsRef.current?.CLOSED &&
          wsRef.current?.readyState !== wsRef.current?.CLOSING
        ) {
          wsRef.current?.send(bytes);
        }
      } catch (error) {
        console.error(error);
      }
    };

    sourceStreamRef.current.connect(scriptNode);
    scriptNode.connect(audioCtxRef.current!.destination);
  };

  const socket = (
    <CustomSocket
      url={voiceIdWsUrl}
      onOpen={(ws: WebSocket | null) => {
        if (ws) {
          wsRef.current = ws;
          // TODO mejorar
          const data = { sid: uuid };
            if(!defaultThresholdEnabled && threshold) {
                data.threshold = threshold;
            }
          ws.send(JSON.stringify(data));
        }
      }}
      onClose={() => {
        if (audioCtxRef.current?.state !== 'closed') {
          audioCtxRef.current?.close();
        }
        onDisconnect();
      }}
      onMessage={({ data }) => {
        const { uuid, error, keep_alive, ...rest } = JSON.parse(data);
        if (isMounted.current) {
          if (error) onError(error);
          else if (keep_alive) {
            // console.log('connected...');
          } else if (uuid) console.log('uuid:', uuid);
          else {
            const { is_user, uid, score, call_uid, top_5 } = rest;
            onResult({ isUser: is_user, key: uid, score, callId: call_uid, top: top_5 });
            if (typeof is_user !== 'undefined') wsRef.current?.close();
          }
        }
      }}
      onError={(error) => {
        audioCtxRef.current?.close();
        const description =
          typeof error === 'string' ? error : JSON.stringify(error);
        if (isMounted.current) onError(description);
      }}
    />
  );

  const recorder = (
    <CustomRecorder
      autoPlay={true}
      onChange={async (state: boolean) => {
        if (isMounted.current) {
          setIsRecording(state);
          if (state) {
            onRecording(true);
          } else {
            onRecording(false);
            try {
              await audioCtxRef.current?.close();
            } catch (error) {
              const description =
                typeof error === 'string' ? error : JSON.stringify(error);
              onError(description);
            }
          }
        }
      }}
      onStreaming={streamHandler}
    />
  );

  return (
    <Container fluid>
      {socket}
      <Row className="pt-4 pb-2">
        <Col className="text-center">{recorder}</Col>
      </Row>
      <Row>
        <Col className="text-center">
          <p
            className={`text-muted font-italic font-weight-light ${isRecording ? '' : 'd-none'}`}
          >
            Recording...
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default VoiceIdConn;
