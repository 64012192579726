import React, { Fragment } from 'react';

import Form from 'react-bootstrap/Form';

import { capitalizeFirstLetter } from '../../utils/helpers';


interface IProps {
	name: string;
	label: string;
	selected: string;
	rows?: number;
	disabled?: boolean;
	onChange: (value: string) => void;
}

const TextArea = ({ name, label, selected, rows, disabled, onChange }: IProps) => {
	const textarea = (
		<Form.Group controlId={name}>
			<Form.Label>
				{capitalizeFirstLetter(label)}
			</Form.Label>
			<Form.Control as="textarea" size="sm"
				rows={rows ? rows : 4}
				value={selected} disabled={disabled}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}>
			</Form.Control>
		</Form.Group>
	);

	return (
		<Fragment>
			{textarea}
		</Fragment>
	);
};

export default TextArea;