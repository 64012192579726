import { v4 as uuidv4 } from 'uuid';
import axios, { AxiosError } from 'axios';

interface IDate {
	date: string;
	time: string;
}

function _timestampToDate(time: string): IDate {
	const timestamp: number = parseInt(time);
	const ts: Date = new Date(timestamp * 1000);
	const months: string[] = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dev',
	];

	const year = ts.getFullYear();
	const month = months[ts.getMonth()];
	const date = ts.getDate();

	const hour = ts.getHours();
	const min = '0' + ts.getMinutes();
	const sec = '0' + ts.getSeconds();

	const formattedTime = `${hour}:${min.substr(-2)}:${sec.substr(-2)}`;
	const formattedDate = `${year}-${month}-${date}`;

	return {
		time: formattedTime,
		date: formattedDate,
	};
}

function getDateByTimestamp(timestamp: string): string {
	const { date } = _timestampToDate(timestamp);
	return date;
}

function getTimeByTimestamp(timestamp: string): string {
	const { time } = _timestampToDate(timestamp);
	return time;
}

function concatBuffers(buffer1: ArrayBuffer, buffer2: ArrayBuffer): ArrayBuffer {
	const tmp: Uint8Array = new Uint8Array(
		buffer1.byteLength + buffer2.byteLength
	);

	tmp.set(new Uint8Array(buffer1), 0);
	tmp.set(new Uint8Array(buffer2), buffer1.byteLength);

	return tmp.buffer;
}

function roundPercentage(score: number): number {
	return Math.round((score + Number.EPSILON) * 100) / 100;
}

function getUserNameByEmail(email: string): string {
	return email.split('@')[0].replace(/[.]/g, "_");
}

function removeExtFromFileName(fileName: string): string {
	const fileNameArray = fileName.split('.');
	fileNameArray.pop();
	return fileNameArray.join('.');
}

function getLanguagebyFileName(fileName: string): string {
	return fileName.split('_')[0];
}

function getFirstKeyByDoneSentences(doneKeys: number[], total: number): number {
	const len: number = doneKeys.length;

	if (!len) return 1; // none done => return 1
	if (doneKeys[0] !== 1) return 1; // first key is not done => return 1
	if (len === 1) return 2; // first key is done => return 2 (next one is the second one)

	let result = 1; // init

	for (let i = 0; i < len; i++) {
		if (doneKeys[i + 1] - doneKeys[i] !== 1) {
			result = doneKeys[i] + 1;
			break;
		}
	}

	if (result > total) return total;
	else return result;
}

function _getRandomNum(max?: number, min?: number): number {
	if (min && max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
	} else if (!min && max) return Math.floor(Math.random() * Math.floor(max));
	else return Math.random();
}

function getRandomColor(alpha?: number): string {
	// const hue = _getRandomNum(360, 0);
	// const sat = _getRandomNum(100, 20);
	// const light = _getRandomNum(40, 60);

	const r = _getRandomNum(200, 50);
	const g = _getRandomNum(255, 50);
	const b = _getRandomNum(255, 50);

	// return `hsla(${hue}, ${sat}%, ${light}%, ${alpha ? alpha : 1})`;
	return `rgba(${r}, ${g}, ${b}, ${alpha ? alpha : 1})`;
}

function getUuid(): string {
	return uuidv4();
}

function insertAt(array: any[], index: number, ...elements: any[]): any[] {
	return array.splice(index, 0, ...elements);
}

function getCurrentTime(): string {
	const now = Math.floor(Date.now() / 1000);
	const time = getTimeByTimestamp(`${now}`);
	const timeArray = time.split(':');
	return `${timeArray[0]}:${timeArray[1]}`;
}

function getRandomNumber(min: number = 1, max: number = 100): number {
	return Math.floor(Math.random() * max) + min; // returns a random integer from 1 to 100
}

function hasNumber(str: string) {
	return /\d/.test(str);
}

function hasUpperCase(str: string) {
	return (/[A-Z]/.test(str));
}

type MathAdjust = 'round' | 'ceil' | 'floor';

/**
	 * Decimal adjustment of a number.
	 *
	 * @param {MathAdjust}  type  The type of adjustment ('round', 'ceil' or 'floor')
	 * @param {Number}  value The number.
	 * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
	 * @returns {Number} The adjusted value.
	 */
function decimalAdjust(type: MathAdjust, value: number, exp: number): number {
	// If the exp is undefined or zero...
	if (typeof exp === 'undefined' || +exp === 0) {
		return Math[type](value);
	}

	value = +value;
	exp = +exp;

	// If the value is not a number or the exp is not an integer...
	if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
		return NaN;
	}

	// Shift
	let valueStr = value.toString().split('e');
	value = Math[type](+(valueStr[0] + 'e' + (valueStr[1] ? (+valueStr[1] - exp) : -exp)));

	// Shift back
	valueStr = value.toString().split('e');
	return +(valueStr[0] + 'e' + (valueStr[1] ? (+valueStr[1] + exp) : exp));
}

function capitalizeFirstLetter(name: string): string {
	return name.charAt(0).toUpperCase() + name.slice(1);
}

function displayAxiosError(error: Error | AxiosError): void {
	if (axios.isAxiosError(error)) {
		console.log('message:', error.message, 'config:', error.config, 'request:', error.request, 'response:', error.response);
	} else {
		console.log(error);
	}
}

function truncateNoRounding(num: number, dec: number): string | undefined {
	const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${dec}})?`, 'g');
	const n = num.toString().match(reg);

	if (!n) return undefined;

	const a = n[0];
	const dot = a.indexOf('.');

	if (dot === -1) return `${a}.${'0'.repeat(dec)}`;

	const b = dec - (a.length - dot) + 1;

	return b > 0 ? `${a}${'0'.repeat(b)}` : a;
}

export {
	getRandomNumber,
	getTimeByTimestamp,
	getDateByTimestamp,
	getCurrentTime,
	concatBuffers,
	roundPercentage,
	getUserNameByEmail,
	removeExtFromFileName,
	getLanguagebyFileName,
	getFirstKeyByDoneSentences,
	getRandomColor,
	getUuid,
	insertAt,
	hasNumber,
	hasUpperCase,
	decimalAdjust,
	capitalizeFirstLetter,
	displayAxiosError,
	truncateNoRounding
};