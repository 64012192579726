import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAudio,
  faFileAlt,
  faFile,
} from '@fortawesome/free-solid-svg-icons';

type TypeIcon = 'audio' | 'text' | 'file';

interface IProps {
  type?: TypeIcon;
  href?: string;
}

const CustomFileLink = ({ type, href }: IProps) => {
  const standardIcon = <FontAwesomeIcon icon={faFile} />;
  const audioFileIcon = <FontAwesomeIcon icon={faFileAudio} />;
  const textFileIcon = <FontAwesomeIcon icon={faFileAlt} />;

  let icon = standardIcon;
  icon = type === 'audio' ? audioFileIcon : icon;
  icon = type === 'text' ? textFileIcon : icon;

  const link = href ? (
    <a href={href} download>
      {icon}
    </a>
  ) : (
    icon
  );
  const className = `pl-2 ${href ? 'text-info' : 'translucent'}`;

  return (
    <div className={className} style={{ fontSize: '1.65rem' }}>
      {link}
    </div>
  );
};

export default CustomFileLink;
