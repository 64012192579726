/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from "../contexts/appContext";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { DomainContext, EnvContext } from '../../../../../context/Context';

const style = css`
	width: 100%;
	height: auto;
	margin-top: 1em;

    .title {
        margin-left: 1em;
    }

	.container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex-basis: content;
		justify-content: center;
	}

	.close-button {
        max-width: 10em;
        float: right;
        margin-right: 1em;
    }

	.item {
		display: flex;
		max-height: 540px;
		max-width: 500px;
		cursor: pointer;
		margin: 0.5em;
		// border: 1px solid #ccc;
		border-radius: 0.2em;
		justify-content: center;
		align-items: center;
		position: relative;
		// background-color: black;
		// color: #ccc;
		overflow: hidden;

		.item-title {
			opacity: 0;
			color: #242424;
		}

        video {
            max-width: 500px;
            max-height: 500px;
            cursor: pointer;
        }

        .video-buttons {
            margin: 0.5em;
        }

        .video-buttons button {
            margin-bottom: 0.5em;
        }
    }
`;

const VideosPanel = ({ drivingVideos }) => {
    const { drivingVideo, setDrivingVideo, setShowVideosPanel } = AppContext();

	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	const containerRef = useRef<HTMLDivElement>(null);
	// const items = useGetItemsFromS3(category, user);

    const selectVideo = (key) => {
        console.log('selectVideo', key);
        setDrivingVideo(key);
        setShowVideosPanel(false);
    };

    const playVideo = (key) => {
        console.log('playVideo', key);
        const elem = document.getElementById(`driving_video_${key}`);
        elem.play();
    };

    const close = () => {
        setShowVideosPanel(false);
    };

	return (
		<div css={style}>
            <Form.Control as="button" className="close-button" onClick={ () => close() }>Back</Form.Control>
			<h2 className="title">Videos</h2>
			<div className="container" ref={containerRef}>
                {
                    drivingVideos && drivingVideos.map((el: any, index: number) => (
                        <div className="item" key={ el.key }>
                          <video onClick={() => selectVideo(el.key) }
                                id={`driving_video_${el.key}`}
                                src={ `https://dialoga-machine-learning.s3.amazonaws.com/${el.key}` }
                          ></video>
                          <div className="video-buttons">
                              <Form.Control as="button" onClick={ () => playVideo(el.key) }>Play</Form.Control>
                              <Form.Control as="button" onClick={ () => selectVideo(el.key) }>Select</Form.Control>
                          </div>
                        </div>
                    ))
                }
			</div>
		</div>
	);
};

                          // <div>{ el.name }</div>

                // (videos && videos.map((el: string, index: number) => (
                //     <div>VIDEO</div>
                // )
export default VideosPanel;

