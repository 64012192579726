import React, { useState, useRef, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CustomRange from '../../../forms/CustomRange';
import CustomSwitch from '../../../forms/CustomSwitch';


import { useVoiceIdForm, IUseVoiceIdForm } from './useVoiceIdForm';

interface IProps {
  uuid: string;
  threshold: number;
  defaultThreshold: number;
  status: boolean;
  gotParams: (params: IParams) => void;
  disabled?: boolean;
  onConnect: () => void;
  onDefaultThresholdEnabledChange: (language: string) => void;
}

const instructions =
  'Press Connect and start to talk. After few seconds, results will be displayed.';

const VoiceIdForm = ({
    uuid,
    threshold,
    defaultThreshold,
    status,
    disabled,
    onConnect,
    gotParams,
    onDefaultThresholdEnabledChanged,
}: IProps) => {
  const isMounted = useRef<boolean>(false);

  const { params, setParams }: IUseVoiceIdForm = useVoiceIdForm(uuid);

  const [defaultThresholdEnabled, setDefaultThresholdEnabled] = useState<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (params) {
      let isFilled = true;

      Object.entries(params).forEach(([key, value]) => {
        if (typeof value === 'string' && value === '') isFilled = false;
        if (typeof value === 'object' && value[0] === '') isFilled = false;
      });

      if (isFilled) {
        const { threshold } = params;
        gotParams({ threshold });
      }
    }
    // eslint-disable-next-line
  }, [params]);

  const thresholdInfo =
    `You can optionally set a confidence level by moving the Threshold slide bar`;

  const form = (
    <Form>
      <Form.Row>
          <Col className="py-2">
              { thresholdInfo }
          </Col>
      </Form.Row>
      <Form.Row>
          <Col className="py-2">
            <CustomSwitch name="default-threshold"
                label={`default threshold (${defaultThreshold})`}
                initial={defaultThresholdEnabled}
                disabled={disabled}
                onChange={(status) => {
                    if (isMounted.current) {
                        console.log('default changed');
                        setDefaultThresholdEnabled(status);
                        onDefaultThresholdEnabledChanged(status);
                    }
                }} />
          </Col>
          <Col className={!defaultThresholdEnabled ? 'py-2' : 'py-2 d-none'}>
              <CustomRange name="voice-id-threshold" label="threshold"
                   disabled={defaultThresholdEnabled} min={0} max={1} step={.01} value={threshold}
                   onChange={(value) => {
                     const { ...props } = params;
                     if (isMounted.current) {
                         setParams({ ...props, threshold: value });
                     }
                   }} />
          </Col>
      </Form.Row>
      <Form.Row>
        <Col className="py-2">
          <Button
            disabled={disabled}
            block
            onClick={() => {
              if (isMounted.current) {
                onConnect();
              }
            }}
          >
            {status ? 'Disconnect' : 'Connect'}
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="text-muted">
            <p>{instructions}</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>{form}</Col>
      </Row>
    </Container>
  );
};

export default VoiceIdForm;
