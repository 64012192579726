import React, { useEffect, useState, useRef, useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { DomainContext, LoginContext, EnvContext } from '../../../../context/Context';
import axios, { AxiosError } from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { getUserNameByEmail, displayAxiosError } from '../../../../utils/helpers';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import Loading from '../../../pages/Loading';

import OptionPanel, { getNameByS3Key } from './OptionPanel';
import AvatarBackground, { Category } from './AvatarBackground';

import AvatarResult from './AvatarResult';
import VideosPanel from './VideosPanel';
import TextToSpeech from '../../loquista/tts/TextToSpeech';
import { IVideo } from './useGetVideos';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';

interface IProps {
  title: string;
  description?: string;
}

const Avatar = ({ title, description }: IProps) => {
  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);

  const { logged, setLogged } = useContext(LoginContext);

  const isMounted = useRef<boolean>(false);
  const wsRef = useRef<WebSocket>();
  const wsId = useRef<string>('');
  const isProcessed = useRef<boolean>(false);

  const [isTTSLoading, setIsTTSLoading] = useState<boolean>(true);
  const [isTTSWorking, setIsTTSWorking] = useState<boolean>(false);
  const [isTTSDisabled, setIsTTSDisabled] = useState<boolean>(false);
  const [isAvatarWorking, setIsAvatarWorking] = useState<boolean>(false);

  const [audioProgress, setAudioProgress] = useState<number>(0);
  const [userName, setUserName] = useState<string>('');
  const [resultSrc, setResultSrc] = useState<string>('');
  const [previousSrc, setPreviosuSrc] = useState<string>('');

  const [gotAlert, setAlert] = useState<string>('');


  const [showVideos, setShowVideos] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<IVideo>({
    name: localStorage.getItem('vision-mimic-video') || '',
    origin: 'source',
    key: ''
  });

  const [isCustomVideo, setIsCustomVideo] = useState<boolean>(false);
  const [showCategoryMenu, setShowCategoryMenu] = useState<boolean>(false);
  const [category, setCategory] = useState<Category>('video');
  const hd = localStorage.getItem('vision-mimic-avatar-hd') === 'true' ? true : false;
  const [avatarHD, setAvatarHD] = useState<boolean>(hd);
  const [video, setVideo] = useState<string>(localStorage.getItem('vision-mimic-video') || 'select video');
  const [image, setImage] = useState<string | undefined>(localStorage.getItem('vision-mimic-image') || undefined);
  const [background, setBackground] = useState<string | undefined>(localStorage.getItem('vision-mimic-background') || undefined);
  const [watermark, setWatermark] = useState<string | undefined>(localStorage.getItem('vision-mimic-watermark') || undefined);

  useEffect(() => {
    isMounted.current = true;

    if (logged && typeof logged === 'string') {
      const name = getUserNameByEmail(logged);
      if (isMounted.current) setUserName(name);
    } else {
      const token = localStorage.getItem(`${env}-token`);
      axios.post(`https://${domain}/login/authentication/user`, { token })
        .then(({ data: { email } }) => {
          const name = getUserNameByEmail(email);
          if (isMounted.current) {
            setLogged(email);
            setUserName(name);
          }
        })
        .catch(error => {
          console.error(error);
          setAlert('No authenticated');
        });
    }

    return () => {
      isMounted.current = false;
      wsRef?.current?.close();
      wsId.current = '';
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedVideo) localStorage.setItem('vision-mimic-video', selectedVideo.name);
  }, [selectedVideo]);

  useEffect(() => {
    video && localStorage.setItem('vision-mimic-video', video);
  }, [video]);

  useEffect(() => {
    // image && localStorage.setItem('vision-mimic-image', image);
    if(!image) {
        localStorage.removeItem('vision-mimic-image');
    } else {
        localStorage.setItem('vision-mimic-image', image);
    }
  }, [image]);

  useEffect(() => {
    // background && localStorage.setItem('vision-mimic-background', background);
    if(!background) {
        localStorage.removeItem('vision-mimic-background');
    } else {
        localStorage.setItem('vision-mimic-background', background);
    }
  }, [background]);

  useEffect(() => {
    if(!watermark) {
        localStorage.removeItem('vision-mimic-watermark');
    } else {
        localStorage.setItem('vision-mimic-watermark', watermark);
    }
  }, [watermark]);

  useEffect(() => {
    localStorage.setItem('vision-mimic-avatar-hd', avatarHD);
  }, [avatarHD]);

  const process = async (audioName: string) => {
    isProcessed.current = false;
    isMounted.current && setIsAvatarWorking(true);
    isMounted.current && setAudioProgress(0);

    const wsPort: number = env.includes('prod') ? 8007 : 4007;
    const wsUrl: string = `wss://${domain}/ws${wsPort}`;

    let audioUrl: string = '';

    try {
      wsRef.current = new WebSocket(wsUrl);

      const headers = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}`
        }
      };

      wsRef.current.onopen = () => {
        console.log('socket is opened');
      };

      wsRef.current.onclose = () => {
        console.log('socket is closed');
        isMounted.current && setIsAvatarWorking(false);
        isMounted.current && setIsTTSLoading(false);
        isMounted.current && setIsTTSWorking(false);
        isMounted.current && setIsTTSDisabled(false);
      };

      wsRef.current.onmessage = async (message) => {
        try {
          const { data } = message;
          const { uuid, keep_alive, predict, update } = JSON.parse(data);

          if (keep_alive) {
            console.info('socket is still working...');
          } else if (!keep_alive && uuid) {
            wsId.current = uuid;
            console.log('Avatar wsId:', uuid);
          } else if (update) {
            const { error: err } = update; // {error, success}
            if (err) {
              console.error(err);
              isMounted.current && setAlert(err);
              isMounted.current && setIsAvatarWorking(false);
              isProcessed.current = true;
              wsRef.current?.close();
              wsId.current = '';
            } else {
              const predictApi = `https://${domain}/api/vision/mimic/predict`;
              const predictParams = {
                id: wsId.current,
                env,
                audioName,
                audioUrl,
                videoName: getNameByS3Key(video),
                videoUrl: video,
                image,
                background,
                watermark,
                avatarHD,
              };
              console.info('predict setup:', wsId.current);
              console.table(predictParams);
              const { data } = await axios.post(predictApi, predictParams, headers);
              console.log('predict:', data);
            }
          } else if (predict) {
            const { error: err, video_url } = predict;
            if (err) {
              console.error(err);
              isMounted.current && setAlert(err);
              isMounted.current && setIsAvatarWorking(false);
              isProcessed.current = true;
            } else if (video_url) {
              isMounted.current && setAlert('');
              isMounted.current && setIsAvatarWorking(false);
              isMounted.current && !isProcessed.current && setResultSrc(video_url);
              isProcessed.current = true;
            }
            wsId.current = '';
          }
        } catch (error) {
          console.warn('socket error', error);
          wsRef.current?.close();
          wsId.current = '';

          const err = error as AxiosError;
          const status = err.response?.status || 500;
          console.error(err.response?.data || 'An unexpected error occurred');
          if (status === 400) {
            isMounted.current && setIsAvatarWorking(false);
            isMounted.current && setIsTTSLoading(false);
            isMounted.current && setIsTTSWorking(false);
            isMounted.current && setIsTTSDisabled(false);
          }
          isMounted.current && setResultSrc('');
          displayAxiosError(err);
        }
      };

      wsRef.current.onerror = (error) => {
        console.warn('Socket error:', error);
        wsRef.current?.close();
        wsId.current = '';
      };

      const audio2mimicApi = `https://${domain}/api/loquista/tts/audio2mimic`;
      const audio2mimicParams = { audioName };
      const { data: { url } } = await axios.post(audio2mimicApi, audio2mimicParams, headers);
      audioUrl = url;

      setTimeout(async () => {
        if (wsId.current) {
          try {
            if (isCustomVideo) {
              const updateApi = `https://${domain}/api/vision/mimic/update/video`;
              const updateParams = {
                id: wsId.current,
                env,
                url: video,
                name: getNameByS3Key(video)
              };
              console.table(updateParams);
              const { data } = await axios.post(updateApi, updateParams, headers);
              console.log('update', data);
            } else {
              const predictApi = `https://${domain}/api/vision/mimic/predict`;
              const predictParams = {
                id: wsId.current,
                env,
                audioName,
                audioUrl,
                videoName: getNameByS3Key(video),
                videoUrl: video,
                image,
                background,
                watermark,
                avatarHD,
              };
              console.info('predict setup:', wsId.current);
              console.table(predictParams);
              const { data } = await axios.post(predictApi, predictParams, headers);
              console.log('predict', data);
            }
          } catch (error) {
            const err = error as AxiosError;
            const status = err.response?.status || 500;
            console.error(err.response?.data || 'An unexpected error occurred');
            if (status === 400) {
              isMounted.current && setIsAvatarWorking(false);
              isMounted.current && setIsTTSLoading(false);
              isMounted.current && setIsTTSWorking(false);
              isMounted.current && setIsTTSDisabled(false);
            }
            isMounted.current && setResultSrc('');
          }
        } else window.location.reload();
      }, 500);

    } catch (error) {
      const err = error as AxiosError;
      const status = err.response?.status || 500;
      console.error(err.response?.data || 'An unexpected error occurred');
      if (status === 400) {
        isMounted.current && setIsAvatarWorking(false);
        isMounted.current && setIsTTSLoading(false);
        isMounted.current && setIsTTSWorking(false);
        isMounted.current && setIsTTSDisabled(false);
      }
      isMounted.current && setResultSrc('');
    }

  };

  const optionPanel = showCategoryMenu && (
    <OptionPanel
      user={userName}
      category={category}
      byDefault={category === 'video' ? video : (category === 'background' ? background : (category === 'watermark' ? watermark : image))}
      onSelect={(_item: string | undefined) => {
        setShowCategoryMenu(false);
        if (category === 'video') setVideo(_item!);
        if (category === 'image') setImage(_item);
        if (category === 'background') setBackground(_item);
        if (category === 'watermark') setWatermark(_item);
      }}
      onCancel={() => {
        console.log('cancel');
        setShowCategoryMenu(false);
      }}
      onChangeCustom={(status: boolean) => {
        setIsCustomVideo(status);
      }}
    />
  );

  const backgroundForm = !isTTSLoading && !resultSrc && !isAvatarWorking && !isTTSWorking && (
    <AvatarBackground
      video={video}
      image={image}
      background={background}
      watermark={watermark}
      avatarHD={avatarHD}
      onSelection={(seletedCategory) => {
        setCategory(seletedCategory);
        setShowCategoryMenu(true);
      }}
      onSetAvatarHD={(status) => {
        console.log('setAvatarHD', status);
        setAvatarHD(status);
      }}
    />
  );

  const previousResult = previousSrc && !resultSrc && !showCategoryMenu && !isTTSLoading && !isAvatarWorking && !isTTSWorking && (
    <Container fluid>
      <Row className="justify-content-center mb-2 text-center">
        <Col xs={6} sm={5} md={4} lg={3}>
          <div className="font-weight-light font-italic">Previous result</div>
        </Col>
      </Row>
      <Row className="justify-content-center mb-2">
        <Col xs={6} sm={5} md={4} lg={3}>
          <div className="embed-responsive video-thumbnail-button"
            onClick={() => setResultSrc(previousSrc)}>
            <div className="video-thubnail-button-icon">
              <FontAwesomeIcon icon={faPlay} />
            </div>
            <div>
              <video
                id="mimic-previous-video"
                className="embed-responsive-item position-relative"
                muted>
                <source
                  src={previousSrc ?? ''}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </Col>
      </Row>
    </Container>

  );

  const tts = <TextToSpeech title='Text to Speech' isComponent={true}
    hidden={resultSrc || gotAlert || isAvatarWorking || showVideos || showCategoryMenu ? true : false}
    disabled={isTTSDisabled}
    audioOff={true}
    gotAudio={(name: string) => {
      if (!video.startsWith('http')) {
        setAlert('None video selected');
        setIsTTSWorking(false);
        setTimeout(() => setAlert(''), 2000);
      } else isMounted.current && process(name);
    }}
    onLoading={(isLoading) => {
      if (isMounted.current) setIsTTSLoading(isLoading);
    }}
    onWorking={(isWorking) => {
      if (isMounted.current) setIsTTSWorking(isWorking);
    }}
    onProgress={(progress) => {
      isMounted.current && setAudioProgress(progress);
      console.info(`TTS progress ${progress}%`);
    }}
  />;

  const progressBar = isTTSWorking && <Container fluid>
    <Row className="my-2">
      <Col>
        <ProgressBar animated now={audioProgress} />
      </Col>
    </Row>
  </Container>;

  const loading = isAvatarWorking || isTTSWorking ?
    <Fragment>
      <Loading />
      <div className="text-center">
        <p>
          {isAvatarWorking && <small>{'Processing video...'}</small>}
          {isTTSWorking && <small>{'Processing audio...'}</small>}
        </p>
      </div>
    </Fragment> :
    null;

  const result = isAvatarWorking ? null : (
    <AvatarResult src={resultSrc}
      goBack={() => {
        if (isMounted.current) {
          setPreviosuSrc(resultSrc);
          setResultSrc('');
        }
      }}
    />
  );

  const videosPanel = (showVideos && userName) && (
    <VideosPanel user={userName} selected={selectedVideo.name}
      onSelect={(_video) => {
        setShowVideos(false);
        setSelectedVideo(_video);
      }} />
  );

  const alert = gotAlert && (
    <Container>
      <Row>
        <Col>
          <Alert variant="warning">{gotAlert}</Alert>
        </Col>
      </Row>
    </Container>
  );


  const mimicContent = (
    <Fragment>
      {backgroundForm}
      {previousResult}
      {tts}
      {progressBar}
      {result}
      {loading}
      {videosPanel}
      {optionPanel}
      {alert}
    </Fragment>
  );


  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description ?? 'Utopia app'} />
      </Helmet>
      <SubTitle name={title} />
      <Description text={description ? description : 'Utopia.AI'} />
      {mimicContent}
    </Fragment>
  );
};

export default Avatar;

// { gotError ? <ErrorPage code={503} /> : mimicContent}
