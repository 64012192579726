import { useState, useEffect, useContext } from 'react';
import { DomainContext } from '../context/Context';

import axios from 'axios';

type Enum = 'true' | 'false';

export interface ISection {
	id: number;
	name: string;
	alias: string;
	description: string;
	submenu: Enum;
	subsection?: string;
	groups?: string;
}

const useGetSections = () => {
	const domain = useContext(DomainContext);
	const url = `https://${domain}/admin/sections`;

	const [sections, setSections] = useState<ISection[]>([]);

	useEffect(() => {
		const getSections = async () => {
			try {
				const { data: { results } } = await axios.get(url);
				setSections(results);
			} catch (error) {
				console.error('An error occurred while getting Sections from database');
				setSections([]);
			}
		};
		getSections();
		// eslint-disable-next-line
	}, []);

	return sections;
};

export default useGetSections;