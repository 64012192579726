import React, { Fragment } from 'react';

import DismissibleErrorMsg, { Variant } from '../utilities/DismissibleErrorMsg';

import Title from '../Title';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export enum Codes {
	UNAVAILABLE = 503,
	INTERNAL = 500
}

interface IProps {
	code: Codes;
	variant?: Variant;
}

const ErrorPage = ({ code, variant }: IProps) => {
	return (
		<Fragment>
			<Title title={'Something went wrong'} />
			<Container fluid>
				<Row className="mt-2 mb-4">
					<Col>
						<DismissibleErrorMsg code={code} variant={variant ? variant : 'warning'} />
					</Col>
				</Row>
				<Row className="my-4">
					<Col>
						<h5>Utopia Error reporting</h5>
						<p />
						<p>We are constantly working to improve our services.</p>
						<p>Try to refresh this page or feel free to send us the error report to Utopia Support.</p>
						<hr />
						<div className="d-flex justify-content-end">
							<Button variant="info" className="mr-2" onClick={() => window.location.reload()}>
								Reload
							</Button>
							<Button variant="primary" className="mr-2" onClick={() => {
								console.log('feedback report de errores');
								window.location.reload();
							}}>
								Send error report
							</Button>
							<Button variant="light" className="mr-2" onClick={() => window.location.reload()}>
								Don't send
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default ErrorPage;