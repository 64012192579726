/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import React, { useState, useContext, useEffect } from 'react';

import axios, { AxiosError } from 'axios';

import { DomainContext, EnvContext } from '../../../../context/Context';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneAlt, faMicrophoneAltSlash } from '@fortawesome/free-solid-svg-icons';

import { IAssistant } from '../useGetAssistants';
import { ISpellCheckParams, ICheckResponse } from '../../loquista/spellChecker/useSpellCheck';
import CustomResizableTextArea from '../../../forms/CustomResizableTextArea';
import SpeechToTextConn from '../../loquista/asr/SpeechToTextConn';

const fade = keyframes`
	0%  {opacity: 1;}
	5%  {opacity: 0.9;}
	10%  {opacity: 0.8;}
	15% {opacity: 0.7;}
	20% {opacity: 0.6;}
	25% {opacity: 0.5;}
	30% {opacity: 0.4;}
	35% {opacity: 0.3;}
	40% {opacity: 0.2;}
	45% {opacity: 0.1;}
	50% {opacity: 0;}
	55% {opacity: 0.1;}
	60% {opacity: 0.2;}
	65% {opacity: 0.3;}
	70% {opacity: 0.4;}
	75% {opacity: 0.5;}
	80% {opacity: 0.6;}
	85% {opacity: 0.7;}
	90% {opacity: 0.8;}
	95% {opacity: 0.9;}
	100% {opacity: 1;}
`;

const style = css`
	.recording > *  {
		animation: ${fade} 1.75s ease infinite;
	}
`;

interface IProps {
	forwardRef: React.RefObject<HTMLTextAreaElement> | null;
	assistant: IAssistant;
	onTextSubmit: (text: string) => void;
	onTextChange?: (text: string) => void;
	onClickSend: (text: string) => void;
}

const ChatForm = ({ forwardRef, assistant, onTextChange, onTextSubmit, onClickSend }: IProps) => {
	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	const [clear, setClear] = useState<boolean>(false);
	const [isConnected, setIsConnected] = useState<boolean>(false);
	const [isRecording, setIsRecording] = useState<boolean>(false);
	const [asrId, setAsrId] = useState<string>('');

	useEffect(() => {
		const _startConnecting = async () => {

			console.table(assistant);

			const asrParams = {
				host: '172.26.161.27',
				language: assistant.language === 'en' ? 'en-US' : 'es-ES',
				nlp: 'GENERAL',
				options: [
					{ name: 'use_enhanced', value: true },
					{ name: 'use_gpu', value: true },
					{ name: 'use_provisional_transcription', value: true }
				]
			};

			const asrUrl = `https://${domain}/api/loquista/asr/connect`;
			const asrHeaders = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
			const { data } = await axios.post(asrUrl, asrParams, asrHeaders);
			setAsrId(data);
			setIsRecording(true);
		};
		if (isConnected) _startConnecting();
		else {
			setAsrId('');
			setIsRecording(false);
		}
	}, [isConnected]);

	useEffect(() => {
		if (isRecording) console.log('start recording');
	}, [isRecording]);

	const form = <Form>
		<CustomResizableTextArea
			forwardRef={forwardRef}
			minRows={1}
			maxRows={3}
			reset={clear ?? false}
			disabled={isConnected}
			onSubmit={(text: string) => {
				onTextSubmit(text);
				setClear(true);
			}}
			onChange={(text: string) => {
				setClear(false);
				onTextChange && onTextChange(text);
			}}
		/>
	</Form>;

	const button = <Button
		block
		variant='brain'
		className='my-2 my-md-0 px-0'
		disabled={isConnected}
		onClick={(event: React.MouseEvent<HTMLElement>) => {
			const text: string | undefined | null = forwardRef?.current && forwardRef.current.value.trim();
			forwardRef?.current && forwardRef.current.focus();
			text && onClickSend(text);
			setClear(true);
		}}
	>
		Send
	</Button>;

	const microphoneButton = (
		<Button
			block
			className={`my-2 my-md-0 px-0 ${isRecording ? 'recording' : ''}`}
			variant={isRecording ? 'danger' : 'primary'}
			onClick={() => setIsConnected(!isConnected)}>
			<FontAwesomeIcon icon={isRecording ? faMicrophoneAlt : faMicrophoneAltSlash} />
		</Button>
	);

	const asr = isRecording && (
		<SpeechToTextConn
			connId={asrId}
			hiddenTemporal={true}
			hiddenHistory={true}
			hiddenAudio={true}
			isComponent={true}
			onConnected={(value: boolean) => console.log('asr connected:', value)}
			gotResult={async (result) => {
				const _check = await _spellChecker(env, domain, {
					text: result,
					id: asrId,
					language: assistant.language
				});
				result && onClickSend(_check || result);
				setIsConnected(false);
			}}
			onError={(error) => console.error(error)}
		/>
	);

	return (
		<Container fluid css={style}>
			<Row className='py-2'>
				<Col md='9' className="px-1">
					{form}
				</Col>
				<Col xs={6} md='2' className="px-1">
					{button}
				</Col>
				<Col xs={6} md="1" className="px-1">
					{microphoneButton}
				</Col>
			</Row>
			{asr}
		</Container>
	);
};

export default ChatForm;

function _spellChecker(env: string, domain: string, params: ISpellCheckParams): Promise<string | null> {
	return new Promise(async (resolve, reject) => {
		try {
			const { id, ...rest } = params;
			const url = `https://${domain}/api/loquista/spellchecker/check`;
			const requestId = `${Date.now()}`;
			const metadata = {
				uid: id,
				rid: requestId
			};
			const args = { metadata, ...rest };
			const options = {
				headers: {
					"Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}`
				}
			};
			const { data }: { data: ICheckResponse; } = await axios.post(url, args, options);
			resolve(data.texts.proposed);
		} catch (error) {
			const err = error as AxiosError;
			const { response } = err;
			console.error(`${response?.status || 500} - ${response?.data || 'Undefined error'}`);
			resolve(null);
		}
	});
}
