import React, { useState, useEffect, useRef, useContext } from 'react';

import axios from 'axios';

import CustomConfirm from '../../utilities/CustomConfirm';
import Loading from '../../pages/Loading';
import { DomainContext, EnvContext } from '../../../context/Context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

export interface IMetaData {
	name: string,
	email?: string;
}

type VoicePrint = {
	key: string;
	metadata: IMetaData;
	selected?: boolean;
};

const AdminSesame = () => {
	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	const isMounted = useRef<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [voicePrints, setVoicePrints] = useState<VoicePrint[]>([]);
	const [names, setNames] = useState<string[]>(['select']);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

	useEffect(() => {
		isMounted.current = true;
		const getVoicePrints = async () => {
			try {
				const response = await _setup(domain, env);
				if (response) {
					const { _names, _voicePrints } = response;

					isMounted.current && _names && setNames(_names);
					isMounted.current && _voicePrints && setVoicePrints(_voicePrints);
				}
				isMounted.current && setIsAllSelected(false);
				isMounted.current && setLoading(false);
			} catch (error) {
				console.error(error);
				isMounted.current && setIsAllSelected(false);
				isMounted.current && setLoading(false);
			}
		};

		voicePrints.length < 1 && getVoicePrints();

		return () => {
			isMounted.current = false;
		};
	});

	const form = (
		<Form.Group className="my-4">
			<Form.Control
				as="select"
				size="sm"
				defaultValue={'select'}
				onChange={(event) => {
					const _selected = event.target.value;
					const _voicePrints = voicePrints.filter(item => item.metadata.name === _selected);
					isMounted.current && setVoicePrints(_voicePrints);
					isMounted.current && setNames([_selected]);
				}}
			>
				{
					names.map((name, index) => (
						<option
							key={`${name}-${index}`}
							value={name}
						>
							{name}
						</option>
					))
				}
			</Form.Control>
			<div className="text-right mt-2">
				<Button
					size="sm"
					variant="info"
					className="ml-2"
					onClick={async () => {
						isMounted.current && setLoading(true);

						const response = await _setup(domain, env);
						if (response) {
							const { _names, _voicePrints } = response;

							isMounted.current && _names && setNames(_names);
							isMounted.current && _voicePrints && setVoicePrints(_voicePrints);
						}
						isMounted.current && setIsAllSelected(false);
						isMounted.current && setLoading(false);
					}}
				>
					{'Clear filters'}
				</Button>
			</div>
		</Form.Group>
	);

	const table = (
		<Table
			striped
			bordered
			hover
			className="text-center mt-3"
		>
			<thead>
				<tr className="text-uppercase">
					<th>#</th>
					<th className="text-left">voicePrint</th>
					<th className="text-left">metadata</th>
					<th>
						<Form>
							<Form.Group>
								<Form.Check
									type="checkbox"
									onChange={() => {
										const _voicePrints = voicePrints.map(item => {
											const { selected, ...rest } = item;
											return {
												selected: !isAllSelected ? true : false,
												...rest
											};
										});

										isMounted.current && setIsAllSelected(!isAllSelected);
										isMounted.current && setVoicePrints(_voicePrints);
									}}
								/>
							</Form.Group>
						</Form>
					</th>
				</tr>
			</thead>
			<tbody>
				{voicePrints.map((item: VoicePrint, key: number) => (
					<tr key={key}>
						<td className="font-italic font-weight-bold">
							{key + 1}
						</td>
						<td className="text-left">
							<pre>
								<code>
									{item.key}
								</code>
							</pre>
						</td>
						<td className="text-left">
							{
								item.metadata && (
									<ul className="list-unstyled mb-0">
										{item.metadata.name && (
											<li>
												<span className={item.metadata.name !== 'undefined' ? "font-weight-bold" : "font-italic text-danger"}>
													{item.metadata.name}
												</span>
											</li>
										)}
										{item.metadata.email && (
											<li>
												<span className="font-italic">
													{item.metadata.email}
												</span>
											</li>
										)}
									</ul>
								)
							}
						</td>
						<td>
							<Form>
								<Form.Group>
									<Form.Check
										type="checkbox"
										checked={item.selected}
										onChange={() => {
											const _voicePrints = voicePrints.map(el => {
												const { selected, ...rest } = el;
												if (el.key === item.key) return { selected: !selected, ...rest };
												else return el;
											});
											isMounted.current && setVoicePrints(_voicePrints);
										}}
									/>
								</Form.Group>
							</Form>

						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);

	const dangerForm = (
		<div className="text-right mb-4">
			<Button
				size="sm"
				variant="danger"
				disabled={voicePrints.filter(vp => vp.selected).length < 1}
				onClick={() => setShowModal(!showModal)}
			>
				<FontAwesomeIcon icon={faTrashAlt} />
				<span className="ml-2">
					{`delete`}
				</span>
			</Button>
		</div>
	);

	return (
		loading ? (
			<Loading />
		) : (
			<Container fluid>
				{
					showModal && (
						<CustomConfirm
							title="Remove selected items"
							message={
								`Are you sure you want to permanently 
								remove ${voicePrints.filter(vp => vp.selected).length} item(s)?`
							}
							show={showModal}
							onResult={async (res: boolean) => {
								setShowModal(!showModal);
								if (res) {
									try {
										isMounted.current && setLoading(true);
										const selectedVoicePrints = voicePrints.filter(vp => vp.selected);
										const _deletedKeys = await _removeVoicePrints(domain, env, selectedVoicePrints.map(vp => vp.key));
										const _voicePrints = voicePrints.filter(vp => !_deletedKeys.includes(vp.key));

										isMounted.current && _voicePrints && setVoicePrints(_voicePrints);
										isMounted.current && setIsAllSelected(false);
										isMounted.current && setLoading(false);
									} catch (error) {
										console.error(error);

										const response = await _setup(domain, env);
										if (response) {
											const { _names, _voicePrints } = response;

											isMounted.current && _names && setNames(_names);
											isMounted.current && _voicePrints && setVoicePrints(_voicePrints);
										}
										isMounted.current && setIsAllSelected(false);
										isMounted.current && setLoading(false);
									}
								}
							}}
						/>
					)
				}
				<Row>
					<Col>
						{form}
					</Col>
				</Row>
				<Row>
					<Col>
						{table}
					</Col>
				</Row>
				<Row>
					<Col>
						{dangerForm}
					</Col>
				</Row>
			</Container>
		)

	);
};

export default AdminSesame;

async function _setup(domain: string, env: string) {
	const { data } = await _getVoicePrints(domain, env);
	if (!data) return null;
	const metadata: IMetaData[] = await Promise.all(data.map((item: string) => checkVoicePrint(domain, env, item)));

	// names
	let _names = metadata.map(item => item.name);
	_names = _names.filter(name => name !== 'undefined');
	_names = ['select', 'undefined', ...new Set(_names)];

	const _voicePrints: VoicePrint[] = data.map((item: string, index: number) => ({
		key: item,
		metadata: metadata[index],
		selected: false
	}));

	return { _names, _voicePrints };
}

function _getVoicePrints(domain: string, env: string): Promise<any> {
	return new Promise(async (resolve, reject) => {
		try {
			const api = `https://${domain}/api/sesame/voiceprints`;
			const options = { headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` } };
			const result = await axios.get(api, options);
			resolve(result);
		} catch (error) {
			reject(error);
		}
	});
}

export function checkVoicePrint(domain: string, env: string, key: string): Promise<IMetaData> {
	return new Promise(async (resolve) => {
		try {
			const api = `https://${domain}/api/sesame/voiceid/check/${key}`;
			const options = { headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` } };
			const { data } = await axios.get(api, options);
			resolve(data);
		} catch (error) {
			const noName = 'undefined';
			if (axios.isAxiosError(error)) {
				if (error.response) {
					if (error.response.status === 404) resolve({ name: noName });
					else resolve({ name: noName });
				} else resolve({ name: noName });
			} else resolve({ name: noName });
		}
	});
}

function _removeVoicePrints(domain: string, env: string, keys: string[]): Promise<string[]> {
	return new Promise(async (resolve, reject) => {
		try {
			const api = `https://${domain}/api/sesame/voiceid/delete`;
			const options = { headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` } };
			const { data }: { data: string[]; } = await axios.post(api, { keys }, options);
			console.log('Deleted keys', data);
			resolve(data);
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				reject(`${error.response.status} - ${error.message}`);
			} else reject('An error occurred while removing data');
		}
	});
}