import React, { useContext, useState, useRef, useEffect } from 'react';

import { LoginContext, DomainContext, EnvContext } from '../../context/Context';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";



import axios from 'axios';

import Title from '../Title';
import SubTitle from '../SubTitle';
import Description from '../Description';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import MicrosoftLogin from "react-microsoft-login";
import { Redirect } from 'react-router';

interface IProps {
  app?: string;
  description?: string;
}

const msOAUTH = '7a566c2d-ca5b-4c51-99d4-5f138feee3d1'; // Microsoft OAUTH ClientId
const developerMail = 'emartinez@dialogagroup.com'; // Development mail for testing

const Login = ({ app, description }: IProps) => {
  const domain = useContext(DomainContext);
  const { setLogged } = useContext(LoginContext);
  const env = useContext(EnvContext);

  const isMounted = useRef<boolean>(false);

  const [isUnauthorized, setUnauthorized] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);

  const utopiaAuthenticationAPI = `https://${domain}/login/authentication/utopia`;
  const authAPIMsal = `https://${domain}/login/authentication/eci`;
  const authAPIMsalRe = `https://${domain}/azure/authenticate`;

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  // ms authentication
  const loginHandler = async (error: any, data: any) => {
    if (error) {
      console.error('Invalid user (MS)');
      if (isMounted.current) setUnauthorized(true);
    } else if (data) {
      const email = data.account.userName;
      try {
        await axios.post(authAPIMsal, { email });
        if (isMounted.current) setLogged(email);
      } catch (err) {
        const { error, details } = err;
        console.error(error, details);
        console.error(`Invalid user: ${email}`);
        if (isMounted.current) setUnauthorized(true);
      }
    }
  };

  // authentication
  const authenticate = async (email: string) => {
    try {
      const { data: { token, success } } = await axios.post(utopiaAuthenticationAPI, { email });
      if (success) {
        localStorage.setItem(`${env}-token`, token);
        setLogged(email);
      } else {
        setLogged(false);
        console.error(`User not found: ${email}`);
        setUnauthorized(true);
      }
    } catch (error) {
      console.error(`Invalid user: ${email}`);
      setUnauthorized(true);
    }
  };

  // google authentication
  const authenticateGoogle = async (response: any) => {
    const credential = jwt_decode(response.credential);
    const email = credential.email;

      authenticate(email);
  };

  const onFailure = (fail: any) => {
    console.warn('Login: on failure,', fail);
    setError(true);
  };

  const devLogin = (
    <Button variant='info' onClick={() => authenticate(developerMail)}>
      Dev login
    </Button>
  );

  const googleLogin = (
    <GoogleLogin buttonText="Sign in with Google"
      theme="filled_blue" width={215}
      onSuccess={authenticateGoogle} onFailure={onFailure} />
  );

  const msLogin = (
    <MicrosoftLogin clientId={msOAUTH} buttonTheme="dark"
      authCallback={loginHandler} redirectUri={authAPIMsalRe} />
  );

  return (
    <Container fluid>
      {app ? null : <Title title="Utopia" />}
      {app ? <SubTitle name={app || 'utopia'} /> : null}
      <Description text={description ? description : window.location.pathname} />
      <Container>
        {env === 'dev' ? <Row><Col className="my-2">{devLogin}</Col></Row> : null}
        {env !== 'dev' ? <Row><Col className="my-2">{googleLogin}</Col></Row> : null}
        {env !== 'dev' ? <Row><Col className="my-2">{msLogin}</Col></Row> : null}
      </Container>
      {isUnauthorized ? <Redirect to="/unauthorized" /> : null}
      {isError ? <Redirect to="/error" /> : null}
    </Container>
  );
};

export default Login;


