/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import React, { useState } from 'react';

// import { DomainContext } from '../../../../context/Context';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useGetVideos, IVideo } from './useGetVideos';
import { Button } from 'react-bootstrap';

interface IProps {
	user?: string;
	selected?: string;
	list?: boolean;
	onSelect?: (video: IVideo) => void;
}

const VideosPanel = (props: IProps) => {
	// const domain = useContext(DomainContext);

	const { user, selected, list, ...rest } = props; // props
	const { onSelect } = rest; // events

	const [show, setShow] = useState<boolean>(true);
	const [showList, setShowList] = useState<boolean>(false);

	const publicVideos = useGetVideos();
	const privateVideos = useGetVideos(user ?? 'list');

	const videos: IVideo[] = [...publicVideos, ...privateVideos];

	const breakpoints = [576, 768, 992, 1200];
	const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`);

	const fadeIn = keyframes`
	from {opacity: 0;}
	to {opacity: 1;}
	`;

	const fadeOut = keyframes`
	from {opacity: 1;}
	to {opacity: 0;}
	`;

	const styleIn = css`
	animation: ${fadeIn} 0.4s ease;
	opacity: 1;
	`;

	const styleOut = css`
animation: ${fadeOut} 0.2s linear;
opacity: 0;
`;

	const videoWrapperStyle = css`
	display: inline-block;
	position:relative;
	width: 100%;
	height: 12rem;
	overflow: hidden;
	cursor: pointer;
	&.selected {
		box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 2px;
	}
	${mq[0]} { height: 10rem;}
	${mq[1]} { height: 8rem;}
	${mq[2]} { height: 6.25rem;}
	${mq[3]} { height: 5.5rem;}
	div {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		padding: 0.3rem;
		justify-content: center;
		align-items: center;
		// margin:auto;
		text-align: center;
		word-break: break-all;
		color: #f5f5f5;
		background: rgba(100, 100, 100, 0.6);
		p {
			margin: 0;
			transition: margin 0.2s ease;
		}
		&:hover, &:focus {
			p {
				margin: 0 0 1rem;
			}
			
		};
	}
	.vision-mimic-media-item {
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: block;
		margin:auto;
	}
	`;

	const listStyle = css`
	font-size: 0.8em;
	padding: 1em 2em;
	`;

	return (
		<React.Fragment>
			{list && (
				<Row>
					<Col className="px-2">
						<Button
							block
							onClick={() => setShowList(!showList)}
						>
							List videos
						</Button>
					</Col>
				</Row>
			)}
			{showList && (
				<Row>
					<Col className="px-2">
						<table className="table table-sm table-responsive" css={listStyle}>
							<thead>
								<tr>
									<th>name</th>
									<th>url</th>
								</tr>
							</thead>
							<tbody>
								{
									videos.map((video, key) => (
										<tr key={`list-${video.name.toLowerCase()}-${key}`}>
											<th>{video.name.replace(/.mp4/g, '')}</th>
											<td>{video.key}</td>
										</tr>
									))
								}
							</tbody>
						</table>
					</Col>
				</Row>
			)}
			{(videos.length > 2) && <Container fluid css={show ? styleIn : styleOut}>
				<Row>
					<Col className="text-center">
						<div className="my-2 px-2">
							{
								onSelect && (
									<Button block
										size='sm'
										variant="light"
										onClick={() => {
											setShow(!show);
											setTimeout(() => {
												const randomIndex = Math.floor(Math.random() * videos.length);
												const defaultVideo = selected ? videos.find(video => video.name === selected) : videos[randomIndex];
												onSelect && onSelect(defaultVideo || videos[randomIndex]);
											}, 200);
										}}>
										Cancel
									</Button>
								)
							}
						</div>
					</Col>
				</Row>
				<Row>
					{
						videos.map((video, key) => (
							<Col
								key={`${video.name.toLowerCase()}-${key}`}
								className="px-2"
								xs={12} sm={6} md={4} lg={3} xl={2}>
								<div className={(selected && video.name === selected) ? 'selected' : ''} css={videoWrapperStyle} onClick={() => {
									setShow(!show);
									// onSelect(`${video}`);
									setTimeout(() => { onSelect && onSelect(video); }, 200);
								}}>
									<video className="vision-mimic-media-item"
										src={video.origin === 'source' ?
											`https://dialoga-machine-learning.s3.amazonaws.com/mimic/${video.origin}/${video.name}` :
											`https://dialoga-machine-learning.s3.amazonaws.com/mimic/videos/${user}/${video.name}`} />
									<div>
										<p>{video.name.replace('.mp4', '')}</p>
									</div>
								</div>
							</Col>
						))
					}
				</Row>
			</Container>}
		</React.Fragment>

	);
};

export default VideosPanel;

// src={`https://dialoga-machine-learning.s3.amazonaws.com/mimic/${video.origin}/${video.name}`}

// 									<img className="vision-mimic-media-item" src={`https://${domain}/static/media/logo-utopia-115x115.1c413246.svg`} alt={video.name.replace('.mp4', '').toLowerCase()} />

