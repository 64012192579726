import React, { useState } from 'react';

import * as helpers from '../../utils/helpers';
import Form from 'react-bootstrap/Form';

interface IProps {
  name: string;
  label?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  onChange: (value: number) => void;
}

const CustomRange = ({
  name,
  label,
  disabled,
  min,
  max,
  step,
  value,
  units,
  onChange,
}: IProps) => {
  const labelComp = label ? <Form.Label>{helpers.capitalizeFirstLetter(label)}</Form.Label> : null;

  const [val, setVal] = useState<number>(value || 0);

  return (
    <Form.Group controlId={name}>
      <Form.Label>
        {labelComp}:{' '}
        <span className='text-muted font-weight-light"'>{val}{units}</span>
      </Form.Label>
      <Form.Control
        type="range"
        size="sm"
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        value={val}
        onChange={(event) => {
          const val = parseFloat(event.target.value);
          setVal(val);
          onChange(val);
        }}
      />
    </Form.Group>
  );
};

export default CustomRange;
