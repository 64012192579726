import React, { useState, useRef, Fragment } from "react";
import { AppContext } from "../contexts/appContext";
import emptyRefImage from '../resources/empty_ref_image.jpg';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const BackgroundImage = () => {
  const { setBackgroundImage } = AppContext();
  const image_ref = useRef(undefined);

  function blobToBase64(blob) {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
      });
  }

  const handleChange = async (e) => {
    if (e.target.files[0]) {
      const blob = e.target.files[0];
      image_ref.current.src = await blobToBase64(blob);
      image_ref.current.height = 100;
      setBackgroundImage(image_ref.current.src);
    }
  };

  return (
    <>
      <Col>
        <Form>
            <Form.File
                id="face-replacement-image"
                className="form-control-sm"
                accept={'image'}
                label={'Choose_ image'}
                onChange={handleChange}
            >
            </Form.File>
        </Form>
      </Col>
      <Col>
        <img ref={image_ref} src={emptyRefImage} />
      </Col>
    </>
  );
};

export default BackgroundImage;
