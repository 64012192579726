function rounded_rect(ctx: CanvasRenderingContext2D,
	left: number,
	top: number,
	width: number,
	height: number,
	radius: number) {
	const K = (4 * (Math.SQRT2 - 1)) / 3; //constant for circles using Bezier curve.

	const right = left + width;
	const bottom = top + height;

	ctx.beginPath();
	// top left
	ctx.moveTo(left + radius, top);
	// top right
	ctx.lineTo(right - radius, top);
	//right top
	ctx.bezierCurveTo(
		right + radius * (K - 1),
		top,
		right,
		top + radius * (1 - K),
		right,
		top + radius
	);
	//right bottom
	ctx.lineTo(right, bottom - radius);
	//bottom right
	ctx.bezierCurveTo(
		right,
		bottom + radius * (K - 1),
		right + radius * (K - 1),
		bottom,
		right - radius,
		bottom
	);
	//bottom left
	ctx.lineTo(left + radius, bottom);
	//left bottom
	ctx.bezierCurveTo(
		left + radius * (1 - K),
		bottom,
		left,
		bottom + radius * (K - 1),
		left,
		bottom - radius
	);
	//left top
	ctx.lineTo(left, top + radius);
	//top left again
	ctx.bezierCurveTo(
		left,
		top + radius * (1 - K),
		left + radius * (1 - K),
		top,
		left + radius,
		top
	);
	ctx.lineWidth = 2;
	ctx.strokeStyle = 'rgb(62,152,199)';
	ctx.stroke();
}

export {
	rounded_rect
};