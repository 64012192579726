import React from 'react';
import { Helmet } from 'react-helmet';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../Title';
import OurProducts from './OurProducts';
import { ISection } from '../../hooks/useGetSections';

interface IProps {
  sections: ISection[];
}

const About = ({ sections }: IProps) => {
  const title = 'About us';
  const utopiaAiClass = 'font-weight-bold text-dark bg-light px-2 rounded';

  return (
    <Container fluid>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Title title={title} />
      <Container fluid>
        <Row>
          <Col xs={12}>
            <p>
              <strong>Utopia Thinking Systems</strong> develops AI solutions
              that are disrupting customer care and contact systems by means of
              Deep Learning usage (<em>Deep Neural Networks</em>).
            </p>
            <p>
              <span className={utopiaAiClass}>
                utopia.AI
              </span>{' '}
              is changing the way commercial liaisons between clients and
              providers are handled allocating value to each second of human
              work.
            </p>
            <p>
              Traditional customer relationship management (CRM) and contact
              centers gain added value and improved efficiency through the
              optimisation of the work carried out by call center agents. Our
              portfolio of services transforms an agent’s role to that of
              exclusively supervision and correction.
            </p>
            <p>
              Neural networks trained by{' '}
              <span className={utopiaAiClass}>
                utopia.AI
              </span>{' '}
              for the processing of natural languages and emotions develop
              personalised products, specific to the sector and corporate
              profile of each organisation.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
      </Container>
      {sections.length > 0 ? <OurProducts sections={sections} /> : null}
    </Container>
  );
};

export default About;
