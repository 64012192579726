import React, { useState, useEffect, useRef, useContext } from 'react';
import { Helmet } from 'react-helmet';

import { DomainContext } from '../../../../context/Context';
import * as requests from '../../../../requests/requests';
// import * as audioTools from '../../../../utils/audio';

import Loading from '../../../pages/Loading';
import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import ErrorBoundary, { IErrorProps } from '../../../ErrorBoundary';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import VoiceTranslatorForm, { IData } from './VoiceTranslatorForm';
import SpeechToTextConn from '../../loquista/asr/SpeechToTextConn';
import { playAudio } from '../tts/TextToSpeech';
import TextToSpeechConn from '../tts/TextToSpeechConn';

interface ITranscription {
  transcription: string;
  is_final: boolean;
  timestamp: string;
  transcript_aux: string;
}

interface IProps {
  title: string;
  description?: string;
}

const asrConntApi = `api/loquista/asr/connect`;
const translateApi = `api/loquista/translate-text/translate`;
const ttsProcessApi = `api/loquista/tts/process`;
// const downloadApi = `api/loquista/tts/download`;

const VoiceTranslator = ({ title, description }: IProps) => {
  const domain = useContext(DomainContext);

  const isMounted = useRef<boolean>(false);
  const paramsRef = useRef<IData | null>(null);
  const uuidRef = useRef<string>('');
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioCtxRef = useRef<AudioContext | null>(null);
  // const sourceRef = useRef<AudioBufferSourceNode | null>(null);
  // const streamRef = useRef<AudioBufferSourceNode[]>([]);
  // const streamIndex = useRef<number>(0);
  // const isAudioWaiting = useRef<boolean>(false);
  const fileNameRef = useRef<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [boundError, setBoundError] = useState<IErrorProps | null>(null);

  const [asrUuid, setASRUuid] = useState<string>('');
  const [ttsUuid, setTTSUuid] = useState<string>('');
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [asrOptions, setAsrOptions] = useState([
    // { name: 'use_nlp', value: true },
    { name: 'use_enhanced', value: true },
    { name: 'use_gpu', value: true },
    { name: 'use_provisional_transcription', value: true },
  ]);

  const [originalText, setOriginalText] = useState<string>('');
  const [translatedSentence, setTranslatedSentence] = useState<string>('');
  const [translatedText, setTranslatedText] = useState<string>('');
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const start = (params: IData) => {
    if (!isConnected) {
      const { asrHost, inputLanguage, formatted } = params;

      const options = asrOptions.map((op) => {
        if (op.name === 'use_nlp') return { name: 'use_nlp', value: formatted };
        else return op;
      });

      requests
        .asrConnect(`https://${domain}/${asrConntApi}`, {
          host: asrHost,
          language: inputLanguage,
          nlp: formatted ? 'GENERAL' : 'NONE',
          options: asrOptions,
        })
        .then((uuid) => {
          if (isMounted.current) {
            setASRUuid(uuid);
            setAsrOptions(options);
            setIsConnected(true);
            // setParams(params);
            paramsRef.current = params;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      if (isMounted.current) {
        setBoundError(null);
        setTranslatedSentence('');
        setIsConnected(false);
        setIsCompleted(false);
      }
    }
  };

  const translateSentence = (sentence: string) => {
    if (paramsRef.current) {
      const possibleCobms = paramsRef.current.combinations;

      const inputLang = paramsRef.current.inputLanguage
        .split('-')[0]
        .toUpperCase();

      const outputLang = paramsRef.current.outputLanguage
        .split('-')[0]
        .toUpperCase();

      const success = possibleCobms.filter(
        (item) =>
          item.input === paramsRef.current!.inputLanguage &&
          item.output === paramsRef.current!.outputLanguage
      );

      if (success.length) {
        requests
          .translate(`https://${domain}/${translateApi}`, {
            uuid: asrUuid,
            host: paramsRef.current.nmtHost,
            code: `${inputLang}_2_${outputLang}`,
            text: sentence,
          })
          .then((response) => {
            const { output_sentence, text_truncated } = response; // output_sentence, score, text_truncated
            if (text_truncated) console.warn('Text truncated');
            if (isMounted.current && !text_truncated) {
              setTranslatedSentence(output_sentence);
            }
          })
          .catch((error) => console.error(error));
      } else {
        if (inputLang === outputLang) {
          if (isMounted.current) setTranslatedSentence(sentence);
        } else {
          if (isMounted.current) {
            const reason = `Translation from ${paramsRef.current!.inputLanguage
              } to ${paramsRef.current!.outputLanguage} is not implemented`;
            setBoundError({ title: 'Translation', description: reason });
          }
        }
      }
    } else console.error('Something went wrong');
  };

  const translateParagraph = (paragraph: string) => {
    if (paramsRef.current) {
      const possibleCobms = paramsRef.current.combinations;

      const inputLang = paramsRef.current.inputLanguage
        .split('-')[0]
        .toUpperCase();

      const outputLang = paramsRef.current.outputLanguage
        .split('-')[0]
        .toUpperCase();

      const success = possibleCobms.filter(
        (item) =>
          item.input === paramsRef.current!.inputLanguage &&
          item.output === paramsRef.current!.outputLanguage
      );

      if (success.length) {
        requests
          .translate(`https://${domain}/${translateApi}`, {
            uuid: asrUuid,
            host: paramsRef.current.nmtHost,
            code: `${inputLang}_2_${outputLang}`,
            text: paragraph,
          })
          .then((response) => {
            const { output_sentence, text_truncated } = response; // output_sentence, score, text_truncated
            if (text_truncated) console.warn('Text truncated');
            if (isMounted.current && !text_truncated) {
              setIsCompleted(true);
              if (!text_truncated) {
                setTranslatedText(output_sentence);
                ttsConnect(output_sentence);
              } else {
                setIsBlocked(false);
              }
            }
          })
          .catch((error) => console.error(error));
      } else {
        if (inputLang === outputLang) {
          if (isMounted.current) {
            setIsCompleted(true);
            setTranslatedText(paragraph);
            ttsConnect(paragraph);
          }
        } else {
          if (isMounted.current) {
            const reason = `Translation from ${paramsRef.current!.inputLanguage
              } to ${paramsRef.current!.outputLanguage} is not implemented`;
            setBoundError({ title: 'Translation', description: reason });
          }
        }
      }
    } else console.error('Something went wrong');
  };

  const ttsConnect = (text: string) => {
    if (paramsRef.current) {
      if (!audioCtxRef.current || audioCtxRef.current.state === 'closed') {
        audioCtxRef.current = new (window.AudioContext ||
          (window as any).webkitAudioContext)();
      }

      if (audioCtxRef.current.state === 'suspended')
        audioCtxRef.current.resume();

      const params = {
        uuid: ttsUuid || uuidRef.current,
        host: paramsRef.current.ttsHost,
        language: paramsRef.current.outputLanguage,
        voice: paramsRef.current.voice,
        sampleRate: 22050,
        speakingRate: 1,
        text,
      };

      requests
        .ttsConnect(`https://${domain}/${ttsProcessApi}`, params)
        .then((audioFileName) => {
          if (isMounted.current) fileNameRef.current = audioFileName;
        })
        .catch((error) => {
          setBoundError({ title: 'Audio process', description: error });
        });
    } else {
      console.error('something went wrong');
    }
  };

  const loading = <Loading />;

  const form = (
    <VoiceTranslatorForm
      className={isLoading ? 'd-none' : ''}
      disabled={isConnected}
      gotParams={() => {
        if (isMounted.current) setIsLoading(false);
      }}
      onError={(error) => console.error(error)}
      onConnect={(data) => {
        start(data);
        setIsBlocked(false);
      }}
    />
  );

  const ttsConnComponent = (
    <TextToSpeechConn
      onConnected={(value: boolean) => {
        if (value) console.log('TTS connected');
      }}
      onError={(error) => {
        if (isMounted.current) {
          console.log('TTS disconnected');
          setBoundError({
            title: 'TTS Connection',
            description: error.message,
          });
        }
      }}
      gotConnId={(id: string) => {
        if (isMounted.current) {
          setTTSUuid(id);
          uuidRef.current = id;
        }
      }}
      gotAudio={(audio, index, blocks, rate) =>
        // audioProcess(audio, index, blocks, rate)
        audioCtxRef.current && playAudio(audio, index, blocks, rate, audioCtxRef.current)
      }
    />
  );

  const asrConnComponent = (
    <SpeechToTextConn
      connId={asrUuid}
      hiddenTemporal={false}
      hiddenHistory={true}
      hiddenAudio={true}
      blocked={isBlocked}
      onConnected={(value: boolean) => {
        if (isMounted.current) {
          setTranslatedSentence('');
          setIsCompleted(false);
          setBoundError(null);
        }
      }}
      onError={(error) => {
        if (isMounted.current) {
          setBoundError({ title: 'Connection', description: error.message });
        }
      }}
      gotResult={(result: string) => {
        console.log('result', result);
        setIsCompleted(false);
        translateSentence(result);
      }}
      gotResults={(results) => {
        const sentences: string[] = results.map((item: string) => {
          const { transcription, is_final }: ITranscription = JSON.parse(item);
          const firstChar = transcription.charAt(0).toUpperCase();
          const rest = transcription.slice(1);
          if (is_final) return firstChar + rest;
          else return '';
        });

        const text = sentences.filter((item) => item).join('. ');

        if (isMounted.current) {
          setTranslatedSentence('');
          setOriginalText(text);
          setIsBlocked(true);
        }

        translateParagraph(text);
      }}
    />
  );

  const asrContent = isConnected ? asrConnComponent : null;

  const result =
    isConnected &&
      !isCompleted &&
      paramsRef.current &&
      paramsRef.current.inputLanguage.split('-')[0].toUpperCase() !==
      paramsRef.current.outputLanguage.split('-')[0].toUpperCase() ? (
      <Container fluid>
        <Row>
          <Col>
            <p
              className={`text-center font-weight-light ${translatedSentence ? 'text-muted font-italic my-2' : ''
                }`}
            >
              {translatedSentence ? translatedSentence : 'working...'}
            </p>
          </Col>
        </Row>
      </Container>
    ) : null;

  const results =
    isConnected && isCompleted ? (
      <Container fluid>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col xs={12} sm={6} className="text-secondary">
            <h6>Original text</h6>
            <p>{originalText}</p>
          </Col>
          {paramsRef.current &&
            paramsRef.current.inputLanguage.split('-')[0].toUpperCase() !==
            paramsRef.current.outputLanguage.split('-')[0].toUpperCase() ? (
            <Col xs={12} sm={6} className="text-primary font-italic">
              <h6>Translation</h6>
              <p>{translatedText}</p>
            </Col>
          ) : null}
        </Row>
      </Container>
    ) : null;

  const audioResult =
    isConnected && isCompleted && !isLoading ? (
      <Container fluid>
        <Row>
          <Col>
            <div className="text-center my-2">
              <audio ref={audioRef} controls />
            </div>
          </Col>
        </Row>
      </Container>
    ) : null;

  const errorBoundary = boundError ? (
    <ErrorBoundary
      title={boundError?.title || 'Error'}
      description={boundError?.description ? boundError.description : ''}
      reason={boundError?.reason ? boundError.reason : ''}
    />
  ) : null;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description ?? 'Utopia app'} />
      </Helmet>
      <SubTitle name={title} />
      <Description text={description ? description : 'Utopia.AI'} />
      {isLoading ? loading : null}
      {ttsConnComponent}
      {form}
      {asrContent}
      {result}
      {results}
      {audioResult}
      {errorBoundary}
    </>
  );
};

export default VoiceTranslator;
