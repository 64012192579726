import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export type CustomMessageStyle = 'title' | 'light';

interface IProps {
  message: string;
  messageStyle?: CustomMessageStyle;
}

const CustomMessage = ({ message, messageStyle }: IProps) => {

  const getMessageItem = () => {
    if (messageStyle) {
      switch (messageStyle) {
        case 'light':
          return (
            <p className="text-center p-2 font-weight-light text-secondary">
              {message}
            </p>);
        case 'title':
          return (
            <h4 className="text-muted font-italic">{message}</h4>
          );
        default:
          return (
            <h4 className="text-muted font-italic">{message}</h4>
          );
      }
    } else {
      return (<h4 className="text-muted font-italic">{message}</h4>);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {getMessageItem()}
        </Col>
      </Row>
    </Container>
  );
};

export default CustomMessage;
