import { useRef, useState, useEffect } from 'react';

export const useBuffer = ({ itemsSize }) => {

    console.log('itemsSize:', itemsSize);

    const states = {
        IDLE: 'idle',
        BUFFERING: 'buffering',
        PLAYING: 'playing',
    };
    const items = useRef([]);
    const currentIndex = useRef(0);
    const [state, setState] = useState(states.BUFFERING);
    const [frame, setFrame] = useState();

    // // TODO
    // const fps = 20;

	// useEffect(() => {
    //     console.log('set interval');
    //     const interval = setInterval(() => {
    //         console.log(`INTERVAL:${new Date().getTime()}`);
    //         const item = getItem();
    //         // item && setResult(item);
    //         item && setFrame(item);
    //     }, 1000 / fps);
    // }, []);


	useEffect(() => {
        // console.log(`STATE__ ${state}`);
    }, [state]);

    function setItem(data) {
        // console.log('useBuffer: setItem');
        const item = { index: currentIndex.current, data: data };
        currentIndex.current++;
        items.current = [...items.current, item];
    }

    function getItem(_state) {

        // console.log(`useBuffer: getItem: items:${items.current.length}, currentIndex:${currentIndex.current}, state:${_state}`);
        let itemData;
        if(items.current.length <= 0) {
            return null;
        }

        if(_state === states.BUFFERING) {
            // console.log(`useBuffer: BUFFERING indexN:${items.current[items.current.length -1].index}, index0:${items.current[0].index}, state:${_state}`);
            if(items.current[items.current.length - 1].index > items.current[0].index + itemsSize) {
                // console.log(`useBuffer: setState ${states.PLAYING}`);
                setState(states.PLAYING);
                itemData = items.current.shift().data;
            }
        } else {
            // console.log(`useBuffer: PLAYING`);
            itemData = items.current.shift().data;
            if(items.current.length === 0) {
                setState(states.BUFFERING);
            }
        }

        return itemData;
    }



    //     console.log('useBuffer: getItem');
    //     const item = items.current[0];
    //     if(item) {
    //         console.log(`TRACE startItems:${startItems.current}, item.time:${item.time}, firstItemTime:${firstItemTime.current}`);
    //     }
    //     const now = new Date().getTime();
    //     if(firstItemTime.current && (now > firstItemTime.current + ms)) {
    //         startItems.current = true;
    //     }
    //     if(item && startItems.current) {
    //         items.current.shift();
    //         // console.log('time', item.time.getTime());
    //         console.log('useBuffer: RETURN item');
    //         return item.data;
    //     }
    // }

    return { setItem, getItem, state, frame };

    // console.log('useBuffer');

	// const [items, setItems] = useState([]);

    // function setItem(item) {
    //     console.log('useBuffer: setItem');
    //     setItems(item => [...items, item]);
    //     console.log('useBuffer: items:', items);
    // }

    // function getItem() {
    //     console.log('useBuffer: getItem');
    // }

    // return { setItem, getItem };

};
