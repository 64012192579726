import React, { useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Collapse from 'react-bootstrap/Collapse';
import { Codes } from '../pages/ErrorPage';

export type Variant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
	| 'light' | 'danger' | 'loquista' | 'sesame' | 'vision' | 'medium' | 'brain' | 'flow';

interface IProps {
	code: Codes;
	variant?: Variant;
}

const DismissibleErrorMsg = ({ code, variant }: IProps) => {
	const [show, setShow] = useState<boolean>(true);

	return (
		<Collapse in={show}>
			<Alert variant={variant ? variant : ''} dismissible transition={show}
				onClose={() => setShow(!show)} >
				<p>{`Code `}<span className="font-weight-bold">{code}</span>{displayError(code)}</p>
				<p>{`You can also check out other services in `}
					<Alert.Link href={`/products`}>our products page.</Alert.Link>
				</p>
			</Alert>
		</Collapse>
	);
};

export default DismissibleErrorMsg;

function displayError(code: Codes): string {
	let description = '';

	switch (code) {
		case 500: // INTERNAL
			description = 'Sorry, something went wrong, please try again';
			break;
		case 503: // UNAVAILABLE
			description = 'Sorry, the service you requested is not available at this time. Please, try again later.';
			break;
		default:
			description = 'Sorry, something went wrong, please try again';
			break;
	}

	return `: ${description}`;
}

/*

					<Fade in={show}>
						<Alert variant={variant ? variant : ''}
							onClose={() => setShow(!show)} dismissible>
							<Alert.Heading>Something went wrong.</Alert.Heading>
							<p>{`Code `}<span className="font-weight-bold">{code}</span>{displayError(code)}</p>
							<hr />
							<p>
								{`Go to `}
								<Alert.Link href={`/${currentPathName}`}>{`${currentPathName} apps page`}.</Alert.Link>
							</p>
							<p>
								{`Go to `}
								<Alert.Link href={`/`}>home</Alert.Link>
							</p>
						</Alert>
					</Fade>


*/