import { useEffect, useState, useContext } from 'react';
import axios from 'axios';

import { DomainContext } from '../../../context/Context';

export type AssistantType = 'text' | 'video';

export interface IAssistant {
	readonly _id: number;
	name: string;
	typename: AssistantType;
	model: string;
	language: string;
	active: string;
}

export const useGetAssistants = () => {
	const domain = useContext(DomainContext);

	const [assistants, setAssistants] = useState<IAssistant[]>([]);

	useEffect(() => {
		const getAssistants = async () => {
			try {
				const api = `https://${domain}/api/minerva/assistants`;
				const { data: { results } } = await axios.get(api);
                const orderedResults = results.sort((itemA, itemB) => itemA.name > itemB.name ? 1 : -1);
				setAssistants(orderedResults);
			} catch (error) {
				console.error(error);
			}
		};
		getAssistants();
		// eslint-disable-next-line
	}, []);

	return assistants;
};
