import React, { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';

interface IProps {
	forwardRef: React.RefObject<HTMLTextAreaElement> | null;
	minRows: number;
	maxRows: number;
	reset: boolean;
	disabled?: boolean;
	onSubmit: (text: string) => void;
	onChange: (text: string) => void;
}

type ChangeEvent = React.ChangeEvent<any>;

const CustomResizableTextArea = ({ forwardRef, minRows, maxRows, reset, disabled, onSubmit, onChange }: IProps) => {
	const [value, setValue] = useState<string>('');
	const [rowsState, setRowsState] = useState<number>(minRows);

	useEffect(() => {
		if (reset) setValue('');
	}, [reset]);

	const handleChange = (event: ChangeEvent) => {
		const textareaLineHeight = 24;
		const previousRows = event.target.rows;
		event.target.rows = minRows; // reset number of rows in textarea

		const currentRows = ~~(event.target.scrollHeight / textareaLineHeight); // ~~ === Math.flor()

		if (currentRows === previousRows) {
			event.target.rows = currentRows;
		}

		if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
		}

		const text = event.target.value;
		setValue(text);
		onChange(text);
		setRowsState(currentRows < maxRows ? currentRows : maxRows);
	};

	const onEnterPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();
			onSubmit(value);
			setValue('');
		}
	};

	return (
		<Form.Control
			ref={forwardRef}
			className="resizable-textarea"
			as='textarea'
			rows={rowsState}
			placeholder="Enter your text here..."
			disabled={disabled ?? false}
			onChange={handleChange}
			onKeyDown={onEnterPress}
			value={value}
		/>
	);
};

export default CustomResizableTextArea;