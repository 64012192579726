import React from 'react';
import { Helmet } from 'react-helmet';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../Title';
import solutions from '../../text/solutions.json';
import OurProducts from './OurProducts';
import { ISection } from '../../hooks/useGetSections';

interface IProps {
  sections: ISection[];
}

const Products = ({ sections }: IProps) => {
  const title = 'Products';

  return (
    <Container fluid>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Title title={title} />
      <Container fluid>
        <Row>
          <Col xs={12}>
            <h4>
              utopia.AI develops disrupting AI solutions for contact centers and
              customer care teams.
            </h4>
            <br />
            <ul className='utopia-products-solutions list-unstyled'>
              {
                solutions.map((solution: string, key: number) =>
                  <li key={key} className='my-2'>
                    {solution}
                  </li>
                )
              }
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
      </Container>
      {sections.length > 0 ? <OurProducts sections={sections} /> : null}
    </Container>
  );
};

export default Products;
