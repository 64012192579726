import { useState, useEffect, useMemo } from 'react';

import { Size } from '../context/Context';

const useScreenSize = () => {
	const limit: number = 992;
	const initialValue = window.screen.width >= limit ? 'large' : 'small';

	const [size, setSize] = useState<Size>(initialValue);

	const providerSize = useMemo(() => ({
		size
	}), [size]);

	useEffect(() => {
		const handleResize = () => {
			const screenWidth = window.screen.width;
			const screenSize: Size = screenWidth >= limit ? 'large' : 'small';
			setSize(screenSize);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		console.log(`%c${size} device`, 'color: blue');
	}, [size]);

	return providerSize;
};

export default useScreenSize;