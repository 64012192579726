import React, {
    createContext,
    useContext,
    useRef,
    useEffect,
    useState,
} from "react";

const VideoContext  = createContext();

export const AppContext = () => {
    const ret = useContext(VideoContext);
    return ret;
};

const VideoContextProvider = ({ children }) => {
    const [requestData, setRequestData] = useState("");
    const [responseData, setResponseData] = useState("");
    const [start, setStart] = useState(false);
    const videoEl = useRef(undefined);
    const websocketEl = useRef(undefined);
    const canvas_ref = useRef(undefined);
    const [refImage, setRefImage] = useState(null);
    const [image, setImage] = useState(null);
    const [imageData, setImageData] = useState(localStorage.getItem('vision-face-movement-image-data'));
	const [video, setVideo] = useState(localStorage.getItem('vision-face-movement-video') || "");


    useEffect(() => {
        if (!websocketEl) return;
        if (start && videoEl) {
            console.log('ws connecting...');
            websocketEl.current = new WebSocket("wss://apps-dev.utopia.ai/ws4011");

            websocketEl.current.onopen = () => {
                console.log("I am open");
                // TODO enviar ref_image
                const d = { image: null, uid: null, ref_image: refImage };
                websocketEl.current.send(JSON.stringify(d));
            };
            websocketEl.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('data');
                if(data?.image) {
                    setResponseData(data);
                }
            };
            websocketEl.current.onclose = () => {
                console.log("stream stopped");
            };
            websocketEl.current.onerror = (e) => {
                console.log(`Stream stopped due to error ${e}`);
            };

            return () => {
                websocketEl.current.close();
                websocketEl.current = null;
                console.log("Closed");
            };
        }
    }, [websocketEl, videoEl, start]);

    useEffect(() => {
        if (websocketEl.current && requestData.length > 0 && start) {
            const d = { image: requestData, uid: null, ref_image: null };
            websocketEl.current.send(JSON.stringify(d));
        }
    }, [requestData, start]);

    useEffect(() => {
        if (!videoEl) {
            return;
        }
        if (start) {
            videoEl.current.play();
        }
    }, [videoEl, start]);

    useEffect(() => {
        if (start === false) {
            if(videoEl && videoEl.current) {
                videoEl.current.pause();
            }
            websocketEl.current = undefined;
            canvas_ref.current = undefined;
            setRequestData("");
            setResponseData("");
        }
    }, [start]);

    useEffect(() => {
        localStorage.setItem('vision-face-movement-video', video);
    }, [video]);

    useEffect(() => {
        localStorage.setItem('vision-face-movement-image-data', imageData);
    }, [imageData]);

    return (
        <VideoContext.Provider
        value={{
            videoEl,
                canvas_ref,
                requestData,
                setRequestData,
                responseData,
                setResponseData,
                start,
                setStart,
                refImage,
                setRefImage,
                image,
                setImage,
                imageData,
                setImageData,
                video,
                setVideo,
        }}
    >
    {children}
    </VideoContext.Provider>
  );
};
export default VideoContextProvider;
