import React, { useContext, useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import { LoginContext, DomainContext, EnvContext } from '../../../../context/Context';
import * as helpers from '../../../../utils/helpers';
import * as requests from '../../../../requests/requests';

import VoiceGeneratorForm from './VoiceGeneratorForm';
import VoiceGeneratorStatistics from './VoiceGeneratorStatistics';
import VoiceGeneratorSentences from './VoiceGeneratorSentences';
import CustomRecorder from '../../../utilities/CustomRecorder';
import CustomFileLink from '../../../utilities/CustomFileLink';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import CustomPagination from '../../../utilities/CustomPagination';
import Loading from '../../../pages/Loading';

import useToast from '../../../../hooks/useToast';

interface IFileHref {
  audio: string;
  text: string;
}

interface IProps {
  title: string;
  description?: string;
}

const VoiceGenerator = ({ title, description }: IProps) => {
  const domain = useContext(DomainContext);
  const { logged, setLogged } = useContext(LoginContext);
  const env = useContext(EnvContext);
  const toast = useToast();
  
  const awsBucket = 'dialoga-machine-learning';
  const appFolder = `voice_generator${env.includes("prod") ? '' : `_test`}`;
  const s3 = `https://${awsBucket}.s3.eu-west-1.amazonaws.com/${appFolder}`;

  const isMounted = useRef<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [forceUpdateStatistics, setForceUpdateStatistics] = useState<boolean>(
    false
  );
  const [forceUpdateSentences, setForceUpdateSentences] = useState<boolean>(
    false
  );
  const [userName, setUserName] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [selectedModel, setSelectedModel] = useState<string>('TTS');
  const [sentences, setSentences] = useState<string[]>(['']); // sentences
  const [doneSentences, setDoneSentences] = useState<number[]>([]);
  const [totalSentences, setTotalSentences] = useState<number>(0);
  const [sentenceIndex, setSentenceIndex] = useState<number>(0);
  const [fileHref, setFileHref] = useState<IFileHref>({ audio: '', text: '' });
  const [updatedSentence, setUpdatedSentece] = useState<string>('');
  const [voiceName, setVoiceName] = useState<string>('');
  const [fileAction, setFileAction] = useState<string>('Upload file');
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [notFinishedVoices, setNotFinishedVoices] = useState<string[]>([]);

  const api = 'api/loquista/generate-voice';
  const fileDownloadUrl = `https://${domain}/${api}/file/download`;
  const fileUploadUrl = `https://${domain}/${api}/file/upload`;
  const fileTextEditUrl = `https://${domain}/${api}/file/edit`;
  const TTSClonServer = `https://${domain}/api/loquista/generate-voice/voice/create`;

  useEffect(() => {
    isMounted.current = true;

    if (logged && typeof logged === 'string') {
      const name = helpers.getUserNameByEmail(logged);
      if (isMounted.current) setUserName(name);
    } else {
      const token = localStorage.getItem(`${env}-token`);
      axios.post(`https://${domain}/login/authentication/user`, { token })
        .then(({ data: { email } }) => {
          const name = helpers.getUserNameByEmail(email);
          if (isMounted.current) {
            setLogged(email);
            setUserName(name);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedModel !== 'TTS') getNotFinishedVoices()
    // eslint-disable-next-line
  }, [selectedFile, selectedLanguage, selectedModel])

  useEffect(() => {
    if (selectedFile) {
      updateHref();
      const path = `source/${selectedLanguage}${selectedModel === 'TTS' ? '' : '/cloning'}`;
      requests
        .voiceGeneratorDownloadFile(fileDownloadUrl, {
          path,
          file: selectedFile,
        })
        .then((fileContent) => {
          const originalSentences: string[] = fileContent.split('\n').filter((sentence: string) => sentence !== '');
          if (isMounted.current) {
            setTotalSentences(originalSentences.length);
            setSentences(originalSentences);
          }
        })
        .catch((error) => console.error(error));
    }
    // eslint-disable-next-line
  }, [selectedFile]);

  useEffect(() => {
    if (totalSentences) {
      const first = helpers.getFirstKeyByDoneSentences(
        doneSentences,
        totalSentences
      );

      let currentIndex = 0;

      if (first - 1 >= 0) {
        if (first > totalSentences) currentIndex = totalSentences - 1;
        else currentIndex = first - 1;
      }

      if (isMounted.current) {
        console.log(`%cFirst undone index: ${currentIndex}`, 'color:blue');
        setSentenceIndex(currentIndex);
      }
    }
    // eslint-disable-next-line
  }, [doneSentences]);

  useEffect(() => {
    if (isMounted.current && selectedFile) {
      updateHref();
      const index = sentenceIndex + 1;
      const folder = helpers.removeExtFromFileName(selectedFile);
      const name = `${userName}.${folder}.${index}`;

      if (doneSentences.includes(index)) {
        requests
          .voiceGeneratorDownloadFile(fileDownloadUrl, {
            path: `${userName}/${selectedLanguage}/${folder}`,
            file: `${name}.txt`,
          })
          .then((fileContent) => {
            if (isMounted.current) {
              if (fileContent !== sentences[sentenceIndex]) {
                const newSentences = sentences;
                newSentences[sentenceIndex] = fileContent;
                setForceUpdateSentences(!forceUpdateSentences);
                setSentences(newSentences);
              }
            }
          })
          .catch((error) => console.error(error));
      }
    }
    // eslint-disable-next-line
  }, [sentenceIndex]);

  useEffect(() => {
    setForceUpdateStatistics(!forceUpdateStatistics);
    // eslint-disable-next-line
  }, [selectedModel, voiceName, selectedFile, voiceName])

  useEffect(() => {
    setForceUpdateSentences(!forceUpdateSentences);
    setForceUpdateStatistics(!forceUpdateStatistics);
    // eslint-disable-next-line
  }, [voiceName])

  const getNotFinishedVoices = async () => {
    const query = {
      user: userName,
      language: selectedLanguage,
      file: selectedFile
    }
    const q = new URLSearchParams(query).toString();
    const api = `https://${domain}/api/loquista/generate-voice/voices/notfinished?${q}`;
    const headers = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
    try {
      const { data } = await axios.get(api, headers);
      const voices = ['', ...data.voices]
      setNotFinishedVoices(voices);
    } catch (error) {
      setNotFinishedVoices([]);
    }
  }

  function updateHref () {
    const folder = helpers.removeExtFromFileName(selectedFile);
    const index = sentenceIndex + 1;
    const name = `${userName}.${folder}.${index}`;
    const href = selectedModel === 'TTS'
      ? `${s3}/${userName}/${selectedLanguage}/${folder}/${name}`
      : `${s3}/${userName}/${selectedLanguage}/${selectedModel}/${folder}/${voiceName}/${name}`;
    setFileHref({ audio: `${href}.wav`, text: `${href}.txt` });
  }

  const form = (
    <VoiceGeneratorForm
      disabled={isDisabled}
      notFinishedVoices={notFinishedVoices}
      onModelSelection={(model) => {
        if (isMounted.current) {
          setSelectedModel(model);
        }
      }}
      onFileSelection={(file) => {
        if (isMounted.current) setSelectedFile(file);
      }}
      onLanguageSelection={(lang) => {
        if (isMounted.current) setSelectedLanguage(lang);
      }}
      onVoiceNameCreated={(name) => {
        if (isMounted.current) {
          setVoiceName(name);
          updateHref();
        }
      }}
      onUploadFile={(file) => {
        if (!isMounted.current) return;
        setUploadFile(file);
      }}
      onFileActionSelection={(action) => {
        if (isMounted.current) {
          setUploadFile(null);
          setFileAction(action);
        }
      }}
    />
  );

  const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as ArrayBuffer);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  async function sendFile (file: File | null) {
    if (!file) return;
    setIsLoading(true);
    const fileArrayBuffer = await readFileAsArrayBuffer(file);
    const blob = new Blob([fileArrayBuffer], { type: file.type });
    const fd = new FormData();
    fd.append(voiceName, blob);
    fd.append('userName', userName);
    fd.append('fileName', selectedFile);
    fd.append('language', selectedLanguage);
    fd.append('model', selectedModel);
    fd.append('voiceName', voiceName);
    try {
      const reference_audio_url = await requests
        .voiceGeneratorUploadFile(fileUploadUrl, fd)
      
      const data = {
        reference_audio_url,
        speaker: voiceName
      }

      await requests.voiceGeneratorCreateVoice(TTSClonServer, data)
      toast({ type: 'success', message: 'Voice created' });
    } catch (error) {
      toast({ type: 'error', message: 'Error creating voice' });
    } finally {
      setUploadFile(null);
      setVoiceName('');
      setIsLoading(false);
    }
  }
  
  const statistics = (
    <VoiceGeneratorStatistics
      userName={userName}
      file={selectedFile}
      total={totalSentences}
      forceUpdate={forceUpdateStatistics}
      model={selectedModel}
      voiceName={voiceName}
      onDoneSentences={(recorderSentences) => {
        if (isMounted.current) setDoneSentences(recorderSentences);
      }}
    />
  );

  const instructions = (
    <div className="text-muted font-italic pt-2">
      <p>
        Press <span className="bg-primary text-light px-1 rounded">Record</span>{' '}
        button and read the next sentence. Click{' '}
        <span className="bg-primary text-light px-1 rounded">Stop</span> when
        finished.
      </p>
    </div>
  );

  const setenceUpdate = (
    <Form className="px-5">
      <Form.Group>
        <Form.Label>Sentence</Form.Label>
        <Form.Control
          type="text"
          defaultValue={sentences[sentenceIndex]}
          onChange={(event) => {
            if (isMounted.current) {
              setUpdatedSentece(event.target.value || sentences[sentenceIndex]);
            }
          }}
        />
        <Form.Text className="text-muted">
          Correct and save this sentence.
        </Form.Text>
      </Form.Group>
      <div className="text-right">
        <Button
          className="mr-2"
          variant="success"
          onClick={() => {
            if (isMounted.current) {
              setIsDisabled(false);

              const currentText = sentences[sentenceIndex];

              const folder = helpers.removeExtFromFileName(selectedFile);
              const index = sentenceIndex + 1;
              const name = `${userName}.${folder}.${index}`;
              const path = `${userName}/${selectedLanguage}/${folder}/${name}.txt`;
              const text = updatedSentence ? updatedSentence : currentText;

              const newSentences = sentences;
              newSentences[sentenceIndex] = text;
              setForceUpdateSentences(!forceUpdateSentences);
              setSentences(newSentences);

              const params = { path, text, name };

              requests
                .voiceGeneratorEditTextFile(fileTextEditUrl, params)
                .then(({ path }) => console.log(path))
                .catch((error) => console.error(error));
            }
          }}
        >
          Save
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            if (isMounted.current) setIsDisabled(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );

  const carousel = (
    <VoiceGeneratorSentences
      sentences={sentences}
      index={sentenceIndex}
      isRecording={isRecording}
      forceUpdate={forceUpdateSentences}
      onChange={(index) => {
        if (isMounted.current) setSentenceIndex(index);
      }}
      onClick={(sentence) => {
        if (isMounted.current) setIsDisabled(true);
      }}
    />
  );

  const sentenceComp = (
    <div className="rounded bg-dark text-light my-2 py-4">
      {isDisabled ? setenceUpdate : carousel}
    </div>
  );

  const pagination = (
    <div className="mt-3">
      <CustomPagination
        disabled={isDisabled}
        pages={sentences.length}
        index={sentenceIndex + 1}
        onChange={(value) => {
          if (isMounted.current) {
            setSentenceIndex(value - 1 > 0 ? value - 1 : 0);
          }
        }}
      />
    </div>
  );

  const checkVoiceName = async () => {
    const url = `https://${domain}/${api}/voices/${voiceName}`;
    const headers = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
    try {
      const { data: { validName } } = await axios.get(url, headers)
      if (validName) {
        toast({ type: 'success', message: 'Voice name is valid' });
        return true
      }
      toast({ type: 'error', message: 'Voice name already exists' });
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const handleCreateVoice = async () => {
    setIsLoading(true);
    const downloadAudiosUrl = `https://${domain}/api/loquista/generate-voice/audios/download`;
    const href = fileHref.audio
      .replace(`${s3}/`, '')
      .split('/')
    href.pop()
    const folder = href.join('/')
    try {
      const audioLocation = await requests.voiceGeneratorMergeAllAudios(
        downloadAudiosUrl,
        {
          path: folder,
          voiceName,
          model: selectedModel
        }
      )
      const data = {
        reference_audio_url: audioLocation,
        speaker: voiceName
      }
      await requests.voiceGeneratorCreateVoice(TTSClonServer, data)
      toast({ type: 'success', message: 'Voice created' });
    } catch (error) {
      toast({ type: 'error', message: 'Error creating voice' });
    } finally {
      setVoiceName('');
      setIsLoading(false);
    }
  };
  
  const blobHandler = async (blob: Blob) => {
    try {
      const index = sentenceIndex + 1;
      const fileName = helpers.removeExtFromFileName(selectedFile);
      const fileUrl = `${userName}.${fileName}.${index}`;
      const thisSentence = sentences[sentenceIndex];

      const fd = new FormData();
      fd.append(fileUrl, blob);
      fd.append('userName', userName);
      fd.append('sentence', thisSentence);
      fd.append('fileName', selectedFile);
      fd.append('language', selectedLanguage);
      if (selectedModel !== 'TTS') {
        fd.append('model', selectedModel);
        fd.append('voiceName', voiceName);
      }

      const path = await requests.voiceGeneratorUploadFile(fileUploadUrl, fd);

      if (isMounted.current) {
        setForceUpdateStatistics(!forceUpdateStatistics);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const recorder = (
    <CustomRecorder
      autoPlay={false}
      block={true}
      reset={true}
      disabled={isDisabled}
      variant="primary"
      onChange={(value) => {
        console.log(`%cIs recording? ${value}`, 'color: orange');
        if (isMounted.current) {
          setIsRecording(value);
        }
      }}
      gotBlob={blobHandler}
    />
  );

  const fileLink = doneSentences.includes(sentenceIndex + 1) ? (
    <div className="d-flex flex-row-reverse">
      <CustomFileLink type="text" href={fileHref.text} />
      <CustomFileLink type="audio" href={fileHref.audio} />
    </div>
  ) : (
    <div className="d-flex flex-row-reverse">
      <CustomFileLink />
      <CustomFileLink />
    </div>
  );

  const createVoiceButton = (
    <Row className='justify-content-center'>
      <Button
        onClick={handleCreateVoice}
        disabled={voiceName.trim() === ''}
      >
        Create voice
      </Button>
    </Row>
  )

  const sendFileButton = (
    <Row className='justify-content-center'>
      <Button
        onClick={async () => {
          const validVoiceName = await checkVoiceName()
          if (validVoiceName) sendFile(uploadFile)
          else toast({ type: 'error', message: 'Invalid voice name' })
        }}
        disabled={voiceName.trim() === ''}
      >
        Create voice
      </Button>
    </Row>
  )

  if (isLoading) return <Loading />

  const baseHTML = (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description ?? 'Voice generator'} />
      </Helmet>
      <SubTitle name={title} />
      <Description text={description ? description : 'Utopia.AI'} />
      {form}
    </>
  )
  
  if (selectedModel === 'TTS') {
    return (
      <>
        {baseHTML}
        {statistics}
        <Container fluid>
          <Row>
            <Col xs={9}>{instructions}</Col>
            <Col xs={3}>{fileLink}</Col>
          </Row>
          <Row>
            <Col>{sentenceComp}</Col>
          </Row>
          <Row>
            <Col>{pagination}</Col>
          </Row>
          <Row>
            <Col>{recorder}</Col>
          </Row>
          {doneSentences.length >= totalSentences && selectedModel !== 'TTS' && createVoiceButton}
        </Container>
      </>
    )
  }

  if (fileAction === 'Upload file') {
    return (
      <>
        {baseHTML}
        {voiceName !== '' && uploadFile != null ? sendFileButton : ''}
      </>
    )
  }
  
  return (
    <>
      {baseHTML}
      <div className={voiceName !== "" ? '' : 'd-none'}>
        {statistics}
        <Container fluid>
          <Row>
            <Col xs={9}>{instructions}</Col>
            <Col xs={3}>{fileLink}</Col>
          </Row>
          <Row>
            <Col>{sentenceComp}</Col>
          </Row>
          <Row>
            <Col>{pagination}</Col>
          </Row>
          <Row>
            <Col>{recorder}</Col>
          </Row>
          {doneSentences.length >= totalSentences && selectedModel !== 'TTS' && createVoiceButton}
        </Container>
      </div>
    </>
  )
};

export default VoiceGenerator;
