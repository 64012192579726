import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";

const VideoContext = createContext();

export const AppContext = () => {
  const ret = useContext(VideoContext);
  return ret;
};

const VideoContextProvider = ({ children }) => {
  const [requestData, setRequestData] = useState("");
  const [responseData, setResponseData] = useState("");
  const [start, setStart] = useState(false);
  const videoEl = useRef(undefined);
  const websocketEl = useRef(undefined);
  const canvas_ref = useRef(undefined);
  const [backgroundImage, setBackgroundImage] = useState(null);

  useEffect(() => {
    if (!websocketEl) return;
    if (start && videoEl) {
      // websocketEl.current = new WebSocket("ws://127.0.0.1:5010");
      websocketEl.current = new WebSocket("wss://apps-dev.utopia.ai/ws4009");

      websocketEl.current.onopen = () => {
        console.log("I am open");
        backgroundImage && websocketEl.current.send(backgroundImage);
      };
      websocketEl.current.onmessage = (event) => {
          const data = JSON.parse(event.data);
          console.log('data');
          if(data?.image) {
            setResponseData(data);
          }
      };
      websocketEl.current.onclose = () => {
        console.log("stream stopped");
      };
      websocketEl.current.onerror = (e) => {
        console.log(`Stream stopped due to error ${e}`);
      };

      return () => {
        websocketEl.current.close();
        websocketEl.current = null;
        console.log("Closed");
      };
    }
  }, [websocketEl, videoEl, start]);

  useEffect(() => {
    if (websocketEl.current && requestData.length > 0 && start) {
      websocketEl.current.send(requestData);
    }
  }, [requestData, start]);

  useEffect(() => {
    if (!videoEl) {
      return;
    }
    if (start) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
              width: {
                  exact: 400
              },
              height: {
                  exact: 300
              }
          }
        })
        .then((stream) => {
          videoEl.current.srcObject = stream;
          videoEl.current.play();
        });
    }
  }, [videoEl, start]);

  useEffect(() => {
    if (start === false) {
      videoEl.current = undefined;
      websocketEl.current = undefined;
      canvas_ref.current = undefined;
      setRequestData("");
      setResponseData("");
    }
  }, [start]);

  return (
    <VideoContext.Provider
      value={{
        videoEl,
        canvas_ref,
        requestData,
        setRequestData,
        responseData,
        setResponseData,
        start,
        setStart,
        backgroundImage,
        setBackgroundImage,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};
export default VideoContextProvider;
