import React, { useEffect, useState, useRef } from 'react';

import { Switch, Route } from 'react-router-dom';
import { LoginContext, ILoginContext } from '../context/Context';

import { IApp } from '../App';
import { ISection } from '../hooks/useGetSections';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import AdminPage from './pages/AdminPage';
import Loading from './pages/Loading';

import LandingPage from './pages/LandingPage';
import LoggedRoute from './pages/LoggedRoute';
import ErrorPage from './pages/ErrorPage';
import NotFound from './pages/NotFound';
import Unauthorized from './pages/Unauthorized';

interface IProps {
  sections: ISection[];
  apps: IApp[];
  gotError?: boolean;
  loginProvider: ILoginContext;
}

const Content = ({ sections, apps, gotError, loginProvider }: IProps) => {
  const isMounted = useRef<boolean>(false);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [knownRoutes, setKnownRoutes] = useState<string[]>([]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (apps && sections && apps.length > 0 && sections.length > 0) {
      const sectionRoutes = sections.map(section => `/${section.name}`);
      const appRoutes = apps.map(app => `/${app.section}/${app.path}`);
      const routesArray = sectionRoutes.concat(appRoutes);
      if (isMounted.current) setKnownRoutes(routesArray);
    }
  }, [apps, sections]);

  useEffect(() => {
    if (knownRoutes.length > 0 && isMounted.current) setLoading(false);
  }, [knownRoutes]);

  useEffect(() => {
    if (!isLoading) console.log('%cReady!!', 'color: green');
  }, [isLoading]);

  const routes = sections && sections.map((item: ISection, key: number) =>
    <Route key={key} path={`/${item.name}`}>
      <LandingPage sections={sections} apps={apps} name={item.alias} />
    </Route>
  );

  const loggedRoutes = (
    <LoginContext.Provider value={loginProvider}>
      {routes}
      <LoggedRoute path="/admin" apps={apps}
        component={() => <AdminPage apps={apps} sections={sections} />} />
    </LoginContext.Provider>
  );

  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/products">
        <Products sections={sections} />
      </Route>
      <Route exact path="/about">
        <About sections={sections} />
      </Route>
      <Route exact path="/error">
        <ErrorPage code={503} />
      </Route>
      {
        gotError ? (
          <Route exact path="*">
            <ErrorPage code={503} />
          </Route>
        ) : null
      }
      {
        isLoading || (loginProvider.logged === false) ?
          (
            <Route component={Loading} />
          ) :
          (
            <Route render={({ location }) => {
              if (knownRoutes.includes(location.pathname) || location.pathname === '/admin') return loggedRoutes;
              else if (location.pathname === '/unauthorized') return <Unauthorized />;
              else return <NotFound />;
            }} />
          )
      }

    </Switch>
  );
};

export default Content;
