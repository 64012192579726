import React from 'react';
import { Link } from 'react-router-dom';

import loquistaLogo from '../../resources/logo_loquista.svg';
import sesameLogo from '../../resources/logo_sesame.svg';
import utopiaLogo from '../../resources/logo-utopia-115x115.svg';
// import brainLogo from '../../resources/logo_brain.svg';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { ISection } from '../../hooks/useGetSections';

interface IProps {
	sections: ISection[];
}

const OurProducts = ({ sections }: IProps) => {
	return (
		<Container fluid>
			<Row>
				<Col>
					<h4>Check our products</h4>
				</Col>
			</Row>
			<Row>
				{
					sections.map((item, key) => (
						<Col key={key} xs={12} md={key < 2 ? 6 : 4} className={`p-1 product-${key}`}>
							{makeProductCard(item)}
						</Col>
					))
				}
			</Row>
		</Container>
	);
};

export default OurProducts;

function makeProductCard(section: ISection): ReturnType<Link<void>> {
	const linkClass = 'utopia-products-links';
	const cardClass = 'utopia-products-cards border-0 bg-light text-center pt-4';
	const cardImgClass = 'img-fluid p-4 mx-auto d-block';

	return (
		<Link to={`/${section.name}`} className={linkClass}>
			<Card className={cardClass}>
				<Card.Img variant="top" className={cardImgClass}
					src={getLogoSrcBySection(section.name)}
				/>
				<Card.Body>
					<Card.Title>{section.alias}</Card.Title>
					<Card.Text>
						{section.description}
					</Card.Text>
				</Card.Body>
			</Card>
		</Link>
	);
}

function getLogoSrcBySection(section: string): string {
	if (section.includes('loquista')) return loquistaLogo;
	else if (section.includes('sesame')) return sesameLogo;
	else return utopiaLogo;
}

/*

			<Row>
				<Col className={colClass} xs={12} md={6}>
					{makeProductCard('loquista')}
				</Col>
				<Col className={colClass} xs={12} md={6}>
					{makeProductCard('loquista')}
				</Col>
			</Row>
			<Row>
				<Col className={colClass} xs={12} md={4}>
					{makeProductCard('loquista')}
				</Col>
				<Col className={colClass} xs={12} md={4}>
					{makeProductCard('loquista')}
				</Col>
				<Col className={colClass} xs={12} md={4}>
					{makeProductCard('loquista')}
				</Col>
			</Row>


*/