import React, { useState } from 'react';
import { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { capitalizeFirstLetter } from '../../utils/helpers';
import { getLabelByNlpFormat } from '../../utils/utils';

interface IProps {
	name: string;
	label?: string;
	selected?: string;
	disabled?: boolean;
	onChange: (value: string) => void;
}

const nlpOptions = ['NONE', 'GENERAL', 'CAR_PLATE', 'ADDRESS', 'PHONE', 'CONFIRMATION', 'NUMBER_MENU', 'NUMBER', 'DIGIT'];

const NlpSelect = ({ name, label, disabled, onChange }: IProps) => {
	const getFormId = () => `${name.replace(/\//g, '-').substring(1)}-nlp`;
	const getLabel = (opt: string) => getLabelByNlpFormat(opt);

	const [option, setOption] = useState<string>(localStorage.getItem(getFormId()) || nlpOptions[1]);

	useEffect(() => {
		if (option) localStorage.setItem(getFormId(), option);
		// eslint-disable-next-line
	}, [option]);

	const changeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const currentOption = event.target.value;
		setOption(currentOption);
		onChange(currentOption);
	};

	const options = (
		nlpOptions.map((opt, key) => (
			<option key={`${opt}.${key}`} value={opt}>
				{getLabel(opt)}
			</option>
		))
	);

	return (
		<Form.Group controlId={getFormId()}>
			{
				label && (
					<Form.Label>
						{capitalizeFirstLetter(label)}
					</Form.Label>
				)
			}
			<Form.Control as="select" size="sm" value={option} disabled={disabled}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeEventHandler(event)}>
				{options}
			</Form.Control>
		</Form.Group>
	);
};

export default NlpSelect;