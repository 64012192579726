import * as byDefault from './default.json';

/**
 * About Languages
 */
type TypeLang =
	| 'en-US'
	| 'en-GB'
	| 'es-ES'
	| 'es-MX'
	| 'ca-ES'
	| 'de-DE'
	| 'fr-FR'
	| 'fr-CA'
	| 'it-IT'
	| 'pt-PT'
	| 'pt-BR'
    | 'ar-AE';

interface IWeights {
	[key: string]: number;
}

function getLabelByLanguage(language: string): string {
	return byDefault.languages.labels[language as TypeLang];
}

function orderLanguages(langs: string[]): string[] {
	const weights: IWeights = byDefault.languages.weights; // check byDefault weights
	let languages: string[] = [];
	for (let i = 0, len = langs.length; i < len; i++) {
		languages[weights[langs[i]]] = langs[i];
	}
	languages = languages.filter((lang: any) => lang !== null);
	return languages;
}

/**
 * About voices
 */

type Gender = 'Male' | 'Female';
type NLPEncoding = 'NONE' | 'GENERAL' | 'CAR_PLATE' | 'ID_CARD' | 'DATE' | 'TIME' | 'EMAIL' | 'ADDRESS' | 'PHONE' | 'CONFIRMATION' | 'NUMBER_MENU';

interface IVoice {
	name: string;
	prefix: string;
	language: string;
	gender: string;
	vId: number;
	nick?: string;
	group: string;
	disabled?: boolean;
}

interface IKnownVoice {
	name: string;
	nick: string;
	group: string;
	disabled: boolean;
}

function _orderVoiceArrayByVId(voices: IVoice[]): IVoice[] {
	const orderVoices = voices.sort((a, b) => (a.vId > b.vId ? 1 : -1));
	return orderVoices;
}
function _orderGroupVoices(voices: IVoice[]): IVoice[] {
	const orderVoices = voices.sort((a, b) => (a.group > b.group ? 1 : -1));
	return orderVoices;
}

function getVoiceDefinition(name: string): IVoice {
	let splitedVoice = name?.split('');

	let prefixArray = [];
	let langArray = [];
	let genderArray = [];
	let vIdArray = [];

	for (let i = 0, len = splitedVoice.length; i < len; i++) {
		const sample: any = splitedVoice[i];

		if (i === 0 || i === 1) prefixArray.push(sample);
		else if (i > 1) {
			if ((sample === 'F' || sample === 'M') && langArray.length > 0) {
				genderArray.push(sample);
			} else {
				if (!isNaN(sample)) vIdArray.push(sample);
				else langArray.push(sample);
			}
		}
	}

	let knownVoices: IKnownVoice[] = byDefault.voices.known_voices;
	let voices: IKnownVoice[] = knownVoices.filter(
		(thisVoice: IKnownVoice) => thisVoice.name === name
	);

	const prefix = prefixArray.join('');
	const language = langArray.join('');
	const gender: Gender = genderArray.join('') === 'F' ? 'Female' : 'Male';
	const vId = vIdArray.length > 0 ? parseInt(vIdArray.join('')) : 1;
	const nick = voices[0] ? voices[0].nick : undefined;
	const group = voices[0] ? voices[0].group : 'anonymous';
	const disabled = voices[0] ? voices[0].disabled : false;


	return { name, prefix, language, gender, vId, nick, group, disabled };
}

function orderVoices(voices: string[]): string[] {
	let detailedVoices: IVoice[] = voices.map((item: string) =>
		getVoiceDefinition(item)
	);

	let femaleVoices: IVoice[] = detailedVoices.filter((item: IVoice) =>
		item.gender.includes('Female')
	);
	let maleVoices: IVoice[] = detailedVoices.filter((item: IVoice) =>
		item.gender.includes('Male')
	);

	let orderFemaleVoices = _orderVoiceArrayByVId(femaleVoices);
	let orderMaleVoices = _orderVoiceArrayByVId(maleVoices);
	let totalVoices = orderFemaleVoices.concat(orderMaleVoices);
	let orderGroupVoices = _orderGroupVoices(totalVoices);
	let orderVoices = orderGroupVoices.map((item) => item.name);

	return orderVoices;
}

function defaultSelectedVoiceByLanguage(
	language: string,
	voices: string[]
): string {
	let defaultVoice: string;
	switch (language) {
		case 'es-ES':
			defaultVoice = voices[voices.indexOf('UTESF2')]
				? voices[voices.indexOf('UTESF2')]
				: voices[0];
			break;
		case 'en-US':
			defaultVoice = voices[voices.indexOf('UTUSF3')]
				? voices[voices.indexOf('UTUSF3')]
				: voices[0];
			break;
		default:
			defaultVoice = voices[0];
			break;
	}
	return defaultVoice;
}

function getLabelByVoice(voice: string): string {
	const { gender, vId, nick } = getVoiceDefinition(voice);
	const label: string = vId > 1 ? `${gender} voice ${vId}` : `${gender} voice`;
  if (!nick && !voice.startsWith('UT')) return voice;
	return nick ? nick : label;
}
function getGroupByVoice(voice: string): string {
	const { gender, vId, group } = getVoiceDefinition(voice);
	const label: string = vId > 1 ? `${gender} voice ${vId}` : `${gender} voice`;
	return group ? group : label;
}
function getDisabledByVoice(voice: string): boolean {
	// const { gender, vId , disabled} = getVoiceDefinition(voice);
	const { disabled } = getVoiceDefinition(voice);

	return disabled ? disabled : false;
}




function getLabelByNlpFormat(option: string): string {
	const nlp: NLPEncoding | string = option;
	if (nlp === 'NONE') return 'disabled';
	else if (nlp === 'GENERAL') return 'enabled';
	else {
		return nlp.toLowerCase().replace('_', ' ');
	}
}

/**
 * About text
 */

interface IText {
	[key: string]: string;
}

function textByLanguage(language: string): string {
	const text: IText = byDefault.languages.texts;
	return text[language] || byDefault.languages.texts['en-US'];
}

/**
 * About Hi world
 */

function hiWorldByLanguage(language: string): string {
	const hiWorld = byDefault.languages.hi_world[language as TypeLang];
	return hiWorld || language;
}

export {
	getLabelByLanguage,
	getLabelByVoice,
	getLabelByNlpFormat,
	getGroupByVoice,
	getDisabledByVoice,
	orderLanguages,
	orderVoices,
	defaultSelectedVoiceByLanguage,
	textByLanguage,
	hiWorldByLanguage
};
