import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';

import { DomainContext, EnvContext } from '../../context/Context';

import * as requests from '../../requests/requests';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

type Details = 'no-data' | 'fail';

interface IUserData {
  name: string;
  email: string;
  score: number;
}

interface IProps {
  uuid: string;
  data?: IUserData | null;
  details?: Details;
  onError: (error: string) => void;
  onRegistered: () => void;
}

const RegisterForm = ({ uuid, details, data, onError, onRegistered }: IProps) => {
  const isMounted = useRef<boolean>(false);
  const isRegisteredRef = useRef<boolean>(false);

  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isRegistered, setIsRegistered] = useState<boolean>(false);

  const isFalseSuccess = details && details === 'no-data';
  const titleText = isFalseSuccess ? 'Identified' : 'Not identified';
  const score = isFalseSuccess && data && data.score ? data.score : null;
  const instructionsText = isFalseSuccess
    ? 'Despite being identified, there is no associated user data'
    : 'Not associated user data';

  const registerUrl = `https://${domain}/api/register`;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      const removeVoicePrint = async () => {
        try {
          const _deletedKey = await _deleteVoicePrint(domain, env, uuid);
          console.info(`${_deletedKey} has been removed automatically`);
        } catch (error) {
          console.warn(error);
        }
      };

      if (!isRegisteredRef.current) removeVoicePrint();
    };
    // eslint-disable-next-line
  }, []);

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    const form: any = event.currentTarget;
    if (form && form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      if (uuid) {
        requests
          .register(registerUrl, { key: uuid, name, email })
          .then((response) => {
            if (isMounted.current) {
              setIsRegistered(true);
              isRegisteredRef.current = true;
            }
          })
          .catch((error) => {
            console.error(error);
            if (error && isMounted.current) onError(error);
          });
      }
    }
  };

  const title = (
    <Row>
      <Col className="text-center">
        <h4>{titleText}</h4>
        {score ? <p className="text-muted">{`Score: ${score}`}</p> : null}
      </Col>
    </Row>
  );

  const instructions = (
    <Row>
      <Col>
        <div className="my-2">
          <p className="text-muted">
            {`${instructionsText}. Please, fill the form below in order to
            update your data and being identified the next time.`}
          </p>
        </div>
      </Col>
    </Row>
  );

  const form = (
    <Row>
      <Col>
        <Form onSubmit={(event: React.FormEvent<HTMLFormElement>) => submitHandler(event)}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control required placeholder="User name" size="sm" type="text"
              onChange={(event) => {
                if (isMounted.current) setName(event.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please, provide a valid name
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control required placeholder="email@example.com" size="sm" type="email"
              onChange={(event) => {
                if (isMounted.current) setEmail(event.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please, provide a valid email
            </Form.Control.Feedback>
          </Form.Group>
          <Button className="mr-2" size="sm" variant="primary" type="submit">
            Submit
          </Button>
          <Button className="ml-2" size="sm" variant="outline-primary"
            onClick={() => {
              if (isMounted.current) onRegistered();
            }}
          >
            Cancel
          </Button>
        </Form>
      </Col>
    </Row>
  );

  const success = (
    <Row>
      <Col className="text-center">
        <h5 className="text-success">Registered successfully</h5>
        <p>Now, you can try again.</p>
        <Button size="sm" variant="light"
          onClick={() => {
            if (isMounted.current) onRegistered();
          }}
        >
          Ok
        </Button>
      </Col>
    </Row>
  );

  return (
    <Container className="mt-4">
      {!isRegistered ? title : null}
      {!isRegistered ? instructions : null}
      {!isRegistered ? form : null}
      {isRegistered ? success : null}
    </Container>
  );
};

export default RegisterForm;

function _deleteVoicePrint(domain: string, env: string, key: string): Promise<string> {
  return new Promise(async (resolve, reject) => {
    try {
      const api = `https://${domain}/api/sesame/voiceid/delete`;
      const options = { headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` } };
      const { data }: { data: string[]; } = await axios.post(api, { keys: [key] }, options);
      console.log('Deleted key', data[0]);
      resolve(data[0]);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        reject(`${error.response.status} - ${error.message}`);
      } else reject('An error occurred while removing data');
    }
  });
}
