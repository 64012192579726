import { useState, useEffect, useContext, useRef } from 'react';
import { EnvContext, DomainContext } from '../../../../context/Context';

import axios from 'axios';
import byDefault from '../../../../utils/default.json';


export interface IMusicGeneratorOptions {
	text: string;
    duration: number;
}

export interface IUseMusicGeneratorForm {
	options: IMusicGeneratorOptions,
	setOptions: React.Dispatch<React.SetStateAction<IMusicGeneratorOptions>>;
	formError: Error | null;
}

interface IProps {
}

const useMusicGeneratorForm = ({}: IProps) => {
	const env = useContext(EnvContext);
	const domain = useContext(DomainContext);

	const isMounted = useRef<boolean>(false);

	const [musicStyles, setMusicStyles] = useState<string[]>(['', 'cut_pressure', 'motorway']); // TODO?
    const [samplePrompts, setSamplePrompts] = useState<string[]>([]); // TODO?

	const [globalError, setGlobalError] = useState<Error | null>(null);
	const [options, setOptions] = useState<IMusicGeneratorOptions>({
        text: getTextFromLocalStorage(),
        duration: getDurationFromLocalStorage(),
	});

	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false; };
	}, []);


	useEffect(() => {
		const getOptions = async () => {
			try {
				const text = getTextFromLocalStorage() || getText();
                const duration = getDurationFromLocalStorage() || getDuration();

				isMounted.current && setOptions({
					text,
                    duration,
				});
			} catch (err) {
				const error = new Error('An error occurred while getting MusicGenerator params');
				isMounted.current && setGlobalError(error);
			}
		};
		getOptions();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
	}, []);

	useEffect(() => {
		// if (options.text) {
			const { text } = options;
			setTextToLocalStorage(text);
		// }
		// eslint-disable-next-line
	}, [options.text]);


	useEffect(() => {
		if (options.duration !== undefined) {
			const { duration } = options;
			setDurationToLocalStorage(duration);
		}
		// eslint-disable-next-line
	}, [options.duration]);

	return { options, setOptions, formError: globalError };
};

export { useMusicGeneratorForm };

// HOSTS
type Env = 'prod' | 'test' | 'dev';
type State = 'available' | 'unavailable';

interface IHost {
	env: Env;
	host: string;
	port?: number;
	state?: State;
}

interface IDataResponse {
	availableHosts: IHost[];
}

interface IHostResponse {
	data: IDataResponse;
}

// TEXT
export function getText(): string {
	return byDefault.musicGeneratorPromt;
};
export function getDuration(): string {
    return 30;
};


// LOCAL STORAGE
type ItemType = 'text';

function getItemFromLocalStorage(type: ItemType): string {
	const key = `loquista-musicgenerator-${type}`;
	return (localStorage.getItem(key) || '');
}

function setItemToLocalStorage(type: ItemType, item: string): void {
	const key = `loquista-musicgenerator-${type}`;
	localStorage.setItem(key, item);
}

function getTextFromLocalStorage(): string {
    const key = `loquista-musicgenerator-text`;
    return (localStorage.getItem(key) || '');
}
function getDurationFromLocalStorage(): string {
    const key = `loquista-musicgenerator-duration`;
    const val = localStorage.getItem(key);
    const value = parseInt(val, 10);
    return (value && value <= 30 ? value : 30);
}
function setTextToLocalStorage(text: string): void {
	const key = `loquista-musicgenerator-text`;
	localStorage.setItem(key, text);
}
function setDurationToLocalStorage(duration: number): void {
    const key = `loquista-musicgenerator-duration`;
    localStorage.setItem(key, duration);
}
