import React, { useState, useRef, useEffect, useContext } from 'react';

import { EnvContext, DomainContext } from '../../../../context/Context';

import * as requests from '../../../../requests/requests';
import { downsampleBuffer, getLinear16 } from '../../../../utils/audio';
import SpechToTextHistory from './SpeechToTextHistory';
import CustomRecorder from '../../../utilities/CustomRecorder';
import CustomSocket from '../../../utilities/CustomSocket';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type BufferSize = 256 | 512 | 1024 | 2048 | 4096 | 8192 | 16384;

interface IProps {
  connId: string;
  hiddenHistory?: boolean;
  hiddenOnline?: boolean;
  hiddenTemporal?: boolean;
  hiddenAudio?: boolean;
  blocked?: boolean;
  isComponent?: boolean;
  onConnected: (isConnected: boolean) => void;
  onRecording?: (isRecording: boolean) => void;
  onError: (error: Error) => void;
  gotResult?: (transcription: string) => void;
  gotResults?: (transcriptions: string[]) => void;
}

const SpeechToTextConn = ({ connId, hiddenHistory, hiddenOnline, hiddenTemporal, hiddenAudio, blocked, isComponent,
  onConnected, onRecording, onError, gotResult, gotResults }: IProps) => {
  const env = useContext(EnvContext);
  const domain = useContext(DomainContext);
  const asrWsPort = env.includes('prod') ? 8000 : 4000;
  const asrWsUrl = `wss://${domain}/ws${asrWsPort}`;

  const isMounted = useRef<boolean>(false);
  const wsRef = useRef<WebSocket | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioCtxRef = useRef<AudioContext | null>(null);
  const sourceStreamRef = useRef<MediaStreamAudioSourceNode | null>(null);

  const [isSocketConn, setIsSocketConn] = useState<boolean>(false);
  const [isGrpcConn, setIsGrpcConn] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isFinal, setIsFinal] = useState<boolean>(false);
  const [resTrans, setResTrans] = useState<string>('');
  const [transList, setTransList] = useState<string[]>([]);

  const transcriptionsUrl = `https://${domain}/api/loquista/asr/transcriptions`;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // socket component
  const socket = (
    <CustomSocket
      url={asrWsUrl}
      onOpen={(ws: WebSocket | null) => {
        if (ws) {
          wsRef.current = ws;
          ws.send(connId);
          setIsSocketConn(true);
          setIsGrpcConn(true);
          onConnected(true);
        }
      }}
      onClose={async () => {
        try {
          if (audioCtxRef.current?.state !== 'closed')
            await audioCtxRef.current?.close();
        } catch (error) {
          onError(new Error(JSON.stringify(error)));
        }
        if (isMounted.current) {
          setIsSocketConn(false);
          setIsGrpcConn(false);
          setIsRecording(false);
        }
        onConnected(false);
      }}
      onMessage={({ data }) => {
        const { error, keep_alive, ...rest } = JSON.parse(data);
        const { transcription } = rest;

        if (error) {
          if (isMounted.current) {
            setIsSocketConn(false);
            setIsGrpcConn(false);
            setIsRecording(false);
          }
          onConnected(false);
          onError(new Error(error));
        } else if (keep_alive) {
          // console.log('connected...');
        } else if (!keep_alive && transcription) {
          const { is_final } = rest;
          const isThisFinal: boolean = is_final;
          if (isThisFinal && gotResult) gotResult(transcription);
          if (isMounted.current) {
            setResTrans(transcription);
            setIsFinal(isThisFinal);
          }
        }
      }}
      onError={async (error) => {
        try {
          await audioCtxRef.current?.close();
          onConnected(false);
          onError(new Error(error));
        } catch (error) {
          onError(new Error(JSON.stringify(error)));
        }

        if (isMounted.current) {
          setIsSocketConn(false);
          setIsGrpcConn(false);
          setIsRecording(false);
        }
      }}
    />
  );

  // recorder component
  const streamHandler = async (stream: MediaStream) => {
    if (!audioCtxRef.current || audioCtxRef.current.state === 'closed') {
      audioCtxRef.current = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
    }

    sourceStreamRef.current = audioCtxRef.current!.createMediaStreamSource(
      stream as MediaStream
    );

    const sampleRate: number = audioCtxRef.current!.sampleRate;
    const targetSampleRate: number = 8000;
    const bufferSize: BufferSize = 4096; // Small numbers lower the latency, but large number may be necessary to avoid audio breakup and glitches.

    let scriptNode = audioCtxRef.current!.createScriptProcessor(
      bufferSize,
      1,
      1
    ); // buffersize 1024

    scriptNode.onaudioprocess = async (audio) => {
      try {
        const inputBuffer: AudioBuffer = audio.inputBuffer;
        const audioBuffer: Float32Array = inputBuffer.getChannelData(0);
        const bufferF32: Float32Array = new Float32Array(audioBuffer);
        const downsampledBuffer = downsampleBuffer(
          bufferF32,
          sampleRate,
          targetSampleRate
        );

        const raw: DataView = await getLinear16(downsampledBuffer);
        const bytes: ArrayBuffer = raw.buffer;

        if (
          wsRef.current?.readyState !== wsRef.current?.CLOSED &&
          wsRef.current?.readyState !== wsRef.current?.CLOSING
        ) {
          wsRef.current?.send(bytes);
        }
      } catch (error) {
        console.error(error);
      }
    };

    sourceStreamRef.current.connect(scriptNode);
    scriptNode.connect(audioCtxRef.current!.destination);
  };

  const onRecordStatusChange = async (state: boolean) => {
    if (isMounted.current) setIsRecording(state);
    if (onRecording) onRecording(state);

    if (state) {
      // console.log('Recording');
    } else {
      try {
        await audioCtxRef.current?.close();
        const list = await requests.getTranscriptions(transcriptionsUrl, {
          uuid: connId,
        });

        if (gotResults) gotResults(list);
        if (isMounted.current) {
          setResTrans('');
          setTransList(list);
        }
      } catch (error) {
        onError(new Error(JSON.stringify(error)));
      }
    }
  };

  const recorder = (
    <CustomRecorder
      autoPlay={true}
      disabled={blocked ?? false}
      onChange={onRecordStatusChange}
      onStreaming={streamHandler}
      hiddeButton={isComponent ?? false}
      gotBlob={(blob) => {
        if (audioRef.current) audioRef.current.src = URL.createObjectURL(blob);
      }}
    />
  );

  // Transcription online (results on streaming)
  const isStreamTranscriptionDisplayed =
    isRecording && resTrans && isSocketConn && isGrpcConn && !hiddenOnline;
  const onlineTranscription = (
    <Row>
      <Col>
        <div
          className={`text-center transcription-result mt-3 mb-2 rounded ${isFinal ? 'bg-white' : 'bg-light border'
            } ${!isFinal && hiddenTemporal ? 'invisible' : ''}`}
        >
          <h5
            className={`transcription-${isFinal ? 'completed text-dark' : 'incompleted text-primary'
              } m-0`}
          >
            {resTrans}
          </h5>
        </div>
      </Col>
    </Row>
  );
  const onlineTransComp = isStreamTranscriptionDisplayed
    ? onlineTranscription
    : null;

  // history component (offline results)
  const isHistoryDisplayed =
    isSocketConn &&
    isGrpcConn &&
    !isRecording &&
    !hiddenHistory &&
    transList?.length > 0;
  const historyComp = isHistoryDisplayed ? (
    <SpechToTextHistory list={transList} />
  ) : null;

  return (
    <Container fluid>
      {socket}
      <Row className={isComponent ? 'd-none' : ''}>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col className="p-0 text-center">{recorder}</Col>
        {!hiddenAudio ? (
          <Col>
            <audio ref={audioRef} className="mt-1" controls />
          </Col>
        ) : null}
      </Row>
      {!isComponent && onlineTransComp}
      {historyComp}
    </Container>
  );
};

export default SpeechToTextConn;
