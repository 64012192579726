import React, { useState, useContext, useRef, useEffect } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import CustomConfirm from '../../utilities/CustomConfirm';
import { upload_file_max_size_MB } from '../../../config/conf.general.json';
import { DomainContext, EnvContext } from '../../../context/Context';
import { useGetVideos, IVideo } from '../vision/mimic/useGetVideos';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

interface IVideos extends IVideo {
	selected?: boolean;
}


const AdminVision = () => {
	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	const isMounted = useRef<boolean>(false);
	const wsRef = useRef<WebSocket>();

	const publicVideos = useGetVideos();
	const privateVideos = useGetVideos('list');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [video, setVideo] = useState<File>();
	const [videos, setVideos] = useState<IVideos[]>([...publicVideos, ...privateVideos]);
	const [showModal, setShowModal] = useState<boolean>(false);

	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false; };
	}, []);

	useEffect(() => {
		setVideos([...publicVideos, ...privateVideos]);
	}, [publicVideos, privateVideos]);

	const handleUpload = async () => {
		if (video) {
			const wsPort = env.includes('prod') ? 8007 : 4007;
			const wsUrl = `wss://${domain}/ws${wsPort}`;

			let sId = '';

			wsRef.current = new WebSocket(wsUrl);

			wsRef.current.onopen = () => {
				console.log('socket is opened');
			};

			wsRef.current.onclose = () => {
				console.log('socket is closed');
				setIsLoading(false);
			};

			wsRef.current.onmessage = async (message) => {
				try {
					const { data } = message;
					const { uuid, keep_alive, update } = JSON.parse(data);

					if (keep_alive) {
						console.log('socket is still working...');
					} else if (!keep_alive && uuid) {
						sId = uuid;
						console.log('sId', uuid);
					} else if (update) {
						const { error: err, success } = update;
						err && console.error(err);
						success && console.info(success);
						wsRef.current?.close();
					}
				} catch (error) {
					console.warn('socket error', error);
					wsRef.current?.close();
				}
			};

			wsRef.current.onerror = (error) => {
				console.warn('Socket error:', error);
				wsRef.current?.close();
			};

			setIsLoading(true);
			try {
				const { name, url } = await _uploadFile(domain, env, video);
				await _updateFile(domain, env, { url, name, id: sId });
				console.log('Updating...');
				// setIsLoading(false);
			} catch (error) {
				console.error(error);
				setIsLoading(false);
			}
		} else console.warn('Undefined video');
	};

	const formFile = (
		<Form.File id="mimic-admin-file" className="form-control-sm"
			disabled={isLoading}
			label={video ? video.name : "Upload video"} custom
			onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
				const { target } = event;
				if (target) {
					const { files } = target;
					if (files && files.length > 0) setVideo(files[0]);
				}
			}}>
		</Form.File>
	);

	const form = (
		<Form>
			<Form.Group as={Row}>
				<Col className="px-2">
					{formFile}
				</Col>
				<Col className="px-2">
					<Button size="sm" disabled={isLoading}
						onClick={() => video && handleUpload()}>
						Upload
					</Button>
				</Col>
			</Form.Group>
		</Form>
	);

	const loading = (
		<Row>
			<Col className="my-4 text-center">
				{isLoading && <Spinner animation="border" variant="primary" />}
			</Col>
		</Row>
	);

	const table = (
		<Table striped bordered hover className="text-center mt-3">
			<thead>
				<tr className="text-uppercase">
					<th>#</th>
					<th className="text-left">video</th>
					<th className="text-left">name</th>
					<th className="text-left">select</th>
				</tr>
			</thead>
			<tbody>
				{
					videos.map((video: IVideos, index: number) => (
						<tr key={`${video.key}-${index}`}>
							<td className="font-italic font-weight-bold">
								{index + 1}
							</td>
							<td className="text-center">
								<video width="230" height="175" controls>
									<source src={video.key} type="video/mp4" />
								</video>
							</td>
							<td className="text-left">
								<small>
									<ul className="list-unstyled">
										<li>
											<strong>Origin: </strong>
											<span className="font-italic">
												{video.origin === 'source' ? video.origin : 'custom video'}
											</span>
										</li>
										<li>
											<strong>
												{video.name.replace(/.mp4/g, '').substring(0, 100)}
											</strong>
										</li>
									</ul>
								</small>
							</td>
							<td>
								<Form>
									<Form.Group>
										<Form.Check type="checkbox"
											checked={video.selected ?? false}
											onChange={() => {
												const _videos = videos.map(item => {
													const { selected, ...rest } = item;
													if (item.key === video.key) return { selected: !selected, ...rest };
													else return item;
												});
												setVideos(_videos);
											}}
										>
										</Form.Check>
									</Form.Group>
								</Form>
							</td>
						</tr>
					))
				}
			</tbody>
		</Table>
	);

	const modal = showModal && (
		<CustomConfirm
			title="Remove selected items"
			message={
				`Are you sure you want to permanently 
			remove ${videos.filter(video => video.selected).length} item(s)?`
			}
			show={showModal}
			onResult={async (res: boolean) => {
				setShowModal(!showModal);
				if (res) {
					try {
						isMounted.current && setIsLoading(true);
						const selectedVideos = videos.filter(video => video.selected);
						const _deletedKeys = await _removeVideos(domain, env, selectedVideos.map(v => v.key));
						const _videos = videos.filter(v => !_deletedKeys.includes(v.key));

						isMounted.current && _videos && setVideos(_videos);
						isMounted.current && setIsLoading(false);
					} catch (error) {
						console.error(error);
					}
				}
			}}
		/>
	);

	const dangerForm = (
		<div className="text-right mb-4">
			<Button
				size="sm"
				variant="danger"
				disabled={videos.filter(video => video.selected).length < 1}
				onClick={() => setShowModal(!showModal)}
			>
				<FontAwesomeIcon icon={faTrashAlt} />
				<span className="ml-2">
					{`delete`}
				</span>
			</Button>
		</div>
	);

	return (
		<Container fluid>
			<Row>
				<Col className="px-2 pb-4">
					<h3 className="mt-3">Vision apps management</h3>
				</Col>
			</Row>
			{form}
			{loading}
			{modal}
			<Row>
				<Col>
					{table}
				</Col>
			</Row>
			<Row>
				<Col>
					{dangerForm}
				</Col>
			</Row>
		</Container>
	);
};

export default AdminVision;

/*
interface IResult {
	url: string;
	name: string;
}
*/

async function _uploadFile(domain: string, env: string, file: File): Promise<{ url: string, name: string; }> {
	const maxFileSize = upload_file_max_size_MB * 1024 * 1024;

	return new Promise(async (resolve, reject) => {
		if (file && file.size > maxFileSize) return reject('Maximun size exceeded (3Mb)');
		if (file && file.type !== 'video/mp4') return reject('Wrong file format. Only supports mp4');

		try {
			const formData = new FormData();

			formData.append('file', file);
			formData.append('user', 'admin');

			const { data } = await axios({
				method: 'post',
				url: `https://${domain}/api/vision/mimic/upload/video`,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}`
				}
			});

			const { url, videoName } = data;
			resolve({ url, name: videoName });
		} catch (error) {
			reject('An error occurred while uploading video');
		}
	});
};

interface IUpdateFileParams {
	url: string;
	name: string;
	id: string;
}

async function _updateFile(domain: string, env: string, args: IUpdateFileParams): Promise<void> {
	const { url, name, id } = args;
	return new Promise(async (resolve, reject) => {
		try {
			const api = `https://${domain}/api/vision/mimic/update/video`;
			const params = { url, name, id, env };
			const options = { headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` } };
			await axios.post(api, params, options);
			resolve();
		} catch (error) {
			reject(`An error occurred while updating video ${url}`);
		}
	});
};

async function _removeVideos(domain: string, env: string, keys: string[]): Promise<string[]> {
	return new Promise(async (resolve, reject) => {
		try {
			const api = `https://${domain}/api/vision/mimic/delete/videos`;
			const options = { headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` } };
			const { data }: { data: string[]; } = await axios.post(api, { keys }, options);
			console.log('Deleted keys', data);
			resolve(data);
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				reject(`${error.response.status} - ${error.message}`);
			} else reject('An error occurred while removing data');
		}
	});
}
