/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useRef, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

declare var MediaRecorder: any;
declare var BlobEvent: any;

type TypeFacingMode = 'user' | 'environment';
type TypeVariant = 'primary' | 'danger';

export interface IConstraints {
  audio: IAudio | boolean;
  video: IVideo | boolean;
}

interface IAudio {
  sampleSize: number;
  sampleRate: number;
  channelCount: number;
  echoCancellation: boolean;
}

interface IVideo {
  width: number | IVideoSize;
  height: number | IVideoSize;
  facingMode?: TypeFacingMode;
  aspectRatio?: any;
}

interface IVideoSize {
  min: number;
  ideal: number;
  max?: number;
}

interface IMeidaRecorderOptions {
  audioBitsPerSecond?: number;
  videoBitsPerSecond?: number;
  mimeType?: string;
}

interface IProps {
  controls?: boolean;
  time?: number;
  autoPlay?: boolean;
  reset?: boolean;
  disabled?: boolean;
  block?: boolean;
  variant?: TypeVariant;
  hiddeButton?: boolean;
  onChange: (state: boolean) => void;
  onStreaming?: (stream: MediaStream) => void;
  onTimeout?: () => void;
  gotBlob?: (blob: Blob) => void;
}

const style = css`
  .hidden {
    opacity: 0;
  }
`;

const audioConstraints: IConstraints = {
  audio: {
    sampleSize: 16,
    sampleRate: 8000,
    channelCount: 1,
    echoCancellation: false,
  },
  video: false,
};

const CustomRecorder = ({
  controls,
  time,
  autoPlay,
  reset,
  disabled,
  block,
  variant,
  hiddeButton,
  onChange,
  onStreaming,
  onTimeout,
  gotBlob,
}: IProps) => {
  const isMounted = useRef<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const chunksRef = useRef<Blob[]>([]);
  const streamRef = useRef<MediaStream>();
  const mediaRecorderRef = useRef<typeof MediaRecorder>(); // In order to use MediaRecorder, install @types/dom-mediacapture-record

  const [isRecording, setIsRecording] = useState<boolean>(false);

  useEffect(() => {
    isMounted.current = true;

    if (autoPlay && isMounted.current) buttonRef.current?.click();

    return () => {
      streamRef.current && streamRef.current.getTracks().map((track) => track.stop());
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isRecording) {
      mediaRecorderRef.current?.stop();
      streamRef.current?.getAudioTracks().map((track) => track.stop());
    }
  }, [isRecording]);

  const getStreaming = async (): Promise<MediaStream | undefined> => {
    const isStreaming = streamRef.current && streamRef.current.active;
    if (isStreaming) {
      return Promise.resolve(streamRef.current);
    } else {
      console.log(`%cStream was not active`, 'color:orange');
      try {
        if (!navigator.mediaDevices) {
          const error = `If the current document isn't loaded securely, 
navigator.mediaDevices will be undefined, and you cannot use getUserMedia().`;
          return Promise.reject(error);
        } else {
          const stream = await navigator.mediaDevices.getUserMedia(
            audioConstraints
          );
          return Promise.resolve(stream);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };

  const gotStream = async (stream: MediaStream) => {
    const options: IMeidaRecorderOptions = { mimeType: '' };

    if (typeof MediaRecorder.isTypeSupported === 'function') {
      if (MediaRecorder.isTypeSupported('audio/webm; codecs=opus')) {
        options.mimeType = 'audio/webm; codecs=opus';
      } else if (MediaRecorder.isTypeSupported('audio/ogg; codecs=opus')) {
        options.mimeType = 'audio/ogg; codecs=opus';
      }
      console.log(`%cmimeType: ${options.mimeType}`, 'color:blue');
      mediaRecorderRef.current = new MediaRecorder(stream!, options);
    } else {
      console.log(`%cmimeType: DEFAULT`, 'color:blue');
      mediaRecorderRef.current = new MediaRecorder(stream!);
    }

    mediaRecorderRef.current?.start(10); // The number of milliseconds to record into each Blob: 10 ms
    console.log(
      `%cmediaRecorder state? ${mediaRecorderRef.current?.state}`,
      'color:blue'
    );

    mediaRecorderRef.current.ondataavailable = async (event: typeof BlobEvent) => {
      const blob: Blob = event.data;
      chunksRef.current.push(blob);
    };

    mediaRecorderRef.current.onstart = (event: any) => {
      console.log(`%cmediaRecorder has been started`, 'color:green');
    };

    mediaRecorderRef.current.onerror = (error: any) => {
      console.log(`%cmediaRecorder error: ${error}`, 'color:red');
    };

    mediaRecorderRef.current.onstop = (event: any) => {
      console.log(`%cmediaRecorder stopped`, 'color:blue');

      const blob = new Blob(chunksRef.current, { type: options.mimeType });
      if (gotBlob) {
        gotBlob(blob);
        if (reset) chunksRef.current = [];
      }
    };
  };

  const onRecord = async () => {
    if (isMounted.current) {
      if (!isRecording) {
        try {
          streamRef.current = await getStreaming();
          if (streamRef.current) {
            if (onStreaming) onStreaming(streamRef.current);
            await gotStream(streamRef.current);
            console.log('%cRecording...', 'color:green');
            setIsRecording(true);
            onChange(true);
          }
        } catch (error) {
          console.log(`c%Error: ${error}`, 'color:red');
        }
      } else {
        console.log('Stop recording!');
        if (isMounted.current) {
          setIsRecording(false);
          onChange(false);
        }
      }
    }
  };

  return (
    <div css={style}>
      <Button
        className={`py-2 my-2 ${hiddeButton ? 'hidden' : ''}`}
        variant={variant && !isRecording ? variant : 'danger'}
        block={block ? true : false}
        disabled={disabled ? true : false}
        ref={buttonRef}
        onClick={onRecord}
      >
        {isRecording ? <Spinner animation="grow" variant="light" size="sm" className="mb-1" /> : null}
        {isRecording ? ' Stop' : 'Record'}
      </Button>
    </div>
  );
};

export default CustomRecorder;
