/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useRef, useEffect } from 'react';

type TypeFacingMode = 'user' | 'environment';

export interface IConstraints {
  audio: IAudio | boolean;
  video: IVideo | boolean;
}

interface IAudio {
  sampleSize: number;
  sampleRate: number;
  channelCount: number;
  echoCancellation: boolean;
}

interface IVideo {
  width: number | IVideoSize;
  height: number | IVideoSize;
  facingMode?: TypeFacingMode;
  aspectRatio?: any;
}

interface IVideoSize {
  min: number;
  ideal: number;
  max?: number;
}

interface IProps {
  forwardRef: React.RefObject<HTMLVideoElement>;
  hidden?: boolean;
  facingMode?: TypeFacingMode;
  onStreaming: (stream: MediaStream) => void;
  onError?: (error: string) => void;
}

const constraintsByDefault: IConstraints = {
  audio: false,
  video: true,
};

const videoStyle = css`
max-width: 100%;
height: auto;
`;

function getConstraints(facingMode?: TypeFacingMode): IConstraints {
  const mode = facingMode ? facingMode : 'user';
  const screenWidth = window.screen.width;

  return {
    audio: false,
    video: {
      // width: { min: 400, ideal: screenWidth < 720 ? 400 : 720, max: 720 },
      width: { min: 400, ideal: screenWidth, max: 1024 },
      height: { min: 300, ideal: screenWidth < 720 ? 300 : 576, max: 576 },
      facingMode: mode,
      aspectRatio: { ideal: 1.7777777778 },
    },
  };
}

const CustomVideo = ({ forwardRef, hidden, facingMode, onStreaming, onError }: IProps) => {
  const isMounted = useRef<boolean>(false);
  const streamRef = useRef<MediaStream>();

  useEffect(() => {
    isMounted.current = true;

    const gotStream = (stream: MediaStream) => {
      const video = forwardRef.current;
      onStreaming(stream);
      if (video) {
        video.srcObject = stream;
        video.onloadedmetadata = () => video.play();
      }
    };

    getStream()
      .then((stream) => {
        if (stream) gotStream(stream);
      })
      .catch((error) => {
        console.error(error);
        if (onError) onError(typeof error === 'string' ? error : JSON.stringify(error));
      });

    return () => {
      streamRef.current &&
        streamRef.current.getTracks().map((track) => track.stop());
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const getStream = (): Promise<MediaStream> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (streamRef.current && streamRef.current.active) {
          console.log('Video stream is active');
        } else {
          console.warn('Video stream is not active');

          if (!navigator.mediaDevices) throw new Error('getUserMedia is not available');

          streamRef.current = await navigator.mediaDevices.getUserMedia(
            getConstraints(facingMode)
          );
        }
        resolve(streamRef.current);
      } catch (error) {
        if (error.message === 'getUserMedia is not available') return reject(error.message);
        console.error(error);
        try {
          if (error.message === 'Constraints could be not satisfied.') {
            streamRef.current = await navigator.mediaDevices.getUserMedia(
              constraintsByDefault
            );
            resolve(streamRef.current);
          } else reject(error);
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }
    });
  };

  return <video ref={forwardRef}
    css={videoStyle}
    className={hidden ? 'd-none' : ''} />;
};

export default CustomVideo;
