import React, { Fragment, useState, useRef, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import { EnvContext, DomainContext } from '../../../../context/Context';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import NIFReaderForm from './NIFReaderForm';
import NIFReaderConn from './NIFReaderConn';
import CustomMessage from '../../../utilities/CustomMessage';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

interface IProps {
	title: string;
	description?: string;
}

const NIFReader = ({ title, description }: IProps) => {
	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	const isMounted = useRef<boolean>(false);

	const [isConnected, setIsConnected] = useState<boolean>(false);
	const [id, setId] = useState<string>('');
	const [nif, setNif] = useState<string>('');
	const [err, setErr] = useState<string>('');

	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false; };
	}, []);

	const form = <NIFReaderForm status={isConnected} onConnect={async () => {
		try {
			if (!isConnected === true) {
				const api = `api/vision/code-reader/connect`;
				const url = `https://${domain}/${api}`;
				const params = { model: 'dni' };
				const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
				const { data } = await axios.post(url, params, options);

				isMounted.current && setId(data);
				isMounted.current && setIsConnected(true);
			} else {
				const api = `api/vision/code-reader/disconnect`;
				const url = `https://${domain}/${api}`;
				const params = { id };
				const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
				const { data } = await axios.post(url, params, options);
				console.log(data);

				isMounted.current && setIsConnected(false);
				isMounted.current && setErr('');
				isMounted.current && setNif('');
			}
		} catch (error) {
			console.error(error);
			isMounted.current && setIsConnected(false);
		}
	}} />;

	const conn = isConnected && (
		<NIFReaderConn id={id} hiddenCamera={true}
			onResult={({ dni }) => (isMounted.current && dni) && setNif(dni)}
			onDisconnect={() => isMounted.current && setIsConnected(false)}
			onError={(error: string) => isMounted.current && setErr(error)}
			onRecording={(status) => console.log(`Is Working? ${status}`)} />
	);

	const result = (nif || err) && (
		<Alert variant={err ? 'danger' : 'info'}>{err ?? nif}</Alert>
	);

	return (
		<Fragment>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
			</Helmet>
			<SubTitle name={title} />
			<Description text={description ? description : 'Utopia.AI'} />
			{form}
			{conn}
			<Container>
				<Row>
					<Col>
						{result}
					</Col>
				</Row>
			</Container>
			<CustomMessage messageStyle={'light'} message={isConnected ? 'Connected' : 'Disconnected'} />
		</Fragment>
	);
};

export default NIFReader;