import React, { useEffect } from 'react';

import CustomCarousel from '../../../utilities/CustomCarousel';

interface IProps {
  readonly sentences: string[];
  readonly index: number;
  readonly isRecording: boolean;
  onChange: (index: number) => void;
  onClick: (sentence: string) => void;
  readonly forceUpdate?: boolean;
}

const VoiceGeneratorSentences = ({ sentences, index, isRecording, onChange, onClick, forceUpdate, }: IProps) => {
  useEffect(() => {
    console.log('Rendering sentences...');
  }, [forceUpdate]);

  useEffect(() => {
    console.log('(*) isRecording?', isRecording);
  }, [isRecording]);

  return (
    <CustomCarousel
      items={sentences}
      activeIndex={index}
      controls={isRecording ? false : true}
      onIndexChange={(index) => onChange(index)}
      onSelectedItem={(item) => onClick(item)}
    />
  );
};

export default VoiceGeneratorSentences;
