import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import * as helpers from '../../../../utils/helpers';

interface IProps {
  list: string[];
}

const SpeechToTextHistory = ({ list }: IProps) => {
  const displayTemp = (): void => {
    const elements = getElemByClass('temp');
    const titles = getElemByClass('history-details');

    drawHTMLCollection(elements, 'd-none');
    drawHTMLCollection(titles, 'bg-info');
  };

  const displayFormat = (): void => {
    const postFormatItems = getElemByClass('transcript-text');
    const preFormatItems = getElemByClass('transcript-aux');
    const titles = getElemByClass('history-preformat');

    drawHTMLCollection(preFormatItems, 'd-none', postFormatItems);
    drawHTMLCollection(titles, 'bg-primary');
  };

  const history = (
    <div className="transcription-history">
      <ul className="list-unstyled">
        {list.map((trans: string, key: number) => {
          const {
            transcription,
            is_final,
            timestamp,
            transcript_aux,
          } = JSON.parse(trans);
          const time = timestamp ? helpers.getTimeByTimestamp(timestamp) : '';
          const liClass = is_final
            ? 'final transcription bg-primary text-white my-1'
            : 'temp d-none transcription text-info';
          const label = `[${time ? time : 'temp'}]`;
          const aux = transcript_aux ? transcript_aux : transcription;

          return (
            <li key={key} className={`${liClass} px-3 py-1 rounded`}>
              <span className="transcript-time mr-2 font-weight-light">
                {label}
              </span>
              <span className="transcript-text">{transcription}</span>
              <span className="transcript-aux font-weight-light d-none">
                {aux}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <Row>
      <Col>
        <div className="transcriptions-wrapper my-4 bg-light text-dark rounded border border-light">
          <h5 className="pb-2">
            Transcriptions
            <Button
              variant="primary"
              size="sm"
              className="ml-2 history-details"
              onClick={displayTemp}
            >
              Details
            </Button>
            <Button
              variant="info"
              size="sm"
              className="ml-2 history-preformat"
              onClick={displayFormat}
            >
              Format
            </Button>
          </h5>
          {history}
        </div>
      </Col>
    </Row>
  );
};

function getElemByClass(className: string): HTMLCollection {
  return document.getElementsByClassName(className);
}

function drawHTMLCollection(
  collection: HTMLCollection,
  target: string,
  auxCollection?: HTMLCollection
): void {
  for (
    let i = 0, len = auxCollection ? auxCollection.length : collection.length;
    i < len;
    i++
  ) {
    const el = collection[i];
    if (el.classList.contains(target)) {
      el.classList.remove(target);
      if (auxCollection) auxCollection[i].classList.add(target);
    } else {
      el.classList.add(target);
      if (auxCollection) auxCollection[i].classList.remove(target);
    }
  }
}

export default SpeechToTextHistory;
