/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';

interface IProps {
	isOpen: boolean;
	onMenuState: (isOpen: boolean) => void;
}

const containerStyle: SerializedStyles = css`
	position: fixed;
	top: 2.25rem;
	right: 1.5rem;
	height: 2.25rem;
	width: 1.75rem;
	z-index: 999;
	cursor: pointer;
	display: inline-block;
	opacity: 0.5;
`;

const closeStyles = css`
	width: 1.75rem;
	height: 0.25rem;
	background-color: #484848;
	margin: 0.25rem 0;
	transition: 0.4s;
`;

const openStyles = [
	css`
	width: 1.75rem;
  height: 0.25rem;
  background-color: #484848;
  margin: 0.25rem 0;
  transition: 0.4s;
	transform: rotate(-45deg) translate(-0.2rem, 0.2rem);
	`,
	css`
	width: 1.75rem;
  height: 0.25rem;
	margin: 0.25rem 0;
	transition: 0.4s;
	opacity: 0;
	`,
	css`
	width: 1.75rem;
  height: 0.25rem;
  background-color: #484848;
  margin: 0.25rem 0;
  transition: 0.4s;
	transform: rotate(45deg) translate(-0.5rem, -0.5rem);
	`
];

const MenuIcon = ({ isOpen, onMenuState }: IProps) => {
	return (
		<React.Fragment>
			<div id="utopia-menu-icon" css={containerStyle}
				onClick={() => onMenuState(!isOpen)}>
				{
					[1, 2, 3].map((num, key) => (
						<div key={`bar-${num}`} className={`icon-bar bar-${num}`}
							css={isOpen ? openStyles[key] : closeStyles}></div>
					))
				}
			</div>
		</React.Fragment>
	);
};

export default MenuIcon;