import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import './styles/App.scss';

// hooks
import useGetSections, { ISection } from './hooks/useGetSections';
import useGetApps from './hooks/useGetApps';
import useAuth from './hooks/useAuth';
import useScreenSize from './hooks/useScreenSize';

import Header from './components/Header';
import NavBar from './components/Navbar';
import Main from './components/Main';
import Footer from './components/Footer';
import MenuIcon from './components/MenuIcon';
import SmallMenu from './components/SmallMenu';

import { BrowserRouter as Router } from 'react-router-dom';

import { SizeContext } from './context/Context';
import { useEffect } from 'react';
import CustomToaster from './components/utilities/CustomToaster';

type Binary = 1 | 0;
export type Env = 'dev' | 'test' | 'prod';

export interface IApp {
  id: number;
  section: string;
  app: string;
  status: Binary;
  path: string;
  description?: string;
  env: Env;
  logged_required: Binary;
}

function App() {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [webApps, setWebApps] = useState<IApp[]>();
  const [webSections, setWebSections] = useState<ISection[]>();

  const { size } = useScreenSize();

  const sections = useGetSections();
  const apps = useGetApps();
  const authValue = useAuth();

  useEffect(() => sections && setWebSections(sections), [sections]);
  useEffect(() => apps && setWebApps(apps), [apps]);

  return (
    <div className="App">
      <CustomToaster />
      <Helmet>
        <title>Utopia apps</title>
      </Helmet>
      <Router>
        <SizeContext.Provider value={{ size }}>
          <Header />
          {(size === 'small') && <MenuIcon isOpen={showMenu} onMenuState={(isOpen: boolean) => setShowMenu(isOpen)} />}
          {(size === 'large') && <NavBar />}
          {((size === 'small') && showMenu && webApps && webSections) &&
            <SmallMenu apps={webApps} sections={webSections} handleHide={() => setShowMenu(false)} />
          }
          {(webApps && webSections) && (
            <Main loginProvider={authValue} showMenu={showMenu}
              apps={webApps} sections={webSections} />
          )}
        </SizeContext.Provider>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
