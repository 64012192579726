import { useState, useEffect, useContext, useRef } from 'react';
import { EnvContext, DomainContext } from '../../../../context/Context';

import axios from 'axios';
import { orderLanguages } from '../../../../utils/utils';

export type Language =
	"es-ES" |
	"es-MX" |
	"ca-ES" |
	"en-US" |
	"en-GB" |
	"fr-FR" |
	"de-DE" |
	"it-IT" |
	"pt-PT" |
	"pt-BR";

export interface ISpeechToTextOptions {
	hosts: string[];
	languages: string[];
	host: string;
	language: Language;
}

export interface IUseSpeechToTextForm {
	params: ISpeechToTextOptions,
	setParams: React.Dispatch<React.SetStateAction<ISpeechToTextOptions>>;
}

const useSpeechToTextForm = (uuid: string) => {
	const isMounted = useRef<boolean>(false);

	const env = useContext(EnvContext);
	const domain = useContext(DomainContext);

	const [params, setParams] = useState<ISpeechToTextOptions>({
		hosts: [''],
		languages: [''],
		host: getItemFromLocalStorage('host'),
		language: getItemFromLocalStorage('language') as Language,
	});

	useEffect(() => {
		isMounted.current = true;
		const getOptions = async () => {
			try {
				// getting hosts and selected host
				const hosts = await getHosts(domain, env); // getting alive hosts
				const storedHost = getItemFromLocalStorage('host');
				const isStoredHostAvailable = storedHost ? hosts.includes(storedHost) : false;
				// if there is not a stored host available, we choose a random one
				const host = isStoredHostAvailable ? storedHost : hosts[Math.floor(Math.random() * hosts.length)];

				// getting languages and selected language by host
				const languages = await getLanguages(domain, env, host, uuid);
				const storedLanguage = getItemFromLocalStorage('language');
				const isStoredLanguageAvailable = storedLanguage ? languages.includes(storedLanguage as Language) : false;
				const language: Language = isStoredLanguageAvailable ? storedLanguage as Language : languages[0];

				isMounted.current && setParams({
					hosts,
					languages,
					host,
					language
				});
			} catch (error) {
				console.log('error', error);
			}
		};
		getOptions();
		return () => { isMounted.current = false; };
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const getOptionsByHost = async () => {
			const { host, hosts } = params;
			setItemToLocalStorage('host', host);

			// getting languages and selected language by host
			const languages = await getLanguages(domain, env, host, uuid);
			const storedLanguage = getItemFromLocalStorage('language');
			const isStoredLanguageAvailable = storedLanguage ? languages.includes(storedLanguage as Language) : false;
			const language: Language = isStoredLanguageAvailable ? storedLanguage as Language : languages[0];

			isMounted.current && setParams({
				hosts,
				languages,
				host,
				language
			});
		};
		if (params.host) getOptionsByHost();
		// eslint-disable-next-line
	}, [params.host]);

	useEffect(() => {
		const getOptionsByLanguage = async () => {
			const { host, hosts, language, languages } = params;
			setItemToLocalStorage('language', language);

			isMounted.current && setParams({
				hosts,
				languages,
				host: host,
				language
			});
		};
		if (params.host && params.language) getOptionsByLanguage();
		// eslint-disable-next-line
	}, [params.language]);

	return { params, setParams };
};

export { useSpeechToTextForm };

// HOSTS
type Env = 'prod' | 'test' | 'dev';
type State = 'available' | 'unavailable';

interface IHost {
	env: Env;
	host: string;
	port?: number;
	state?: State;
}

interface IDataResponse {
	availableHosts: IHost[];
}

interface IHostResponse {
	data: IDataResponse;
}

async function getHosts(domain: string, env: string): Promise<string[]> {
	try {
		const url = `https://${domain}/api/loquista/asr/hosts`;
		const params = { env };
		const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
		const { data: { availableHosts } }: IHostResponse = await axios.post(url, params, options);
		const hosts = availableHosts.map(item => item.host);
		return Promise.resolve(hosts);
	} catch (error) {
		return Promise.reject(error);
	}
};

// LANGUAGES
interface ILanguageData {
	availableLanguages: string[];
}

interface ILangResponse {
	data: ILanguageData;
}

async function getLanguages(domain: string, env: string, host: string, uuid: string): Promise<Language[]> {
	try {
		const url = `https://${domain}/api/loquista/asr/languages`;
		const params = { host, uuid };
		const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
		const { data: { availableLanguages } }: ILangResponse = await axios.post(url, params, options);
		const sortedLanguages: Language[] = orderLanguages(availableLanguages) as Language[];
		return Promise.resolve(sortedLanguages);
	} catch (error) {
		return Promise.reject(error);
	}
};

// LOCAL STORAGE
type ItemType = 'host' | 'language';

function getItemFromLocalStorage(type: ItemType): string {
	const key = `loquista-asr-${type}`;
	return (localStorage.getItem(key) || '');
}

function setItemToLocalStorage(type: ItemType, item: string): void {
	const key = `loquista-asr-${type}`;
	localStorage.setItem(key, item);
}