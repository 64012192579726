/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useContext } from 'react';
import logo from '../resources/logo-utopia-115x115.svg';
import horizontalLogo from '../resources/utopia-horizontal.svg';

import { SizeContext } from '../context/Context';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

const Header = () => {
  const { size } = useContext(SizeContext);

  const style = css`
    text-align: center;
    background-color: #f5f5f5;
    height: 14vh;
    width: 100%;
    padding-bottom: 0.5rem;
    .row {
      height: 14vh;
    }
    .col {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }
    .img-fluid.fluid-logo {
      height: 72%;
    }
    .img-fluid.horizontal-logo {
      height: 50%;
    }
    @media (min-width: 768px) {
      padding-bottom: 0;
    }
  `;

  return (
    <React.Fragment>
      <Container fluid css={style}>
        <Row>
          <Col>
            <Image
              className={size === 'large' ? 'fluid-logo' : 'horizontal-logo'}
              src={size === 'large' ? logo : horizontalLogo}
              fluid
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Header;
