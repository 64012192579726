import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IProps {
	avatar: string;
	voice: string;
	background: string | undefined;
	watermark: string | undefined;
}

const VideoAssistantLegend = ({ ...props }: IProps) => {
	return (
		<Container>
			<Row>
				<Col className="text-center">
					<ul className="list-inline">
						{
							Object.keys(props).map((prop: string, key: number) => (
								<li key={`${prop}-${key}`} className="list-inline-item">
									<small>
										<span className="font-weight-bold text-capitalize">{`${prop}: `}</span>
										<span>
											{Object.values(props)[key] ? displayName(Object.values(props)[key]) : ''}</span>
									</small>
								</li>
							))
						}
					</ul>
				</Col>
			</Row>
		</Container>

	);
};

export default VideoAssistantLegend;

export function displayName(url: string): string {
	const _arr = url.split('/');
	return _arr[_arr.length - 1].replace(/.mp4|.jpeg|.jpg|.png/, '');
}
