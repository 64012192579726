import React, { useEffect, useState, useRef, Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import { DomainContext, EnvContext } from '../../../../context/Context';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faBarcode } from '@fortawesome/free-solid-svg-icons';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import ErrorBoundary, { IErrorProps } from '../../../ErrorBoundary';
import CustomMessage from '../../../utilities/CustomMessage';

import CodeReaderForm from './CodeReaderForm';
import CodeReaderConn, { ICodeReaderMessage, ICode } from './CodeReaderConn';

interface IProps {
  title: string;
  description?: string;
}

const CodeReader = ({ title, description }: IProps) => {
  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);

  const isMounted = useRef<boolean>(false);

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [boundError, setBoundError] = useState<IErrorProps | null>(null);

  const [id, setId] = useState<string>('');
  const [code, setCode] = useState<ICode | null>(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const gotResult = (result: ICodeReaderMessage) => {
    const { codes } = result;

    if (codes && isMounted.current) {
      console.log(codes);
      setCode(codes[0]);
      setIsConnected(false);
    }
  };

  const form = (
    <CodeReaderForm
      status={isConnected}
      disabled={false}
      onConnect={async () => {
        if (isMounted.current) {
          if (!isConnected === true) {
            try {
              const api = `api/vision/code-reader/connect`;
              const url = `https://${domain}/${api}`;
              const params = { model: 'code' };
              const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
              const { data } = await axios.post(url, params, options);
              setId(data);

              setIsConnected(true);
              setBoundError(null);
              setCode(null);
            } catch (error) {
              console.error(error);

              setIsConnected(false);
              setBoundError({
                title: 'Connection',
                description: 'Impossible to connect to Code reader server'
              });
            }
          } else {
            console.log('estoy desconectando?');
            setIsConnected(false);
            setBoundError(null);
          }
        }
      }}
    ></CodeReaderForm>
  );

  const conn = isConnected ? (
    <CodeReaderConn
      id={id}
      hiddenCamera={true}
      onResult={gotResult}
      onDisconnect={() => {

        if (isMounted.current) {
          setIsConnected(false);
          setCode(null);
        }
      }}
      onError={(error: string) => {
        console.error(error);
        setBoundError({
          title: 'Barcode reader',
          description: error,
        });
      }}
      onRecording={(status) => console.log(`Is Working? ${status}`)}
    />
  ) : null;

  const errorBoundary = boundError ? (
    <ErrorBoundary
      title={boundError?.title || 'Error'}
      description={boundError?.description ? boundError.description : ''}
      reason={boundError?.reason ? boundError.reason : ''}
    />
  ) : null;

  const qrLabel = <h3>
    {'QR Code '}<FontAwesomeIcon icon={faQrcode} />
  </h3>;

  const barcodeLabel = <h3>
    {'Barcode '}<FontAwesomeIcon icon={faBarcode} />
  </h3>;

  const unknownLabel = <h3>Unknown</h3>;

  const result = code ? (
    <Container fluid>
      <Row className="mt-4">
        <Col>
          {code.name === 'QRCODE' ? qrLabel : (code.name === 'OTHER' ? unknownLabel : barcodeLabel)}
        </Col>
      </Row>
      <Row>
        <Col className="mx-1">
          <p>Score: {code.score}</p>
          <Alert variant={'info'}>{
            code.name === 'QRCODE' && code.properties === 'URL' ? <Alert.Link href={code.value} target="_blank">{code.value}</Alert.Link> : code.value
          }</Alert>
        </Col>
      </Row>
    </Container>
  ) : null;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <SubTitle name={title} />
      <Description text={description ? description : 'Utopia.AI'} />
      {form}
      {conn}
      {result}
      <CustomMessage messageStyle={'light'} message={isConnected ? 'Connected' : 'Disconnected'} />
      {errorBoundary}

    </Fragment>
  );
};

export default CodeReader;
