import React, { Fragment, useState } from 'react';
import classNames from 'classnames';

import { getLabelByVoice } from '../../../../utils/utils';

import { IAssistant } from '../useGetAssistants';
import GreetingButton from './GreetingButton';
import VideoAssistantForm, { IVideoAssistantData } from './VideoAssistantForm';
import VIdeoAssistantLegend from './VideoAssistantLegend';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

interface IProps {
	assistant: IAssistant;
	shown: boolean;
	disabled?: boolean;
	greeting: () => void;
	farewell: () => void;
	gotParams?: ({ voice, avatar }: IVideoAssistantData) => void;
}

const AssistantForm = ({ assistant, shown, disabled, greeting, farewell, gotParams }: IProps) => {
	const className = classNames('text-center');

	const [hideStart, setHideStart] = useState<boolean>(false);
	const [params, setParams] = useState<IVideoAssistantData>({
		voice: '',
		avatar: '',
		background: undefined,
		watermark: undefined
	});

	const videoForm = assistant.typename === 'video' && (
		<VideoAssistantForm
			shown={shown}
			onSelect={(params: IVideoAssistantData) => {
				setParams(params);
				gotParams && gotParams(params);
			}}
			onSelection={(hidden) => setHideStart(hidden)}
		/>
	);

	const videoLegend = assistant.typename === 'video' && !hideStart && (
		<VIdeoAssistantLegend
			avatar={params.avatar}
			// background={params.background}
			// watermark={params.watermark}
			voice={getLabelByVoice(params.voice)} />
	);

	const button = disabled ? (
		<Spinner animation="border" variant="primary" />
	) : (
		!hideStart && (
			<GreetingButton
				disabled={(params.voice && params.avatar) ? (disabled ?? false) : false}
				shown={shown}
				assistant={assistant}
				greeting={greeting}
				farewell={farewell} />
		)
	);

	return (
		<Fragment>
			{!shown && videoForm}
			{videoLegend}
			<Container fluid className={className}>
				<Row>
					<Col className="border-bottom pb-2">
						{button}
					</Col>
				</Row>
			</Container>
		</Fragment>

	);
};

export default AssistantForm;
