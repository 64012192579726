import React, { useEffect, useRef, useState, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import ErrorPage from '../../../pages/ErrorPage';

import SpellCheckerForm from './SpellCheckerForm';

interface IProps {
	title: string;
	description?: string;
}

const SpellChecker = ({ title, description }: IProps) => {
	const isMounted = useRef<boolean>(false);

	const [gotError, setError] = useState<boolean>(false);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const form = <SpellCheckerForm
		onError={(error) => {
			console.error(error.message);
			setError(true);
		}}
		onResult={(result) => {
			// console.log(result);
		}}
	/>;

	return (
		<Fragment>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
			</Helmet>
			<SubTitle name={title} />
			<Description text={description ? description : 'Utopia.AI'} />
			{gotError ? <ErrorPage code={503} /> : form}
		</Fragment>
	);
};

export default SpellChecker;