import React, { Fragment, useState, useRef, useContext, useEffect } from 'react';
import axios from 'axios';
import classNames from 'classnames';

import { DomainContext, EnvContext } from '../../../../context/Context';
import { getCurrentTime } from '../../../../utils/helpers';
import { IAssistant } from '../useGetAssistants';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ChatForm from './ChatForm';
import ChatBubble from './ChatBubble';

type Speaker = 'bot' | 'user';

export interface IMessage {
	speaker: Speaker;
	time: string;
	message: string;
	hidden?: boolean;
}

export interface IChatProps {
    id: string;
    ttsId: string;
	shown: boolean;
	assistant: IAssistant;
	voice: string;
	avatar: string;
	background: string | undefined,
	watermark: string | undefined;
}

interface IProps extends IChatProps {
	// id: string;
	displayMessages: boolean;
	toggleDisplayMessages: () => void;
}

const Chat = ({ id, ttsId, shown, assistant, host, voice, avatar, displayMessages, toggleDisplayMessages, mode }: IProps) => {
	const env = useContext(EnvContext);
	const domain = useContext(DomainContext);

	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const bottomRef = useRef<HTMLDivElement>(null);

	// const [id, setId] = useState<string>(getUuid());
	const [messages, setMessages] = useState<IMessage[]>([]);

	useEffect(() => {
		if (displayMessages) {
			const updateMessages = messages.map(message => {
				const { hidden, ...messageProps } = message;
				return {
					hidden: false,
					...messageProps
				};
			});
			setMessages(updateMessages);
		}
		// eslint-disable-next-line
	}, [displayMessages]);

	useEffect(() => {
		const startMessage = async () => {
			const isEnglish = assistant.language === 'en';
			const text = isEnglish ? 'hello' : 'hola';

			try {
				const output: string[] = await sendMessage(text, assistant, { id, env, domain });
				handleAssistantMessage(output);

			} catch (error) {
				console.warn(error);
				handleErrorMessage();
			}
		};

		if (shown) {
			textAreaRef.current?.focus();
			// startMessage(); // Start message desactivado (no necesitan en Utopia -Thies-)
		} else setMessages([]);
		// eslint-disable-next-line
	}, [shown]);

	useEffect(() => {
		textAreaRef.current?.scrollIntoView({
			behavior: 'smooth'
		});
	}, [messages]);

	const handleUserMessage = async (text: string) => {
		const msg: IMessage = {
			speaker: 'user',
			time: getCurrentTime(),
			message: text
		};

		const isOnlySpace = !!(/^ *$/.test(text));
		const isOnlyLineBreak = !!(text.match("^[\\n\\r]+$"));
		const isDone = text === '[DONE]';
		const isReset = text === '[RESET]';

		if (!isOnlySpace && !isOnlyLineBreak && !isDone && !isReset) {
			toggleDisplayMessages();
			setMessages((messages) => [...messages, msg]);
			try {
				const output: string[] = await sendMessage(text, assistant, { id, env, domain });
				handleAssistantMessage(output);
			} catch (error) {
				console.warn(error);
				handleErrorMessage();
			}
		}
	};

	const handleAssistantMessage = (sentences: string[]) => {
		const assistantMessages: IMessage[] = sentences.map((el) => {
			const regex = /^[^a-zA-Z]+$/g;
			const last = el.slice(-1);
			const sufix = last && last.match(regex) ? '' : '.';
			const message = `${el}${sufix}`;
			assistant.typename === 'video' && ttsProcess(message, assistant, { id: ttsId, host, voice, domain, env });
			return {
				speaker: 'bot',
				time: getCurrentTime(),
				message,
				hidden: avatar ? true : false
			};
		});

		assistantMessages.map((item) => setMessages((messages) => [...messages, item]));
		textAreaRef.current?.focus();
	};

	const handleErrorMessage = () => {
		const isEnglish = assistant.language === 'en';
		const englishErrorMessage = "There is something wrong. I can't help you right now. Please, try again later. Thank you.";
		const spanishErrorMessage = "Parece que algo va mal y no puedo ayudarte ahora mismo. Por favor, vuelve más tarde. Gracias.";
		const message = isEnglish ? englishErrorMessage : spanishErrorMessage;

		const errorMessage: IMessage = {
			speaker: 'bot',
			time: getCurrentTime(),
			message
		};

		setMessages((messages) => [...messages, errorMessage]);
	};

	const containerClasses = classNames(
		{ 'utopia-chat-container': !avatar },
		{ 'utopia-video-chat-container': avatar },
		'px-0',
		'py-3');

	return (
		<Fragment>
			<Container fluid className={containerClasses}>
				{
					messages.map((item, key, array) => {
						return (
							<Row key={key} className={item.hidden && !displayMessages ? 'd-none' : ''}>
								<Col className={item.speaker === 'user' ? 'text-right px-3' : 'px-3'}>
									<ChatBubble message={item} index={key} messages={array} />
									<div
										style={{ float: 'left', clear: 'both' }}
										ref={bottomRef}
									/>
								</Col>
							</Row>
						);
					})
				}
			</Container>
			<ChatForm
				forwardRef={textAreaRef}
				assistant={assistant}
				onTextSubmit={handleUserMessage}
				onClickSend={handleUserMessage}
			/>
		</Fragment>
	);
};

export default Chat;

interface ISendMessageFnParams {
	id: string;
	env: string;
	domain: string;
}

interface ISendMessageApiParams {
	uuid: string;
	env: string;
	model: string;
	name: string;
	language: string;
	text: string;
    mode: string;
}

function sendMessage(text: string, assistant: IAssistant, params: ISendMessageFnParams): Promise<string[]> {
	return new Promise(async (resolve, reject) => {
		const { id, domain, env } = params;
		const { name, model, language } = assistant;
		const api = 'api/brain/chat/message';

        const mode = assistant.typename === 'video' ? 'AUDIO' : 'DEFAULT';

		const requestParams: ISendMessageApiParams = { uuid: id, env, model, name, language, text, mode };
		const requestHeaders = {
			headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` }
		};

		try {
			const { data } = await axios.post(`https://${domain}/${api}`, requestParams, requestHeaders);
			const { text_truncated, output_sentence } = data;
			text_truncated && console.warn(text_truncated);
			const outputMessages: string[] = output_sentence.filter((el: string) =>
				(el && !/^ *$/.test(el))
			);
			resolve(outputMessages);
		} catch (err) {
			const error = new Error(`${name} doesn't respond`);
			console.error(err);
			reject(error.message);
		}
	});
}

interface IttsFnParams {
	id: string;
    host: string;
	voice: string;
	domain: string;
	env: string;
}

interface IttsApiParams {
	uuid: string;
	host: string;
	language: string;
	voice: string;
	sampleRate: number;
	speakingRate: number;
	text: string;
}

function ttsProcess(text: string, assistant: IAssistant, params: IttsFnParams): Promise<void> {
	return new Promise(async (resolve, reject) => {
		const { id, host, voice, domain, env } = params;
		console.log('tts process', text);
		console.log('tts process', id);
		const { language } = assistant;
		const api = 'api/loquista/tts/process';
		const requestParams: IttsApiParams = {
			uuid: id,
			host: params.host || '100.100.100.78',
			language: language.includes('es') ? 'es-ES' : 'en-US',
			voice,
			sampleRate: 22050,
			speakingRate: 1,
			text
		};
		const requestHeaders = {
			headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` }
		};

		try {
			await axios.post(`https://${domain}/${api}`, requestParams, requestHeaders);
			resolve();
		} catch (err) {
			const error = new Error(`TTS doesn't respond`);
			console.error(err);
			reject(error.message);
		}
	});
}
