import * as helpers from './helpers';

function _floatTo16BitPCM(output: DataView, offset: number, input: Float32Array): Promise<void> {
	return new Promise(resolve => {
		for (let i = 0; i < input.length; i++, offset += 2) {
			let s = Math.max(-1, Math.min(1, input[i]));
			output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
		}
		resolve();
	});
}

function downsampleBuffer(
	buffer: Float32Array,
	recordSampleRate: number,
	targetSampleRate: number
): Float32Array {
	if (targetSampleRate === recordSampleRate) return buffer;
	if (targetSampleRate > recordSampleRate) {
		console.error('Target sample rate must be lower than recorded sample rate');
	}

	const sampleRateRatio: number = recordSampleRate / targetSampleRate;
	const newLength: number = Math.round(buffer.length / sampleRateRatio);
	const result: Float32Array = new Float32Array(newLength);

	let offsetResult: number = 0;
	let offsetBuffer: number = 0;

	while (offsetResult < result.length) {
		let nextOffsetBuffer: number = Math.round(
			(offsetResult + 1) * sampleRateRatio
		);
		let accum: number = 0;
		let count: number = 0;

		for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
			accum += buffer[i];
			count++;
		}

		result[offsetResult] = accum / count;
		offsetResult++;
		offsetBuffer = nextOffsetBuffer;
	}

	return result;
}

async function getLinear16(samples: Float32Array): Promise<DataView> {
	let buffer: ArrayBuffer = new ArrayBuffer(0 + samples.length * 2);
	let view: DataView = new DataView(buffer);
	await _floatTo16BitPCM(view, 0, samples);
	return Promise.resolve(view);
}

function toArrayBuffer(buffer: Buffer): ArrayBuffer {
	const ab: ArrayBuffer = new ArrayBuffer(buffer.length);
	const view: Uint8Array = new Uint8Array(ab);
	for (let i = 0; i < buffer.length; ++i) {
		view[i] = buffer[i];
	}
	return ab;
}

function withWaveHeader(
	data: ArrayBuffer,
	numberOfChannels: number,
	sampleRate: number
): ArrayBuffer {
	// http://soundfile.sapp.org/doc/WaveFormat/

	const header: ArrayBuffer = new ArrayBuffer(44);
	const SubChunk2Size: number = data.byteLength;
	const bitsPerSample: number = 16;
	const bytesPerSample: number = bitsPerSample / 8;

	const dataView: DataView = new DataView(header);

	dataView.setUint8(0, 'R'.charCodeAt(0));
	dataView.setUint8(1, 'I'.charCodeAt(0));
	dataView.setUint8(2, 'F'.charCodeAt(0));
	dataView.setUint8(3, 'F'.charCodeAt(0));

	dataView.setUint32(4, SubChunk2Size + 36, true);

	dataView.setUint8(8, 'W'.charCodeAt(0));
	dataView.setUint8(9, 'A'.charCodeAt(0));
	dataView.setUint8(10, 'V'.charCodeAt(0));
	dataView.setUint8(11, 'E'.charCodeAt(0));
	dataView.setUint8(12, 'f'.charCodeAt(0));
	dataView.setUint8(13, 'm'.charCodeAt(0));
	dataView.setUint8(14, 't'.charCodeAt(0));
	dataView.setUint8(15, ' '.charCodeAt(0));

	dataView.setUint32(16, 16, true); // 16 for PCM
	dataView.setUint16(20, 1, true); // 1 for PCM
	dataView.setUint16(22, numberOfChannels, true);
	dataView.setUint32(24, sampleRate, true);
	dataView.setUint32(28, sampleRate * numberOfChannels * bytesPerSample, true);
	dataView.setUint16(32, numberOfChannels * bytesPerSample, true);
	dataView.setUint16(34, bitsPerSample, true);

	dataView.setUint8(36, 'd'.charCodeAt(0));
	dataView.setUint8(37, 'a'.charCodeAt(0));
	dataView.setUint8(38, 't'.charCodeAt(0));
	dataView.setUint8(39, 'a'.charCodeAt(0));
	dataView.setUint32(40, SubChunk2Size, true);
	return helpers.concatBuffers(header, data);
}

export {
	downsampleBuffer,
	getLinear16,
	toArrayBuffer,
	withWaveHeader
};