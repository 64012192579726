import React, { useRef, useState, useEffect } from "react";
import { Row, FormControl, InputGroup, Button, Form } from "react-bootstrap";
import { AppContext } from "../contexts/appContext";

const SelectVideo = () => {
  const { video, setVideo, start } = AppContext();
  const defaultValue = useRef(undefined);

  const handleChange = (e) => {
	// console.log('handleChange',  e.target.value);
    setVideo(e.target.value);
  };

  const videos = [
      { value: null, name: '' },
      { value: 'Felipe.mp4', name: 'Felipe VI' },
      { value: 'Felipe_2.mp4', name: 'Felipe VI b' },
      { value: 'JuanCarlos.mp4', name: 'Juan Carlos I' },
      { value: 'MarioDrag.mp4', name: 'Mario Dragi' },
      { value: 'PabloIglesias.mp4', name: 'Pablo Iglesias' },
      { value: 'PedroSánchez.mp4', name: 'Pedro Sánchez' },
  ];

  function getVideoOptions(videos) {
      return videos.map((video) => {
          return <option value={video.value} key={video.value}>{video.name}</option>;
      });
  }

  return (

	<Form.Group controlId="exampleForm.ControlSelect1">
		<Form.Label>Select a driving video</Form.Label>
		<Form.Control as="select" onChange={handleChange} value={video} disabled={start} >
            {getVideoOptions(videos)}
		</Form.Control>
	  </Form.Group>
  );
};

export default SelectVideo;
