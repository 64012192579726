import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Switch, Route } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../Title';
import LogoCol from '../LogoCol';

import { IApp } from '../../App';
import { ISection } from '../../hooks/useGetSections';
import LoggedRoute from './LoggedRoute';
import Section from '../pages/Section';
import NotFound from '../pages/NotFound';

import SpeechToText from '../utopia/loquista/asr/SpeechToText';
import TextToSpeech from '../utopia/loquista/tts/TextToSpeech';
import TextTranslator from '../utopia/loquista/translateText/TextTranslator';
import VoiceTranslator from '../utopia/loquista/translateVoice/VoiceTranslator';
import VoiceGenerator from '../utopia/loquista/voiceGenerator/VoiceGenerator';
import SpellChecker from '../utopia/loquista/spellChecker/SpellChecker';
import Labeling from '../utopia/loquista/labeling/Labeling';
import MusicGenerator from '../utopia/loquista/musicGenerator/MusicGenerator';
import VoiceId from '../utopia/sesame/voiceIdentifier/VoiceId';
import FaceRecog from '../utopia/vision/faceRecognition/FaceRecog';
import FaceReplacement from '../utopia/vision/faceReplacement/FaceReplacement';
import FaceMovement from '../utopia/vision/faceMovement/FaceMovement';
import FashionAnimation from '../utopia/vision/fashionAnimation/FashionAnimation';
import Clothes from '../utopia/vision/clothesDetection/Clothes';
import CodeReader from '../utopia/vision/codeReader/CodeReader';
import NIFReader from '../utopia/vision/nifReader/NIFReader';
import ObjectDetection from '../utopia/vision/objectDetection/ObjectDetection';
import Avatar from '../utopia/vision/mimic/Avatar';
import Avatar2 from '../utopia/vision/mimic2/Avatar';
import Assistant from '../utopia/brain/digital_assistants/Assistant';
import VA from '../utopia/flow/va/VA';
import { capitalizeFirstLetter } from '../../utils/helpers';

interface IProps {
  sections: ISection[];
  apps: IApp[];
  name: string;
}

const LandingPage = ({ sections, apps, name }: IProps) => {
  const [isShrunk, setShrunk] = useState<boolean>(false);

  useEffect(() => {
    const scrollHandler = () => {
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop > 50) setShrunk(true);
      if (!scrollTop) setShrunk(false);
    };

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);


  const routes = sections.map((item, key) => (
    <Route key={key} exact path={`/${item.name}`}>
      <Section location={item.name} apps={apps} groups={item.groups ?? ''} />
    </Route>
  ));

  const loggedRoutes = apps.map((item, key) => (
    <LoggedRoute key={key} path={`/${item.section}/${item.path}`} apps={apps}
      component={getCompByApp(item)} />
  ));

  const content = (
    <Switch>
      {routes}
      {loggedRoutes}
    </Switch>
  );

  return (
    <Container fluid className="utopia-landing-page">
      <Helmet>
        <title>{capitalizeFirstLetter(name)}</title>
      </Helmet>
      <Row>
        <Col id="utopia-landing-page-logo" className={`p-0 utopia-landing-page-panel ${isShrunk ? 'shrunk' : ''}`}
          xs={12} md={3} lg={2}>
          <LogoCol name={name} />
        </Col>
        <Col id="utopia-landing-page-content" className={`p-0 utopia-landing-page-panel ${isShrunk ? 'shrunk' : ''}`}
          xs={12} md={9} lg={10}>
          <Title title={name} />
          {content}
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPage;

function getCompByApp(app: IApp): React.ComponentType<any> {
  const { path } = app;

  switch (true) {
    case /asr/.test(path):
      return SpeechToText;
    case /tts/.test(path):
      return TextToSpeech;
    case /translate-text/.test(path):
      return TextTranslator;
    case /translate-voice/.test(path):
      return VoiceTranslator;
    case /generate-voice/.test(path):
      return VoiceGenerator;
    case /spellcheck/.test(path):
      return SpellChecker;
    case /labeling/.test(path):
      return Labeling;
    case /music-generator/.test(path):
      return MusicGenerator;
    case /voice-id/.test(path):
      return VoiceId;
    case /face-id/.test(path):
      return FaceRecog;
    case /face-replacement/.test(path):
      return FaceReplacement;
    case /face-movement/.test(path):
      return FaceMovement;
    case /fashion-animation/.test(path):
      return FashionAnimation;
    case /clothes/.test(path):
      return Clothes;
    case /codereader/.test(path):
      return CodeReader;
    case /nifreader/.test(path):
      return NIFReader;
    case /object-detection/.test(path):
      return ObjectDetection;
    case /mimic$/.test(path):
      return Avatar;
    case /mimic2/.test(path):
      return Avatar2;
    case /assistant/.test(path):
      return Assistant;
    case /dialoga-general/.test(path):
      return VA;
    default:
      return NotFound;
  }
};
