import React from 'react';

type Direction = 'left' | 'right';

interface IProps {
	direction: Direction;
}

const ChatBubbleTriangle = ({ direction }: IProps) => {
	return (
		<span className={`triangle-${direction}`}></span>
	);
};

export default ChatBubbleTriangle;