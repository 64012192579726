import React, { Fragment, useState, useRef, useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';

import { EnvContext, DomainContext } from '../../../../context/Context';

import TextToSpeechForm, { IParams } from './TextToSpeechForm';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import ErrorPage from '../../../pages/ErrorPage';
import Loading from '../../../pages/Loading';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import { toArrayBuffer, withWaveHeader } from '../../../../utils/audio';
import { Model } from './useTextToSpeechForm';

interface IProps {
	title: string;
	description?: string;
	isComponent?: boolean;
	hidden?: boolean;
	disabled?: boolean;
	audioOff?: boolean;
	gotAudio?: (name: string) => void;
	onLoading?: (isLoading: boolean) => void;
	onWorking?: (isWorking: boolean) => void;
	gotParams?: (params: IParams) => void;
	onProgress?: (progress: number) => void;
}

let stream: AudioBufferSourceNode[] = [];
let streamIndex: number = 0;
let isWaiting: boolean = false;

const TextToSpeech = (props: IProps) => {
	const { title, description, isComponent, hidden, disabled, audioOff, ...rest } = props; // props
	const { gotAudio, onLoading, onWorking, gotParams, onProgress } = rest; // events

	// useContext
	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	// useRef
	const audioRef = useRef<HTMLAudioElement>();
	const wsRef = useRef<WebSocket>();

	// useState
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isWorking, setIsWorking] = useState<boolean>(false);
	// const [socketId, setSocketId] = useState<string>('');
	const [params, setParams] = useState<IParams>();
	const [isError, setIsError] = useState<boolean>(false);
	const [warn, setWarn] = useState<string>('');


	useEffect(() => {
		return () => wsRef?.current?.close();
	}, []);

	const handleDownload = async (file: string) => {
		try {
			const fileContent = await downloadFile(domain, env, file, params?.model as Model);
			const src: string = `data:audio/wav; base64, ${fileContent}`;

			wsRef?.current?.close();

			if (audioRef.current) {
				audioRef.current.src = src;
				audioRef.current.load();
			}

			gotAudio && gotAudio(file);
		} catch (error) {
			console.warn(error);
			setWarn('An error occurred while downloading audio file');
		}
	};

	const handleConnect = async (audioContext: AudioContext) => {
    let wsPort: number = 0
    if (params?.model !== 'TTS') wsPort = env.includes('prod') ? 8014 : 4014;
		else wsPort = env.includes('prod') ? 8001 : 4001;
		const wsUrl: string = `wss://${domain}/ws${wsPort}`;

		let fileName: string = '';

		wsRef.current = new WebSocket(wsUrl);

		wsRef.current.onopen = () => {
			setIsWorking(true);
			setWarn('');
			onWorking && onWorking(true);
		};

		wsRef.current.onclose = () => {
			setIsWorking(false);
			onWorking && onWorking(false);
		};

    let numBlock = 0
		wsRef.current.onmessage = async (message) => {
			try {
				const { data } = message;
				const { uuid, error, keep_alive, audio_content, finished, ...rest } = JSON.parse(data);
        
				if (error) {
					console.warn(error);
					setWarn('An error occurred while receiving message');
				} else if (keep_alive) console.log('web socket is still connected');
				else if (!keep_alive && uuid) {
					// setSocketId(uuid);
					if (params) {
						fileName = await processAudio(domain, env, uuid, params);
						console.log(`Audio ${fileName} is processing...`);
					}
				} else if (audio_content) {
          numBlock++
					const { total_blocks } = rest;
          let { num_block, sampleRate } = rest;
          if (params?.model !== 'TTS') {
            sampleRate = 24000;
            num_block = numBlock
          }

          const model = params?.model as Model
          
					!audioOff && await playAudio(audio_content, num_block, total_blocks, sampleRate, audioContext, model);
					onProgress && onProgress(Math.round(Math.floor((num_block / total_blocks) * 100)));
					if (num_block && total_blocks && num_block === total_blocks) {
						handleDownload(fileName);
					}
				}
        if (finished) {
          handleDownload(fileName);
        }
			} catch (error) {
				console.warn(error);
				setWarn('An error occurred while playing audio');
			}
		};

		wsRef.current.onerror = (error) => {
			console.warn(error);
			setWarn('An error occurred with tcp connection');
		};
	};

	const handleDisconnect = () => {
		if (wsRef.current) wsRef.current.close();
		// setSocketId('');
		setIsWorking(false);
		// setWarn('');
		onWorking && onWorking(false);

		// audio elements
		stream = [];
		streamIndex = 0;
		isWaiting = false;
	};

	const handleFormParams = (params: IParams) => {
		setIsLoading(false);
		setParams(params);
		onLoading && onLoading(false);
		gotParams && gotParams(params);
	};

	const form = (
		<TextToSpeechForm
			hidden={isLoading}
			disabled={isWorking || (disabled ?? false)}
			audioRef={audioRef}
			onConnect={(audioContext) => handleConnect(audioContext)}
			onDisconnect={() => handleDisconnect()}
			gotParams={(params: IParams) => handleFormParams(params)}
			onError={(error: Error) => {
				console.log(error.message);
				setIsError(true);
			}}
		/>
	);

	const helmet = !isComponent && (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description ?? 'Utopia app'} />
		</Helmet>
	);


	const alert = (
		<Container fluid>
			<Row>
				<Col>
					<Alert className="my-1 mx-2" variant="warning">{warn}</Alert>
				</Col>
			</Row>
		</Container>
	);

	const content = (
		<Fragment>
			{form}
			{isLoading && <Loading />}
			{warn && alert}
		</Fragment>
	);

	return (
		<Fragment>
			{helmet}
			<div className={hidden ? 'd-none' : ''}>
				<SubTitle name={title} hidden={isComponent ?? false} />
				<Description text={description || 'Utopia.AI'} hidden={isComponent ?? false} />
				{isError ? <ErrorPage code={503} /> : content}
			</div>
		</Fragment>
	);
};

export default TextToSpeech;

async function processAudio(domain: string, env: string, uuid: string, formParams: IParams): Promise<string> {
	try {
		const url = `https://${domain}/api/loquista/tts/process`;
		const { ...args } = formParams;
		const params = { uuid, ...args };
		const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
		const { data } = await axios.post(url, params, options);
		return Promise.resolve(data as string);
	} catch (error) {
		return Promise.reject(new Error('An error occurred while processing audio file'));
	}
}

/**
 * Audio play handler
 */
type AudioContent = { data: Buffer; };

export function playAudio(audio: AudioContent, num: number, total: number, rate: number = 22050, audioContext?: AudioContext, model: Model = 'TTS'): Promise<void> {
	return new Promise((resolve, reject) => {
		const audioCtx = audioContext || new (window.AudioContext || (window as any).webkitAudioContext)();
		const orange: string = '#ffa500';
		const green: string = '#5bc336';

		try {
			const buffer: Buffer = audio.data;
			const arrayBuffer: ArrayBuffer = toArrayBuffer(buffer);
			const audioWithWaveHeader: ArrayBuffer = withWaveHeader(arrayBuffer, 1, rate);

			const onDecodeSuccess = (audioBufferChunk: AudioBuffer) => {
				const source: AudioBufferSourceNode = audioCtx.createBufferSource();
				source.buffer = audioBufferChunk;
				source.connect(audioCtx.destination);
				stream.push(source);

				console.log(`%cReceived [${num}/${total}]`, `color: ${orange}`);

				if (num === 1) {
					stream[streamIndex].start(0);
					streamIndex++;
				}

				if (isWaiting) {
					isWaiting = false;
					if (stream[streamIndex]) {
						stream[streamIndex].start();
						streamIndex++;
					}
				}

        if (model !== 'TTS') {
          source.onended = () => {
            if (!stream[streamIndex]) {
              isWaiting = true;
              console.warn('Waiting for new package...');
            } else {
              stream[streamIndex].start();
              streamIndex++;
            }
          };
        }
        else {
          source.onended = () => {
            console.log(`%cProcessed [${streamIndex}/${total}]`, `color: ${green}`);

            if (streamIndex < total) {
              if (!stream[streamIndex]) {
                isWaiting = true;
                console.warn('Waiting for new package...');
              } else {
                stream[streamIndex].start();
                streamIndex++;
              }
            } else {
              stream = [];
              streamIndex = 0;
              return resolve();
            }
          };
        }
			};

			const onDecodeError = (error: unknown) => {
				console.error(`onDecodeError: ${error}`);
				return reject(new Error('An error occurred on decoded audio'));
			};

			if (audioCtx.state === 'suspended' || audioCtx.state === 'closed') audioCtx.resume();
			audioCtx.decodeAudioData(audioWithWaveHeader, onDecodeSuccess, onDecodeError);
		} catch (error) {
			return reject(new Error('An error occurred while decoding audio'));
		}
	});
}

/**
 * Download file
 */
async function downloadFile(domain: string, env: string, fileName: string, model: Model = 'TTS'): Promise<string> {
	return new Promise(async (resolve, reject) => {
		try {
			const url = `https://${domain}/api/loquista/tts/download`;
			const params = { fileName, model };
			const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
			const { data: { fileContent } } = await axios.post(url, params, options);
			resolve(fileContent as string);
		} catch (error) {
			reject(new Error('An error occurred while downloading audio file'));
		}
	});
}