import React, { Fragment } from 'react';

import { IAssistant } from '../useGetAssistants';
import { capitalizeFirstLetter } from '../../../../utils/helpers';
import CustomMessage from '../../../utilities/CustomMessage';
import Button from 'react-bootstrap/Button';

interface IProps {
	shown: boolean;
	disabled?: boolean;
	assistant: IAssistant;
	greeting: () => void;
	farewell: () => void;
}

const GreetingButton = ({ shown, disabled, assistant, greeting, farewell }: IProps) => {
	const hello = assistant.language === 'en' ? 'Hello' : 'Hola';
	const bye = assistant.language === 'en' ? 'Bye' : 'Adiós';

	return (
		<Fragment>
			{assistant.active === 'true' && (
				<Button
					disabled={disabled ?? false}
					className="d-inline"
					variant="outline-dark"
					size={shown ? 'sm' : 'lg'}
					onClick={shown ? farewell : greeting}>
					{shown ? bye : hello} {capitalizeFirstLetter(assistant.name)}
				</Button>)
			}
			{assistant.active === 'false' && <CustomMessage message="In Progress" />}
		</Fragment>
	);
};

export default GreetingButton;