/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useContext } from 'react';

import { SizeContext } from '../context/Context';

import loquistaLogo from '../resources/logo_loquista.svg';
import sesameLogo from '../resources/logo_sesame.svg';
import utopiaLogo from '../resources/logo-utopia-115x115.svg';
// import brainLogo from '../resources/logo_brain.svg';

interface IProps {
  name: string;
}

const LogoCol = ({ name }: IProps) => {
  const { size } = useContext(SizeContext);

  const style = css`
  text-align: center;
  @media (max-width: 576px) {
    img {
      display: none;
    }
  }
  @media (max-width: 768px) {
    position: fixed;
    height: 14vh;
    top: 0;
    left: 0;
    img {
      height: 75%;
      margin-left: 2rem;
      margin-top: 0.75rem;
    }
  }
  @media (min-width: 768px) {
    background: #eeeeee;
    height: 100%;
    img {
      margin: 1.5rem auto;
      width: 80%;
    }
  }
  `;

  return (
    <div className={`utopia-logo-column ${size}`} css={style}>
      <img src={getSrcByName(name)} alt={name} />
    </div>
  );
};

export default LogoCol;

function getSrcByName(name: string): string {
  if (name === 'loquista') return loquistaLogo;
  else if (name === 'sesame') return sesameLogo;
  else return utopiaLogo;
}
