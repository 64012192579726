/** @jsxImportSource @emotion/react */
// import { css } from '@emotion/react';
import React from 'react';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

// import { getUuid } from '../../../../utils/helpers';
import { ICheckResponse } from './useSpellCheck';

interface IProps {
	id?: string;
	text: string;
	language?: string;
	onChange: (proposed: string) => void;
	onResult?: (result: ICheckResponse) => void;
}

const SpellCheckerResult = ({ id, text, language, onChange, onResult }: IProps) => {
	// const [show, setShow] = useState<boolean>(false);

	/*
	const style = css`
	.proposed-text {
		position: absolute;
		top: 0;
		left: 2.5rem;
		padding: 0.5rem;
		background: rgb(245, 245, 245);
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		color: rgb(10, 10, 10);
		font-style: italic;
		border-radius: 0.3rem;
		width: auto;
		height: auto;
		p {
			margin: 0;
		}
	}
	.proposed-text-content {
		cursor: pointer;
	}
	.hide-proposed-text {
		cursor: pointer;
		padding: 2px;
		margin: 0 5px;
	}
	`;
	*/


	/*
	const result = useSpellCheck({
		id: id ?? getUuid(),
		text,
		language
	});
	*/

	/*
	useEffect(() => {
		if (result) {
			const proposed = result.texts.proposed.replace(/\s/g, '');
			const original = result.texts.original.replace(/\s/g, '');
			if (proposed !== original) {
				setShow(true);
			} else setShow(false);
			onResult && onResult(result);
		}
		// eslint-disable-next-line
	}, [result]);
	*/

	return (
		<React.Fragment>
			{
				/*
				(result && text) && (
				<div css={style}>
					{show && (
						<div className="proposed-text">
							<p>
								<span className="proposed-text-content" onClick={() => onChange(result.texts.proposed)}>
									{result.texts.proposed ?? ''}
								</span>
								<FontAwesomeIcon className='hide-proposed-text' icon={faTimesCircle} onClick={() => setShow(false)} />
							</p>
						</div>
					)}
				</div>
			)
			*/
			}
		</React.Fragment>
	);
};

export default SpellCheckerResult;