import React, { useEffect, useState, useRef, useContext } from 'react';

import { EnvContext, DomainContext } from '../../../../context/Context';
import axios from 'axios';

import * as requests from '../../../../requests/requests';
import * as utils from '../../../../utils/utils';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import CustomSelect from '../../../forms/CustomSelect';
import CustomTextArea from '../../../forms/CustomTextArea';

const languagesApi = 'api/loquista/translate-text/languages';
const translateApi = 'api/loquista/translate-text/translate';

export interface IParams {
  host: string;
  inputLanguage: string;
  outputLanguage: string;
  inputText?: string;
  outputText?: string;
}

interface IHost {
  host: string;
  env: string;
}

interface ICombination {
  input: string;
  output: string;
}

interface IProps {
  className: string;
  onError: (error: string | null) => void;
  gotParams: () => void;
}

const TextTranslatorForm = ({ className, onError, gotParams }: IProps) => {
  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);
  const isMounted = useRef<boolean>(false);

  const [hosts, setHosts] = useState<string[]>(['']);
  const [selectedHost, setSelectedHost] = useState<string>('');
  const [languages, setLanguages] = useState<string[]>(['']);
  const [inputLanguage, setInputLanguage] = useState<string>('');
  const [outputLanguage, setOutputLanguage] = useState<string>('');
  const [inputText, setInputText] = useState<string>('');
  const [outputText, setOutputText] = useState<string>('');
  const [possibleCombs, setPossibleCombs] = useState<ICombination[]>([]);

  useEffect(() => {
    isMounted.current = true;

    const getHosts = async () => {
      const url = `https://${domain}/api/loquista/translate-text/hosts`;
      const params = { frontEnv: env };
      const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };

      try {
        const { data: { availableHosts } } = await axios.post(url, params, options);

        const hosts = availableHosts.map((item: IHost) => item.host);
        const host = hosts[0];
        if (isMounted.current) {
          setHosts(hosts);
          setSelectedHost(host);
        }

      } catch (error) {
        isMounted.current && onError('Service unavailable');
      }
    };

    if (!selectedHost) getHosts();

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedHost) {
      requests
        .getTranslatorLanguages(`https://${domain}/${languagesApi}`, {
          host: selectedHost,
          uuid: 'apps.utopia.ai',
        })
        .then(({ languages, combinations }) => {
          if (isMounted.current) {
            setLanguages(languages);
            setInputLanguage(languages[1]);
            setOutputLanguage(languages[0]);
            setPossibleCombs(combinations);
            setInputText(utils.hiWorldByLanguage(languages[1]));
            gotParams();
          }
        })
        .catch((error) => {
          const reason =
            typeof error === 'string' ? error : JSON.stringify(error);
          if (isMounted.current) onError(reason);
        });
    }
    // eslint-disable-next-line
  }, [selectedHost]);

  useEffect(() => {
    if (inputLanguage && isMounted.current) {
      setInputText(utils.hiWorldByLanguage(inputLanguage));
      onError(null);
    }
    // eslint-disable-next-line
  }, [inputLanguage]);

  useEffect(() => {
    if (outputLanguage && isMounted.current) {
      onError(null);
    }
    // eslint-disable-next-line
  }, [outputLanguage]);

  const translate = () => {
    // Permitido cualquier combinacion de idiomas (con el nuevo proto/servicio)
    // const success = possibleCombs.filter(
    //   (item) => item.input === inputLanguage && item.output === outputLanguage
    // );

    if (!inputText) {
      const reason = `Please, write something to translate it.`;
      return onError(reason);
    }

    // if (inputLanguage === outputLanguage) {
    //   const reason = `Please, choose two different languages.`;
    //   return onError(reason);
    // }

    const inputCode = inputLanguage.split('-')[0].toUpperCase();
    const outputCode = outputLanguage.split('-')[0].toUpperCase();

    const params = {
      uuid: 'apps.utopia.ai',
      host: selectedHost,
      code: `${inputCode}_2_${outputCode}`,
      text: inputText,
    };
    requests
      .translate(`https://${domain}/${translateApi}`, params)
      .then((response) => {
        const { output_sentence, text_truncated } = response; // output_sentence, score, text_truncated
        if (text_truncated) console.warn('Text truncated');
        if (isMounted.current && !text_truncated) {
          setOutputText(output_sentence);
        }
      })
      .catch((error) => {
        const reason =
          typeof error === 'string' ? error : JSON.stringify(error);
        if (isMounted.current) onError(reason);
      });

    // } else {
    //   if (inputLanguage === outputLanguage) {
    //     if (isMounted.current) setOutputText(inputText);
    //   } else {
    //     const reason = `Translation from ${inputLanguage} to ${outputLanguage} is not implemented`;
    //     if (isMounted.current) onError(reason);
    //   }
    // }
  };

  const copyOnClipBoard = () => {
    if (outputText) {
      navigator.clipboard
        .writeText(outputText)
        .then(() => console.log(`Got it: ${outputText}`))
        .catch((error) => console.error(error));
    }
  };

  return (
    <Container fluid className={className}>
      <Row>
        <Col>
          <Form>
            <Form.Row className={env === 'prod' ? 'd-none' : ''}>
              <Col>
                <CustomSelect
                  name="speech-to-text-host"
                  label="host"
                  options={hosts}
                  onChange={(value) => setSelectedHost(value)}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={12} sm={6}>
                <CustomSelect
                  name="text-translator-input-language"
                  label="Input language"
                  options={[...languages, "XX"]}
                  selected={inputLanguage}
                  onChange={(value) => setInputLanguage(value)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <CustomSelect
                  name="text-translator-output-language"
                  label="Output language"
                  options={languages}
                  selected={outputLanguage}
                  onChange={(value) => setOutputLanguage(value)}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={12} md={6}>
                <CustomTextArea
                  name="text-translator-input"
                  label="input"
                  value={inputText}
                  rows={6}
                  onChange={(text) => setInputText(text)}
                />
                <div className="text-right">
                  <Button
                    variant="primary"
                    className="mb-3"
                    onClick={translate}
                    disabled={!inputText ? true : false}
                  >
                    Translate
                  </Button>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <CustomTextArea
                  name="text-translator-output"
                  label="output"
                  rows={6}
                  disabled={true}
                  value={outputText}
                />
                <div className="text-right text-md-left">
                  <Button
                    variant="light"
                    className="mb-3"
                    onClick={copyOnClipBoard}
                  >
                    Copy
                  </Button>
                </div>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default TextTranslatorForm;
