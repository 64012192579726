import React, { useEffect, useRef, useState, useContext } from 'react';
import axios from 'axios';

import { DomainContext, EnvContext } from '../../../../context/Context';

import * as utils from '../../../../utils/utils';
import * as requests from '../../../../requests/requests';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import CustomSelect from '../../../forms/CustomSelect';
import useToast from '../../../../hooks/useToast';

interface IProps {
  disabled?: boolean;
  onLanguageSelection: (language: string) => void;
  onFileSelection: (file: string) => void;
  onModelSelection: (model: string) => void;
  onVoiceNameCreated?: (voiceName: string) => void;
  onUploadFile: (file: File | null) => void;
  onFileActionSelection?: (action: string) => void;
  notFinishedVoices: string[];
}

const VoiceGeneratorForm = (
  {
    disabled,
    onLanguageSelection,
    onFileSelection,
    onModelSelection,
    onVoiceNameCreated,
    onUploadFile,
    onFileActionSelection,
    notFinishedVoices
  }:
  IProps
) => {
  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);

  const isMounted = useRef<boolean>(false);

  const [languages, setLanguages] = useState<string[]>(['']);
  const [selectedLanguage, setSelectedLanguages] = useState<string>('');
  const [files, setFiles] = useState<string[]>(['']);
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [models, setModels] = useState<string[]>(['']);
  const [selectedModel, setSelectedModel] = useState<string>('TTS');
  const [voiceName, setVoiceName] = useState<string>('');
  const [selectedFileAction, setSelectedFileAction] = useState<string>('Upload file');
  const [selectedNotFinishedVoice, setSelectedNotFinishedVoice] = useState<string>('');

  const api = 'api/loquista/generate-voice';
  const languagesUrl = `https://${domain}/${api}/languages`;

  const toast = useToast();

  
  // first render useEffect
  useEffect(() => {
    isMounted.current = true;

    requests
      .getS3Languages(languagesUrl)
      .then((languages) => {
        if (isMounted.current) {
          const orderedLangs = utils.orderLanguages(languages);
          setLanguages(orderedLangs);
          setSelectedLanguages(orderedLangs[1]);
          onLanguageSelection(orderedLangs[1]);
          setModels(getModels())
        }
      })
      .catch((error) => console.error(error));

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const getFiles = async (model = "TTS") => {
    try {
      const api = `https://${domain}/api/loquista/generate-voice/files/${selectedLanguage}?model=${model}`;
      const headers = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
      const { data: { files } }: { data: { files: string[]; }; } = await axios.get(api, headers);
      if (files.length > 0) {
        isMounted.current && setFiles(files);
        isMounted.current && setSelectedFile(files[0]);
        isMounted.current && onFileSelection(files[0]);
      } else console.warn('None file found');
    } catch (error) {
      console.error(error);
    }
  };
  
  // onChange: selectedLanguage
  useEffect(() => {
    selectedLanguage && getFiles(selectedModel);
    // eslint-disable-next-line
  }, [selectedLanguage]);

  useEffect(() => {
    selectedModel && getFiles(selectedModel);
    setVoiceName('');
    onVoiceNameCreated && onVoiceNameCreated('');
    // eslint-disable-next-line
  }, [selectedModel])

  useEffect(() => {
    if (voiceName.trim() === '') onVoiceNameCreated && onVoiceNameCreated('');

    if (notFinishedVoices.includes(voiceName))
      setSelectedNotFinishedVoice(voiceName);
    else
      setSelectedNotFinishedVoice('');

    // eslint-disable-next-line
  }, [voiceName])

  const handleCreateName = async (_voiceName: string = voiceName) => {
    const url = `https://${domain}/${api}/voices/${_voiceName}`;
    const headers = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
    const { data: { validName } } = await axios.get(url, headers)
    if (!validName) {
      toast({ type: 'error', message: 'Voice name already exists' });
      setVoiceName('');
      onVoiceNameCreated && onVoiceNameCreated('');
      return;
    }
    toast({ type: 'success', message: 'Voice name is valid' });
    onVoiceNameCreated && onVoiceNameCreated(_voiceName);
  };
  
  const form = (
    <Form>
      <Form.Row>
        <Col xs={12} md={6}>
          <CustomSelect
            name="voice-generator-language"
            label="language"
            disabled={disabled ?? false}
            options={languages}
            selected={selectedLanguage}
            onChange={(language) => {
              if (isMounted.current) {
                setSelectedLanguages(language);
                onLanguageSelection(language);
              }
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <CustomSelect
            name="voice-generator-file"
            label="file"
            disabled={disabled ?? false}
            options={files}
            selected={selectedFile}
            onChange={(file) => {
              if (isMounted.current) {
                setSelectedFile(file);
                onFileSelection(file);
              }
            }}
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col xs={12} md={6}>
          <CustomSelect
            name="voice-generator-model"
            label="model"
            options={models}
            selected={selectedModel}
            onChange={(model) => {
              if (isMounted.current) {
                setSelectedModel(model);
                onModelSelection(model);
              }
            }}
          />
        </Col>

        { selectedModel !== 'TTS' &&
          <>
            <Form.Row>
              <Col>
                <CustomSelect
                  name='voice-generator-uploadorcreate'
                  label='File action'
                  options={['Upload file', 'Create file']}
                  selected={selectedFileAction}
                  onChange={(option) => {
                    if (isMounted.current) {
                      setSelectedFileAction(option);
                      onFileActionSelection && onFileActionSelection(option);
                      setVoiceName('');
                      onVoiceNameCreated && onVoiceNameCreated('');
                    }
                  }}
                />
              </Col>
              { selectedFileAction === 'Upload file' && <Col xs={12} md={6}>
                <Form.Label>Upload file</Form.Label>
                <Form.Control
                  type='file'
                  accept='.wav, .mp3'
                  onChange={(e) => {
                    if (!isMounted.current) return;
                    
                    const target = e.target as HTMLInputElement;
                    if (!target.files) return;

                    const file = target.files[0];
                    if (!file.name.endsWith('.wav') && !file.name.endsWith('.mp3')) {
                      alert('Invalid file type');
                      target.value = '';
                      onUploadFile(null);
                      return
                    }
                    
                    onUploadFile(target.files[0]);
                  }}
                />
              </Col> }
            </Form.Row>
          
            <Col xs={12} md={6}>
              <Form.Label>Voice name</Form.Label>
              <Form.Row>
                <Col xs={8} md={8}>
                  <Form.Control
                    type="text"
                    onChange={(e) => setVoiceName(e.target.value)}
                    style={{ height: '31px' }}
                    value={voiceName}
                  />
                </Col>
                <Col xs={4} md={4}>
                  <Button
                    onClick={() => handleCreateName()}
                    disabled={voiceName.trim() === ""}
                    className='d-flex align-items-center'
                    style={{ height: '31px' }}
                  >
                    Create name
                  </Button>
                </Col>
              </Form.Row>
            </Col>
            <Col>
              <CustomSelect
                name='voice-generator-uploadorcreate'
                label='Voice names'
                options={notFinishedVoices}
                selected={selectedNotFinishedVoice}
                onChange={(_voiceName) => {
                  console.log('selected voice name ' + _voiceName)
                  if (!isMounted.current) return;
                  if (_voiceName.trim() === '') {
                    setVoiceName('');
                    return;
                  }
                  setVoiceName(_voiceName)
                  onVoiceNameCreated && onVoiceNameCreated(_voiceName)
                  handleCreateName(_voiceName)
                }}
              />
            </Col>
          </>
        }
      </Form.Row>
    </Form>
  );
  
  return (
    <Container fluid>
      <Row>
        <Col>{form}</Col>
      </Row>
    </Container>
  );
};

export default VoiceGeneratorForm;

function getModels() {
  return ['TTS', 'cloning']
}