import React, { useState, useEffect } from 'react';

import Carousel from 'react-bootstrap/Carousel';

interface IProps {
  items: string[];
  activeIndex: number;
  controls?: boolean;
  onIndexChange?: (index: number) => void;
  onSelectedItem?: (item: string) => void;
}

const CustomCarousel = ({ items, activeIndex, controls, onIndexChange, onSelectedItem }: IProps) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(activeIndex);
  }, [activeIndex]);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
    if (onIndexChange) onIndexChange(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      indicators={false}
      interval={null}
      fade={true}
      controls={controls ?? false}
    >
      {items.map((item: string, key: number) => (
        <Carousel.Item key={key}>
          <div className="text-center">
            <h4
              className="pt-4 pb-2 utopia-carousel-title"
              onClick={() => {
                if (onSelectedItem) onSelectedItem(item);
              }}
            >
              {item}
            </h4>
            <p className="font-weight-light">
              <small>{`${index + 1}/${items.length}`}</small>
            </p>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
