import React, { useEffect, useState, useRef, useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { DomainContext, LoginContext, EnvContext } from '../../../../context/Context';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';

import { AppContext } from "./contexts/appContext";
import CanvasFeed from "./components/CanvasFeed";
import DisplayResponse from "./components/DisplayResponse";
import VideoFeed from "./components/VideoFeed";
import GetImage from "./components/GetImage";
import SelectVideo from "./components/SelectVideo";
import RefImage from "./components/RefImage";
import OutputVideo from "./components/OutputVideo";
// import BackgroundImage from "./components/BackgroundImage";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

interface IProps {
    title: string;
    description?: string;
}

const FaceMovement = ({ title, description }: IProps) => {
    const domain = useContext(DomainContext);
    const env = useContext(EnvContext);

    const { start, setStart, video, setVideo, imageData } = AppContext();

    useEffect(() => {
        console.log('video:', video);
    }, [video]);

    const startHandler = e => {
        console.log('Start');
        setStart(true);
    };

    const stopHandler = e => {
        console.log('Stop');
        setStart(false);
    };

    const faceMovementContent = (
        <Container fluid>
            <Row className={'face-movement-row'}>
                <Col>
                    <GetImage/>
                </Col>
                <Col>
                    <SelectVideo/>
                </Col>
            </Row>

            <Row className={'face-movement-row'}>
                <Col>
                    <h4>Reference image</h4>
                    <RefImage/>
                </Col>
                <Col>
                    <h4>Driving video</h4>
                    <VideoFeed/>
                </Col>
                <Col>
                    <h4>Output video</h4>
                    <OutputVideo/>
                </Col>
            </Row>

            {start && <CanvasFeed />}

            <Row className={'face-movement-row'}>
                <Col>
                    <Button onClick={startHandler}
                        className={`${start ? 'd-none' : ''}`}
                        disabled={!imageData || !video}
                    >Start</Button>
                    <Button
                        onClick={stopHandler}
                        className={`${start ? '' : 'd-none'}`}
                    >Stop</Button>
                </Col>
            </Row>
        </Container>
    );

    return (
        <Fragment>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description ?? 'Utopia app'} />
            </Helmet>
            <SubTitle name={title} />
            <Description text={description ? description : 'Utopia.AI'} />
            {faceMovementContent}
        </Fragment>
    );
};

export default FaceMovement;
