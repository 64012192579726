import React from 'react'
import { Toaster } from 'react-hot-toast'

interface Props {
  posistion?: "top-right" | "top-left" | "top-center" | "bottom-right" | "bottom-left" | "bottom-center"
  expand?: boolean
}

const CustomToaster = ({ posistion, expand }: Props) => {
  if (!posistion) posistion = "top-right"
  if (!expand) expand = false
  
  return (
    <Toaster />
  )
}

export default CustomToaster