import React, { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import { LoginContext } from '../../context/Context';
import { IApp } from '../../App';
import Login from './Login';

interface IProps {
  path: string;
  apps: IApp[];
  component: React.ComponentType<any>;
}

const LoggedRoute = ({ path, apps, component: Component }: IProps) => {
  const { logged } = useContext(LoginContext);

  const [props, setProps] = useState<IApp | undefined>(undefined);

  useEffect(() => {
    const app = apps.filter((item) => path === `/${item.section}/${item.path}`)[0];
    setProps(app);
    // eslint-disable-next-line
  }, [apps]);

  const renderFn = (): JSX.Element => {
    if (logged) return <Component {...props} title={props?.app} />;
    else return <Login app={props?.app} description={props?.description} />;
  };

  return <Route exact path={path} render={renderFn} />;
};

export default LoggedRoute;
