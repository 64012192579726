import { useEffect, useContext, useState } from 'react';
import axios from 'axios';

import { DomainContext, EnvContext } from '../../../../context/Context';

export interface IVideo {
	name: string;
	origin: string;
	key: string;
}

const useGetVideos = (user?: string) => {
	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	const [videos, setVideos] = useState<IVideo[]>([]);

	useEffect(() => {
		const getVideos = async () => {
			const url = `https://${domain}/api/vision/mimic/videos${user ? `/${user}` : ''}`;
			// const params = user && { user };
			const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
			try {
				// const { data } = user ? await axios.get(url, options) : await axios.get(url, options);
				const { data } = await axios.get(url, options);
				const { items } = data;

				const root = 'https://dialoga-machine-learning.s3.amazonaws.com';

				const _videos: IVideo[] = items.map(({ name, key }: { name: string, key: string; }) => ({
					name,
					origin: user ?? 'source',
					key: `${root}/${key}`
				}));

				setVideos(_videos);
			} catch (error) {
				console.log(error);
			}
		};
		getVideos();
		// eslint-disable-next-line 
	}, []);

	return videos;
};

export { useGetVideos };