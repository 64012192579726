import React, { useContext, useRef, useEffect, useState, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import axios from 'axios';

import { LoginContext, EnvContext, DomainContext } from '../../../../context/Context';
import * as helpers from '../../../../utils/helpers';
import * as requests from '../../../../requests/requests';

import LabelingForm from './LabelingForm';
import LabelingWave, { ISegment } from './LabelingWave';
import LabelingWait, { IStatus } from './LabelingWait';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import ErrorBoundary, { IErrorProps } from '../../../ErrorBoundary';
import CustomMessage from '../../../utilities/CustomMessage';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

interface IProps {
  title: string;
  description?: string;
}

const autoSaveTime = 30000; // 30 seconds
const sleepFactor = 0.002;
const awsBucket = 'dialoga-machine-learning';
const appFolder = 'labeling';
const s3 = `https://${awsBucket}.s3.eu-west-1.amazonaws.com`;

interface ICheckFileParams {
  name: string;
}

async function checkFile(params: ICheckFileParams, url: string, callback: (error?: Error) => void) {
  try {
    const response = await requests.labelingCheckSegments(url, params);
    if (response === 'Not found') return callback(new Error(response));
    else return callback();
  } catch (error) {
    callback(new Error('Not found'));
  }
}

function sleep(time: number) {
  let timeout: NodeJS.Timeout;

  const promise = new Promise((resolve) => {
    console.log('Waitting Time (ms):', time);
    timeout = setTimeout(resolve, time);
  });

  return {
    promise,
    cancel: () => clearTimeout(timeout),
  };
}

const Labeling = ({ title, description }: IProps) => {
  const domain = useContext(DomainContext);
  const { logged, setLogged } = useContext(LoginContext);
  const env = useContext(EnvContext);

  const isMounted = useRef<boolean>(false);
  const countRef = useRef<any>();
  const saveBtnRef = useRef<HTMLButtonElement>(null);
  const checkFileIntervalRef = useRef<NodeJS.Timeout>();
  const saveIntervalRef = useRef<NodeJS.Timeout>();
  const isUpdated = useRef<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [waveSrc, setWaveSrc] = useState<string>('');
  const [waveSegments, setWaveSegments] = useState<ISegment[]>([]);
  const [boundError, setBoundError] = useState<IErrorProps | null>(null);
  const [waitStatus, setWaitStatus] = useState<IStatus>({ step: 0 });
  const [successAlert, setSuccessAlert] = useState<boolean>(false);
  const [successUrl, setSuccessUrl] = useState<string>('');
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);
  const [workingFile, setWorkingFile] = useState<string>('');
  const [workingPath, setWorkingPath] = useState<string>('');

  const api = `api/loquista/${appFolder}`;
  const fileMoveUrl = `https://${domain}/${api}/file`;
  const fileProcessUrl = `https://${domain}/${api}/process`;
  const fileCheckUrl = `https://${domain}/${api}/file/check`;
  const fileSegmentsUrl = `https://${domain}/${api}/file/segments`;
  const fileDownloadUrl = `https://${domain}/${api}/file/download`;
  const fileSaveUrl = `https://${domain}/${api}/file/save`;
  const fileLoadUrl = `https://${domain}/${api}/file/load`;
  const fileDeleteUrl = `https://${domain}/${api}/file/delete`;

  useEffect(() => {
    isMounted.current = true;

    if (logged && typeof logged === 'string') {
      const name = helpers.getUserNameByEmail(logged);
      if (isMounted.current) setUserName(name);
    } else {
      const token = localStorage.getItem(`${env}-token`);
      axios.post(`https://${domain}/login/authentication/user`, { token })
        .then(({ data: { email } }) => {
          const name = helpers.getUserNameByEmail(email);
          if (isMounted.current) {
            setLogged(email);
            setUserName(name);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }

    return () => {
      isMounted.current = false;
      if (countRef.current) countRef.current.cancel();
      if (checkFileIntervalRef.current)
        clearInterval(checkFileIntervalRef.current);
      if (saveIntervalRef.current) clearInterval(saveIntervalRef.current);
    };
    // eslint-disable-next-line
  }, []);

  const reset = (): Promise<void> => {
    return new Promise((resolve) => {
      if (saveIntervalRef.current) clearInterval(saveIntervalRef.current);
      if (isMounted.current) {
        setWaitStatus({ step: 0 });
        setWaveSrc('');
        setWaveSegments([]);
        setIsLoading(true);
        setIsDisabled(true);
        setBoundError(null);
        setSuccessAlert(false);
        setSuccessUrl('');
        setWorkingFile('');
        setWorkingPath('');
        resolve();
      }
    });
  };

  const autoSave = () => {
    saveIntervalRef.current = setInterval(() => {
      if (saveBtnRef.current && isUpdated.current) {
        saveBtnRef.current.click();
        isUpdated.current = false;
      } else setSuccessAlert(false);
    }, autoSaveTime);
  };

  const check = (params: ICheckFileParams, time: number): Promise<void> => {
    let attempt = 1;
    return new Promise((resolve) => {
      checkFileIntervalRef.current = setInterval(() => {
        checkFile(params, fileCheckUrl, (error) => {
          if (error) {
            console.warn(`Checking file (${attempt++}): ${error.message}`);
          } else {
            if (checkFileIntervalRef.current)
              clearInterval(checkFileIntervalRef.current);
            resolve();
          }
        });
      }, time);
    });
  };

  const process = async () => {
    try {
      await reset();

      const thrownError = new Error('Component is not mounted');

      if (!selectedFile) throw new Error('No file selected');

      const moveParams = {
        user: userName,
        language: selectedLanguage,
        file: selectedFile,
      };

      if (!isMounted.current) throw thrownError;
      const { path } = await requests.labelingMoveFile(fileMoveUrl, moveParams);

      if (isMounted.current) {
        setWaitStatus({ step: 1 });
        setWorkingPath(`${s3}/${path}`);
      }

      const proccessParams = { path, language: selectedLanguage };
      if (!isMounted.current) throw thrownError;
      const { name, size } = await requests.labelingProcess(
        fileProcessUrl,
        proccessParams
      );

      const time = size * sleepFactor > 1500 ? size * sleepFactor : 1500;

      if (isMounted.current) setWaitStatus({ step: 2, time });

      countRef.current = sleep(time); // wait for a period of time
      if (!isMounted.current) throw thrownError;
      await countRef.current.promise;

      if (isMounted.current) setWaitStatus({ step: 3 });

      const segmentsParams = { name };
      if (!isMounted.current) throw thrownError;

      await check(segmentsParams, time > 1500 ? time / 2 : 1500);

      const fileContent: string = await requests.labelingGetSegments(
        fileSegmentsUrl,
        segmentsParams
      );

      if (!fileContent) throw new Error('File content is undefined');

      const patt = /\n/;
      const segmentsArray: string[] = fileContent.search(patt)
        ? fileContent.split('\n')
        : [fileContent];

      const segments = segmentsArray.map((fragment: string) => {
        try {
          return JSON.parse(fragment);
        } catch (error) {
          console.warn(`Impossible to parse the fragment: ${fragment}`);
          return null;
        }
      });

      const segLen = segments.length;

      if (segLen < 1) throw new Error('No detected audio');

      console.log(`%cNumber of segments: ${segments.length}`, 'color:blue');

      const fragments = segments.map((segment, key) => {
        let { start, end, ...args } = segment;
        start = helpers.decimalAdjust('ceil', start, -2) + 0.01;
        end = helpers.decimalAdjust('floor', end, -2) - 0.01;
        return { key, lang: selectedLanguage, start, end, ...args };
      });

      if (isMounted.current) setWaitStatus({ step: 4, segments: segLen });

      const downParams = { path, file: selectedFile };
      if (!isMounted.current) throw thrownError;
      const { data } = await requests.labelingDownloadFile(
        fileDownloadUrl,
        downParams
      );

      if (!data) {
        if (isMounted.current) {
          setWaitStatus({ step: 0 });
          setBoundError({ title: 'Audio', description: 'Not found data' });
        }
        throw new Error('Downloaded file is undefined');
      }

      const arrayBuffer = data;
      const blob = new Blob([new Uint8Array(arrayBuffer)]);

      if (isMounted.current) {
        setWaitStatus({ step: 0 });
        setWaveSrc(URL.createObjectURL(blob));
        setWaveSegments(fragments);
      }

      const saveParams = {
        user: userName,
        language: selectedLanguage,
        file: selectedFile.replace('.wav', ''),
        segments: fragments,
      };

      if (!isMounted.current) throw thrownError;
      const { location } = await requests.labelingSaveFile(
        fileSaveUrl,
        saveParams
      );

      if (isMounted.current) setSuccessUrl(location);

      const deleteParams = { language: selectedLanguage, file: selectedFile };
      if (!isMounted.current) throw thrownError;
      await requests.labelingDeleteFile(fileDeleteUrl, deleteParams);

      if (isMounted.current) {
        setSuccessAlert(true);
        setIsLoading(false);
        setIsDisabled(false);
        setWorkingFile(selectedFile);
        setForceRefresh(!forceRefresh); // after opening and processing a new file it's necessary to update the file list
        autoSave();
      }
    } catch (error) {
      const description = error as string;
      console.error(description);
      if (isMounted.current) {
        setBoundError({ title: 'Audio processing', description });
        setIsLoading(false);
        setIsDisabled(false);
      }
    }
  };

  const loadFile = async () => {
    try {
      await reset();

      const thrownError = new Error('Component is not mounted');

      if (!selectedFile) throw new Error('No file selected');

      const loadParams = {
        user: userName,
        language: selectedLanguage,
        file: selectedFile.replace('.wav', ''),
      };

      if (!isMounted.current) throw thrownError;
      let { segments } = await requests.labelingLoadFile(
        fileLoadUrl,
        loadParams
      );

      segments = segments.map((segment: any) => {
        let { start, end, ...args } = segment;
        start = helpers.decimalAdjust('ceil', start, -2) + 0.01;
        end = helpers.decimalAdjust('floor', end, -2);
        return { start, end, ...args };
      });

      if (isMounted.current) {
        setWaitStatus({ step: 4, segments: segments.length });
      }

      const path = `${appFolder}/${userName}/${selectedLanguage}/${selectedFile}`;
      const downParams = { path, file: selectedFile };
      if (!isMounted.current) throw thrownError;
      const { data } = await requests.labelingDownloadFile(
        fileDownloadUrl,
        downParams
      );

      if (!data) {
        if (isMounted.current) {
          setWaitStatus({ step: 0 });
          setBoundError({ title: 'Audio', description: 'Not found data' });
        }
        throw new Error('Downloaded file is undefined');
      }

      const arrayBuffer = data;
      const blob = new Blob([new Uint8Array(arrayBuffer)]);

      if (isMounted.current) {
        setWaitStatus({ step: 0 });
        setWaveSrc(URL.createObjectURL(blob));
        setWaveSegments(segments);

        setSuccessAlert(true);
        setIsLoading(false);
        setIsDisabled(false);
        setWorkingFile(selectedFile);
        autoSave();
      }
    } catch (error) {
      const description = error as string;
      console.error(description);
      if (isMounted.current) {
        setBoundError({ title: 'Loading file', description });
        setIsLoading(false);
        setIsDisabled(false);
      }
    }
  };

  const resetFile = async () => {
    try {
      await reset();

      const thrownError = new Error('Component is not mounted');

      if (!selectedFile) throw new Error('No file selected');

      const path = `${appFolder}/${userName}/${selectedLanguage}/${selectedFile}`;
      const resetParams = { path, language: selectedLanguage };
      if (!isMounted.current) throw thrownError;
      const { name, size } = await requests.labelingProcess(
        fileProcessUrl,
        resetParams
      );

      const time = size * sleepFactor > 1500 ? size * sleepFactor : 1500;

      if (isMounted.current) setWaitStatus({ step: 2, time });
      if (isMounted.current) setWaitStatus({ step: 2, time });

      countRef.current = sleep(time); // wait for a period of time
      if (!isMounted.current) throw thrownError;
      await countRef.current.promise;

      if (isMounted.current) setWaitStatus({ step: 3 });

      const segmentsParams = { name };
      if (!isMounted.current) throw thrownError;

      await check(segmentsParams, time > 1500 ? time / 2 : 1500);

      const fileContent: string = await requests.labelingGetSegments(
        fileSegmentsUrl,
        segmentsParams
      );

      if (!fileContent) throw new Error('File content is undefined');

      const patt = /\n/;
      const segmentsArray: string[] = fileContent.search(patt)
        ? fileContent.split('\n')
        : [fileContent];

      const segments = segmentsArray.map((fragment: string) => {
        try {
          return JSON.parse(fragment);
        } catch (error) {
          console.warn(`Impossible to parse the fragment: ${fragment}`);
          return null;
        }
      });

      const segLen = segments.length;

      if (segLen < 1) throw new Error('No detected audio');

      console.log(`%cNumber of segments: ${segments.length}`, 'color:blue');

      const fragments = segments.map((segment, key) => {
        let { start, end, ...args } = segment;
        start = helpers.decimalAdjust('ceil', start, -2) + 0.01;
        end = helpers.decimalAdjust('floor', end, -2) - 0.01;
        return { key, lang: selectedLanguage, start, end, ...args };
      });

      if (isMounted.current) setWaitStatus({ step: 4, segments: segLen });

      const downParams = { path, file: selectedFile };
      if (!isMounted.current) throw thrownError;
      const { data } = await requests.labelingDownloadFile(
        fileDownloadUrl,
        downParams
      );

      if (!data) {
        if (isMounted.current) {
          setWaitStatus({ step: 0 });
          setBoundError({ title: 'Audio', description: 'Not found data' });
        }
        throw new Error('Downloaded file is undefined');
      }

      const arrayBuffer = data;
      const blob = new Blob([new Uint8Array(arrayBuffer)]);

      if (isMounted.current) {
        setWaitStatus({ step: 0 });
        setWaveSrc(URL.createObjectURL(blob));
        setWaveSegments(fragments);
      }

      const saveParams = {
        user: userName,
        language: selectedLanguage,
        file: selectedFile.replace('.wav', ''),
        segments: fragments,
      };

      if (!isMounted.current) throw thrownError;
      const { location } = await requests.labelingSaveFile(
        fileSaveUrl,
        saveParams
      );

      if (isMounted.current) setSuccessUrl(location);

      const deleteParams = { language: selectedLanguage, file: selectedFile };
      if (!isMounted.current) throw thrownError;
      await requests.labelingDeleteFile(fileDeleteUrl, deleteParams);

      if (isMounted.current) {
        setSuccessAlert(true);
        setIsLoading(false);
        setIsDisabled(false);
        setWorkingFile(selectedFile);
        setForceRefresh(!forceRefresh); // after opening and processing a new file it's necessary to update the file list
        autoSave();
      }
    } catch (error) {
      const description = error as string;
      console.error(description);
      if (isMounted.current) {
        setBoundError({ title: 'Reset file', description });
        setIsLoading(false);
        setIsDisabled(false);
      }
    }
  };

  const waitComp = <LabelingWait status={waitStatus} />;

  const form = userName ? (
    <LabelingForm
      disabled={isDisabled}
      user={userName}
      refresh={forceRefresh}
      onFileSelection={(file) => {
        if (isMounted.current) {
          setSelectedFile(file || workingFile);
        }
      }}
      onLanguageSelection={(lang) => {
        if (isMounted.current) setSelectedLanguage(lang);
      }}
      onProcess={process}
      onLoadFile={loadFile}
      onResetFile={resetFile}
    />
  ) : null;

  const waveComp = selectedFile ? (
    <LabelingWave
      src={waveSrc}
      segments={waveSegments}
      language={selectedLanguage}
      onChange={(segments) => {
        if (isMounted.current) {
          setSuccessUrl('');
          setSuccessAlert(false);
          setWaveSegments(segments);
          isUpdated.current = true; // is updated
        }
      }}
    />
  ) : null;

  const saveButton = (
    <Container fluid>
      <Row>
        <Col>
          <Button
            variant="success"
            className="my-4"
            ref={saveBtnRef}
            block
            onClick={async () => {
              try {
                const params = {
                  user: userName,
                  language: selectedLanguage,
                  file: workingFile.replace('.wav', ''),
                  segments: waveSegments,
                };
                if (!isMounted.current) throw new Error('Not mounted');
                const { location } = await requests.labelingSaveFile(
                  fileSaveUrl,
                  params
                );
                if (isMounted.current) {
                  setSuccessAlert(true);
                  setSuccessUrl(location);
                }
              } catch (error) {
                console.error(error);
              }
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );

  const displayedFileName = workingFile ? (
    <Container fluid>
      <Row>
        <Col>
          <Alert variant="primary" className="my-3">
            Working on file{' '}
            <a
              className="font-weight-bold text-dark"
              href={workingPath}
              target="_blank"
              rel="noopener noreferrer"
            >
              {workingFile}
            </a>
          </Alert>
        </Col>
      </Row>
    </Container>
  ) : null;

  const successBoundary = successAlert ? (
    <Container fluid>
      <Row>
        <Col>
          {' '}
          <Alert variant="success" className="my-3">
            The text file has been created/updated successfully. Click{' '}
            <Alert.Link href={successUrl}>here</Alert.Link> to download a copy.
          </Alert>
        </Col>
      </Row>
    </Container>
  ) : null;

  const errorBoundary = boundError ? (
    <ErrorBoundary
      title={boundError?.title || 'Error'}
      description={boundError?.description ? boundError.description : ''}
      reason={boundError?.reason ? boundError.reason : ''}
    />
  ) : null;

  const labelingBody = <Fragment>
    {form}
    {displayedFileName}
    {isLoading ? waitComp : waveComp}
    {successBoundary}
    {waveSegments.length > 0 && !isLoading ? saveButton : null}
    {errorBoundary}
  </Fragment>;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <SubTitle name={title} />
      <Description text={description ? description : 'Utopia.AI'} />
      {env.includes('prod') ? <CustomMessage message="Restricted access" /> : labelingBody}
    </Fragment>
  );
};

export default Labeling;
