import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { getNameByS3Key } from './OptionPanel';

interface IProps {
	video: string;
	image: string | undefined;
	background: string | undefined;
	watermark: string | undefined;
	options: Array<string> | undefined;
	onSelection: (selection: Category) => void;
}

export type Category = 'video' | 'background' | 'watermark' | 'image';

const AvatarBackground = ({ video, image, background, watermark, options, ...events }: IProps) => {
	const { onSelection } = events;

	options = options || ['video', 'background', 'watermark'];

	const menus = options.map((category, index) => (
		<Button
			key={`${index}-${category}`}
			variant='light'
			size='sm'
			className="d-flex justify-content-start border p-1 my-1 mx-1 pointer"
			onClick={() => onSelection(category as Category)}
		>
			<Row>
				<Col>
					<div className="mr-2">
						<h6 className="font-weight-bold text-capitalize">
							{category}
						</h6>
					</div>
				</Col>
				<Col>
					<div className="font-italic">
						<span>
							{category === 'video' && getNameByS3Key(video)}
							{category === 'image' && image && getNameByS3Key(image)}
							{category === 'background' && background && getNameByS3Key(background)}
							{category === 'watermark' && watermark && getNameByS3Key(watermark)}
						</span>
					</div>
				</Col>
			</Row>


		</Button>
	));

	return (
		<Container fluid>
			<div className="d-flex justify-content-between">
				{menus}
			</div>
		</Container>
	);
};

export default AvatarBackground;
