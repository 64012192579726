import React, { useRef, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

interface IProps {
	src: string;
	goBack: () => void;
}

const AvatarResult = ({ src, goBack }: IProps) => {
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (videoRef.current && src) {
			videoRef.current.src = src;
			videoRef.current.play();
		}
	}, [src]);

	return (
		<Container fluid className={src ? '' : 'd-none'}>
			<Row>
				<Col className="text-center col-md-10 offset-md-1 col-lg-8 offset-lg-2">
					<div className="embed-responsive border rounded mb-4">
						<video
							id="mimic-video"
							className="embed-responsive-item position-relative"
							ref={videoRef}
							controls
							onEnded={() => goBack()}>
							<source
								type="video/mp4"
							/>
						</video>
					</div>
				</Col>
			</Row>
			<Row>
				<Col className="text-center">
					<Button size="sm" variant="light"
						onClick={() => goBack()}>Cancel</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default AvatarResult;