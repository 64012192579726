import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => {
  // const env = useContext(EnvContext);
  const [year, setYear] = useState<number>(0);

  useEffect(() => setYear(new Date().getFullYear()), []);

  // const privateLink = !env.includes('prod') ? <a href="/admin">private</a> : <span className="text-muted">utopia.ai</span>;

  return (
    <Container fluid className='utopia-footer'>
      <Row>
        <Col xs={12} sm={6} md={4} className='is-vertical-centered'>
          <p className='m-0'>
            ©{year} All rights reserved
          </p>
        </Col>
        <Col xs={12} sm={6} md={4} className="is-vertical-centered">
          <p className="m-0">
            <a href="https://utopia.ai/">Utopia Thinking Systems</a>
          </p>
        </Col>
        <Col xs={12} sm={12} md={4} className="is-vertical-centered">
          <p className="m-0">
            <Link to='/admin'>private</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;


/*

        <Col className="is-vertical-centered" sm="2">
          <p className="m-0">
            {privateLink}
          </p>
        </Col>

*/