import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';

interface INote {
  off: string;
  on: string;
}

interface IProps {
  name: string;
  label?: string;
  initial?: boolean;
  note?: INote;
  disabled?: boolean;
  className?: string;
  onChange: (value: boolean) => void;
}

const CustomSwitch = ({
  name,
  label,
  disabled,
  className,
  initial,
  note,
  onChange,
}: IProps) => {
  const [status, setStatus] = useState<boolean>(initial || false);

  const onLabel = !note ? 'On' : `${note.on}`;
  const offLabel = !note ? 'Off' : `${note.off}`;

  return (
    <>
      {label ? <label className="mt-1">{label}</label> : null}
      <Form.Check
        className={className}
        type="switch"
        id={name}
        checked={status}
        label={status ? onLabel : offLabel}
        disabled={disabled}
        onChange={() => {
          setStatus(!status);
          onChange(!status);
        }}
      />
    </>
  );
};

export default CustomSwitch;
