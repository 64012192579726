import React, { useState, useEffect, useRef, Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet';

import * as requests from '../../../../requests/requests';
import { DomainContext } from '../../../../context/Context';

import Loading from '../../../pages/Loading';
import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import ErrorPage, { Codes } from '../../../pages/ErrorPage';

import SpeechToTextForm, { IParams } from './SpeechToTextForm';
import SpeechToTextConn from './SpeechToTextConn';

interface IProps {
  title: string;
  description?: string;
}

const SpeechToText = ({ title, description }: IProps) => {
  const domain = useContext(DomainContext);
  const isMounted = useRef<boolean>(false);

  const [errorCode, setErrorCode] = useState<Codes | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [connUuid, setConnUuid] = useState<string>(domain);
  const [params, setParams] = useState<IParams | null>(null);

  const connectUrl = `https://${domain}/api/loquista/asr/connect`;
  const checkLoadUrl = `https://${domain}/api/loquista/asr/loadcheck`;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onConnect = () => {
    if (!isConnected && params) {
      requests.asrConnect(connectUrl, params)
        .then((uuid) => {
          console.log(`%c Connected [uuid:${uuid}]`, 'color: green');
          if (isMounted.current) {
            setConnUuid(uuid);
            setIsConnected(true);
          }
          return requests.asrCheckLoad(checkLoadUrl, { host: params.host, uuid });
        })
        .then(({ load }) => {
          console.log(`Host ${params.host} load: ${load}`);
        })
        .catch((error) => {
          console.error('An error occurred while trying to connect to check service load.');
          if (isMounted.current) setErrorCode(503);
        });
    } else {
      if (isMounted.current) {
        setIsConnected(false);
      }
    }
  };

  const connComponent = (
    <SpeechToTextConn
      connId={connUuid}
      onConnected={(value: boolean) => {
        if (isMounted.current) setIsConnected(value);
      }}
      onError={(error) => {
        console.error('An error occurred while trying to connect to Speech to Text (TCP connection).');
        if (isMounted.current) setErrorCode(503);
      }}
    />
  );

  const form = (
    <SpeechToTextForm
      className={isLoading ? 'd-none' : ''}
      uuid={connUuid}
      disabled={isConnected}
      onConnect={onConnect}
      gotParams={(params) => {
        if (isMounted.current) {
          if (isLoading) setIsLoading(false);
          setParams(params);
        }
      }}
      onError={(error) => {
        console.error('An error occurred while trying to connect to Speech to text service (form error).');
        if (isMounted.current) setErrorCode(503);
      }}
    />
  );

  const content = isConnected ? connComponent : null;

  const mainContent = errorCode ?
    <ErrorPage code={errorCode} /> :
    <Fragment>
      {form}
      {isLoading ? <Loading /> : content}
    </Fragment>;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <SubTitle name={title} />
      <Description text={description ? description : 'Utopia.AI'} />
      {mainContent}
    </Fragment>
  );
};

export default SpeechToText;
