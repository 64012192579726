import React, { useEffect, useState, useRef, useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { DomainContext, LoginContext, EnvContext } from '../../../../context/Context';
import axios, { AxiosError } from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { getUserNameByEmail, displayAxiosError } from '../../../../utils/helpers';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import Loading from '../../../pages/Loading';

import OptionPanel, { getNameByS3Key } from './OptionPanel';
import AvatarBackground, { Category } from './AvatarBackground';

import TextToSpeechConn, { IAudioContent } from '../../loquista/tts/TextToSpeechConn';
import TextToSpeechForm, { IParams } from '../../loquista/tts/TextToSpeechForm';

import AvatarResult from './AvatarResult';
import VideosPanel from './VideosPanel';
import TextToSpeech from '../../loquista/tts/TextToSpeech';
import { IVideo } from './useGetVideos';
import { getUuid } from '../../../../utils/helpers';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import PCMPlayer from 'pcm-player';

import { getLinear16, toArrayBuffer, withWaveHeader } from '../../../../utils/audio';

import { usePlayAvatar } from './usePlayAvatar';
import Image from 'react-bootstrap/Image';

interface IProps {
    title: string;
    description?: string;
}

const sampleRate = 22050;
const playerSampleRate = 16000;

const Avatar = ({ title, description }: IProps) => {

    // TODO refactorizar/modularizar

    const env = useContext(EnvContext);
    const domain = useContext(DomainContext);
    const { logged, setLogged } = useContext(LoginContext);

    const isMounted = useRef<boolean>(false);
    const idRef = useRef<string>(getUuid());
    const [audio, setAudio] = useState<IAudioContent>();
    const player = useRef<PCMPlayer>();

    // const running = useRef<boolean>(false);

    // Avatar
	const wsRef = useRef<WebSocket>();
	const audioCtxRef = useRef<AudioContext>();

    const [showVideos, setShowVideos] = useState<boolean>(false);
    const [selectedVideo, setSelectedVideo] = useState<IVideo>({
        name: localStorage.getItem('vision-mimic-video') || '',
        origin: 'source',
        key: ''
    });

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const [isCustomVideo, setIsCustomVideo] = useState<boolean>(false);
    const [showCategoryMenu, setShowCategoryMenu] = useState<boolean>(false);
    const [category, setCategory] = useState<Category>('video');

    const [video, setVideo] = useState<string>(localStorage.getItem('vision-mimic-video') || 'select video');
    const [image, setImage] = useState<string | undefined>(localStorage.getItem('vision-mimic-image') || undefined);
    const [background, setBackground] = useState<string | undefined>(localStorage.getItem('vision-mimic-background') || undefined);
    const [watermark, setWatermark] = useState<string | undefined>(localStorage.getItem('vision-mimic-watermark') || undefined);

    const [userName, setUserName] = useState<string>('');

    const [ ttsCx, setTtsCx] = useState();


    // Inicio de la peticion TTS:

    let message = 'Somos un operador de confianza que proporciona servicios a otros operadores y a clientes finales. El servicio de comunicaciones en la nube de DIALOGA, le permite desarrollar nuevas propuestas de valor, generar nuevas vías de ingresos, aumentar la rentabilidad de sus activos de red, mejorar los niveles de fidelización de sus clientes, mediante la puesta en marcha de servicios que se ajustan a sus necesidades y todo ello bajo un modelo de pago por uso y servicios gestionados.';
    let voice = "UTESM12";
    let language;
    let host;
    let speakingRate;
    let sampleRate;

	const { result, setResult, start, stop, state } = usePlayAvatar({ audio, avatar: video, background, watermark });

    useEffect(() => {
        isMounted.current = true;

        if (logged && typeof logged === 'string') {
            const name = getUserNameByEmail(logged);
            if (isMounted.current) setUserName(name);
        } else {
            const token = localStorage.getItem(`${env}-token`);
            axios.post(`https://${domain}/login/authentication/user`, { token })
                .then(({ data: { email } }) => {
                    const name = getUserNameByEmail(email);
                    if (isMounted.current) {
                        setLogged(email);
                        setUserName(name);
                    }
                })
                .catch(error => {
                    console.error(error);
                    setAlert('No authenticated');
                });
        }

        return () => {
            isMounted.current = false;
            wsRef?.current?.close();
            wsId.current = '';
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedVideo) localStorage.setItem('vision-mimic-video', selectedVideo.name);
    }, [selectedVideo]);

    useEffect(() => {
        video && localStorage.setItem('vision-mimic-video', video);
    }, [video]);

    useEffect(() => {
        image && localStorage.setItem('vision-mimic-image', image);
    }, [image]);

    useEffect(() => {
        background && localStorage.setItem('vision-mimic-background', background);
    }, [background]);

    useEffect(() => {
        watermark && localStorage.setItem('vision-mimic-watermark', watermark);
    }, [watermark]);

	useEffect(() => {
        console.log('AVATAR RESULT');
	}, [result]);

    const assistant:IAssistant = {
        active: "true",
        language: "en",
        model: "macri",
        name: "macri",
        typename: "video",
        _id: 3  // ¿?
    };

    useEffect(() => {
        isMounted.current = true;

        player.current = new PCMPlayer({
            inputCodec: 'Int16',
            channels: 1,
            sampleRate: playerSampleRate,
            flushTime: 50
        });

		// connectSocket();

        return () => {
            isMounted.current = false;
            player.current?.destroy();
			disconnectFromAvatar(env, domain, idRef.current);
			audioCtxRef.current?.suspend();
			audioCtxRef.current?.close();
        };
    }, []);

    // // para el audio de TTS. normalmente no activado.
    // // pero lo tendre que guardar para reproducirlo con un <audio> como en el anterior
    // useEffect(() => {
    //     if(!audio) {
    //         return;
    //     }
    //     const playSound = () => {
    //         const arrayBuffer = audio ? new Int8Array(audio.data) : null;
    //         console.log('arrayBuffer:', arrayBuffer);
    //         arrayBuffer && player.current?.feed(arrayBuffer);
    //     };
    //     isMounted.current && audio && playSound();
    //     // eslint-disable-next-line
    // }, [audio]);

    // TODO prueba
    const [count, setCount] = useState(0);

    // TODO recuperar. funciona para el audio de AVATAR
    useEffect(() => {
        const playSound = () => {
            const { audio_contents } = result;
            const arrayBuffer = audio_contents ? new Int8Array(audio_contents.data) : null;
            console.log('arraybuffer:', arrayBuffer.length);
            arrayBuffer && player.current?.feed(arrayBuffer);
        };
        isMounted.current && result.audio_contents && playSound();
        if (result.image) {
            // gotImage();
        }
        // TODO prueba
        setCount(prev => prev + 1);
        console.log('count: ', count);

        // eslint-disable-next-line
    }, [result]);

    // Ws Recepción del audio de TTS
    // Con TextToSpeechConn


  const optionPanel = !isRunning && showCategoryMenu && (
    <OptionPanel
      user={userName}
      category={category}
      byDefault={category === 'video' ? video : (category === 'background' ? background : (category === 'watermark' ? watermark : image))}
      onSelect={(_item: string | undefined) => {
        setShowCategoryMenu(false);
        if (category === 'video') setVideo(_item!);
        if (category === 'image') setImage(_item);
        if (category === 'background') setBackground(_item);
        if (category === 'watermark') setWatermark(_item);
      }}
      onCancel={() => {
        console.log('cancel');
        setShowCategoryMenu(false);
      }}
      onChangeCustom={(status: boolean) => {
        setIsCustomVideo(status);
      }}
    />
  );

  // const backgroundForm = !isTTSLoading && !resultSrc && !isAvatarWorking && !isTTSWorking && (
  // const backgroundForm = (
  const backgroundForm = !isRunning && (
    <AvatarBackground
      video={video}
      image={image}
      background={background}
      watermark={watermark}
      onSelection={(seletedCategory) => {
        setCategory(seletedCategory);
        setShowCategoryMenu(true);
      }}
    />
  );

    function onStart() {
        console.log('start');
        
		start();
        // running.current = true;
        setIsRunning(true);

        setTtsCx(
            <TextToSpeechConn
            onConnected={(value: boolean) => value && console.log('TTS connected')}
            onError={(error) => console.warn('TTS disconnected because of', error)}
            gotConnId={(id: string) => {
                idRef.current = id;
                ttsProcess(message, language, { id: idRef.current, voice, domain, env });
            }}
            gotAudio={(
                audioData: IAudioContent,
                num_block: number,
                total_blocks: number,
                sampleRate: number
            ) => {
                console.log('gotAudio:', audioData, num_block, total_blocks);
                if (num_block && total_blocks && num_block === total_blocks) {
                    console.log('TRACE end of TTS');
                    // TODO como "desactivar" el websocket de este tts TextToSpeechConn ?
                    // Aunque en /loquista/tts no se cierra. se van acumulando sockets ws
                    // en cada ejecucion
                    // setTtsCx(null);
                }
                setAudio(audioData);
            }}
            />
        );
    }

    function onStop() {
        console.log('stop');
        // running.current = false;
        setIsRunning(false);

        stop();
    }


    // const startStopButton = running.current ? (
	//     <Button className="m-2 utopia-tts-button" block onClick={() => isMounted.current && onStop()}>
	//         Stop
	//     </Button>
    // ) : (
	//     <Button className="m-2 utopia-tts-button" block onClick={() => isMounted.current && onStart()}>
	//         Start
	//     </Button>
    // );

        // hidden={isLoading}
        // disabled={isWorking || (disabled ?? false)}
        // audioRef={audioRef}
    const ttsForm = !isRunning && (<TextToSpeechForm
        onConnect={(audioContext) => onStart(audioContext)}
        onDisconnect={() => onStop()}
        gotParams={(params: IParams) => handleFormParams(params)}
        onError={(error: Error) => {
            console.log(error.message);
            setIsError(true);
        }}
    />);

    // function handleConnect() {}
    // function handleDisconnect() {}
    function handleFormParams(params) {
        console.log('handleFormParams', params);
        message = params.text;
        voice = params.voice;
        language = params.language;
        host = params.host;
        sampleRate = params.sampleRate;
        speakingRate = params.speakingRate;
    }

    // function ttsProcess(text: string, assistant: IAssistant, params: IttsFnParams): Promise<void> {
    function ttsProcess(text: string, language: string, params: IttsFnParams): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const { id, voice, domain, env } = params;
            console.log('tts process', id);
            // const { language } = assistant;
            const api = 'api/loquista/tts/process';
            const requestParams: IttsApiParams = {
                uuid: id,
                host: host, // '100.100.100.78',
                language: language, // language.includes('es') ? 'es-ES' : 'en-US',
                voice,
                sampleRate: sampleRate,
                speakingRate: speakingRate,
                text
            };
            const requestHeaders = {
                headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` }
            };

            try {
                await axios.post(`https://${domain}/${api}`, requestParams, requestHeaders);
                resolve();
            } catch (err) {
                const error = new Error(`TTS doesn't respond`);
                console.error(err);
                reject(error.message);
            }
        });
    }


      // {startStopButton}
  const mimicContent = (
    <Fragment>
      {optionPanel}
      {backgroundForm}
      {ttsForm}
      {isRunning && state === 'buffering' && (<Spinner animation="border"/>)}
      {alert}
    </Fragment>
  );
    return (
        <Fragment>
        <Helmet>
        <title>{title}</title>
        <meta name="description" content={description ?? 'Utopia app'} />
        </Helmet>
        <SubTitle name={title} />
        <Description text={description ? description : 'Utopia.AI'} />
        {ttsCx}
        {mimicContent}
        {audio && (<Image fluid alt={video} src={`data:image/jpeg;base64,${result.image}`} />)}
        </Fragment>
    );

};

export default Avatar;
