import React, { Fragment, useState, useRef, useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';

import delay from '../../../../utils/delay';

import { EnvContext, DomainContext } from '../../../../context/Context';

import MusicGeneratorForm, { IParams } from './MusicGeneratorForm';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import ErrorPage from '../../../pages/ErrorPage';
import Loading from '../../../pages/Loading';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import { toArrayBuffer, withWaveHeader } from '../../../../utils/audio';

interface IProps {
	title: string;
	description?: string;
	isComponent?: boolean;
	hidden?: boolean;
	disabled?: boolean;
	audioOff?: boolean;
	gotAudio?: (name: string) => void;
	onLoading?: (isLoading: boolean) => void;
	onWorking?: (isWorking: boolean) => void;
	gotParams?: (params: IParams) => void;
	onProgress?: (progress: number) => void;
}

let stream: AudioBufferSourceNode[] = [];
let streamIndex: number = 0;
let isWaiting: boolean = false;

const MusicGenerator = (props: IProps) => {
	const { title, description, isComponent, hidden, disabled, audioOff, ...rest } = props; // props
	const { gotAudio, onLoading, onWorking, gotParams, onProgress } = rest; // events

	// useContext
	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	// useRef
	const audioRef = useRef<HTMLAudioElement>();
	const wsRef = useRef<WebSocket>();

	// useState
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isWorking, setIsWorking] = useState<boolean>(false);
	// const [socketId, setSocketId] = useState<string>('');
	const [params, setParams] = useState<IParams>();
	const [isError, setIsError] = useState<boolean>(false);
	const [warn, setWarn] = useState<string>('');

    // const [duration, setDuration] = useState<number>(5);

    const [downloadAudioFilename , setDownloadAudioFilename] = useState<string>('music.wav');
    const progressIntervalRef = useRef<NodeJS.Timeout>();
    const [requestProgress, setRequestProgress] = useState<number>(0);
    const [playingAudio, setPlayingAudio] = useState<number>(0);
	const closedRef = useRef<boolean>(false);

	useEffect(() => {
		return () => wsRef?.current?.close();
	}, []);

	const handleConnect = async (audioContext: AudioContext) => {
        console.log('handleConnect');
		const wsPort: number = env.includes('prod') ? 8013 : 4013;
		const wsUrl: string = `wss://${domain}/ws${wsPort}`;

        audioRef.current.src = undefined;

        closedRef.current = false;

        if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
        }
        setRequestProgress(0);

		let fileName: string = '';

        const estimatedTimeByEnv = {
            'dev': 1.85,
            'test': 1.85,
            'prod': 1.85,
        };
        const estimatedTime  = params.duration * estimatedTimeByEnv[env];
        console.log(`ESTIMATION: estimatedTime:${estimatedTime} (${params.duration}s audio)`);

        const startProcessingTime = new Date();
        let timePrinted = false;

        const timeStepMs = 100;
        const progressStep = 100 * timeStepMs / estimatedTime / 1000; // 0-100% (ms/1000 / s)
        let progress = 0;
        const interv = setInterval(() => {
                progress += progressStep;
                progress = progress > 100 ? 100: progress;
                setRequestProgress(progress);
                if(progress === 100) {
                    if (progressIntervalRef.current) {
                        clearInterval(progressIntervalRef.current);
                    }
                }
            }, timeStepMs);

        progressIntervalRef.current = interv;
        //////////


		wsRef.current = new WebSocket(wsUrl);

		wsRef.current.onopen = () => {
			setIsWorking(true);
			setWarn('');
			onWorking && onWorking(true);
		};

		wsRef.current.onclose = () => {
			setIsWorking(false);
			onWorking && onWorking(false);
		};

		wsRef.current.onmessage = async (message) => {
            const { data } = message;
            // console.log('WEBSOCKET MSG', data);

            const { uuid, url, error, keep_alive, ...rest } = JSON.parse(data);

            if(url) {
                console.log('URL', url);
                console.log('audio created. Closing ws');
                wsRef?.current?.close();

                audioRef.current.src = url;
                audioRef.current.load();
                audioRef.current.play();
                return;
            }

            if(keep_alive) {
                return;
            }

            const res = await processAudio(domain, env, uuid, params);
            console.log('RES', res);
		};

		wsRef.current.onerror = (error) => {
			console.warn(error);
			setWarn('An error occurred with tcp connection');
		};
	};

	const handleDisconnect = () => {
        console.log('Request stopped');
        closedRef.current = true;
		if (wsRef.current) {
                wsRef.current.close();
        }
		// setSocketId('');
		setIsWorking(false);
		// setWarn('');
		onWorking && onWorking(false);

		// audio elements
        stream.forEach((str, index) => {
            try {
                str.stop();
            } catch(err) {
                console.log(`Error trying to stop a stream (music chunk #${index}`);
            }
        });
		stream = [];
		streamIndex = 0;
		isWaiting = false;

        setPlayingAudio(0);
        // TDOO aqui?
        if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
        }
	};

	const handleFormParams = (params: IParams) => {
		setIsLoading(false);
		setParams(params);
		onLoading && onLoading(false);
		gotParams && gotParams(params);
	};

	const form = (
		<MusicGeneratorForm
			hidden={isLoading}
			disabled={isWorking || (disabled ?? false)}
			audioRef={audioRef}
			onConnect={(audioContext) => handleConnect(audioContext)}
			onDisconnect={() => handleDisconnect()}
			gotParams={(params: IParams) => handleFormParams(params)}
			onError={(error: Error) => {
				console.log(error.message);
				setIsError(true);
			}}
            downloadAudioFilename={downloadAudioFilename}
            requestProgress={requestProgress}
            playingAudio={playingAudio}
            // duration={params.duration}
		/>
	);

	const helmet = !isComponent && (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description ?? 'Utopia app'} />
		</Helmet>
	);


	const alert = (
		<Container fluid>
			<Row>
				<Col>
					<Alert className="my-1 mx-2" variant="warning">{warn}</Alert>
				</Col>
			</Row>
		</Container>
	);

	const content = (
		<Fragment>
			{form}
			{isLoading && <Loading />}
			{warn && alert}
		</Fragment>
	);

	return (
		<Fragment>
			{helmet}
			<div className={hidden ? 'd-none' : ''}>
				<SubTitle name={title} hidden={isComponent ?? false} />
				<Description text={description || 'Utopia.AI'} hidden={isComponent ?? false} />
				{isError ? <ErrorPage code={503} /> : content}
			</div>
		</Fragment>
	);
};

export default MusicGenerator;

async function processAudio(domain: string, env: string, uuid: string, formParams: IParams): Promise<string> {
	try {
		const url = `https://${domain}/api/loquista/music-generator/process`;
		const { ...args } = formParams;
		const params = { uuid, ...args };
		const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
		const { data } = await axios.post(url, params, options);
		return Promise.resolve(data as string);
	} catch (error) {
		return Promise.reject(new Error('An error occurred while processing audio file'));
	}
}

/**
 * Audio play handler
 */
type AudioContent = { data: Buffer; };

export function playAudio(audio: AudioContent, num: number, total: number, rate: number = 22050, audioContext?: AudioContext): Promise<void> {
	return new Promise((resolve, reject) => {

        console.log(`Play audio #${num} of ${total}...`);
		const audioCtx = audioContext || new (window.AudioContext || (window as any).webkitAudioContext)();
		const orange: string = '#ffa500';
		const green: string = '#5bc336';

		try {
			const buffer: Buffer = audio.data;
			const arrayBuffer: ArrayBuffer = toArrayBuffer(buffer);
			const audioWithWaveHeader: ArrayBuffer = withWaveHeader(arrayBuffer, 1, rate);

			const onDecodeSuccess = (audioBufferChunk: AudioBuffer) => {
				const source: AudioBufferSourceNode = audioCtx.createBufferSource();
				source.buffer = audioBufferChunk;
				source.connect(audioCtx.destination);
				stream.push(source);

				console.log(`%cReceived [${num}/${total}]`, `color: ${orange}`);

				if (num === 1) {
                    console.warn(`starting stream #${streamIndex + 1}`);
					stream[streamIndex].start(0);
					streamIndex++;
				}

				if (isWaiting) {
					isWaiting = false;
					if (stream[streamIndex]) {
						stream[streamIndex].start();
						streamIndex++;
					}
				}

				source.onended = () => {
					console.log(`%cProcessed [${streamIndex}/${total}]`, `color: ${green}`);

					if (streamIndex < total) {
						if (!stream[streamIndex]) {
							isWaiting = true;
							console.warn('Waiting for new package...');
						} else {
							console.warn(`starting stream #${streamIndex + 1}`);
							stream[streamIndex].start();
							streamIndex++;
						}
					} else {
						stream = [];
						streamIndex = 0;
						return resolve();
					}
				};
			};

			const onDecodeError = (error: unknown) => {
				console.error(`onDecodeError: ${error}`);
				return reject(new Error('An error occurred on decoded audio'));
			};

			if (audioCtx.state === 'suspended' || audioCtx.state === 'closed') audioCtx.resume();
			audioCtx.decodeAudioData(audioWithWaveHeader, onDecodeSuccess, onDecodeError);
		} catch (error) {
			return reject(new Error('An error occurred while decoding audio'));
		}
	});
}

/**
 * Download file
 */
async function downloadFile(domain: string, env: string, fileName: string): Promise<string> {
	return new Promise(async (resolve, reject) => {
		try {
			const url = `https://${domain}/api/loquista/music-generator/download`;
			const params = { fileName };
			const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
			const { data: { fileContent } } = await axios.post(url, params, options);
			resolve(fileContent as string);
		} catch (error) {
			reject(new Error('An error occurred while downloading audio file'));
		}
	});
}
