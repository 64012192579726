import React from 'react';

import Badge from 'react-bootstrap/Badge';

type colorType = 'success' | 'primary' | 'info' | 'danger' | 'warning';

interface IProps {
  label: string;
  number: number;
  color?: colorType;
}

const CustomBadge = ({ label, number, color }: IProps) => {
  return (
    <div>
      <span>{label}</span>{' '}
      <Badge variant={color ? color : 'primary'}>{number}</Badge>
    </div>
  );
};

export default CustomBadge;
