import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import VideoContextProvider from "./components/utopia/vision/faceReplacement/contexts/appContext";
import VideoContext2Provider from "./components/utopia/vision/faceMovement/contexts/appContext";
import VideoContext3Provider from "./components/utopia/vision/fashionAnimation/contexts/appContext";

import { GoogleOAuthProvider } from '@react-oauth/google';

const googleOAUTH = '326304190120-sjbkl6kl8u4vsal50cvo218o65mf3gnh.apps.googleusercontent.com'; // Google OAUTH ClientId

ReactDOM.render(
  <React.StrictMode>
    <VideoContextProvider>
        <VideoContext2Provider>
            <VideoContext3Provider>
                <GoogleOAuthProvider clientId={googleOAUTH}>
                    <App />
                </GoogleOAuthProvider>
            </VideoContext3Provider>
        </VideoContext2Provider>
    </VideoContextProvider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
