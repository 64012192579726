/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState, useRef, useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { DomainContext, LoginContext, EnvContext } from '../../../../context/Context';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';

import { AppContext } from "./contexts/appContext";
import CanvasFeed from "./components/CanvasFeed";
import DisplayResponse from "./components/DisplayResponse";
import DrivingVideo from "./components/DrivingVideo";
import GetImage from "./components/GetImage";
import SelectVideo from "./components/SelectVideo";
import RefImage from "./components/RefImage";
import OutputVideo from "./components/OutputVideo";
// import ZoomOutputVideo from "./components/ZoomOutputVideo";
import VideosPanel  from './components/VideosPanel';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar';

const style = css`
    .fashion-animation-row {
        margin-bottom: 0.8em;

        h4 {
            text-align: center;
        }
    }

    .main-selectors div {
        margin-left: 0.4em;
        margin-right: 0.4em;
    }

    .main-elements {
        text-align: center;
    }

    .main-buttons {
        button {
            margin-right: 1em;
        }
    }
`;

interface IProps {
    title: string;
    description?: string;
}

const FashionAnimation = ({ title, description }: IProps) => {

    // CONTEXT
    const domain = useContext(DomainContext);
    const env = useContext(EnvContext);

     const [show, setShow] = useState(true);

    const {
        start,
        setStart,
        drivingVideo,
        imageData,
        drivingVideos,
        showVideosPanel,
        setShowVideosPanel,
        outputVideoUrl,
        setOutputVideoUrl,
        outputVideoEl,
        // zoomOutputVideoEl,
        // zoomOutput,
        // setZoomOutput,
        drivingVideoEl,
        feedback,
        playing,
        setPlaying,
        outputVideoProgress,
    } = AppContext();

    // EFECTS

    useEffect(() => {
        console.log('drivingVideo:', drivingVideo);
        setShowVideosPanel(false);
    }, [drivingVideo]);


    useEffect(() => {
        if(outputVideoUrl) {
            if(outputVideoEl && outputVideoEl.current) {
                outputVideoEl.current.play();
            }
            // if(zoomOutputVideoEl && zoomOutputVideoEl.current) {
            //     zoomOutputVideoEl.current.play();
            // }
            if(drivingVideoEl && drivingVideoEl.current) {
                drivingVideoEl.current.play();
            }
        }
        setPlaying(true);
    }, [outputVideoUrl]);

    // HANDLERS

    const startHandler = e => {
        console.log('Start');
        setOutputVideoUrl(null);
        setStart(true);
    };

    const stopHandler = e => {
        console.log('Stop');
        setStart(false);
    };

    const playHandler = e => {
        console.log('Play');
        if(outputVideoUrl) {
            if(outputVideoEl && outputVideoEl.current) {
                outputVideoEl.current.play();
            }
            if(drivingVideoEl && drivingVideoEl.current) {
                drivingVideoEl.current.play();
            }
        }
        setPlaying(true);
    };

    const pauseHandler = e => {
        console.log('Pause');
        if(outputVideoUrl) {
            if(outputVideoEl && outputVideoEl.current) {
                outputVideoEl.current.pause();
            }
            if(drivingVideoEl && drivingVideoEl.current) {
                drivingVideoEl.current.pause();
            }
        }
        setPlaying(false);
    };

    // const outputVideoClick = e => {
    //     console.log('outputVideoClick');
    //     setZoomOutput(true);
    //     setPlaying(false);
    //     if(zoomOutputVideoEl && zoomOutputVideoEl.current) {
    //         zoomOutputVideoEl.current.play();
    //     }
    // };

    // CONTENT

    const videosPanel = showVideosPanel && (
      <VideosPanel
        drivingVideos={drivingVideos}
        />
    );

    // const zoomOutputVideo = outputVideoUrl && zoomOutput && (
    //     <ZoomOutputVideo/>
    // );

    const spinner = start && !outputVideoUrl && (
        <Spinner animation="border" as="span" role="status" size="sm" />
    );

    const feedbackAlert = feedback && (
        <div className="alert alert-warning" role="alert">{feedback}</div>
    );

    // const fashionAnimationContent = !showVideosPanel && !zoomOutput && (
    const fashionAnimationContent = !showVideosPanel && (
        <Container fluid css={style}>
            <Row className={'fashion-animation-row main-selectors'}>
                <Col>
                    <GetImage/>
                </Col>
                <Col>
                    <SelectVideo/>
                </Col>
                <Col>
                </Col>
            </Row>

            <Row className={'fashion-animation-row'}>
                <Col>
                    {show && (<Alert variant="warning" onClose={() => setShow(false)} dismissible>
                        It is important to enter as a reference the image of the whole body of a woman from the front, wearing a dress or skirt and heels, with her hands on the sides of her body.
                    </Alert>)}
                </Col>
            </Row>

            <Row className={'fashion-animation-row main-elements'}>
                <Col>
                    <h4>Reference image</h4>
                    <RefImage/>
                </Col>
                <Col>
                    <h4>Driving video</h4>
                    <DrivingVideo/>
                </Col>
                <Col>
                    <h4>Output video</h4>
                    <OutputVideo/>
                </Col>
            </Row>


            <Row className={'fashion-animation-row main-buttons'}>
                <Col>
                    <Button onClick={startHandler}
                        className={`${start ? 'd-none' : ''}`}
                        disabled={!imageData || !drivingVideo}
                    >Start</Button>

                    <Button
                        onClick={stopHandler}
                        className={`${start ? '' : 'd-none'}`}
                    >Stop {spinner}</Button>

                    <Button onClick={playHandler} variant="light"
                        className={`${(outputVideoUrl && !playing) ? '' : 'd-none'}`}
                    >Play</Button>
                    <Button onClick={pauseHandler} variant="light"
                        className={`${(outputVideoUrl && playing) ? '' : 'd-none'}`}
                    >Pause</Button>

                    <a
                        href={outputVideoUrl}
                        className={`${outputVideoUrl ? '' : 'd-none'}`}
                    >Download video</a>

                    {feedbackAlert}
                </Col>
            </Row>
        </Container>
    );

            // {start && <CanvasFeed />}
    return (
        <Fragment>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description ?? 'Utopia app'} />
            </Helmet>
            <SubTitle name={title} />
            <Description text={description ? description : 'Utopia.AI'} />
            {fashionAnimationContent}
            {videosPanel}
        </Fragment>
    );
};
            // {zoomOutputVideo}

export default FashionAnimation;
