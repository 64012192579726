import React, { useEffect, useRef, useState, useContext } from 'react';

import { DomainContext } from '../../../../context/Context';

import * as helpers from '../../../../utils/helpers';
import * as requests from '../../../../requests/requests';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomBadge from '../../../utilities/CustomBadge';

interface IProps {
  userName: string;
  file: string;
  total: number;
  forceUpdate: boolean;
  model: string;
  voiceName: string;
  onDoneSentences: (doneSentences: number[]) => void;
}

const ttsTeam = [
  'ziad@dialogagroup.com',
  'emartinez@dialogagroup.com',
];

const VoiceGeneratorStatistics = ({ userName, file, total, forceUpdate, model, voiceName, onDoneSentences }: IProps) => {
  const domain = useContext(DomainContext);

  const isMounted = useRef<boolean>(false);

  const [audioFilesCount, setAudioFilesCount] = useState<number>(0);
  const [fileDoneSentences, setFileDondeSentences] = useState<number>(0);
  const [filePendingSentences, setFilePendingSentences] = useState<number>(0);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const api = 'api/loquista/generate-voice';
  const audioFilesCountUrl = `https://${domain}/${api}/files/audio/count`;
  const filesKeysUrl = `https://${domain}/${api}/files/keys`;
  const mailUrl = `https://${domain}/api/mail`;

  // first render useEffect
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (file && total) {
      const user = userName;
      const language = helpers.getLanguagebyFileName(file);
      const folder = helpers.removeExtFromFileName(file);
      const params = { user, folder, language, model, voiceName };
      let totalCount = 0;

      requests
        .voiceGeneratorAudioFilesCount(audioFilesCountUrl, params)
        .then((count) => {
          totalCount = count;
          return requests.voiceGeneratorFilesKeys(filesKeysUrl, params);
        })
        .then(({ text, audio }) => {
          if (isMounted.current) {
            const pending = total - text.length >= 0 ? total - text.length : 0;
            setAudioFilesCount(totalCount);
            setFileDondeSentences(audio.length);
            setFilePendingSentences(pending);
            onDoneSentences(audio);

            if (!pending && !isEmailSent) {
              console.log('Sending email (file completed)...');
              _sendMail(mailUrl, userName, file)
                .then((response) => {
                  console.log(response);
                  if (isMounted.current) setIsEmailSent(true);
                })
                .catch((error) => console.error(error));
            } else if (total % 1000 === 0 && !isEmailSent) {
              console.log('Sending email (1000)...');
              _sendMail(mailUrl, userName, '', total)
                .then((response) => {
                  console.log(response);
                  if (isMounted.current) setIsEmailSent(true);
                })
                .catch((error) => console.error(error));
            }
          }
        })
        .catch((error) => console.error(error));
    }
    // eslint-disable-next-line
  }, [total, forceUpdate]);

  return (
    <Container id="voice-generator-statistics" fluid>
      <Row className="mt-1 mt-md-2">
        <Col>
          <h5>Statistics</h5>
        </Col>
      </Row>
      <Row className="border rounded mx-3 mb-4 mx-3 font-weight-light">
        <Col className="border rounded my-2 ml-2 mr-1 px-0 py-2">
          <Container className="p-0">
            <Row>
              <Col>
                <h6 className="text-muted font-italic">Total</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomBadge
                  label="Total"
                  number={audioFilesCount}
                  color="success"
                />
              </Col>
              <Col className="text-sm-right">
                <CustomBadge
                  label="Pending"
                  number={
                    1000 - audioFilesCount >= 0 ? 1000 - audioFilesCount : 0
                  }
                  color="warning"
                />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col className="border rounded my-2 ml-1 mr-2 px-0 py-2">
          <Container className="p-0">
            <Row>
              <Col>
                <h6 className="text-muted font-italic">File</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomBadge
                  label="Unrecorded"
                  number={filePendingSentences}
                  color="danger"
                />
              </Col>
              <Col className="text-sm-right">
                <CustomBadge label="Recorded" number={fileDoneSentences} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default VoiceGeneratorStatistics;

async function _sendMail(url: string, userName: string, file?: string, total?: number): Promise<string> {
  const main = file
    ? `The file "${file}" has been completed by user "${userName}"`
    : `The threshold has been reached: ${total} sentences completed`;
  const message = `
Hello,
  
${main},
  
Thanks`;

  const mailParams = {
    to: ttsTeam.join(', '),
    subject: 'Utopia TTS: voice generator',
    message,
  };

  try {
    const response = await requests.sendMail(url, mailParams);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}
