import React, { Fragment } from 'react';

import classNames from 'classnames';
import { IMessage } from './Chat';
import ChatBubbleTriangle from './ChatBubbleTriangle';

interface IProps {
	message: IMessage;
	index: number;
	messages: IMessage[];
	hidden?: boolean;
}

const ChatBubble = ({ message, index, messages, hidden }: IProps) => {
	const isFirstMessage = index === 0;
	const isMessageFromBot = message.speaker === 'bot';
	const isPreviousMessageFromBot = index > 0 && messages[index - 1].speaker === 'bot';

	const isLeftTriangle = isMessageFromBot && (!isPreviousMessageFromBot || isFirstMessage);
	const isRightTriangle = !isMessageFromBot && (isPreviousMessageFromBot || isFirstMessage);

	const bubbleClasses = classNames(
		'utopia-chat-message',
		`utopia-chat-message-${message.speaker}`,
		'text-left',
		'd-inline-block',
		'mb-2',
		'py-2',
		'pl-2',
		'border',
		{ 'border-brain': message.speaker === 'bot' },
		{ 'border-sesame': message.speaker === 'user' },
		'rounded',
		{ 'bot-arrow': isLeftTriangle },
		{ 'user-arror': isRightTriangle }
	);

	const timeClasses = classNames('utopia-chat-time text-muted font-italic');

	return (
		<Fragment>
			<div className={hidden ? 'd-none' : ''}>
				{isLeftTriangle && <ChatBubbleTriangle direction='left' />}
				<div className={bubbleClasses}>
					{formattingBubbleText(message.message)}
					<div className={timeClasses}>
						{message.time}
					</div>
				</div>
				{isRightTriangle && <ChatBubbleTriangle direction='right' />}
			</div>
		</Fragment>
	);
};

export default ChatBubble;

const formattingBubbleText = (text: string) => {
	const stringify = JSON.stringify(text).slice(1, -1);

	// links
	if (stringify.search(/www./g) > -1) {
		const arrayText = stringify.split(/\s/g);
		const textLinks = arrayText.filter(item => item.search(/www./g) > -1);

		const formattedText = arrayText.map((item, key) => {
			if (!textLinks.includes(item)) return <Fragment key={key}>{`${item} `}</Fragment>;
			else return <a key={key} target='_blank' rel="noopener noreferrer"
				href={`https://${item.endsWith('.') ? item.slice(0, -1) : item}`}>
				{`${item} `}
			</a>;
		});

		return formattedText;
	}

	// lists
	if (stringify.match(/^\d+.\s|^\d+.|^\d+\)/g)) {
		const array = stringify.split(/\\n/);
		const list = array.map((item, key) => (
			<li key={key}>{item}</li>
		));
		return <ul className="list-unstyled">{list}</ul>;
	}

    // paragraphs
    const paragraphs = text.split('\n');
    if(paragraphs.length > 1) {
        const elems = paragraphs.map((item, index) => (
            <p key={index}>{item}</p>
        ));

        return <div>{elems}</div>;
    }

	return text;
};
