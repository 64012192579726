import React, { useRef, useState, useEffect } from "react";
import { Row, FormControl, InputGroup, Button, Form } from "react-bootstrap";
import { AppContext } from "../contexts/appContext";

const GetImage = () => {
    const [img, setImg] = useState(null);
    const { setImage, setImageData, start } = AppContext();
    const image_ref = useRef(undefined);

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        setImg(URL.createObjectURL(file));
    };

    const handleContext = (e) => {
        const get_image = e.target;
        setImage(get_image);

        const imgData = getBase64Image(image_ref.current);
        console.log(imgData);
        setImageData(imgData);
    };

    function getBase64Image(img) {
        var canvas = document.createElement("canvas");

        canvas.height = img.height;
        canvas.width = img.width;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        // Get the data-URL formatted image
        var dataURL = canvas.toDataURL("image/png");

        return dataURL;
    }

    return (
        <Row>
        <Form.File accept="image" onChange={handleChange} disabled={start} label="Please upload a picture of a face"/>

        {img && (
            <img
            src={img}
            ref={image_ref}
            alt="ref image"
            onLoad={handleContext}
            style={{ display: "none" }}
            />
        )}
        </Row>
    );
};

export default GetImage;
