import React, { useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "../contexts/appContext";

const VideoFeed = () => {
  const { setRequestData, responseData, start, setStart, video, setVideo } = AppContext();
  const { videoEl } = AppContext();
  const canvas = useRef(undefined);

  const started = useRef(false);

  const PlayEvent = () => {
  };

  const ClickEvent = () => {
  };

  useEffect(() => {
    videoEl.current.crossOrigin = "anonymous";
  }, []);

  useEffect(() => {
      if(video) {
        videoEl.current.src = `/Driving_videos/${video}`;
      }
  }, [video]);

  return (
          <video
            ref={videoEl}
            onClick={ClickEvent}
            onPlay={PlayEvent}
            onEnded={() => videoEl.current.play()}
            width="256px" height="256px"
            style={{ backgroundColor: "#f7f7f7" }}
          />
  );
};

export default VideoFeed;
