import React, { useRef, useEffect, useState, useContext, Fragment } from 'react';
import axios from 'axios';

import { EnvContext, DomainContext } from '../../../../context/Context';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import CustomSelect from '../../../forms/CustomSelect';
import Loading from '../../../pages/Loading';

interface IHost {
  host: string;
  port: number;
}

interface IProps {
  status: boolean;
  disabled?: boolean;
  onConnect: (data: IHost) => void;
  onError: (error: string) => void;
}

const instructions = 'Please, check you camera and press Connect. After few seconds, results will be displayed.';

const FaceRecogForm = ({ status, disabled, onConnect, onError }: IProps) => {
  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);
  const isMounted = useRef<boolean>(false);

  const [loading, SetLoading] = useState(true);
  const [hosts, setHosts] = useState<IHost[]>([]);
  const [selectedHost, setSelectedHost] = useState<IHost>();

  useEffect(() => {
    isMounted.current = true;

    const getHosts = async () => {
      const url = `https://${domain}/api/vision/face/hosts`;
      const params = { frontEnv: env };
      const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };

      try {
        const { data: { availableHosts } } = await axios.post(url, params, options);
        const host = availableHosts[Math.floor(Math.random() * hosts.length)].host;

        if (isMounted.current) {
          SetLoading(false);
          setHosts(availableHosts);
          setSelectedHost(host);
        }

      } catch (error) {
        onError('Service unavailable');
      }
    };

    getHosts();
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const hostsSelect = (
    <CustomSelect
      name="speech-to-text-host"
      label="host"
      options={hosts.map(item => (`${item.host}:${item.port}`))}
      disabled={disabled}
      onChange={(currentHost) => {
        const [host, port] = currentHost.split(':');
        setSelectedHost({ host, port: parseInt(port) });
      }}
    />
  );

  const form = (
    <Form>
      <Form.Row className={env === 'prod' ? 'd-none' : ''}>
        <Col>{hostsSelect}</Col>
      </Form.Row>
      <Form.Row>
        <Col className="py-2">
          <Button
            disabled={disabled}
            block
            onClick={() => {
              if (selectedHost) {
                onConnect(selectedHost);
              }
            }}
          >
            {status ? 'Disconnect' : 'Connect'}
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );

  const main = (
    <Container fluid>
      <Row>
        <Col>
          <div className="text-muted">
            <p>{instructions}</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>{form}</Col>
      </Row>
    </Container>
  );

  return <Fragment>{loading ? <Loading /> : main}</Fragment>;
};

export default FaceRecogForm;