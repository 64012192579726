import React, { useEffect, useRef, useState, useContext } from 'react';

import { DomainContext } from '../../../../context/Context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import * as utils from '../../../../utils/utils';
import * as requests from '../../../../requests/requests';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';

import CustomSelect from '../../../forms/CustomSelect';
import CustomSwitch from '../../../forms/CustomSwitch';
import CustomConfirm from '../../../utilities/CustomConfirm';

interface IProps {
  disabled?: boolean;
  user: string;
  refresh: boolean;
  onLanguageSelection: (language: string) => void;
  onFileSelection: (file: string) => void;
  onProcess: () => void;
  onLoadFile: () => void;
  onResetFile: () => void;
}

const LabelingForm = ({
  disabled,
  user,
  refresh,
  onLanguageSelection,
  onFileSelection,
  onProcess,
  onLoadFile,
  onResetFile,
}: IProps) => {
  const isMounted = useRef<boolean>(false);

  const domain = useContext(DomainContext);

  const [newFileMode, setNewFileMode] = useState<boolean>(true);
  const [languages, setLanguages] = useState<string[]>(['']);
  const [selectedLanguage, setSelectedLanguages] = useState<string>('');
  const [newFiles, setNewFiles] = useState<string[]>(['']);
  const [doneFiles, setDoneFiles] = useState<string[]>(['']);
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);

  const languagesUrl = `https://${domain}/api/loquista/labeling/languages`;
  const filesUrl = `https://${domain}/api/loquista/labeling/files`;

  // first render useEffect
  useEffect(() => {
    isMounted.current = true;

    requests
      .getS3Languages(languagesUrl)
      .then((languages) => {
        if (isMounted.current) {
          const orderedLangs = utils.orderLanguages(languages);
          setLanguages(orderedLangs);
          setSelectedLanguages(orderedLangs[1]);
          onLanguageSelection(orderedLangs[1]);
        }
      })
      .catch((error) => console.error(error));

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  // onChange: selectedLanguage
  useEffect(() => {
    if (selectedLanguage) {
      const mode = newFileMode;
      console.log(`mode: ${mode ? 'new files' : 'load files'}`);
      requests
        .getS3FilesByLanguage(filesUrl, {
          language: selectedLanguage,
        })
        .then((files) => {
          if (isMounted.current) {
            setNewFiles(files);
            if (mode) {
              setSelectedFile(files[0]);
              onFileSelection(files[0]);
            }
          }
          return requests.getS3FilesByLanguageAndUser(filesUrl, {
            language: selectedLanguage,
            user,
          });
        })
        .then((files) => {
          if (isMounted.current) {
            setDoneFiles(files);
            if (!mode) {
              setSelectedFile(files[0]);
              onFileSelection(files[0]);
            }
          }
        })
        .catch((error) => console.error(error));
    }
    // eslint-disable-next-line
  }, [selectedLanguage, refresh]);

  useEffect(() => {
    if (isMounted.current) {
      const first = newFileMode ? newFiles[0] : doneFiles[0];
      setSelectedFile(first);
      onFileSelection(first);
    }
    // eslint-disable-next-line
  }, [newFileMode]);

  const handleReset = () => {
    if (isMounted.current) setShowModal(true);
  };

  const handleResetResponse = (res: boolean) => {
    if (isMounted.current) {
      setShowModal(false);
      if (res) onResetFile();
    }
  };

  const form = (
    <Form
      id="labeling-form"
      className={`border rounded p-4 bg-light ${visible ? '' : 'd-none'}`}
    >
      <Form.Row>
        <Col xs={8}>
          <CustomSelect
            name="labeling-language"
            label="language"
            disabled={disabled}
            options={languages}
            selected={selectedLanguage}
            onChange={(language) => {
              if (isMounted.current) {
                setSelectedLanguages(language);
                onLanguageSelection(language);
              }
            }}
          />
        </Col>
        <Col xs={4} className="text-center pt-2">
          <CustomSwitch
            className="mt-4 py-1"
            name="labeling-switch"
            note={{ on: 'New file', off: 'Load file' }}
            initial={newFileMode}
            disabled={disabled}
            onChange={(value) => {
              if (isMounted.current) {
                setNewFileMode(value);
              }
            }}
          />
        </Col>
      </Form.Row>
      <Fade in={newFileMode} timeout={600}>
        <Form.Row className={newFileMode ? '' : 'd-none'}>
          <Col xs={12} md={8}>
            <CustomSelect
              name="labeling-file"
              label="new files"
              disabled={disabled}
              options={newFiles}
              selected={selectedFile}
              onChange={(file) => {
                if (isMounted.current) {
                  setSelectedFile(file);
                  onFileSelection(file);
                }
              }}
            />
          </Col>
          <Col xs={12} md={4} className="text-center">
            <Button
              className="mt-4"
              variant="loquista"
              disabled={disabled || newFiles.length < 1}
              onClick={onProcess}
            >
              Open
            </Button>
          </Col>
        </Form.Row>
      </Fade>
      <Fade in={!newFileMode} timeout={600}>
        <Form.Row className={!newFileMode ? '' : 'd-none'}>
          <Col xs={12} md={8}>
            <CustomSelect
              name="labeling-user-file"
              label="your files"
              disabled={disabled}
              options={doneFiles}
              selected={selectedFile}
              onChange={(file) => {
                if (isMounted.current) {
                  setSelectedFile(file);
                  onFileSelection(file);
                }
              }}
            />
          </Col>
          <Col xs={12} md={4} className="text-center">
            <Button
              className="mt-4"
              variant="loquista"
              disabled={disabled || doneFiles.length < 1}
              onClick={onLoadFile}
            >
              Load
            </Button>
            <Button
              className="mt-4 ml-2"
              variant="outline-danger"
              disabled={disabled || doneFiles.length < 1}
              onClick={handleReset}
            >
              Reset
            </Button>
          </Col>
        </Form.Row>
      </Fade>
    </Form>
  );

  return (
    <Container>
      <CustomConfirm
        title="Reset File"
        message="With this action the audio segments and transcriptions will be redefined, therefore all your work in this file will be lost. It is strongly recommendable to save and download your work before continuing."
        show={showModal}
        onResult={(res) => handleResetResponse(res)}
      />
      <Row>
        <Col>
          <div id="labeling-form-container" className="mb-4">
            {form}
            <div
              id="labeling-form-handler"
              className="px-2"
              title="menu"
              onClick={() => setVisible(!visible)}
            >
              {visible ? (
                <FontAwesomeIcon icon={faCaretUp} />
              ) : (
                <FontAwesomeIcon icon={faCaretDown} />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LabelingForm;
