import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export interface IErrorProps {
  title: string;
  description?: string;
  reason?: string;
}

const ErrorBoundary = ({ title, description, reason }: IErrorProps) => {
  return (
    <Container>
      <Row>
        <Col>
          <Card className="text-center my-4 mb-1" border="light">
            <Card.Header>Error</Card.Header>
            <Card.Body>
              <Card.Title>{title}</Card.Title>
              {description ? <Card.Text>{description}</Card.Text> : null}
            </Card.Body>
            {reason ? (
              <Card.Footer className="text-muted">{reason}</Card.Footer>
            ) : null}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorBoundary;
