/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React, { Fragment, useState, useEffect, useContext } from 'react';
import axios from 'axios';

// import Carousel, { CarouselRef } from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import AvatarBackground, { Category } from '../../vision/mimic/AvatarBackground';
import OptionPanel from '../../vision/mimic/OptionPanel';
import Select from '../../../forms/Select';
import { useTextToSpeechForm, IUseTextToSpeechForm, Language } from '../../loquista/tts/useTextToSpeechForm';
// import { useGetAvatars, IAvatar } from '../useGetAvatars';

import { LoginContext, DomainContext, EnvContext } from '../../../../context/Context';
import { getUserNameByEmail } from '../../../../utils/helpers';

export interface IVideoAssistantData {
	host: string;
	voice: string;
	avatar: string;
	background: string | undefined,
	watermark: string | undefined;
}

interface IProps {
	shown: boolean;
	onSelect: ({ avatar, background, watermark, host, voice }: IVideoAssistantData) => void;
	onSelection: (status: boolean) => void;
}

const style: SerializedStyles = css`

`;

const selects = ['voice', 'host'];

const VideoAssistantForm = ({ shown, onSelect, onSelection }: IProps) => {
	// const carouselRef = useRef<React.Ref<CarouselRef>>();
    const env = useContext(EnvContext);
	const domain = useContext(DomainContext);
	const { logged, setLogged } = useContext(LoginContext);

	// const avatars = useGetAvatars();
	const { options, setOptions }: IUseTextToSpeechForm = useTextToSpeechForm({
		defaultHost: null,// '100.100.100.78',
		defaultLanguage: 'es-ES'
	});

	const [userName, setUserName] = useState<string>('');

	// eslint-disable-next-line
	const [isCustomVideo, setIsCustomVideo] = useState<boolean>(false);
	const [showCategoryMenu, setShowCategoryMenu] = useState<boolean>(false);
	const [category, setCategory] = useState<Category>('video');
	const [video, setVideo] = useState<string>(localStorage.getItem('vision-mimic-video') || 'select video');
	const [background, setBackground] = useState<string>(localStorage.getItem('vision-mimic-background') || 'select background');
	const [watermark, setWatermark] = useState<string>(localStorage.getItem('vision-mimic-watermark') || 'select watermark');

	useEffect(() => {
		const getUserName = async () => {
			try {
				const token = localStorage.getItem(`${env}-token`);
				const api = `https://${domain}/login/authentication/user`;
				const { data: { email } } = await axios.post(api, { token });
				const name = getUserNameByEmail(email);
				setLogged(email);
				setUserName(name);
			} catch (error) {
				console.error(error);
			}
		};
		if (logged && typeof logged === 'string') {
			const name = getUserNameByEmail(logged);
			setUserName(name);
		} else getUserName();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (options && video) {
			onSelect({
				avatar: video,
				background,
				watermark,
                host: options.host,
				voice: options.voice
			});
		}
		// eslint-disable-next-line
	}, [options]);

	useEffect(() => {
		video && localStorage.setItem('vision-mimic-video', video);
		if (options && video) {
			onSelect({ avatar: video, background, watermark, host: options.host, voice: options.voice });
		}
		// eslint-disable-next-line
	}, [video]);

	useEffect(() => {
		background && localStorage.setItem('vision-mimic-background', background);
		if (options && video) {
			onSelect({ avatar: video, background, watermark, host: options.host, voice: options.voice });
		}
		// eslint-disable-next-line
	}, [background]);

	useEffect(() => {
		watermark && localStorage.setItem('vision-mimic-watermark', watermark);
		if (options && video) {
			onSelect({ avatar: video, background, watermark, host: options.host, voice: options.voice });
		}
		// eslint-disable-next-line
	}, [watermark]);

	useEffect(() => {
		onSelection(showCategoryMenu);
		// eslint-disable-next-line
	}, [showCategoryMenu]);

    // const avatarOptions = ['video', 'background', 'watermark'];
    const avatarOptions = ['video'];

	const avatarForm = (
		<AvatarBackground
			video={video}
			background={background}
			watermark={watermark}
            options={avatarOptions}
			onSelection={(seletedCategory) => {
				setCategory(seletedCategory);
				setShowCategoryMenu(true);
			}}
		/>
	);

	const avatarOptionsPanel = showCategoryMenu && (
		<OptionPanel
			user={userName}
			category={category}
			byDefault={category === 'video' ? video : (category === 'background' ? background : watermark)}
			onSelect={(_item: string) => {
				setShowCategoryMenu(false);
				if (category === 'video') setVideo(_item);
				if (category === 'background') setBackground(_item);
				if (category === 'watermark') setWatermark(_item);
			}}
			onCancel={() => {
				console.log('cancel');
				setShowCategoryMenu(false);
			}}
			onChangeCustom={(status: boolean) => setIsCustomVideo(status)}
		/>
	);

			// <Form.Row className="justify-content-center">
	const textForm = !showCategoryMenu && (
		<Form>
			<Form.Row>
			{
				selects.map((item, key) => (
                  <Col key={`${item}-${key}`} xs={12} md={6} lg={6}
                    className={env.includes('prod') && item === 'host' ? 'd-none' : ''}
                  >
					<Select name={`loquista-tts-${item}`} key={key}
						label={item}
						options={item === 'host' ? options.hosts : (item === 'language' ? options.languages : options.voices)}
						selected={item === 'host' ? options.host : (item === 'language' ? options.language : options.voice)}
						onChange={(value) => {
							const { ...props } = options;
							if (item === 'host') setOptions({ ...props, host: value });
							if (item === 'language') setOptions({ ...props, language: value as Language });
							if (item === 'voice') setOptions({ ...props, voice: value });
						}} />
                  </Col>
				))
			}
			</Form.Row>
		</Form>);

	return (
		<Fragment>

			<Container fluid css={style} className={shown ? 'd-none' : ''}>
				{avatarForm}
				{avatarOptionsPanel}
				<Row className="justify-content-center mt-2">
					<Col xs={12} sm={12}>
						{textForm}
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default VideoAssistantForm;
