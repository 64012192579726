import React, { useRef, useEffect, Fragment, useContext } from 'react';

import { EnvContext, DomainContext } from '../../../../context/Context';
import CustomSocket from '../../../utilities/CustomSocket';

export interface IAudioContent {
  data: Buffer;
}

interface IProps {
  onConnected: (isConnected: boolean) => void;
  onError: (error: Error) => void;
  gotConnId: (id: string) => void;
  gotAudio: (
    audio_content: IAudioContent,
    num_block: number,
    total_blocks: number,
    sampleRate: number
  ) => void;
}

const TextToSpeechConn = ({ onConnected, onError, gotConnId, gotAudio }: IProps) => {
  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);
  const ttsWsPort = env.includes('prod') ? 8001 : 4001;
  const ttsWsUrl = `wss://${domain}/ws${ttsWsPort}`;

  const isMounted = useRef<boolean>(false);


  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const socket = (
    <CustomSocket
      url={ttsWsUrl}
      onOpen={(ws: WebSocket | null) => {
        console.log('Socket open');
        if (isMounted.current && ws) onConnected(true);
      }}
      onClose={() => {
        console.log('Socket close');
        if (isMounted.current) {
          onError(new Error('Socket connection closed'));
        }
      }}
      onMessage={({ data }) => {
        const { uuid, error, keep_alive, audio_content, ...rest } = JSON.parse(
          data
        );

        if (error) {
          if (isMounted.current) {
            const description =
              typeof error === 'string' ? error : JSON.stringify(error);
            onError(new Error(description));
          }
        } else if (keep_alive) {
          // console.log('connected...');
        } else if (!keep_alive && uuid) {
          gotConnId(uuid);
        } else if (audio_content) {
          const { num_block, total_blocks, sampleRate } = rest;
          gotAudio(audio_content, num_block, total_blocks, sampleRate);
        }
      }}
      onError={(error) => {
        if (isMounted.current) {
          const description =
            typeof error === 'string' ? error : JSON.stringify(error);
          onError(new Error(description));
        }
      }}
    />
  );

  return <Fragment>{socket}</Fragment>;
};

export default TextToSpeechConn;
