import React, { useEffect, useState, useRef, useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { DomainContext, LoginContext, EnvContext } from '../../../../context/Context';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';

import { AppContext } from "./contexts/appContext";
import CanvasFeed from "./components/CanvasFeed";
import DisplayResponse from "./components/DisplayResponse";
import VideoFeed from "./components/VideoFeed";
import BackgroundImage from "./components/BackgroundImage";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

interface IProps {
  title: string;
  description?: string;
}

const FaceReplacement = ({ title, description }: IProps) => {
  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);

  const { start, setStart } = AppContext();

  const startHandler = e => {
      console.log('Start');
      setStart(true);
  };

  const stopHandler = e => {
      console.log('Stop');
      setStart(false);
  };



  const faceReplacementContent = (
      <Container fluid>
        <Row className={'face-replacement-row'}>
          <BackgroundImage />
        </Row>
        <Row className={'face-replacement-row'}>
          <Col>
            <Button onClick={startHandler}
              className={`${start ? 'd-none' : ''}`}
              disabled={start}
              >Start</Button>
            <Button
              onClick={stopHandler}
              className={`${start ? '' : 'd-none'}`}
              disabled={!start}
              >Stop</Button>
            {start && <VideoFeed />}
          </Col>
        </Row>
        <Row className={'face-replacement-row'}>
          <Col>
            {start && <CanvasFeed />}
            <DisplayResponse />
          </Col>
        </Row>
      </Container>
  );

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description ?? 'Utopia app'} />
      </Helmet>
      <SubTitle name={title} />
      <Description text={description ? description : 'Utopia.AI'} />
      {faceReplacementContent}
    </Fragment>
  );
};

export default FaceReplacement;
