import React, { useEffect, useRef, useState } from 'react';

import WaveSurfer from 'wavesurfer.js';
// @ts-ignore
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
// @ts-ignore
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
// @ts-ignore
import MiniMap from 'wavesurfer.js/dist/plugin/wavesurfer.minimap.min.js';
// @ts-ignore
import Cursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faStop,
  faStepBackward,
  faStepForward,
  faPlayCircle,
  faPlus,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import * as helpers from '../../../../utils/helpers';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import LabelingRegion from './LabelingRegion';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Alert from 'react-bootstrap/Alert';

export interface IWave {
  src: string;
  segments: ISegment[];
}

export interface ISegment {
  key: number;
  id: string;
  transcription: string;
  start: number;
  end: number;
  lang: string;
}

interface IProps {
  src?: string;
  segments: ISegment[];
  language: string;
  onChange: (segments: ISegment[]) => void;
}

type PlayingButton = 'general' | 'region';
// type ClickDivEvent = React.MouseEvent<HTMLDivElement, MouseEvent>;
// type WheelDivEvent = React.WheelEvent<HTMLDivElement>;
type DivElement = HTMLDivElement;

const rateOptions = [0.5, 0.75, 1];

const LabelingWave = ({ src, segments, language, onChange }: IProps) => {
  const isMounted = useRef<boolean>(false);
  const wave = useRef<WaveSurfer>();
  const waveDiv = useRef<DivElement>(null);
  const timelineDiv = useRef<DivElement>(null);
  const minimapDiv = useRef<DivElement>(null);
  const _segments = useRef<ISegment[]>([]);
  const _playingMode = useRef<PlayingButton>('general');

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [rate, setRate] = useState<number>(1);
  const [alertText, setAlertText] = useState<string>('');

  const [_key, setKey] = useState<number>(-1);
  const [_id, setId] = useState<string>('');
  const [_start, setStart] = useState<number>(0);
  const [_end, setEnd] = useState<number>(0);
  const [_transcription, setTranscription] = useState<string>('');
  const [_lang, setLang] = useState<string>('');

  const updateSegment = (segment: ISegment) => {
    if (isMounted.current) {
      const { key, id, start, end, transcription, lang } = segment;

      const regions = document.getElementsByClassName('wavesurfer-region');
      for (let i = 0, len = regions.length; i < len; i++) {
        const attrId = regions[i].attributes.getNamedItem('data-id')?.value;

        if (attrId === id) {
          regions[i].setAttribute('key', `${key + 1}`);
        }
      }

      setKey(key);
      setId(id);
      setStart(start);
      setEnd(end);
      setTranscription(transcription);
      setLang(lang);
    }
  };

  useEffect(() => {
    isMounted.current = true;

    wave.current = WaveSurfer.create({
      container: '#waveform',
      waveColor: 'rgba(50, 50, 50, 0.5)',
      progressColor: 'rgba(0, 0, 0, 0.8)',
      cursorColor: 'rgba(235, 103, 28, 0.8)',
      cursorWidth: 2,
      interact: true,
      height: 256,
      responsive: true,
      scrollParent: true,
      backend: 'MediaElement',
      plugins: [
        RegionsPlugin.create({}),
        TimelinePlugin.create({ container: timelineDiv.current }),
        MiniMap.create({ container: minimapDiv.current }),
        Cursor.create({
          showTime: true,
          opacity: 1,
          customShowTimeStyle: {
            'background-color': '#000',
            color: '#fff',
            padding: '2px',
            'font-size': '10px',
          },
        }),
      ],
    });

    wave.current.on('interaction', () => {
      if (isMounted.current) setAlertText('');
    });

    wave.current.on('ready', () => {
      if (isMounted.current) {
        onChange(segments);
      }
    });

    wave.current.on('play', () => {
      if (isMounted.current) {
        setIsPlaying(true);

        const currentTime = wave.current?.getCurrentTime();

        if (currentTime) {
          const segment = _segments.current.find((el) => el.end > currentTime);
          if (segment) updateSegment(segment);
          else {
            const nextSegments = _segments.current.filter(
              (el) => el.start >= currentTime
            );

            if (nextSegments.length > 0) {
              updateSegment(nextSegments[0]);

              const regions = document.getElementsByClassName(
                'wavesurfer-region'
              );
              for (let i = 0, len = regions.length; i < len; i++) {
                const attrId = regions[i].attributes.getNamedItem('data-id')
                  ?.value;
                if (attrId === nextSegments[0].id)
                  regions[i].classList.add('selected');
                else regions[i].classList.remove('selected');
              }
            }
          }
        }
      }
    });

    wave.current.on('pause', () => {
      if (isMounted.current) setIsPlaying(false);
    });

    wave.current.on('region-click', (region) => {
      const { id } = region;

      const segment = _segments.current.find((el) => el.id === id);
      if (segment) updateSegment(segment);

      const regions = document.getElementsByClassName('wavesurfer-region');
      for (let i = 0, len = regions.length; i < len; i++) {
        const attrId = regions[i].attributes.getNamedItem('data-id')?.value;
        if (attrId === id) regions[i].classList.add('selected');
        else regions[i].classList.remove('selected');
      }
    });

    wave.current.on('region-in', (region) => {
      const { id, start } = region;

      const currentTime = wave.current?.getCurrentTime();

      if (
        _playingMode.current === 'region' &&
        currentTime &&
        currentTime < start
      ) {
        console.warn('Region update cancelled');
      } else {
        const segment = _segments.current.find((el) => el.id === id);
        if (segment) if (segment) updateSegment(segment);

        const regions = document.getElementsByClassName('wavesurfer-region');
        for (let i = 0, len = regions.length; i < len; i++) {
          const attrId = regions[i].attributes.getNamedItem('data-id')?.value;

          if (attrId === id) regions[i].classList.add('selected');
          else regions[i].classList.remove('selected');
        }
      }
    });

    wave.current.on('region-out', (region) => {
      const { id } = region;

      const regions = document.getElementsByClassName('wavesurfer-region');
      for (let i = 0, len = regions.length; i < len; i++) {
        const attrId = regions[i].attributes.getNamedItem('data-id')?.value;
        if (attrId === id) regions[i].classList.remove('selected');
      }
    });

    wave.current.on('region-play', (region) => {
      const { id } = region;

      if (isMounted.current) setIsPlaying(true);

      const regions = document.getElementsByClassName('wavesurfer-region');
      for (let i = 0, len = regions.length; i < len; i++) {
        const attrId = regions[i].attributes.getNamedItem('data-id')?.value;
        if (attrId === id) regions[i].classList.add('selected');
        else regions[i].classList.remove('selected');
      }
    });

    wave.current.on('region-update-end', (region) => {
      const { id, start, end } = region;

      const segment = _segments.current.find((el) => el.id === id);
      if (segment && isMounted.current) {
        const { key, transcription, lang } = segment;
        const updatedSegment = { key, id, start, end, transcription, lang };

        // update start and end values in current segment
        const updatedSegments = _segments.current.map((el) => {
          if (el.id === id) return updatedSegment;
          else return el;
        });

        // sort segments by new start values
        const sortedSegments = updatedSegments.sort((a, b) => {
          if (a.start < b.start) return -1;
          if (a.start > b.start) return 1;
          return 0;
        });

        // update segments keys after new order
        const updatedSortedSegments = sortedSegments.map((el, index) => {
          const { key, ...args } = el;
          return { key: index, ...args };
        });

        updateSegment(updatedSortedSegments.filter((el) => el.id === id)[0]);
        onChange(updatedSortedSegments);
      }
    });

    wave.current.on('region-created', (region) => {
      const regions = document.getElementsByClassName('wavesurfer-region');
      for (let i = 0, len = regions.length; i < len; i++) {
        regions[i].setAttribute('key', `${i + 1}`);
      }
      setTranscription('');
    });

    wave.current.on('region-removed', (region) => {
      const regions = document.getElementsByClassName('wavesurfer-region');
      for (let i = 0, len = regions.length; i < len; i++) {
        regions[i].setAttribute('key', `${i + 1}`);
      }
      setTranscription('');
    });

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (src && wave.current && isMounted.current) {
      console.log(`%c${src}`, 'color:blue');
      wave.current.load(src);
    }
    // eslint-disable-next-line
  }, [src]);

  useEffect(() => {
    if (segments && segments.length > 0) {
      console.table(segments);
      _segments.current = segments;
      segments.forEach((el) => {
        const { id, start, end, key } = el;
        if (wave.current && !wave.current.regions.list[id]) {
          const color = helpers.getRandomColor(0.5);
          wave.current.addRegion({ id, start, end, color });
        }

        const regions = document.getElementsByClassName('wavesurfer-region');
        for (let i = 0, len = regions.length; i < len; i++) {
          const attrId = regions[i].attributes.getNamedItem('data-id')?.value;
          if (attrId === id) {
            regions[i].setAttribute('key', `${key + 1}`);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [segments]);

  const onPlayPause = () => {
    if (isMounted.current) {
      _playingMode.current = 'general';
      wave.current?.playPause();
    }
  };

  const onPlayRegion = () => {
    const previousStatus = wave.current?.isPlaying();
    if (isMounted.current) {
      if (!previousStatus && segments) {
        const { id } = segments[_key];
        const currentRegion = wave.current?.regions.list[id];
        if (currentRegion) currentRegion.play();
        _playingMode.current = 'region';
      }
    }
  };

  const statistics = (
    <Row className="mt-4 text-center">
      <Col>Selected Region: {_key < 0 ? 'none' : _key + 1}</Col>
      <Col>Number of regions: {segments ? segments.length : 0}</Col>
    </Row>
  );

  const minimap = (
    <div
      id="minimap"
      className="bg-white mb-3 border rounded"
      ref={minimapDiv}
    ></div>
  );

  const waveform = (
    <div
      id="waveform"
      className="bg-white border rounded-top"
      ref={waveDiv}
    // onClick={onPlayPause}
    // onWheel={(event) => onWaveWheel(event)}
    ></div>
  );

  const timeline = (
    <div
      id="timeline"
      className="bg-white border rounded-bottom mb-3"
      ref={timelineDiv}
    ></div>
  );

  const alertComp = alertText ? (
    <Row>
      <Col className="p-0 text-center">
        <Alert variant="warning" className="my-1">
          {alertText}
        </Alert>
      </Col>
    </Row>
  ) : null;

  const regionComp = _id ? (
    <LabelingRegion
      id={_id}
      start={_start}
      end={_end}
      text={_transcription}
      language={_lang}
      onChangeText={(text: string) => {
        if (segments) {
          const segment = segments.find((el) => el.id === _id);
          if (segment) {
            const { key, id, start, end, lang } = segment;
            const transcription = text;
            const updatedSegment = { key, id, start, end, transcription, lang };
            const updatedSegments = segments.map((el) => {
              if (el.id === _id) return updatedSegment;
              else return el;
            });
            if (isMounted.current) {
              updateSegment(updatedSegment);
              onChange(updatedSegments);
            }
          }
        }
      }}
      onChangeLang={(newLang: string) => {
        if (segments) {
          const segment = segments.find((el) => el.id === _id);
          if (segment) {
            const { key, id, start, end, transcription } = segment;
            const lang = newLang;
            const updatedSegment = { key, id, start, end, transcription, lang };
            const updatedSegments = segments.map((el) => {
              if (el.id === _id) return updatedSegment;
              else return el;
            });
            if (isMounted.current) {
              updateSegment(updatedSegment);
              onChange(updatedSegments);
            }
          }
        }
      }}
    />
  ) : null;

  const playButton = (
    <Button
      size="sm"
      variant="outline-success"
      className="py-1 px-2 mx-1"
      title="play audio"
      onClick={onPlayPause}
    >
      Play <FontAwesomeIcon icon={faPlay} />
    </Button>
  );

  const stopButton = (
    <Button
      size="sm"
      variant="outline-danger"
      className="py-1 px-2 mx-1"
      title="stop audio"
      onClick={onPlayPause}
    >
      Stop <FontAwesomeIcon icon={faStop} />
    </Button>
  );

  const playRegionButton = (
    <Button
      size="sm"
      variant="outline-loquista"
      className="py-1 px-2 mx-1"
      title="play selected region"
      disabled={!_id || isPlaying}
      onClick={onPlayRegion}
    >
      <FontAwesomeIcon icon={faPlayCircle} /> region
    </Button>
  );

  const prevBtn = (
    <Button
      size="sm"
      variant="outline-primary"
      className="py-1 px-2 mx-1"
      title="prev region"
      onClick={() => {
        if (isMounted.current && segments) {
          const prevKey = _key > 0 ? _key - 1 : 0;
          const { id, start, end, transcription, lang } = segments[prevKey];
          wave.current?.regions.list[id].play();
          _playingMode.current = 'region';
          updateSegment({ key: prevKey, id, start, end, transcription, lang });
        }
      }}
    >
      <FontAwesomeIcon icon={faStepBackward} />
    </Button>
  );
  const nextBtn = (
    <Button
      size="sm"
      variant="outline-primary"
      className="py-1 px-2 mx-1"
      title="next region"
      onClick={() => {
        if (isMounted.current && segments) {
          const nextKey = _key < segments.length - 1 ? _key + 1 : _key;
          const { id, start, end, transcription, lang } = segments[nextKey];
          wave.current?.regions.list[id].play();
          _playingMode.current = 'region';
          updateSegment({ key: nextKey, id, start, end, transcription, lang });
        }
      }}
    >
      <FontAwesomeIcon icon={faStepForward} />
    </Button>
  );

  const navButtons = src ? (
    <div className="my-2 text-center text-md-left">
      {prevBtn}
      {isPlaying ? stopButton : playButton}
      {playRegionButton}
      {nextBtn}
    </div>
  ) : null;

  const speedButtons = src ? (
    <div className="my-2 text-center text-md-right">
      <ButtonGroup
        id="labeling-rate-buttons"
        className="border rounded"
        aria-label="labeling-rates"
      >
        {rateOptions.map((r, key) => (
          <Button
            id={`btn-rate-${r}`}
            key={key}
            size="sm"
            variant="light"
            className={`py-1 px-2`}
            onClick={() => {
              wave.current?.setPlaybackRate(r);
              if (isMounted.current) setRate(r);
            }}
          >{`x ${r === 0.5 ? '1/2' : r === 0.75 ? '3/4' : r}`}</Button>
        ))}
      </ButtonGroup>
      <span className='border rounded border-primary bg-primary text-light px-1 pb-1 pt-0 ml-2'>
        <small>rate: {rate}</small>
      </span>
    </div>
  ) : null;

  const addRegionBtn = (
    <Button
      size="sm"
      variant="outline-info"
      className="py-1 px-2 mx-1"
      title="add new region"
      onClick={() => {
        const id = helpers.getUuid(); // random uuid for the new segment
        const currentTime = wave.current?.getCurrentTime() || 0; // getting current cursor position

        const duration = wave.current?.getDuration();
        const overlaps = segments.filter(
          (el) => el.start <= currentTime && el.end >= currentTime
        );

        if (!overlaps.length && duration) {
          const start = currentTime;

          const prevItems = segments.filter((el) => el.end < start);
          let nextItems = segments.filter((el) => el.start >= start);

          const end = nextItems.length
            ? nextItems[0].start - 0.05
            : duration - 0.05;

          if (end - start >= 0.2) {
            let newKey = 0;
            if (nextItems.length > 0) {
              newKey = nextItems[0].key;
              nextItems = nextItems.map((el) => {
                const { key, ...args } = el;
                return { key: key + 1, ...args };
              });
            } else if (prevItems.length > 0) {
              newKey = prevItems[prevItems.length - 1].key + 1;
            } else {
              newKey = 0;
            }

            const newSegment = {
              key: newKey,
              id,
              start,
              end,
              transcription: '',
              lang: language,
            };

            if (isMounted.current) {
              updateSegment(newSegment);
              onChange([...prevItems, newSegment, ...nextItems]);
            }
          } else {
            setAlertText(
              'It is not possible to insert a segment at this position. Please, move the cursor to a wider and empty area to add a new segment.'
            );
          }
        } else {
          if (isMounted.current)
            setAlertText(
              'It is not possible to insert a segment at this position. Please, move the cursor to an empty area to add a new segment.'
            );
        }
      }}
    >
      <FontAwesomeIcon icon={faPlus} />
    </Button>
  );

  const removeRegionBtn = (
    <Button
      size="sm"
      variant="outline-danger"
      className="py-1 px-2 mx-1"
      title="remove selected region"
      onClick={() => {
        const { id } = segments[_key];
        const currentRegion = wave.current?.regions.list[id];
        currentRegion.remove();

        const prevItems = segments.filter((el) => el.key < _key);
        let nextItems = segments.filter((el) => el.key > _key);
        nextItems = nextItems.map((el) => {
          const { key, ...args } = el;
          return { key: key - 1, ...args };
        });

        if (isMounted.current) {
          onChange([...prevItems, ...nextItems]);
        }
      }}
    >
      <FontAwesomeIcon icon={faTrashAlt} />
    </Button>
  );

  const updateButtons = src ? (
    <div className="my-2 text-center text-md-right">
      {addRegionBtn}
      {removeRegionBtn}
    </div>
  ) : null;

  return (
    <Container>
      {src ? statistics : null}
      <Row>
        <Col>{minimap}</Col>
      </Row>
      <Row>
        <Col>{waveform}</Col>
      </Row>
      <Row>
        <Col>{timeline}</Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={4}>
          {navButtons}
        </Col>
        <Col xs={12} sm={12} md={4}>
          {speedButtons}
        </Col>
        <Col xs={12} sm={12} md={4}>
          {updateButtons}
        </Col>
      </Row>
      {alertComp}
      {regionComp}
    </Container>
  );
};

export default LabelingWave;
