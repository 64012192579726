import React, { useEffect, useRef, Fragment } from 'react';

interface IProps {
  url: string;
  onOpen: (ws: WebSocket | null) => void;
  onClose: () => void;
  onMessage: (message: any) => void;
  onError: (error: any) => void;
}

export const socketStates = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

const CustomSocket = ({ url, onOpen, onClose, onMessage, onError }: IProps) => {
  const isMounted = useRef<boolean>(false);
  const wsRef = useRef<WebSocket | null>(null);

  useEffect(() => {
    isMounted.current = true;

    try {
      wsRef.current = new WebSocket(url);
    } catch (error) {
      onError(error);
    }

    if (wsRef.current) {
      wsRef.current.onopen = () => {
        console.log('TCP Established');
        if (isMounted.current) {
          onOpen(wsRef.current || null);
        }
      };

      wsRef.current.onclose = () => {
        console.log('TCP disconnected');
        if (isMounted.current) onClose();
      };

      wsRef.current.onmessage = (message: MessageEvent) => {
        if (isMounted.current) onMessage(message);
      };

      wsRef.current.onerror = (error: Event) => {
        if (isMounted.current) onError(error);
      };
    }

    return () => {
      wsRef.current?.close();
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  return (<Fragment></Fragment>);
};

export default CustomSocket;
