/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { IApp } from '../App';
import { ISection } from '../hooks/useGetSections';
import { EnvContext } from '../context/Context';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { capitalizeFirstLetter } from '../utils/helpers';

interface IMenuItems {
	name: string;
	path: string;
}

interface IProps {
	apps: IApp[],
	sections: ISection[];
	handleHide: () => void;
}

const meunItems: IMenuItems[] = [
	{ name: 'home', path: '/' },
	{ name: 'about us', path: '/about' }
];

const SmallMenu = ({ apps, sections, handleHide }: IProps) => {
    const env = useContext(EnvContext);

	const fade = keyframes`
	from { opacity: 0;}
	to { opacity: 1;}
	`;

	const style = css`
	width: 100vw;
	min-height: 100vh;
	background: #f5f5f5;
	.col {
		animation: ${fade} 0.4s ease;
		opacity: 1;
	}
	h2 a, h3 a { color:  #000000; }
	h4 a {color: #626262; }
	.apps a { color: #8c8f91; }
	`;

	return (
		<React.Fragment>
			<Container fluid css={style}>
				<Row>
					<Col>
						<ul className="list-unstyled">
							{
								meunItems.map((item, key) => (
									<h2 key={`${item.name}-${key}`}>
										<li onClick={handleHide}>
											<Link to={item.path}>
												{capitalizeFirstLetter(item.name)}
											</Link>
										</li>
									</h2>
								))
							}
						</ul>
						<hr />
						<h3>
							<Link to='/products' onClick={handleHide}>Products</Link>
						</h3>
						<ul className="sections list-unstyled" onClick={handleHide}>
							{
								sections && sections.map((section) => (
									<li key={section.id} className="mt-4 ml-2">
										<h4>
											<Link to={`/${section.name}`}>
												{capitalizeFirstLetter(section.alias)}
											</Link>
										</h4>
										{
											(section.submenu && section.subsection) &&
											section.subsection.split(',').map((subSection, sId) => (
												<div key={section.groups!.split(',')[sId]}>
													<h5 className="text-secondary font-italic mt-3">
														{capitalizeFirstLetter(subSection)}
													</h5>
													<ul className="apps list-unstyled">
														{
															apps &&
                                                            apps
                                                            .filter(el => isEnv(env, el.env))
                                                            .map((el) => {
																console.log(el.path, el.path.includes(section.groups!.split(',')[sId]), section.groups!.split(',')[sId]);
																if (el.section === section.name && el.path.includes(section.groups!.split(',')[sId])) {
																	return (
																		<li key={el.id} className="mb-1 ml-2">
																			<Link to={`/${el.section}/${el.path}`}>
																				{el.app}
																			</Link>
																		</li>
																	);
																} else return null;
															})
														}
													</ul>
												</div>
											))
										}
										{
											section.submenu === 'false' && (
												<ul className="apps list-unstyled">
													{
														apps &&
                                                        apps
                                                        .filter(el => isEnv(env, el.env))
                                                        .map((el) => {
															if (el.section === section.name) {
																return (
																	<li key={el.id} className="mb-1 ml-2">
																		<Link to={`/${el.section}/${el.path}`}>
																			{el.app}
																		</Link>
																	</li>
																);
															} else return null;
														})
													}
												</ul>
											)
										}
									</li>
								))
							}
						</ul>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
};

function isEnv(currentEnv: Env, env: Env): boolean {
  if (currentEnv === 'prod') {
    if (env !== 'prod') return false;
    else return true;
  } else if (currentEnv === 'test') {
    if (env === 'dev') return false;
    else return true;
  } else return true;
}

export default SmallMenu;
