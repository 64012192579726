import React, { useRef, useEffect, useState } from 'react';

import * as helpers from '../../../../utils/helpers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import CustomSelect from '../../../forms/CustomSelect';

interface IProps {
  id: string;
  start: number;
  end: number;
  text: string;
  language: string;
  onChangeText: (text: string) => void;
  onChangeLang: (lang: string) => void;
}

interface ILabel {
  name: string;
  label: string;
}

const labels: ILabel[] = [
  { name: 'name', label: 'NAME' },
  { name: 'car plate', label: 'CAR_PLATE' },
  { name: 'id', label: 'ID_CARD' },
  { name: 'date', label: 'DATE' },
  { name: 'time', label: 'TIME' },
  { name: 'schedule', label: 'SCHEDULE' },
  { name: 'email', label: 'EMAIL' },
  { name: 'address', label: 'ADDRESS' },
  { name: 'phone', label: 'PHONE' },
  { name: 'url', label: 'URL' },
  { name: 'iban', label: 'IBAN' },
  { name: 'credit card', label: 'CREDIT_CARD' },
  { name: 'incomplete', label: 'INCOMPLETE' }
];

const specials: string[] = ['grammar', 'unclear', 'music', 'laughing', 'x'];

const languages: string[] = [
  'en-GB',
  'en-US',
  'es-ES',
  'pt-PT',
  'pt-BR',
  'fr-FR',
  'de-DE',
  'ca-ES',
  'it-IT',
];

const LabelingRegion = ({
  id,
  start,
  end,
  text,
  language,
  onChangeText,
  onChangeLang,
}: IProps) => {
  const isMounted = useRef<boolean>(false);

  const [currentId, setCurrentId] = useState<string>('');
  const [currentStart, setCurrentStart] = useState<number>(0);
  const [currentEnd, setCurrentEnd] = useState<number>(0);
  const [currentText, setCurrentText] = useState<string>('');
  const [currentLang, setCurrentLang] = useState<string>('');
  const [originalText, setOriginalText] = useState<string>('');
  const [wordLang, setWordLang] = useState<string>(language);
  const [invalidText, setInvalidText] = useState<string>('');
  const [warningText, setWarningText] = useState<string>('');

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (id) {
        setCurrentId(id);
        setOriginalText('');
      }
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (isMounted.current) {
      if (start) setCurrentStart(start);
    }
    // eslint-disable-next-line
  }, [start]);

  useEffect(() => {
    if (isMounted.current) {
      if (end) setCurrentEnd(end);
    }
    // eslint-disable-next-line
  }, [end]);

  useEffect(() => {
    if (isMounted.current) {
      setCurrentText(text);
      if (!originalText) setOriginalText(text);

      const hasNumbers = helpers.hasNumber(text);
      const hasUpperCases = helpers.hasUpperCase(text);
      if (hasNumbers) setInvalidText('Digits are not allowed');
      else setInvalidText('');

      if (hasUpperCases) {
        setWarningText('Please, check capital letters before saving');
      } else setWarningText('');
    }
    // eslint-disable-next-line
  }, [text]);

  useEffect(() => {
    if (isMounted.current) {
      if (language) {
        setCurrentLang(language);
        if (!wordLang) setWordLang(language);
      }
    }
    // eslint-disable-next-line
  }, [language]);

  const insertLabels = (startTag: string, endTag?: string) => {
    const msgInput: HTMLTextAreaElement | undefined =
      (document.getElementById('labeling-textarea') as HTMLTextAreaElement) ||
      undefined;
    if (msgInput) {
      const oldText = msgInput.value;
      const selTextStart = msgInput.selectionStart;
      const selTextEnd = msgInput.selectionEnd;

      const labeledText = endTag
        ? `${startTag}${oldText.substring(selTextStart, selTextEnd)}${endTag}`
        : startTag;
      msgInput.value = `${oldText.substring(
        0,
        selTextStart
      )}${labeledText}${oldText.substring(selTextEnd)}`;

      const rangeStart: number =
        endTag || selTextStart === selTextEnd
          ? selTextStart + startTag.length
          : selTextStart;
      const rangeEnd: number =
        (endTag ? selTextEnd : selTextStart) + startTag.length;
      msgInput.setSelectionRange(rangeStart, rangeEnd);
      msgInput.focus();

      if (isMounted.current) onChangeText(msgInput.value);
    }
  };

  const removeLabels = () => {
    const msgInput: HTMLTextAreaElement | undefined =
      (document.getElementById('labeling-textarea') as HTMLTextAreaElement) ||
      undefined;
    if (msgInput) {
      const oldText = msgInput.value;
      const selTextStart = msgInput.selectionStart;
      const selTextEnd = msgInput.selectionEnd;
      const selectedText = msgInput.value?.substr(
        selTextStart,
        selTextEnd - selTextStart
      );

      const oldPrevText = oldText.substring(0, selTextStart);
      const oldNextText = oldText.substring(selTextEnd);

      const prevTagInit = oldPrevText.lastIndexOf('<');

      if (prevTagInit >= 0 && oldPrevText[prevTagInit + 1] !== '/') {
        const newPrevText = oldPrevText.substring(0, prevTagInit);

        const nextTagEnd = oldNextText.indexOf('>');
        if (nextTagEnd >= 0) {
          const newNextText = oldNextText.substring(nextTagEnd + 1);

          msgInput.value = `${newPrevText}${selectedText} ${newNextText}`;
          msgInput.focus();

          if (isMounted.current) onChangeText(msgInput.value);
        } else {
          console.warn('No label found');
          if (isMounted.current) setWarningText('No label found');
        }
      } else {
        console.warn('No label found');
        if (isMounted.current) setWarningText('No label found');
      }
    }
  };

  const undo = () => {
    const msgInput: HTMLTextAreaElement | undefined =
      (document.getElementById('labeling-textarea') as HTMLTextAreaElement) ||
      undefined;
    if (msgInput) {
      msgInput.value = originalText;
      msgInput.focus();

      if (isMounted.current) onChangeText(msgInput.value);
    }
  };

  return (
    <Row id={`labeling-region-${currentId}`} className="mt-4">
      <Col>
        <Form>
          <Form.Row>
            <Col className="p-0">
              <Form.Group as={Row}>
                <Col sm="2" className="pt-1 px-0 text-xs-center">
                  <Form.Label>Start</Form.Label>
                </Col>
                <Col sm="10" className="px-0">
                  <Form.Control
                    type="text"
                    value={currentStart.toFixed(2)}
                    disabled
                    size="sm"
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col className="p-0">
              <Form.Group as={Row}>
                <Col sm="2" className="pt-1 px-0 text-sm-center">
                  <Form.Label>End</Form.Label>
                </Col>
                <Col sm="10" className="px-0">
                  <Form.Control
                    type="text"
                    value={currentEnd.toFixed(2)}
                    disabled
                    size="sm"
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col className="p-0">
              <Form.Group as={Row}>
                <Col sm="3" className="pt-1 px-0 text-sm-center">
                  <Form.Label>Length</Form.Label>
                </Col>
                <Col sm="9" className="px-0">
                  <Form.Control
                    className={(parseFloat(currentEnd.toFixed(2)) - parseFloat(currentStart.toFixed(2))) > 15 ? 'border-danger' : ''}
                    type="text"
                    value={(parseFloat(currentEnd.toFixed(2)) - parseFloat(currentStart.toFixed(2))).toFixed(2)}
                    disabled
                    size="sm"
                  />
                </Col>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="p-0">
              <Form.Label>Transcription</Form.Label>
              <Form.Control
                id="labeling-textarea"
                as="textarea"
                rows={4}
                value={currentText}
                isInvalid={invalidText ? true : false}
                isValid={invalidText ? false : true}
                onChange={(event) => {
                  const value = event.target.value;
                  if (isMounted.current) {
                    if (!value) setInvalidText('The transcription is empty');
                    else {
                      const hasNumbers = helpers.hasNumber(value);
                      const hasUpperCases = helpers.hasUpperCase(value);
                      if (hasNumbers) setInvalidText('Digits are not allowed');
                      else setInvalidText('');

                      if (hasUpperCases) {
                        setWarningText(
                          'Please, check capital letters before saving'
                        );
                      } else setWarningText('');
                    }
                    setCurrentText(value);
                    onChangeText(value);
                  }
                }}
              />
              <Form.Control.Feedback type="invalid">
                {invalidText}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid">
                {warningText ? warningText : 'Valid transcription'}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm="9" className="overflow-auto p-0">
              <ButtonGroup
                className="border rounded"
                aria-label="labeling-labels"
              >
                {labels.map((item, key) => (
                  <Button
                    key={`labels-inline-${key}`}
                    id={`labeling-label-${item.label}-${key}`}
                    variant="light"
                    size="sm"
                    onClick={(event: any) => {
                      console.log(`Selected Label: ${item.label}`);
                      insertLabels(`<${item.label}>`, `</${item.label}>`);
                    }}
                  >
                    {item.name}
                  </Button>
                ))}
              </ButtonGroup>
            </Form.Group>
            <Form.Group as={Col} sm="3" className="text-right p-0">
              <ButtonGroup aria-label="labeling-labels-options">
                <Button variant="danger" size="sm" onClick={removeLabels}>
                  Remove label
                </Button>
                <Button variant="primary" size="sm" onClick={undo}>
                  Undo
                </Button>
              </ButtonGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm={8} className="p-0">
              <ButtonGroup
                className="border rounded"
                aria-label="labeling-special-labels"
              >
                {specials.map((item, key) => (
                  <Button
                    key={`special-labels-inline-${key}`}
                    id={`special-labels-${item}-${key}`}
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      if (item === 'grammar') {
                        insertLabels(
                          `<${item.toUpperCase()}>`,
                          `</${item.toUpperCase()}>`
                        );
                      } else if (item === 'unclear') {
                        insertLabels('(...)');
                      } else if (item === 'music') {
                        insertLabels('(#)');
                      } else if (item === 'laughing') {
                        insertLabels('(hahaha)');
                      } else if (item === 'x') {
                        insertLabels('(x)');
                      }
                    }}
                  >
                    {item}
                  </Button>
                ))}
              </ButtonGroup>
            </Form.Group>
            <Form.Group as={Col} sm={4} className="p-0 text-right">
              <Form.Group as={Row}>
                <Col xs={6} className="p-0">
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      insertLabels(
                        `<LANG=${wordLang.split('-')[0]}>`,
                        '</LANG>'
                      );
                    }}
                  >
                    Word language
                  </Button>
                </Col>
                <Col xs={6} className="p-0">
                  <CustomSelect
                    name="labeling-word-language"
                    label="word language"
                    hiddenLabel={true}
                    options={languages}
                    selected={wordLang}
                    onChange={(lang) => {
                      if (isMounted.current) setWordLang(lang);
                    }}
                  />
                </Col>
              </Form.Group>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="p-0">
              <CustomSelect
                name="labeling-region-language"
                label="Region language"
                options={languages}
                selected={currentLang}
                onChange={(lang) => {
                  if (isMounted.current) onChangeLang(lang);
                }}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </Col>
    </Row>
  );
};

export default LabelingRegion;
