/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useRef, useState, useEffect } from "react";
import { Row, FormControl, InputGroup, Button, Form, Alert } from "react-bootstrap";
import { AppContext } from "../contexts/appContext";

const style = css`

    .alert {
        padding: 5px 30px 5px 10px;

        .close {
            padding: 0px 5px;
        }
    }
`;

const GetImage = () => {
    const [img, setImg] = useState(null);
    const { setImage, setImageData, start } = AppContext();
    const image_ref = useRef(undefined);
    const [fileName, setFileName] = useState("Please upload a full body picture");
     const [show, setShow] = useState(true);

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        setFileName(e.target.files[0].name);
        setImg(URL.createObjectURL(file));
    };

    const handleContext = (e) => {
        const get_image = e.target;
        setImage(get_image);

        const imgData = getBase64Image(image_ref.current);
        console.log(imgData);
        setImageData(imgData);
    };

    function getBase64Image(img) {
        var canvas = document.createElement("canvas");

        canvas.height = img.height;
        canvas.width = img.width;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        // Get the data-URL formatted image
        var dataURL = canvas.toDataURL("image/png");

        return dataURL;
    }

    return (
        <Row css={style}>
            <Form.File
                accept="image"
                onChange={handleChange}
                disabled={start}
                label={fileName}
                // onChange={(e) => setFileName(e.target.files[0].name)}
                custom
            />

            {img && (
                <img
                src={img}
                ref={image_ref}
                alt="ref image"
                onLoad={handleContext}
                style={{ display: "none" }}
                />
            )}
        </Row>
    );

            // {show && (<Alert variant="warning" onClose={() => setShow(false)} dismissible>
            //     It is important to enter as a reference the image of the whole body of a woman from the front, wearing a dress or skirt and heels, with her hands on the sides of her body.
            // </Alert>)}
};

export default GetImage;
