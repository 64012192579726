/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';

import PCMPlayer from 'pcm-player';

import { IAudioContent } from '../../loquista/tts/TextToSpeechConn';
import { usePlayAvatar } from '../usePlayAvatar';

import Image from 'react-bootstrap/Image';

const style: SerializedStyles = css`
.avatar-wrapper {
	width: 100%;
	padding-bottom: 1rem;
	margin-bottom: 0.5rem;
}
.avatar-wrapper > img {
	margin: auto;
	width: 75%;
}

@media (min-width: 768px) {
	.avatar-wrapper {
		// position:absolute;
		// left: 1rem;
		// bottom: 0.5rem;
		// padding-bottom: 0;
		// margin-bottom: 0;
		//border: none;
	}
	.avatar-wrapper > img {
		width: 100%;
	}
}

@media (min-width: 992px) {
	.avatar-wrapper > img {
		width: 80%;
	}
}
`;

interface IProps {
	audio: IAudioContent;
	avatar: string;
	background: string | undefined,
	watermark: string | undefined,
	shown: boolean;
	gotImage: () => void;
}

const VideoAssistant = (props: IProps) => {
	const { audio, avatar, background, watermark, shown, gotImage } = props;

	const isMounted = useRef<boolean>(false);
	const player = useRef<PCMPlayer>();

	const { result, setResult } = usePlayAvatar({ audio, avatar, background, watermark });

	const [times, setTimes] = useState<Date[]>([]);

	useEffect(() => {
		isMounted.current = true;
		player.current = new PCMPlayer({
			inputCodec: 'Int16',
			channels: 1,
			sampleRate: 16000,
			flushTime: 50
		});

		return () => {
			isMounted.current = false;
			player.current?.destroy();
		};
	}, []);

	useEffect(() => {
		const playSound = () => {
			const { audio_contents } = result;
			const arrayBuffer = audio_contents ? new Int8Array(audio_contents.data) : null;
            console.log('arrayBuffer:', arrayBuffer.length);
			arrayBuffer && player.current?.feed(arrayBuffer);
		};
		isMounted.current && result.audio_contents && playSound();
		if (result.image) {
			gotImage();
			// const now = new Date();
			// const updatedTimes = [...times, now];

			// let delays: number[] = [];

			// setTimes(updatedTimes);
			// updatedTimes.reduce((acc, current, index) => {
			//     // console.log('Diff', current.getTime() - acc.getTime());
			//     delays = [...delays, current.getTime() - acc.getTime()];
			//     return current;
			// });

            // console.log('delays:', delays);

			// console.log(Date.now());
		}
		// eslint-disable-next-line
	}, [result]);

	useEffect(() => {
		if (!shown) {
			setResult({ image: null, audio_contents: null });
		}
		// eslint-disable-next-line
	}, [shown]);


	return (
		<div css={style}>
			{result.image && (
				<div className="avatar-wrapper">
					<Image fluid alt={avatar} src={`data:image/jpeg;base64,${result.image}`} />
				</div>
			)}
		</div>
	);
};

export default VideoAssistant;
