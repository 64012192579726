import React, {
    createContext,
    useContext,
    useRef,
    useEffect,
    useState,
} from "react";

import axios, { AxiosError } from 'axios';

import { DomainContext, EnvContext } from '../../../../../context/Context';

const VideoContext  = createContext();

export const AppContext = () => {
    const ret = useContext(VideoContext);
    return ret;
};

const VideoContextProvider = ({ children }) => {
    const [requestData, setRequestData] = useState("");
    const [responseData, setResponseData] = useState("");
    const [start, setStart] = useState(false);
    const outputVideoEl = useRef(undefined);
    const [zoomOutput, setZoomOutput] = useState(false);
    const zoomOutputVideoEl = useRef(undefined);
    const drivingVideoEl = useRef(undefined);
    const canvas_ref = useRef(undefined);
    const [refImage, setRefImage] = useState(null);
    const [image, setImage] = useState(null);
    const [imageData, setImageData] = useState(localStorage.getItem('vision-fashion-animation-image-data'));
	const [drivingVideo, setDrivingVideo] = useState(localStorage.getItem('vision-fashion-animation-video') || "");
	const [drivingVideos, setDrivingVideos] = useState([]);
	const [outputVideoUrl, setOutputVideoUrl] = useState(null);
    const [outputVideoProgress, setOutputVideoProgress] = useState(0);
    const [progressInterval, setProgressInterval] = useState(null);
    const [showVideosPanel, setShowVideosPanel] = useState<boolean>(true);
    const [feedback, setFeedback] = useState(null);
    const [playing, setPlaying] = useState(false);


    const env = useContext(EnvContext);
    const domain = useContext(DomainContext);

    useEffect(() => {
            const loadVideos = async () => {
                const url = `https://${domain}/api/vision/fashionAnimation/videos`;
                const headers = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
                const params = { };
                const { data } = await axios.get(url, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
                console.log('FA videos', data.items);
                setDrivingVideos(data.items);
            };
            loadVideos();

            setOutputVideoProgress(0);
    }, []);

    // useEffect(() => {
    // }, [requestData, start]);

    useEffect(() => {
        if (start === false) {
            // TODO
            // if(outputVideoEl && outputVideoEl.current) {
            //     outputVideoEl.current.pause();
            // }
            // if(drivingVideoEl && drivingVideoEl.current) {
            //     drivingVideoEl.current.pause();
            // }
            canvas_ref.current = undefined;
            setRequestData("");
            setResponseData("");

            clearInterval(progressInterval);
        } else {
            console.log('START');

            setFeedback(null);

            // Estimated time
            const videoItem = drivingVideos.find(video => video.key === drivingVideo);
            const frames = videoItem?.frames || 0;
            const serviceFps = 3.5; // Velocidad del servicio fashionAnimation procesando/renderizando frames
            const estimatedTime = 1.15 * (frames / serviceFps); // + 15% extra
            console.log(`Estinated processing time: ${Math.floor(estimatedTime)}s`);

            // Progress
            const timeStepMs = 100;
            const progressStep = 100 * timeStepMs / estimatedTime / 1000; // 0-100% (ms/1000 / s)
            // console.log(`progressStep: ${progressStep}s`);
            // console.log(`Estinated fashionAnimation processing time: ${estimatedTime}s`);
            let progress = 0;
            setProgressInterval(
                setInterval(() => {
                    progress += progressStep;
                    progress = progress > 100 ? 100: progress;
                    console.log('progress:', progress);
                    setOutputVideoProgress(progress);
                }, timeStepMs)
            );


            const base64Image = refImage.split(',')[1]

            const request = async () => {
                // const domain = `172.26.72.128:4412`;
                const fashionUrl = `https://${domain}/api/vision/fashionAnimation/animate`;
                const headers = {
                    headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` },
                    timeout: 5 * 60 * 1000
                };
                const body = {
                    drivingVideoUrl: `https://dialoga-machine-learning.s3.amazonaws.com/${drivingVideo}`,
                    image: base64Image,
                };

                try {
                    const instance = axios.create({
                          baseURL: `https://${domain}/api/vision/fashionAnimation`,
                          timeout: 5 * 60 * 1000,
                          headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` },
                    });
                    // const { data } = await axios.post(fashionUrl, body, headers);

                    const { data } = await instance.post('/animate', body);

                    setOutputVideoUrl(data.videoUrl);
                    setFeedback(null);
                } catch(err) {
                    console.log('ERROR', err);
                    // TODO error feedback
                    setFeedback('Server Error. Try again later');
                    setStart(false);
                }

                setStart(false);
            };
            request();
        }

    }, [start]);


    useEffect(() => {
        if(outputVideoEl && outputVideoEl.current) {
            outputVideoEl.current.addEventListener('ended', (event) => {
                console.log('END PLAYING');
              setPlaying(false);
            });
        }
    }, [outputVideoUrl]);


    useEffect(() => {
        localStorage.setItem('vision-fashion-animation-video', drivingVideo);
        setOutputVideoUrl(null);
    }, [drivingVideo]);

    useEffect(() => {
        localStorage.setItem('vision-fashion-animation-image-data', imageData);
    }, [imageData]);

    return (
        <VideoContext.Provider
        value={{
            outputVideoEl,
            zoomOutputVideoEl,
            zoomOutput,
            setZoomOutput,
            drivingVideoEl,
            canvas_ref,
            requestData,
            setRequestData,
            responseData,
            setResponseData,
            start,
            setStart,
            refImage,
            setRefImage,
            image,
            setImage,
            imageData,
            setImageData,
            drivingVideo,
            setDrivingVideo,
            drivingVideos,
            setDrivingVideos,
            outputVideoUrl,
            setOutputVideoUrl,
            showVideosPanel,
            setShowVideosPanel,
            feedback,
            playing,
            setPlaying,
            outputVideoProgress,
        }}
    >
    {children}
    </VideoContext.Provider>
  );
};
export default VideoContextProvider;
