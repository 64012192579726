import { useState, useEffect, useContext, useRef } from 'react';
import { EnvContext, DomainContext } from '../../../../context/Context';

import axios from 'axios';
import byDefault from '../../../../utils/default.json';
import { orderLanguages, orderVoices, defaultSelectedVoiceByLanguage } from '../../../../utils/utils';

export type Language =
	"es-ES" |
	"es-MX" |
	"ca-ES" |
	"en-US" |
	"en-GB" |
	"fr-FR" |
	"de-DE" |
	"it-IT" |
	"pt-PT" |
	"pt-BR";

export type Model = 'TTS' | 'F5Cloning';
  
export interface ITextToSpeechOptions {
	hosts: string[];
	languages: string[];
	voices: string[];
  models: string[];
	host: string;
	language: Language;
	voice: string;
	text: string;
  model: Model;
}

export interface IUseTextToSpeechForm {
	options: ITextToSpeechOptions,
	setOptions: React.Dispatch<React.SetStateAction<ITextToSpeechOptions>>;
	formError: Error | null;
}

interface IProps {
	defaultHost?: string;
	defaultLanguage?: Language;
}

const useTextToSpeechForm = ({ defaultHost, defaultLanguage }: IProps) => {
	const env = useContext(EnvContext);
	const domain = useContext(DomainContext);

	const isMounted = useRef<boolean>(false);

	const [globalError, setGlobalError] = useState<Error | null>(null);
	const [options, setOptions] = useState<ITextToSpeechOptions>({
		hosts: [''],
		languages: [''],
		voices: [''],
    models: getModels(),
		host: getItemFromLocalStorage('host'),
		language: getItemFromLocalStorage('language') as Language,
		voice: getItemFromLocalStorage('voice'),
		text: '',
    model: getItemFromLocalStorage('model') as Model
	});

	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false; };
	}, []);

	useEffect(() => {
		const getOptions = async () => {
			try {
				// getting hosts and selected host
				const hosts = await getHosts(domain, env); // getting alive hosts
				const storedHost = getItemFromLocalStorage('host');
				const isStoredHostAvailable = storedHost ? hosts.includes(storedHost) : false;
				// if there is not a stored host available, we choose a random one unless ENV was 'PROD', in this case => 78

                let host;
				if (!env.includes('prod')) {
					const randomHost = hosts[Math.floor(Math.random() * hosts.length)];
					host = isStoredHostAvailable ? storedHost : randomHost;
				} else {
                    host = '172.26.161.12'; // TODO!!
                }

				// getting languages and selected language by host
				const languages = await getLanguages(domain, env, host, options.model);
				const storedLanguage = getItemFromLocalStorage('language');
				const isStoredLanguageAvailable = storedLanguage ? languages.includes(storedLanguage as Language) : false;
				const language: Language = isStoredLanguageAvailable ? storedLanguage as Language : languages[0];

				const text = getTextFromLocalStorage(language) || getText(language);

        const model = getItemFromLocalStorage('model') as Model || 'TTS';

				// getting voices and selected voice by host and language
				const voices = await getVoices(domain, env, host, language, model);
				const storedVoice = getItemFromLocalStorage('voice');
				const isStoredVoiceAvailable = storedVoice ? voices.includes(storedVoice) : false;
				const voice = isStoredVoiceAvailable ? storedVoice : defaultSelectedVoiceByLanguage(language, voices);

				isMounted.current && setOptions({
					hosts,
					languages,
					voices,
          models: getModels(),
					host: defaultHost ?? host,
					language: defaultLanguage ?? language,
					voice,
					text,
          model
				});
			} catch (err) {
				const error = new Error('An error occurred while getting TTS params');
				isMounted.current && setGlobalError(error);
			}
		};
		getOptions();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const getOptionsByHost = async () => {
			const { host, hosts } = options;
			setItemToLocalStorage('host', host);
			try {
				// getting languages and selected language by host
				const languages = await getLanguages(domain, env, host, options.model);
				const storedLanguage = getItemFromLocalStorage('language');
				const isStoredLanguageAvailable = storedLanguage ? languages.includes(storedLanguage as Language) : false;
				const language: Language = isStoredLanguageAvailable ? storedLanguage as Language : languages[0];

				const text = getTextFromLocalStorage(language) || getText(language);

        const model = getItemFromLocalStorage('model') as Model || 'TTS';

				// getting voices and selected voice by host and language
				const voices = await getVoices(domain, env, host, language, model);
				const storedVoice = getItemFromLocalStorage('voice');
				const isStoredVoiceAvailable = storedVoice ? voices.includes(storedVoice) : false;
				const voice = isStoredVoiceAvailable ? storedVoice : defaultSelectedVoiceByLanguage(language, voices);

				isMounted.current && setOptions({
					hosts,
					languages,
					voices,
          models: getModels(),
					host,
					language,
					voice,
					text,
          model
				});
			} catch (err) {
				const error = new Error('An error occurred while getting TTS params');
				isMounted.current && setGlobalError(error);
			}
		};
		if (options.host) getOptionsByHost();
		// eslint-disable-next-line
	}, [options.host]);

	useEffect(() => {
		const getOptionsByLanguage = async () => {
			const { host, hosts, language, languages } = options;
			setItemToLocalStorage('language', language);

			try {
				const text = getTextFromLocalStorage(language) || getText(language);

        const model = getItemFromLocalStorage('model') as Model || 'TTS';
        
				// getting voices and selected voice by host and language
				const voices = await getVoices(domain, env, host, language, model);
				const storedVoice = getItemFromLocalStorage('voice');
				const isStoredVoiceAvailable = storedVoice ? voices.includes(storedVoice) : false;
				const voice = isStoredVoiceAvailable ? storedVoice : defaultSelectedVoiceByLanguage(language, voices);

				isMounted.current && setOptions({
					hosts,
					languages,
					voices,
          models: getModels(),
					host: host,
					language,
					voice,
					text,
          model
				});
			} catch (err) {
				const error = new Error('An error occurred while getting TTS params');
				isMounted.current && setGlobalError(error);
			}
		};
		if (options.host && options.language) getOptionsByLanguage();
		// eslint-disable-next-line
	}, [options.language]);

	useEffect(() => {
    
		if (options.host && options.language && options.voice) {
			setItemToLocalStorage('voice', options.voice);
		}
		// eslint-disable-next-line
	}, [options.voice]);

  useEffect(() => {
    const getOptionsByHost = async () => {
      if (options.host && options.language && options.model) {
        const languages = await getLanguages(domain, env, options.host, options.model);
        const storedLanguage = getItemFromLocalStorage('language');
        const isStoredLanguageAvailable = storedLanguage ? languages.includes(storedLanguage as Language) : false;
        const language: Language = isStoredLanguageAvailable ? storedLanguage as Language : languages[0];
        
        const text = getTextFromLocalStorage(language) || getText(language);

        setItemToLocalStorage('model', options.model);
        setItemToLocalStorage('language', language);

        getVoices(domain, env, options.host, options.language, options.model)
          .then((voices) => setOptions({
            ...options,
            voices,
            languages,
            language,
            voice: defaultSelectedVoiceByLanguage(language, voices)
          })
        )
      }
    }
    getOptionsByHost();
    // eslint-disable-next-line
  }, [options.model]);

	useEffect(() => {
		if (options.host && options.languages && options.voice && options.text) {
			const { language, text } = options;
			setTextToLocalStorage(language, text);
		}
		// eslint-disable-next-line
	}, [options.text]);

	return { options, setOptions, formError: globalError };
};

export { useTextToSpeechForm };

// HOSTS
type Env = 'prod' | 'test' | 'dev';
type State = 'available' | 'unavailable';

interface IHost {
	env: Env;
	host: string;
	port?: number;
	state?: State;
}

interface IDataResponse {
	availableHosts: IHost[];
}

interface IHostResponse {
	data: IDataResponse;
}

async function getHosts(domain: string, env: string): Promise<string[]> {
	try {
		const url = `https://${domain}/api/loquista/tts/hosts`;
		const params = { env };
		const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
		const response = await axios.post(url, params, options);
		const { data: { availableHosts } }: IHostResponse = response;
		const hosts = availableHosts.map(item => item.host);
		return Promise.resolve(hosts);
	} catch (error) {
		console.error(error);
		return Promise.reject(new Error('An error occurred while getting tts hosts'));
	}
};

// LANGUAGES
interface ILanguageData {
	availableLanguages: string[];
}

interface ILangResponse {
	data: ILanguageData;
}

async function getLanguages(domain: string, env: string, host: string, model: Model = 'TTS'): Promise<Language[]> {
	try {
		const url = `https://${domain}/api/loquista/tts/languages`;
		const params = { host, model };
		const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
		const { data: { availableLanguages } }: ILangResponse = await axios.post(url, params, options);
		const sortedLanguages: Language[] = orderLanguages(availableLanguages) as Language[];
		return Promise.resolve(sortedLanguages);
	} catch (error) {
		console.error(error);
		return Promise.reject(new Error('An error occurred while getting tts languages'));
	}
};

// VOICES
interface IVoiceData {
	availableVoices: string[];
}

interface IVoiceResponse {
	data: IVoiceData;
}

async function getVoices(domain: string, env: string, host: string, language: Language, model: Model): Promise<string[]> {
	try {
    const url = `https://${domain}/api/loquista/tts/voices`;
    const params = { host, language, model };
    const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
    const { data: { availableVoices } }: IVoiceResponse = await axios.post(url, params, options);
    const sortedVoices: string[] = orderVoices(availableVoices);
    return Promise.resolve(sortedVoices);
	} catch (error) {
		console.error(error);
		return Promise.reject(new Error('An error occurred while getting tts voices'));
	}
};

// TEXT
export function getText(lang: Language): string {
	return byDefault.languages.texts[lang];
};

// LOCAL STORAGE
type ItemType = 'host' | 'language' | 'voice' | 'text' | 'model';

function getItemFromLocalStorage(type: ItemType): string {
	const key = `loquista-tts-${type}`;
	return (localStorage.getItem(key) || '');
}

function setItemToLocalStorage(type: ItemType, item: string): void {
	const key = `loquista-tts-${type}`;
	localStorage.setItem(key, item);
}

function getTextFromLocalStorage(language?: Language): string {
	if (language) {
		const key = `loquista-tts-text[${language}]`;
		return (localStorage.getItem(key) || '');
	} else return '';
}

function setTextToLocalStorage(language: Language, text: string): void {
	const key = `loquista-tts-text[${language}]`;
	localStorage.setItem(key, text);
}

function getModels(): Model[] {
  return ['TTS', 'F5Cloning']
}
