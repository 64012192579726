/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import VideoAssistant from './VideoAssistant';
import Chat, { IChatProps } from './Chat';

import Container from 'react-bootstrap/Container';
import TextToSpeechConn, { IAudioContent } from '../../loquista/tts/TextToSpeechConn';
import { getUuid } from '../../../../utils/helpers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const style: SerializedStyles = css`
	&.minerva-chat-container {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
	}
	&.minerva-text-chat {
		height: 61.75vh;
	}
	&.minerva-video-chat {
		height: 56.75vh;
	}
	.row {
		height: 100%;
	}
	.video-assistant-chat {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	/*
	.minerva-gradient {
		height: 50vh;
		width: 100%;
		position: absolute;
		bottom: 0;
		z-index: 999;
		background: linear-gradient( 0deg, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1) 90%);
		}
		*/
`;

const ChatContainer = ({ ...props }: IChatProps) => {
	const { id, shown, assistant, avatar, background, watermark } = props;

    const ttsIdRef = useRef<string>(getUuid());

	const [audio, setAudio] = useState<IAudioContent>();
	const [displayMessages, setDisplayMessages] = useState<boolean>(false);

	const ttsCx = (
		<TextToSpeechConn
			onConnected={(value: boolean) => value && console.log('TTS connected')}
			onError={(error) => console.warn('TTS disconnected because of', error)}
			gotConnId={(id: string) => {
                ttsIdRef.current = id;
            }}
			gotAudio={(audio: IAudioContent) => setAudio(audio)}
		/>
	);

	const videoAssitant = (
		<Col className="px-0 text-center video-assistant-chat" xs={12} md={3} lg={4}>
			{audio && (
				<VideoAssistant
					avatar={avatar}
					background={background}
					watermark={watermark}
					audio={audio}
					shown={shown}
					gotImage={() => {
						console.log('got Image!!');
						setDisplayMessages(true);
					}}
				/>
			)}
		</Col>
	);

	const containerClasses = classNames(
		'minerva-chat-container',
		{ 'minerva-text-chat': assistant.typename !== 'video' },
		{ 'minerva-video-chat': assistant.typename === 'video' },
		'mt-2',
		'px-0',
		'pb-2',
		{ 'd-none': !shown }
	);

	return (
		<Container css={style} fluid className={containerClasses}>

			<Row>
				{assistant.typename === 'video' && ttsCx}
				{assistant.typename === 'video' && videoAssitant}
				<Col className="px-0"
					xs={12}
					md={assistant.typename === 'video' ? 9 : 12}
					lg={assistant.typename === 'video' ? 8 : 12}>
					<Chat
						{...props}
						id={id}
                        ttsId={ttsIdRef.current}
						displayMessages={displayMessages}
						toggleDisplayMessages={() => setDisplayMessages(false)}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default ChatContainer;

/*
				<MinervaContextProvider>
					<VideoAssistant />
				</MinervaContextProvider>
				<div className="minerva-gradient"></div>
*/
