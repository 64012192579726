import React, { useState, useEffect, useContext, useRef } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';

// import Select from 'react-select';
import Select from '../../../forms/Select';
// import Select from '../../../forms/CustomSelect';
import SimpleSelect from '../../../forms/SimpleSelect';
import TextArea from '../../../forms/TextArea';
import CustomRange from '../../../forms/CustomRange';
import CustomInputNumber from '../../../forms/CustomInputNumber';

import { useMusicGeneratorForm, IUseMusicGeneratorForm, IMusicGeneratorOptions, getText } from './useMusicGeneratorForm';

import { EnvContext } from '../../../../context/Context';

export interface IParams {
	text: string;
    duration: number;
}

interface IProps {
	hidden: boolean;
	audioRef: any,
	disabled: boolean;
	onConnect: (audioContext: AudioContext) => void;
	onDisconnect: () => void;
	gotParams: (params: IParams) => void;
	onError?: (error: Error) => void;
    downloadAudioFilename: string;
    requestProgress: number;
    playingAudio: boolean;
}


const MusicGeneratorForm = (props: IProps) => {
	const { hidden, audioRef, disabled, downloadAudioFilename, requestProgress, playingAudio, ...rest } = props; // props
	const { onConnect, onDisconnect, gotParams, onError } = rest; // events

    const a = 1;

	const env = useContext(EnvContext) || process.env.REACT_APP_ENV;
	const isMounted = useRef<boolean>(false);

	const { options, setOptions, formError, musicStyles,
        samplePrompts
    }: IUseMusicGeneratorForm = useMusicGeneratorForm({});

	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false; };
	}, []);

	useEffect(() => {
		if (options) {
			// let isFilled = true;

			// Object.entries(options).forEach(([key, value]) => {
			//     if (typeof value === 'string' && value === '') isFilled = false;
			//     if (typeof value === 'object' && value[0] === '') isFilled = false;
			// });

			// if (isFilled) {
				const { text,
                    duration,
                    // samplePrompt
                } = options;
				if (isMounted.current) gotParams({ text, duration });
			// }
		}
		// eslint-disable-next-line
	}, [options]);

	useEffect(() => {
		if (formError && onError && isMounted.current) onError(formError);
		// eslint-disable-next-line
	}, [formError]);

    const spinner = disabled && (
        <Spinner animation="border" as="span" role="status" size="sm" />
    );

	const form = (
		<Form>
			<Form.Row>
				<Col xs={6} md={4} lg={4}>
					<CustomRange name="loquista-music-duration" label="duration"
						disabled={disabled} min={10} max={90} step={5} value={options.duration} units={'s'}
						onChange={(value) => {
                            if(isMounted.current)  {
								const { ...props } = options;
								setOptions({ ...props, duration: value });
                            }
                        }} />
				</Col>
			</Form.Row>

			<Form.Row>
				<Col>
					<TextArea name="loquista-musicgenerator-text" label="prompt"
						disabled={disabled} rows={6}
						selected={options.text} onChange={(value) => {
							if (isMounted.current) {
								const { ...props } = options;
								setOptions({ ...props, text: value });
							}
						}} />
				</Col>
			</Form.Row>
		</Form>
	);


	const control = disabled ? (
		<Button className="m-2 utopia-musicgenerator-button" block onClick={() => isMounted.current && onDisconnect()}>
			Stop {spinner}
		</Button>
	) : (
		<Button sm={6} className="m-2 utopia-musicgenerator-button" block
			onClick={() => {
				const audioContext: AudioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
				if (audioContext.state === 'suspended') audioContext.resume();
				(isMounted.current) && onConnect(audioContext);
			}}>
			Generate music
		</Button>
	);

	const modal = (
		<Row className="text-center">
			<Col xs={12} md={{ order: 12 }} className='p-0 '>
				{control}
			</Col>
			<Col xs={12} md={{ order: 1 }}>
				{audioRef &&
                    <div className={`${disabled ? 'd-none' : ''}`}>
                        <audio ref={audioRef} title={downloadAudioFilename} className='utopia-musicgenerator-audio' controls playsInline />
                    </div>}
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}>
                    <ProgressBar
                        className={`${(disabled && !playingAudio && requestProgress < 99) ? '' : 'd-none'}`}
                        style={{ width: '100%' }}
                        now={requestProgress}
                        label={`${Math.round(requestProgress)}%`}
                        striped
                        animated
                     />
                </div>
			</Col>
		</Row>
	);

	return (
		<Container fluid>
			<Row>
				<Col className={hidden ? 'd-none' : ''}>
					{form}
					<hr />
					{modal}
				</Col>
			</Row>
		</Container>
	);
};

export default MusicGeneratorForm;

function checkSpeakingRateByVoice(options: IMusicGeneratorOptions): boolean {
    return true;
	// return options.host.includes('78') ? false : true;
}
