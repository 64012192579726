import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import CustomMessage from '../../../utilities/CustomMessage';

interface IProps {
	title: string;
	description?: string;
}

const Clothes = ({ title, description }: IProps) => {
	return (
		<Fragment>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
			</Helmet>
			<SubTitle name={title} />
			<Description text={description ? description : 'Utopia.AI'} />
			<CustomMessage message='In progress' />
		</Fragment>
	);
};

export default Clothes;