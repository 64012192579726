import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import Loading from '../../../pages/Loading';
import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import ErrorBoundary, { IErrorProps } from '../../../ErrorBoundary';

import TextTranslatorForm from './TextTranslatorForm';

interface IProps {
  title: string;
  description?: string;
}

const TextTranslator = ({ title, description }: IProps) => {
  const isMounted = useRef<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [boundError, setBoundError] = useState<IErrorProps | null>(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const loading = <Loading />;

  const form = (
    <TextTranslatorForm
      className={isLoading ? 'd-none' : ''}
      gotParams={() => {
        if (isMounted.current) setIsLoading(false);
      }}
      onError={(error) => {
        if (error) {
          console.error(error);
          if (isMounted.current)
            setBoundError({ title: 'Translation', description: error });
        } else setBoundError(null);
      }}
    />
  );

  const errorBoundary = boundError ? (
    <ErrorBoundary
      title={boundError?.title || 'Error'}
      description={boundError?.description ? boundError.description : ''}
      reason={boundError?.reason ? boundError.reason : ''}
    />
  ) : null;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <SubTitle name={title} />
      <Description text={description ? description : 'Utopia.AI'} />
      {isLoading ? loading : null}
      {form}
      {errorBoundary}
    </Fragment>
  );
};

export default TextTranslator;
