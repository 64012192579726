import { useState, useContext, useRef } from 'react';
import { EnvContext, DomainContext } from '../../../../context/Context';

export interface IVoiceIdOptions {
    threshold: number;
}

export interface IUseSpeechToTextForm {
	params: IVoiceIdOptions,
	setParams: React.Dispatch<React.SetStateAction<IVoiceIdOptions>>;
}

const useVoiceIdForm = (uuid: string) => {
	const isMounted = useRef<boolean>(false);

	const env = useContext(EnvContext);
	const domain = useContext(DomainContext);

	const [params, setParams] = useState<IVoiceIdOptions>({});

	return { params, setParams };
};

export { useVoiceIdForm };

// HOSTS
type Env = 'prod' | 'test' | 'dev';
type State = 'available' | 'unavailable';

