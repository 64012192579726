import { useState, useEffect, useContext, useMemo } from 'react';
import { DomainContext, EnvContext } from '../context/Context';

import axios from 'axios';

const useAuth = () => {
	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	const [isLogged, setIsLogged] = useState<boolean | string>(false);

	const providerValue = useMemo(() => ({
		logged: isLogged,
		setLogged: setIsLogged
	}), [isLogged, setIsLogged,]);

	useEffect(() => {
		const checkLogin = async () => {
			try {
				const getUserUrl = `https://${domain}/login/authentication/user`;
				const token = localStorage.getItem(`${env}-token`);
				const { data: { email } } = await axios.post(getUserUrl, { token });

				const authUrl = `https://${domain}/login/authentication/utopia`;
				const { data: { success } } = await axios.post(authUrl, { email, token });

				if (success) providerValue.setLogged(email);
				else providerValue.setLogged('');
			} catch (error) {
				console.warn('User not found');
				providerValue.setLogged('');
			}
		};

		checkLogin();
		// eslint-disable-next-line
	}, []);

	return providerValue;
};

export default useAuth;