import React from 'react';

import * as utils from '../../utils/utils';
import * as helpers from '../../utils/helpers';

import Form from 'react-bootstrap/Form';

type Size = 'sm' | 'lg' | 'md';

interface IProps {
  name: string;
  label: string;
  options: string[] | null;
  size?: Size;
  disabled?: boolean;
  selected?: string;
  hiddenLabel?: boolean;
  className?: string;
  onChange?: (value: string) => void;
}

const CustomSelect = ({
  name,
  options,
  label,
  size,
  disabled,
  selected,
  hiddenLabel,
  className,
  onChange,
}: IProps) => {
  const labelComp = !hiddenLabel ? <Form.Label>{helpers.capitalizeFirstLetter(label)}</Form.Label> : null;

  return (
    <Form.Group controlId={name}>
      {labelComp}
      <Form.Control
        as="select"
        size={size ? (size === 'md' ? undefined : size) : 'sm'}
        disabled={disabled}
        value={selected}
        className={className}
        onChange={(event: any) =>
          onChange
            ? onChange(event.target.value)
            : console.warn(`onChange event not handled: ${event.target.value}`)
        }>
        {options && options.map((op, key) => (
          <option key={key} value={op} disabled={utils.getDisabledByVoice(op)}>
            {getLabel(label, op)}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

function getLabel(type: string = '', option: string): string {
  if (type.toLowerCase().includes('language')) return !option.toLowerCase().includes('detect') ? utils.getLabelByLanguage(option) : option;
  else if (type.toLowerCase().includes('voice')) return utils.getLabelByVoice(option);
  else if (type.toLowerCase().includes('format')) return utils.getLabelByNlpFormat(option);
  else return option;
}

export default CustomSelect;
