import React, { useEffect, useState, useRef, useContext } from 'react';
import axios, { AxiosError } from 'axios';

import { DomainContext, EnvContext } from '../../../../context/Context';
import * as helpers from '../../../../utils/helpers';
import * as utils from '../../../../utils/utils';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import CustomSelect from '../../../forms/CustomSelect';
import CustomSwitch from '../../../forms/CustomSwitch';

import SpellCheckerResult from './SpellCheckerResult';
// import SpellCheckerComponent from './SpellCheckerComponent';

const languagesApi = 'api/loquista/spellchecker/languages';
const checkApi = 'api/loquista/spellchecker/check';
const maxChars = 300;

export interface IParams {
	language: string;
	text: string;
}

interface ICheckingRequest {
	text: string;
	id: string;
	language: string;
	url: string;
	predict?: boolean;
}

interface ITexts {
	original: string;
	proposed: string;
}

interface IBlock {
	original: string;
	proposed: string;
	modified: boolean;
}

interface ICheckedResponse {
	texts: ITexts;
	blocks: IBlock[];
	prediction: string;
	language: string;
	score: number;
}

interface IProps {
	onError: (error: Error) => void;
	onResult: (response: string) => void;
}

const SpellCheckerForm = ({ onError, onResult }: IProps) => {
	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	const isMounted = useRef<boolean>(false);
	const currentText = useRef<string>('');

	const [currentId, setCurrentId] = useState<string>('');
	const [possibleLanguages, setPossibleLanguages] = useState<string[]>(['Detect language']);
	const [selectedLanguage, setSelectedLanguage] = useState<string>('');
	const [proposedLanguage, setProposedLanguage] = useState<string>('');
	const [inputText, setInputText] = useState<string>('');
	const [outputText, setOutputText] = useState<string>('');
	const [isWaiting, setIsWaiting] = useState<boolean>(false);
	const [outputBlocks, setOutputBlocks] = useState<IBlock[] | null>(null);
	const [changes, setChanges] = useState<string>('');
	const [predictiveModeOn, setPredictiveModeOn] = useState<boolean>(false);
	const [nextWord, setNextWord] = useState<string>('');
	const [maxLengthReached, setMaxLengthReached] = useState<boolean>(false);

	const languagesUrl = `https://${domain}/${languagesApi}`;
	const checkUrl = `https://${domain}/${checkApi}`;

	const checking = ({ text, id, language, url, predict }: ICheckingRequest, callback: (error: Error | null, response?: ICheckedResponse) => void) => {
		if (text.length <= maxChars) {
			setIsWaiting(true);
			setMaxLengthReached(false);
			check(env, text, id, language, url, predict)
				.then((response) => callback(null, response))
				.catch((error) => callback(new Error(error)));
		} else {
			setMaxLengthReached(true);
			callback(null);
		}
	};

	const checkingCallback = (error: Error | null, response?: ICheckedResponse) => {
		if (error) {
			console.error(error.message);
			setIsWaiting(false);
			if (isMounted.current) {
				if (error.message.includes('422')) setMaxLengthReached(true);
				else onError(error);
			}
		} else if (response) {
			const { texts: { original, proposed }, blocks, prediction, language } = response; // { blocks, prediction, language, score }
			if (isMounted.current) {
				if (!language.toLowerCase().includes('none')) setProposedLanguage(language);
				setNextWord(prediction);
				setOutputBlocks(blocks);

				const tempChanges = blocks.map((block) => {
					if (block.modified) return `<mark class="text-black">${block.proposed}</mark>`;
					else return `<span class="text-muted font-italic">${block.original}</span>`;
				});

				setChanges(tempChanges.join(' '));

				setOutputText(proposed);
				setIsWaiting(false);
				if (original.replace(/\s/g, '') !== currentText.current.replace(/\s/g, '')) {
					checking({
						text: currentText.current,
						id: currentId,
						language: selectedLanguage.toLowerCase().includes('detect') ? '' : selectedLanguage,
						url: checkUrl,
						predict: predictiveModeOn
					}, checkingCallback);
				}
			}
		}
	};

	useEffect(() => {
		isMounted.current = true;

		const uuid = helpers.getUuid();
		setCurrentId(uuid);
		axios.get(languagesUrl, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } })
			.then(({ data }) => {
				const { languages } = data;
				if (isMounted.current) {
					const newLanguages = [...possibleLanguages, ...languages];
					setPossibleLanguages(newLanguages);
					setSelectedLanguage(newLanguages[0]);
				}
			})
			.catch(error => {
				const { response: { status, data } } = error;
				if (isMounted.current) onError(new Error(`${status} ${data}`));
			});

		return () => {
			isMounted.current = false;
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (isMounted.current && inputText.length > 0) {
			if (!isWaiting) {
				checking({
					text: inputText,
					id: currentId,
					language: selectedLanguage.toLowerCase().includes('detect') ? '' : selectedLanguage,
					url: checkUrl,
					predict: predictiveModeOn
				}, checkingCallback);
			}
		}
		// eslint-disable-next-line
	}, [inputText]);

	useEffect(() => {
		if (isMounted.current) {
			if (inputText === '') setOutputText('');
		}
		// eslint-disable-next-line
	}, [outputText]);

	const isTheSame = (str1: string, str2: string): boolean => {
		return str1.replace(/\s/g, '').localeCompare(str2.replace(/\s/g, '')) === 0 ? true : false;
	};

	const copyOnClipBoard = () => {
		if (isMounted.current && outputText) {
			setInputText(outputText);
			if (navigator.clipboard) {
				navigator.clipboard
					.writeText(outputText)
					.then(() => console.log(`Got it: ${outputText}`))
					.catch((error) => console.error(error));
			}
		}
	};

	const resOriginal = inputText ?
		<div id="spell-checker-original">
			<p>
				<span className="font-weight-bold">{'Original: '}</span>
				<span className={isTheSame(inputText, outputText) ?
					'' : 'text-danger'}>{` ${inputText}`}
				</span>
			</p>
		</div> :
		null;

	const resProposed = ((outputText && !isTheSame(inputText, outputText)) || predictiveModeOn) ?
		<div id="spell-checker-proposed">
			<p>
				<span className="font-weight-bold">{'Proposed: '}</span>
				<span className={isTheSame(inputText, outputText) ?
					'' : 'text-success'}>{outputText}{nextWord && <span className="font-italic">{` ${nextWord}`}</span>}
				</span>
			</p>
		</div> :
		null;

	const resChanges = outputBlocks ?
		<div id="spell-checker-blocks">
			<p>
				<span className="font-weight-bold">{'Changes: '}</span>
				<span dangerouslySetInnerHTML={{ __html: changes }} />
			</p>
		</div> :
		null;

	return (
		<Container fluid>
			<Row>
				<Col>
					<Form>
						<Form.Row>
							<Col className="col-sm-8">
								<CustomSelect name="spell-check-languages"
									label="Language"
									options={possibleLanguages}
									onChange={(value) => {
										if (isMounted.current) {
											setSelectedLanguage(value);
											setInputText('');
											setOutputText('');
										}
									}} />
							</Col>
							<Col className="col-sm-4 text-center">
								<CustomSwitch name="spell-check-predictive-mode"
									label="predictive mode"
									initial={false}
									onChange={(status) => {
										if (isMounted.current) setPredictiveModeOn(status);
									}} />
							</Col>
						</Form.Row>
						<Form.Row>
							<Col className="mb-2">
								<SpellCheckerResult
									text={inputText}
									language={selectedLanguage.toLowerCase().includes('detect') ? '' : selectedLanguage}
									onChange={(proposed: string) => isMounted.current && setInputText(proposed)}
								/>
								<Form.Label>Text</Form.Label>
								<Form.Control
									id="spell-check-text"
									as="textarea"
									spellCheck={false}
									rows={4}
									placeholder={selectedLanguage.includes('en') ? 'Please, write something here...' : 'Por favor, escribe algo aquí...'}
									value={inputText}
									isInvalid={inputText.length > maxChars || inputText.length < 1 ? true : false}
									isValid={inputText.length <= maxChars && inputText.length > 0 ? true : false}
									onChange={(event) => {
										const text = event.target.value;
										onResult(text);

										const isOnlyLineBreak = text === '\n';
										const isOnlySpace = text && text.length > 0 && /^ *$/.test(text);
										const isEmpty = !text || text.length < 1 || isOnlySpace || isOnlyLineBreak;

										if (!isEmpty) {
											isMounted.current && setInputText(text);
											currentText.current = text;
										} else {
											if (isMounted.current) {
												setInputText('');
												setOutputText('');
												setOutputBlocks(null);
												setChanges('');
											}
										}
									}}
									onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
										if (event.key === 'Tab') {
											event.preventDefault();
											isMounted.current && setInputText(outputText);
										}
									}}
								/>
								<Form.Control.Feedback type="valid">
									{`${inputText.length} characters (max ${maxChars})`}
								</Form.Control.Feedback>
								<Form.Control.Feedback type="invalid">
									{`${inputText.length} characters (max ${maxChars})`}
								</Form.Control.Feedback>
							</Col>
						</Form.Row>
					</Form>
				</Col>
			</Row>

			{
				maxLengthReached ?
					(
						<Row>
							<Col>
								<Alert variant="warning">
									{`The max length of ${maxChars} characters is reached`}
								</Alert>
							</Col>
						</Row>
					) :
					null
			}
			<Row className={!!(outputText || inputText) ? '' : 'd-none'}>
				<Col md={8} sm={12} className={selectedLanguage.toLowerCase().includes('detect') || (proposedLanguage && proposedLanguage !== 'NONE') ? 'mt-3' : 'd-none'}>
					<label className="form-label">Possible correction</label>
					<div className="p-2 rounded bg-light overflow-auto">
						{resOriginal}
						{resProposed}
						{resChanges}
					</div>
				</Col>
				<Col md={4} sm={12} className={selectedLanguage.toLowerCase().includes('detect') || (proposedLanguage && proposedLanguage !== 'NONE') ? 'mt-5' : 'd-none'}>
					<Card>
						<Card.Header>Language</Card.Header>
						<Card.Body>
							<blockquote className="blockquote mb-0">
								<p>
									{`${utils.getLabelByLanguage(proposedLanguage)} detected`}
								</p>
							</blockquote>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className={outputText ? '' : 'd-none'}>
				<Col className="text-left">
					<Button variant="light"
						className="my-2"
						onClick={copyOnClipBoard}
					>Copy and Replace</Button>
				</Col>
			</Row>
		</Container >

	);
};

export default SpellCheckerForm;

function check(env: string, text: string, uid: string, lang: string, url: string, predict?: boolean): Promise<ICheckedResponse> {
	return new Promise(async (resolve, reject) => {
		try {
			const rid = `${Date.now()}`;
			const metadata = { uid, rid };
			const params = { text, language: lang, predict, metadata };

			if (text.length <= maxChars) {
				const { data: { texts, blocks, prediction, language, score } }: { data: ICheckedResponse; } = await axios.post(url, params,
					{ headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
				resolve({ texts, blocks, prediction, language, score });
			}
		} catch (error) {
			const err = error as AxiosError;
			const { response } = err;
			reject(`${response?.status || 500} - ${response?.data || 'Undefined error'}`);
		}
	});
};

/*

						<Form.Row>
							<Col>
								<SpellCheckerComponent />
							</Col>
						</Form.Row>

						*/