import React, { useState, useContext, Fragment } from 'react';

import useRole from '../../hooks/useRole';
import { LoginContext } from '../../context/Context';
import { IApp } from '../../App';
import { ISection } from '../../hooks/useGetSections';

import AdminUtopia from '../utopia/admin/AdminUtopia';
import AdminSesame from '../utopia/admin/AdminSesame';
import AdminVision from '../utopia/admin/AdminVision';
import CloningVoices from '../utopia/admin/CloningVoices';
import Unauthorized from '../pages/Unauthorized';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Nav from 'react-bootstrap/Nav';

import Title from '../Title';
import Description from '../Description';


interface IProps {
	apps: IApp[];
	sections: ISection[];
}

const AdminPage = ({ sections, apps }: IProps) => {
	const title = 'Utopia';
	const description = 'Admin page';

	const { logged } = useContext(LoginContext);
	const { role } = useRole(logged);

	const [visible, setVisible] = useState<string>('utopia');

	const adminMenu = <Nav variant="tabs">
		{
			['utopia', 'sesame', 'vision', 'cloning-voices'].map((item: string, key: number) => (
				<Nav.Item key={`${item}-${key}`}>
					<Nav.Link onClick={() => setVisible(item)}>{item}</Nav.Link>
				</Nav.Item>
			))
		}
	</Nav>;

	const content = (
		<Fragment>
			<Row>
				<Col>
					{adminMenu}
				</Col>
			</Row>
			{visible === 'utopia' && <AdminUtopia apps={apps} sections={sections} />}
			{visible === 'sesame' && <AdminSesame />}
			{visible === 'vision' && <AdminVision />}
      {visible === 'cloning-voices' && <CloningVoices />}
		</Fragment>
	);

	return (
		<Container fluid>
			<Title title={title} />
			<Description text={description} />
			{role === 'admin' ? content : <Unauthorized />}
		</Container>
	);

};

export default AdminPage;
