/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';

import logo from '../resources/utopia-horizontal.svg';

import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';

const NavBar = () => {
  const style = css`
  background: #f5f5f5;
  `;

  return (
    <React.Fragment>
      <Navbar className='utopia-nav' css={style}>
        <Navbar.Brand href='/' className='is-75'>
          <Image src={logo} fluid={false} />
        </Navbar.Brand>
      </Navbar>
    </React.Fragment>
  );
};

export default NavBar;