import React from 'react';
import { IApp } from '../../../App';
import { ISection } from '../../../hooks/useGetSections';
import {
  Container,
  Row,
  Col,
  Form,
  FormControl,
  Table,
  Button,
  InputGroup
} from 'react-bootstrap';

interface IProps {
	apps: IApp[];
	sections: ISection[];
}

const AdminUtopia = ({ apps, sections }: IProps) => {
  apps.sort((a, b) => a.id - b.id);
  
	const sectionItems = apps.map((item, key) => (
		<tr key={key}>
			<td className="font-weight-light">{item.id}</td>
			<td className={`font-weight-bold text-${item.section}`}>{item.section}</td>
			<td className="font-weight-bold">
				<a href={`/${item.section}/${item.path}`}>{item.app}</a>
			</td>
			<td className="font-weight-light text-left">{item.description}</td>
			<td className="font-weight-light">
				<Form.Check
          type="checkbox"
          role="button"
					disabled
					label={item.status ? 'on' : 'off'}
					checked={item.status ? true : false}
					onChange={() => {
						console.log('___ cambio a desarrollar:', item.status);
					}}
				/>
			</td>
		</tr>
	));

	const table = (
		<Table striped bordered hover className="text-center mt-3">
			<thead>
				<tr>
					<th>#</th>
					<th>Section</th>
					<th>Title</th>
					<th className="text-left">Description</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				{sectionItems}
			</tbody>
		</Table>
	);

	const editable = (
		<InputGroup className="mt-3 mb-4">
			<FormControl
				placeholder="New App title"
				aria-label="New App title"
				aria-describedby="basic-addon2"
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					console.log('a desarrollar...');
				}}
			/>
			<InputGroup.Append
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					console.log('a desarrollar...');
				}}>
				<Form.Control as="select" custom>
					{
						sections?.map((item, key) =>
							<option key={key}>{item.name}</option>)
					}
				</Form.Control>
			</InputGroup.Append>
			<InputGroup.Append>
				<Button variant="outline-secondary" disabled onClick={() => console.log('create handler a desarrollar....')}>Create</Button>
			</InputGroup.Append>
		</InputGroup>
	);

	return (
		<Container>
			<Row>
				<Col>
					<h3 className="mt-3">General web management</h3>
				</Col>
			</Row>
			<Row>
				<Col>
					{table}
				</Col>
			</Row>
			<Row>
				<Col>
					{editable}
				</Col>
			</Row>
		</Container>
	);
};

export default AdminUtopia;
