import React, { Fragment, useState, useRef, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import { DomainContext, EnvContext } from '../../../../context/Context';
import { useLocation } from 'react-router-dom';
import { useGetAssistants, IAssistant } from '../useGetAssistants';
import { IVideoAssistantData } from './VideoAssistantForm';

import Spinner from 'react-bootstrap/Spinner';

import AssistantForm from './AssistantForm';
import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import ChatContainer from './ChatContainer';
import { getUuid } from '../../../../utils/helpers';

interface IProps {
  title: string;
  description?: string;
}

const Assistant = ({ title, description }: IProps) => {
  const location = useLocation();
  const isMounted = useRef<boolean>(false);

  const [id, setId] = useState<string>(getUuid());

  const [assistant, setAssistant] = useState<IAssistant | null>(null);

  const [shown, setShown] = useState<boolean>(false);
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const [videoAssistantData, setVideoAssistantData] = useState<IVideoAssistantData>({
    avatar: '',
    host: '',
    voice: '',
    background: undefined,
    watermark: undefined
  });

  const [modelDateText, setModelDateText] = useState<string>("");
  const env = useContext(EnvContext);
  const domain = useContext(DomainContext);

  const assistants = useGetAssistants();

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  useEffect(() => {
    if (assistants) {
      const currentLocation = location.pathname;
      const { 3: type, 4: name } = currentLocation.split('/');

      const botName = name.replace(/-/g, ' ');
      const assistant = assistants.filter(item => item.name === botName && item.typename === type)[0];
      isMounted.current && assistant && setAssistant(assistant);
    }
    // eslint-disable-next-line
  }, [assistants]);

  useEffect(() => {
     async function callGetInfoModel() {
         if(assistant) {
              const data = await getInfoModel(assistant.model, assistant.language, {
                    id: getUuid(),
                    env,
                    domain,
              });
              if(data.modifiedTime) {
                  const options = {  year: 'numeric', month: 'long', day: 'numeric' };
                  const stringDate = new Date(data.modifiedTime).toLocaleDateString('en-US', options);
                  setModelDateText(`Last updated on ${stringDate}.`);
              } else {
                  setModelDateText('');
              }
         }
     }
    callGetInfoModel();
  }, [assistant]);


  const content = (
    <Fragment>
      <AssistantForm
        assistant={assistant!}
        shown={shown}
        disabled={false}
        greeting={() => setShown(true)}
        gotParams={(_params) => setVideoAssistantData(_params)}
        farewell={() => {
          setShown(false);
          setId(getUuid());
          if (assistant?.typename === 'video') document.location.reload();
        }}
      />
      <ChatContainer
        id={id}
        shown={shown}
        assistant={assistant!}
        host={videoAssistantData.host}
        voice={videoAssistantData.voice}
        avatar={videoAssistantData.avatar}
        background={videoAssistantData.background}
        watermark={videoAssistantData.watermark}
      />
    </Fragment>
  );

  const loading = (
    <div className="text-center pt-2">
      <Spinner animation="border" variant="primary" />
    </div>
  );

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description || 'Digital assistant'} />
      </Helmet>
      <SubTitle name={title} />
      <Description text={description ? `${description} ${modelDateText}` : 'Utopia.AI'} />
      {assistant ? content : loading}
    </Fragment>
  );
};

export default Assistant;

// getInfoModel

interface IGetInfoModelFnParams {
	id: string;
	env: string;
	domain: string;
}

interface IGetInfoModelApiParams {
	uuid: string;
	env: string;
	model: string;
	model: string;
	language: string;
}

function getInfoModel(model: string, language:string, params: IGetInfoModelFnParams): Promise<string[]> {
	return new Promise(async (resolve, reject) => {
		const { id, domain, env } = params;

		// const { name, model, language } = assistant;
		const api = 'api/brain/infoModel';
		const requestParams: IGetInfoModelApiParams = { uuid: id, env, model, language };
		const requestHeaders = {
			headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` }
		};

		try {
			const { data } = await axios.post(`https://${domain}/${api}`, requestParams, requestHeaders);
			const {
                startTime,
                status,
                memoryUsage,
                gpu,
                gpuUsage,
                createdTime,
                modifiedTime,
            } = data;

			resolve(data);
		} catch (err) {
			const error = new Error(`${model} doesn't respond`);
			console.error(err);
			reject(error.message);
		}
	});
}
