import React, { useState, useContext } from 'react';

import { SizeContext, ILoginContext } from '../context/Context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { IApp } from '../App';
import { ISection } from '../hooks/useGetSections';
import Menu from './Menu';
import Content from './Content';

interface IProps {
  showMenu: boolean;
  apps: IApp[];
  sections: ISection[];
  gotError?: boolean;
  loginProvider: ILoginContext;
}

const Main = ({ apps, sections, showMenu, gotError, loginProvider }: IProps) => {
  const { size } = useContext(SizeContext);

  const [isMenuDisplayed, setIsMenuDisplayed] = useState<boolean>(true);

  const utopiaMenuClass = `utopia-menu ${isMenuDisplayed ? '' : 'd-none'}`;

  const faCaretLeftIcon = <FontAwesomeIcon icon={faCaretLeft} />;
  const faCaretRightIcon = <FontAwesomeIcon icon={faCaretRight} />;
  const icon = isMenuDisplayed ? faCaretLeftIcon : faCaretRightIcon;

  const utopiaMenuToggleClassName = "px-3 py-2 bg-dark text-light rounded-right";
  const utopiaMenuToggleHandler = () => setIsMenuDisplayed(!isMenuDisplayed);

  const utopiaMenu = (size === 'large') && (
    <Col md={3} xl={2} className={utopiaMenuClass}>
      <Menu apps={apps} sections={sections} />
    </Col>
  );

  return (
    <Container fluid className={`utopia-main ${showMenu ? 'd-none' : ''}`}>
      <Row>
        {utopiaMenu}
        <Col md className='utopia-content p-0'>
          <Content sections={sections} apps={apps} gotError={gotError}
            loginProvider={loginProvider} />
        </Col>
      </Row>
      <div id="utopia-menu-toggle" className={utopiaMenuToggleClassName} onClick={utopiaMenuToggleHandler}>
        {icon}
      </div>
    </Container>
  );
};

export default Main;
