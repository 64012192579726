import React from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface IProps {
  title: string;
  message: string;
  show: boolean;
  onResult: (res: boolean) => void;
}

const CustomConfirm = ({ title, message, show, onResult }: IProps) => {
  const handleClose = (res: boolean) => {
    onResult(res);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => handleClose(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={() => handleClose(true)}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomConfirm;
