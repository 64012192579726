/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useContext } from 'react';
import classNames from 'classnames';

import { EnvContext } from '../context/Context';
import * as helpers from '../utils/helpers';

import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
// import Accordion from 'react-bootstrap/Accordion';

import { IApp, Env } from '../App';
import { ISection } from '../hooks/useGetSections';
import MenuItem from './MenuItem';

interface IProps {
  apps: IApp[];
  sections: ISection[];
}

const pathname = window.location.pathname.substring(1);
const initState = pathname ? pathname : 'home';

/**
 * Menu - solo visible en dispositivos grandes > md
 */
const Menu = ({ apps, sections }: IProps) => {
  const env = useContext(EnvContext);

  const [active, setActive] = useState<string>(initState);

  const style = css`
  
  `;

  const menuItemClass = 'nav-link utopia-menu-link text-uppercase';

  const menuSections = sections && sections.map(item => {
    const { name, description, submenu, subsection, groups } = item;
    return {
      name,
      description,
      submenu,
      subsection: subsection ?? null,
      groups: groups ?? null,
      children: apps.filter(el => el.section === name && isEnv(env, el.env))
    };
  });

  // utopia-menu-link-wrap
  const makeMenuLink = (name: string, alias?: string) => (
    <Link className={`${menuItemClass} ${active === name ? 'active' : ''}`}
      to={name === 'home' ? '/' : `/${name}`}
      onClick={() => setActive(name)}
    >
      {helpers.capitalizeFirstLetter(alias ? alias : name)}
    </Link>
  );

  const menu = (
    <div>
      {
        menuSections && menuSections.map((item, key) => (
          <MenuItem
            key={key}
            title={item.name}
            description={item.description}
            submenu={item.submenu === 'true' ? true : false}
            subsections={item.subsection ? item.subsection.split(',') : undefined}
            groups={item.groups ? item.groups.split(',') : undefined}
            children={item.children}
            eventKey={`${key}`}
            onSelect={(selected => {
              const htmlItems = document.getElementsByClassName('utopia-menu-child');
              for (let i = 0, len = htmlItems.length; i < len; i++) {
                htmlItems[i].classList.remove('selected');
              }
            })}
          />
        ))
      }
    </div>
  );

  const classes = classNames('flex-column');
  // flex-column
  return (
    <Nav
      css={style}
      className={classes}
      defaultActiveKey='/'>
      {makeMenuLink('home')}
      {makeMenuLink('products')}
      {menu}
      {makeMenuLink('about', 'about us')}
    </Nav>
  );
};

export default Menu;

function isEnv(currentEnv: Env, env: Env): boolean {
  if (currentEnv === 'prod') {
    if (env !== 'prod') return false;
    else return true;
  } else if (currentEnv === 'test') {
    if (env === 'dev') return false;
    else return true;
  } else return true;
}