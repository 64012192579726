import React, { useRef, useState, useEffect } from "react";
import { AppContext } from "../contexts/appContext";

const RefImage = () => {
    const canvas_ref = useRef(undefined);

    const { setRefImage, imageData } = AppContext();

    useEffect(() => {
        if(!imageData) {
            return;
        }

        const drawDatatoCanvas = async (data, canvas) => {
            return new Promise((resolve, reject) => {
                var ctx = canvas.getContext("2d");

                var image = new Image();
                image.onload = function() {
                    // console.log('image', image);
                    canvas.width = image.width;
                    canvas.height = image.height;
                    ctx.drawImage(image, 0, 0, image.width, image.height);
                    resolve();
                };
                image.src = data;
            });
        }

        drawDatatoCanvas(imageData, canvas_ref.current);
        setRefImage(imageData );

    }, [imageData]);

    return (
        <canvas ref={canvas_ref} width="400px" height="400px" style={{ maxWidth: 400, maxHeight: 400 }} />
    );
};

export default RefImage;
