import React, { useContext } from 'react';

import { EnvContext } from '../../context/Context';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';

import { Link } from 'react-router-dom';
import { IApp } from '../../App';

type Env = 'dev' | 'prod' | 'test';

interface IProps {
	location: string;
	apps: IApp[];
	groups?: string;
}

const Section = ({ location, apps, groups }: IProps) => {
	const env = useContext(EnvContext);

	const tBody = (
		<tbody>
			{
				apps.map((item, key) => {
					if (item.section === location && isEnv(env, item.env)) {
						return (
							<tr className='section-landing-page-row' key={key}>
								<td className={`section-landing-page-cell title ${item.section} py-3 align-middle`}>
									<Link className="section-link" to={`/${item.section}/${item.path}`}>
										{item.app}
									</Link>
								</td>
								{groups && (
									<td className="section-landing-page-cell group pl-2 py-3 align-middle text-center">
										<h5>
											<Badge variant="light">
												{groups.split(',').filter(g => item.path.includes(g))[0]}
											</Badge>
										</h5>
									</td>)
								}
								<td className="section-landing-page-cell description pl-2 py-3 align-middle">
									{item.description}
								</td>

							</tr>
						);
					} else return null;
				})
			}
		</tbody>
	);

	return (
		<Container fluid>
			<Row>
				<Col>
					<div className='my-4'>
						<Table hover responsive size="sm" className="section-landing-page">
							{tBody}
						</Table>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Section;

function isEnv(currentEnv: Env, env: Env): boolean {
	if (currentEnv === 'prod') {
		if (env !== 'prod') return false;
		else return true;
	} else if (currentEnv === 'test') {
		if (env === 'dev') return false;
		else return true;
	} else return true;
}