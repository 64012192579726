import React, { Fragment } from 'react';

import { SizeContext } from '../../context/Context';

import Select from 'react-select';
import Form from 'react-bootstrap/Form';

import { capitalizeFirstLetter } from '../../utils/helpers';
import { getLabelByLanguage, getLabelByVoice, getGroupByVoice } from '../../utils/utils';
import { useContext } from 'react';

const groupStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
};

const groupBadgeStyles = {
	backgroundColor: '#EBECF0',
	borderRadius: '2em',
	color: '#172B4D',
	display: 'inline-block',
	fontSize: 12,
	lineHeight: '1',
	minWidth: 1,
	padding: '0.16666666666667em 0.5em'
};

interface IProps {
	name: string;
	label: string;
	selected: string;
	options: string[];
	disabled?: boolean;
	hideLabel?: boolean;
	onChange: (value: string) => void;
}

const SelectComponent = ({ name, label, selected, options, disabled, hideLabel, onChange }: IProps) => {
	const { size } = useContext(SizeContext);

	const getTextByName = (name: string, value: string) => {
		if (name.includes('language')) return getLabelByLanguage(value);
		else if (name.includes('voice')) return getLabelByVoice(value);
		return value;
	};

	const formatGroupLabel = (data: any) => (
		<div style={groupStyles}>
			<span>{data.label}</span>
			<span style={groupBadgeStyles}>{data.options.length}</span>
		</div>
	);

	const getGroupedOptions = () => {
		const groupsByItem = options.map(item => getGroupByVoice(item));
		const groups = [...new Set(groupsByItem)];
		return groups.map(group => ({
			label: group,
			options: options.flatMap(op => {
				if (getGroupByVoice(op) === group) {
					return {
						value: op,
						label: getLabelByVoice(op)
					};
				} else return {};
			}).filter(item => item.value && item.value)
		}));
	};

	const simpleSelect = (
		<Form.Group controlId={name}>
			<Form.Label className={hideLabel ? 'd-none' : ''}>
				{capitalizeFirstLetter(label)}
			</Form.Label>
			<Form.Control
        as="select"
        size="sm"
        value={selected}
        disabled={disabled ?? false}
        className={hideLabel ? 'd-none' : ''}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
      >
				{
					options.map((op, key) => (
						<option key={key} value={op}>{
							getTextByName(name, op)
						}</option>
					))
				}
			</Form.Control>
		</Form.Group>
	);

	const groupSelect = (
		<div className="mb-3">
			<p className={hideLabel ? 'd-none' : 'mb-1'}>
				{capitalizeFirstLetter(label)}
			</p>
			<Select
				id={name} value={{ value: selected, label: getLabelByVoice(selected) }}
				className={`custom-grouped-select sm ${disabled ? 'is-disabled' : ''}`}
				options={getGroupedOptions()}
				formatGroupLabel={formatGroupLabel}
				isDisabled={disabled ?? false}
				onChange={(selectedValue: any) => onChange(selectedValue.value)
				}
			/>
		</div>
	);

	return (
		<Fragment>
			{(name.includes('voice') && size === 'large') ? groupSelect : simpleSelect}
		</Fragment>
	);
};

export default SelectComponent;
