/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { AppContext } from "../contexts/appContext";

const style = css`
    /* cursor: zoom-in; */

    .icons {
        text-align: center;
        width: 100%;
    }
`;

const OutputVideo = ({ onClick }) => {
  const { responseData, outputVideoUrl, outputVideoEl, outputVideoProgress, start  } = AppContext();

  useEffect(() => {
      if(outputVideoUrl) {
        // videoEl.current.src = `https://dialoga-machine-learning.s3.amazonaws.com/${video}`;
        outputVideoEl.current.src = outputVideoUrl;// `https://dialoga-machine-learning.s3.amazonaws.com/${video}`;
      }
  }, [outputVideoUrl]);


  const icon = <FontAwesomeIcon icon={faPlusCircle} />;

  return (
          outputVideoUrl && (
          <div css={style}
            onClick={() => onClick()}>
              <video
                ref={outputVideoEl}
                width="400px" height="400px"
                style={{ backgroundColor: "#fff" }}
              />
          </div>)
          || (
          <div style={{ width: "400px", height: "400px" }}>
              <div style={{ backgroundColor: "#fff", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "left"}}>
                  <div style={{ backgroundColor: "#f5f5f5", width: `${outputVideoProgress}%`, height: "100%" }}>
                  </div>
              </div>
            <ProgressBar
                now={outputVideoProgress} 
                label={`${Math.round(outputVideoProgress)}%`}
                className={`${start ? '' : 'd-none'}`}
                striped
                animated
             />
          </div>
          )
  );

              // <Spinner animation="border" as="span" role="status" size="sm" />
};

export default OutputVideo;
