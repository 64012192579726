import { toast } from "react-hot-toast"

interface Props {
  type: ToastType
  message: string
}

type ToastType = "success" | "error"

const useToast = () => {
  return ({ type, message }: Props) => {
    toast[type](message)
  }
}

export default useToast
