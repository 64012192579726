import React, { Fragment, useRef, useState } from 'react';

import { IApp } from '../App';

import SubMenuItem from './SubMenuItem';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { Link } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import classNames from 'classnames';

interface IProps {
  title: string;
  description: string;
  eventKey: string;
  children: IApp[];
  submenu?: boolean;
  subsections?: string[] | undefined;
  groups?: string[] | undefined;
  onSelect: (item?: string) => void;
}

const MenuItem = ({ title, description, submenu, subsections, groups, eventKey, children, onSelect }: IProps) => {
  const collapse = useRef<HTMLDivElement>(null);

  const [selected, setSelected] = useState<string>('');

  const onCardToggle = () => {
    const item = collapse.current;
    const isShown = item?.parentElement?.classList.contains('show');
    // const menuCards = document.getElementsByClassName('utopia-menu-card');
    if (isShown) item?.parentElement?.classList.remove('show');
    else item?.parentElement?.classList.add('show');
  };

  const className = `utopia-menu-card ${title} show`;

  const header = (
    <Card.Header onClick={onCardToggle}>
      <Link to={`/${title}`} className='utopia-menu-card-header'
        onClick={() => {
          setSelected('');
          onSelect();
        }}>
        <div className='utopia-menu-card-title ml-3'>{title.toUpperCase()}</div>
        <div className='utopia-menu-card-description ml-3'>{description}</div>
        <FontAwesomeIcon className='utopia-arrow utopia-arrow-up' icon={faCaretUp} />
        <FontAwesomeIcon className='utopia-arrow utopia-arrow-down' icon={faCaretDown} />
      </Link>
    </Card.Header>
  );

  const list = children.map((item: IApp, key: number) => {
    const selectedItemName = item.app.toLowerCase().replace(/ /g, '-');
    const className = classNames('utopia-menu-child', `utopia-menu-child-${selectedItemName}`, item.section, selected === selectedItemName ? 'selected' : '');
    return (
      <Link key={key} to={`/${item.section}/${item.path}`}>
        <ListGroupItem className={className}
          onClick={() => {
            setSelected(selectedItemName);
            onSelect(selectedItemName);
          }}>
          <span className='ml-1-rem-2'>
            {item.app}
          </span>
        </ListGroupItem>
      </Link>
    );
  });

  const getSubList = (subTitles: string[], groups: string[]) => (
    <Fragment>
      {subTitles.map((subTitle, key) => (
        <SubMenuItem
          key={key}
          title={subTitle}
          group={groups[key]}
          apps={children}
          selected={selected}
          onSelected={(item) => {
            setSelected(item);
            onSelect(item);
          }}
        />
      ))}
    </Fragment>
  );

  const body = (
    <Card.Body ref={collapse}>
      <ListGroup className='list-group-flush'>
        {submenu ? getSubList(subsections!, groups!) : list}
      </ListGroup>
    </Card.Body>
  );

  return (
    <Card key={eventKey} className={className}>
      {header}
      {body}
    </Card>
  );
};

export default MenuItem;
