/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Loading from '../../../pages/Loading';
import { Category } from './AvatarBackground';
import AsideButton, { Variant, Position } from './AsideButton';

import { DomainContext, EnvContext } from '../../../../context/Context';
import { upload_file_max_size_MB } from '../../../../config/conf.general.json';

const style = css`
	width: 100%;
	height: auto;
	margin-top: 1em;
		
	.title {
		text-align: center;
		text-transform: capitalize;
	}

	.custom-form {
		display: inline-block;
	}

	.container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex-basis: content;
		justify-content: center;
	}

	.item {
		display: flex;
		height: 8em;
		width: 14em;
		cursor: pointer;
		margin: 0.5em;
		// border: 1px solid #ccc;
		border-radius: 0.2em;
		justify-content: center;
		align-items: center;
		position: relative;
		// background-color: black;
		// color: #ccc;
		overflow: hidden;

		.item-title {
			opacity: 0;
			color: #242424;
		}

		.video,
		.img {
			position: absolute;
			//width: 100%;
			//height: 100%;
			max-width: 100%;
			max-height: 100%;
			overflow: hidden;
			display: block;
			margin: auto;
		}

		.video {
			opacity: 0.8;
		}

		&:hover, &:focus {			
			.item-title {
				z-index: 9999;
				opacity: 1;
			}
			.video {
				opacity: 0.5;
				transition: opacity 0.25s ease;
			}
		}
	}

	.item.png {
		background-color: #ccc;
		opacity: 0.8;
		background-image:  repeating-linear-gradient(45deg, #aaa 25%, transparent 25%, transparent 75%, #aaa 75%, #aaa), repeating-linear-gradient(45deg, #aaa 25%, #ccc 25%, #ccc 75%, #aaa 75%, #aaa);
		background-position: 0 0, 10px 10px;
		background-size: 20px 20px;

		.item-title {
			display: none;
		}
	}

	.item.block {
		flex-grow: 1;
		border: none;
	}

	.item.selected {
		background-color: #945151;
		border: 3px solid #945151;
		.video {
			opacity: 1;
		}
	}

	.item-title {
		flex: 0;
	}
`;

interface IProps {
	user: string;
	category: Category;
	byDefault: string | undefined;
	onSelect: (option: string | undefined) => void;
	onChangeCustom: (isCustom: boolean) => void;
	onCancel: () => void;
}

const OptionPanel = ({ user, category, byDefault, onSelect, onChangeCustom, onCancel }: IProps) => {
	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	const containerRef = useRef<HTMLDivElement>(null);
	// const items = useGetItemsFromS3(category, user);

	const [items, setItems] = useState<string[]>(['']);
	const [selected, setSelected] = useState<string | undefined>(byDefault);
	const [showCustomForm, setShowCustomForm] = useState<boolean>(false);
	const [customVideo, setCustomVideo] = useState<File | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const getItems = async (_category: Category) => {
			const _items = await _getItems(domain, env, _category, user);
			setItems(_items);
		};

		if (category) getItems(category);
		// eslint-disable-next-line
	}, [category]);

	useEffect(() => {
		setSelected(byDefault);
		// eslint-disable-next-line
	}, [byDefault]);

	const loading = isLoading && <Loading />;

	const customVideoForm = !isLoading && (
		<Form>
			<Form.Group as={Row}>
				<Form.File
					id="mimic-file"
					className="form-control-sm"
					label={'Choose video'}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						const { target } = event;
						if (target) {
							const { files } = target;
							if (files && files.length > 0) {
								setSelected(files[0].name);
								setCustomVideo(files[0]);
								onChangeCustom(true);
							}
						}
					}}
				>
				</Form.File>
			</Form.Group>
		</Form>
	);

	const buttons = [
		{ variant: 'select', pos: 'first' },
		{ variant: 'cancel', pos: 'second' },
		{ variant: 'up', pos: 'third' }
	].map((item: { variant: string, pos: string; }, index: number) => (
		<AsideButton
			key={`${item.variant}-${index}`}
			variant={item.variant as Variant}
			position={item.pos as Position}
			disabled={isLoading}
			onClick={async () => {
				const { variant } = item;
				if (variant === 'up') containerRef.current?.scrollIntoView({ behavior: 'smooth' });
				else if (variant === 'select') {
					try {
						if (customVideo) {
							setIsLoading(true);
							console.table(customVideo);
							const fileUrl = await _uploadFile(domain, env, user, customVideo);
							console.info(`file url: ${fileUrl}`);
							setIsLoading(false);
							onSelect(fileUrl);
						} else {
							const root = 'https://dialoga-machine-learning.s3.amazonaws.com';
							onSelect(`${root}/${selected}`);
						}
					} catch (error) {
						console.error(error);
					}
				} else onCancel();
			}}
		/>
	));

	return (
		<div css={style}>
			<h2 className="title">{category}</h2>
			{
				category === 'video' && showCustomForm && (
					<div className="container">
						<div className="item block">
							{customVideoForm}
						</div>
					</div>
				)
			}
			{buttons}
			<div className="container" ref={containerRef}>
				{
					category === 'video' && (
						<div className="item border" onClick={() => setShowCustomForm(true)}>
							<h4>Custom video</h4>
						</div>
					)
				}
				{
					category !== 'video' && (
						<div className="item border" onClick={() => onSelect(undefined)}>
							<h4>None</h4>
						</div>
					)
				}
				{
					isLoading ? loading : (items && items.map((el: string, index: number) => (
						<div
							key={`${el}-${index}`}
							className={`item ${el.includes('.png') ? 'png' : ''} ${el === selected ? 'selected' : ''}`}
							onClick={() => {
								console.log(el);
								setShowCustomForm(false);
								setCustomVideo(null);
								onChangeCustom(false);
								setSelected(el);
							}}
						>
							{
								el.includes('.mp4') && (
									<video className="video">
										<source src={`https://dialoga-machine-learning.s3.amazonaws.com/${el}`} />
									</video>
								)
							}
							{
								(el.includes('.jpeg') || el.includes('.jpg') || el.includes('.png')) && (
									<img
										className="img"
										alt={`https://dialoga-machine-learning.s3.amazonaws.com/${el}`}
										src={`https://dialoga-machine-learning.s3.amazonaws.com/${el}`} />
								)
							}
							<h6 className="item-title">
								{getNameByS3Key(el)}
							</h6>

						</div>
					)))
				}
			</div>
		</div>
	);
};

export default OptionPanel;

export function getNameByS3Key(key: string): string {
	const keyArray = key.split('/');
	return keyArray[keyArray.length - 1].replace(/.mp4|.jpeg|.jpg|.png/, '');
}

function _getItems(domain: string, env: string, category: Category, user?: string): Promise<string[]> {
	return new Promise(async (resolve, reject) => {
		try {
			const _path = `https://${domain}/api/vision/mimic`;

			let _api = 'images';
			switch (category) {
				case 'video':
					_api = `videos/source/${user}`;
					break;
				case 'image':
					_api = 'images';
					break;
				case 'background':
					_api = 'backgrounds';
					break;
				case 'watermark':
					_api = 'watermarks';
					break;
				default:
					_api = 'images';
					break;
			}

			const url = `${_path}/${_api}`;
			const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
			const { data } = await axios.get(url, options);
			resolve(data);
		} catch (error) {
			if (axios.isAxiosError(error)) reject(error.message);
			else reject(JSON.stringify(error));
		}
	});
}

function _uploadFile(domain: string, env: string, user: string, file: File | null): Promise<string> {
	return new Promise(async (resolve, reject) => {
		if (!file) return reject('None file selected');

		const maxFileSize = upload_file_max_size_MB * 1024 * 1024;
		const { name, size, type } = file;

		try {
			if (size > maxFileSize) {
				reject(`Maximun size exceeded (${upload_file_max_size_MB}Mb)`);
			} else if (type !== 'video/mp4') {
				reject('Wrong file format. Only supports mp4');
			} else if (size <= maxFileSize && type === 'video/mp4') {
				const checkUrl = `https://${domain}/api/vision/mimic/check/video`;
				const params = { user, file: name };
				const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
				const { data: { url } } = await axios.post(checkUrl, params, options);

				if (url) return resolve(url);

				const formData = new FormData();
				formData.append('file', file);
				formData.append('user', user);

				const uploadUrl = `https://${domain}/api/vision/mimic/upload/video`;
				const headers = {
					'Content-Type': 'multipart/form-data',
					'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}`
				};
				const { data: { url: _url, videoName } } = await axios({
					method: 'post',
					url: uploadUrl,
					data: formData,
					headers
				});

				console.log(videoName);
				resolve(_url);
			}
		} catch (error) {
			console.error(error);
			reject('Something went wrong');
		}


	});
}
