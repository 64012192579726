import React, { useEffect, useState, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-bootstrap/Spinner';

export interface IStatus {
  step: number;
  time?: number;
  segments?: number;
}

interface IProps {
  status: IStatus;
}

const LabelingWait = ({ status }: IProps) => {
  const isMounted = useRef<boolean>(false);
  const timeoutRef = useRef<any>(null);

  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      clearTimeout(timeoutRef.current);
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current && status.time) {
      const timer = 500;
      const total = status.time;

      const fraction = timer * (100 / total);

      setProgress(fraction);

      timeoutRef.current = setInterval(() => {
        setProgress((progress) => progress + fraction);
      }, timer);
    } else if (!status.time && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [status]);

  const firstSep = (
    <Row className={status.step >= 1 ? 'my-2' : 'd-none'}>
      <Col>
        <h5>
          Downloading the audio file
          <FontAwesomeIcon
            className={`${status.step > 1 ? 'ml-2 text-success' : 'd-none'}`}
            icon={faCheck}
          />
        </h5>
      </Col>
    </Row>
  );

  const secondStep = (
    <Row className={status.step >= 2 ? 'my-2' : 'd-none'}>
      <Col>
        <h5>
          Processing the audio file
          <FontAwesomeIcon
            className={`${status.step > 2 ? 'ml-2 text-success' : 'd-none'}`}
            icon={faCheck}
          />
        </h5>
        <ProgressBar
          className="my-2"
          animated
          variant="loquista"
          now={progress}
        />
      </Col>
    </Row>
  );

  const thirdStep = (
    <Row className={status.step >= 3 ? 'my-2' : 'd-none'}>
      <Col>
        <h5>
          Defining audio segments (Speech to text)
          <FontAwesomeIcon
            className={`${status.step > 3 ? 'ml-2 text-success' : 'd-none'}`}
            icon={faCheck}
          />
        </h5>
      </Col>
    </Row>
  );

  const fourthStep = (
    <Row className={status.step >= 4 ? 'my-2' : 'd-none'}>
      <Col>
        <h5>
          Rendering {status.segments} segments of the audio waveform
          <Spinner
            className="pb-2 ml-4"
            animation="border"
            variant="loquista"
          />
        </h5>
      </Col>
    </Row>
  );

  return (
    <Container className="mt-4">
      {firstSep}
      {secondStep}
      {thirdStep}
      {fourthStep}
    </Container>
  );
};

export default LabelingWait;
