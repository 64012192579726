import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import CustomSelect from '../../forms/CustomSelect';
import axios from 'axios';
import useToast from '../../../hooks/useToast';
import { DomainContext, EnvContext } from '../../../context/Context';

const CloningVoices = () => {
  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);
  
  const toast = useToast();
  
  const [voices, setVoices] = useState<string[]>([]);
  const [voiceToDelete, setVoiceToDelete] = useState<string>('');

  const fillVoicesSelect = async () => {
    getVoices(domain, env)
      .then((voices) => {
        setVoices(voices)
        setVoiceToDelete(voices[0])
      })
      .catch((error) => console.error(error));
  }
  
  useEffect(() => {
    fillVoicesSelect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteVoice = async () => {
    try {
      const api = `https://${domain}/api/loquista/generate-voice/voices/delete`;
      const headers = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
      const body = { voiceName: voiceToDelete };
      await axios.post(api, body, headers);
      toast({ type: 'success', message: 'Voice deleted' });
    } catch (error) {
      console.error(error);
      toast({ type: 'error', message: 'Error deleting voice' });
    } finally {
      fillVoicesSelect();
    }
  }
  
  return (
    <Container className='mt-2'>
      <Row>
        <Col md={6}>
          <CustomSelect
            label='Voices'
            name='delete-voices'
            options={voices}
            onChange={(value) => {
              setVoiceToDelete(value);
            }}
          />
        </Col>
        <Col md={6}>
          <Button onClick={handleDeleteVoice} className='mt-4'>Delete voice {voiceToDelete}</Button>
        </Col>
      </Row>
    </Container>
  )
}

export default CloningVoices

async function getVoices(domain: string, env: string): Promise<string[]> {
	try {
    const model = 'cloning'
    const language = 'es-ES'
    
    const url = `https://${domain}/api/loquista/tts/voices`;
    const params = { language, model };
    const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
    const { data: { availableVoices } } = await axios.post(url, params, options);
    return Promise.resolve(availableVoices);
	} catch (error) {
		console.error(error);
		return Promise.reject(new Error('An error occurred while getting tts voices'));
	}
};
