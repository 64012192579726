import axios from 'axios';

import * as utils from '../utils/utils';
import { IOption } from '../components/utopia/loquista/asr/SpeechToTextForm';
import { ISegment } from '../components/utopia/loquista/labeling/LabelingWave';

// type typeEnv = 'prod' | 'test' | 'dev';

interface IUuid { uuid: string; }
interface IKey { key: string; };

const env = process.env.REACT_APP_ENV;

interface ILanguageRequest extends IUuid { host: string; };
interface ILanguageResposne { data: string[]; }

async function getLanguages(url: string, params: ILanguageRequest): Promise<string[]> {
	try {
		const { data }: ILanguageResposne = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		if (!data.length) return Promise.reject('No languages');
		const orderedLangs: string[] = utils.orderLanguages(data);
		return Promise.resolve(orderedLangs);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IAsrConnectRequest { host: string; language: string; nlp: string; options: IOption[]; }
interface IAsrConnectResonse { data: string; };

async function asrConnect(url: string, params: IAsrConnectRequest): Promise<string> {
	try {
		const { data }: IAsrConnectResonse = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IAsrCheckLoadRequest { host: string; uuid: string; }
interface ILoadCheck { load: number; };
interface IAsrCheckLoadResponse { data: ILoadCheck; }

async function asrCheckLoad(url: string, params: IAsrCheckLoadRequest): Promise<ILoadCheck> {
	try {
		const { data }: IAsrCheckLoadResponse = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IAsrTranscriptionsRequest extends IUuid { }
interface IAsrTranscriptionsResponse { data: string; }

async function getTranscriptions(url: string, params: IAsrTranscriptionsRequest): Promise<string[]> {
	try {
		const { data }: IAsrTranscriptionsResponse = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		if (!data.length) return Promise.resolve([]);
		const list: string[] = data.split('\n');
		return Promise.resolve(list);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IVoicesRequest extends IUuid { host: string; language: string; };
interface IVoicesResposne { data: string[]; }

async function getVoices(url: string, params: IVoicesRequest): Promise<string[]> {
	try {
		const { data }: IVoicesResposne = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		if (!data.length) return Promise.reject('No voices');
		const orderedVoices: string[] = utils.orderVoices(data);
		return Promise.resolve(orderedVoices);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ITtsConnectRequest extends IUuid { host: string, language: string, voice: string, sampleRate?: number, speakingRate?: number, text: string; }
interface ITtsConnectResponse { data: string; }

async function ttsConnect(url: string, params: ITtsConnectRequest): Promise<string> {
	try {
		const { data }: ITtsConnectResponse = await axios.post(url, params, {
			headers: {
				"Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}`,
				"Access-Control-Allow-Origin": "*"
			}
		});
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ITtsDownloadRequest { fileName: string; };
interface ITtsDownloadResponse { data: any; };

async function ttsDownloadAudio(url: string, params: ITtsDownloadRequest): Promise<any> {
	try {
		const { data }: ITtsDownloadResponse = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

async function avatarUploadAudio(url: string, gender: any): Promise<any> {
	try {
		const { data }: ITtsDownloadResponse = await axios.post(url, gender, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ITranslatorLanguageRequest extends IUuid { host: string; };
interface ITranslatorLanguageResponse { data: ITranslatorLanguageData; }
interface ITranslatorLanguageOption { input: string, output: string; };
interface ITranslatorLanguageData { languages: ITranslatorLanguageOption[]; }
interface ITraslatorLanguagesResult { languages: string[], combinations: ITranslatorLanguageOption[]; }

async function getTranslatorLanguages(url: string, params: ITranslatorLanguageRequest): Promise<ITraslatorLanguagesResult> {
	try {
		const { data: { languages } }: ITranslatorLanguageResponse = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		const inputs: string[] = languages.map((lang => lang.input));
		const langs = [...new Set(inputs)];
		if (!langs.length) return Promise.reject('No languages');
		const orderedLangs: string[] = utils.orderLanguages(langs);
		return Promise.resolve({ languages: orderedLangs, combinations: languages });
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ITranslateRequest { host: string, uuid: string, code: string, text: string; };
interface ITranslateResponseData { output_sentence: string; score: string, text_truncated: boolean; };
interface ITranslateResponse { data: ITranslateResponseData; }

async function translate(url: string, params: ITranslateRequest): Promise<ITranslateResponseData> {
	try {
		const { data: { output_sentence, score, text_truncated } }: ITranslateResponse = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve({ output_sentence, score, text_truncated });
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ICheckKeyRequest extends IKey { };
interface ICheckKeyResponse { error: string, name: string, email: string; }

async function checkKey(url: string, params: ICheckKeyRequest): Promise<ICheckKeyResponse> {
	try {
		const { data: { error, name, email } } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve({ error, name, email });
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IRegisterRequest { key: string; name: string, email: string; };

async function register(url: string, params: IRegisterRequest): Promise<any> {
	try {
		const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ISendMailRequest {
	readonly to: string;
	readonly from?: string;
	readonly subject?: string;
	readonly message?: string;
}

async function sendMail(url: string, params: ISendMailRequest): Promise<string> {
	try {
		const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IFaceRecogConnectRequest { host: string, port: number; }

async function faceRecognitionConnect(url: string, params: IFaceRecogConnectRequest): Promise<void> {
	try {
		await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve();
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

async function getS3Languages(url: string): Promise<string[]> {
	try {
		const { data: { languages } } = await axios.get(url, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return languages;
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface getS3FilesByLanguageRequest { language: string; }

async function getS3FilesByLanguage(url: string, params: getS3FilesByLanguageRequest): Promise<string[]> {
	try {
		const { data: { files } } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return files;
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface getS3FilesByLanguageAndUserRequest { language: string; user: string; }

async function getS3FilesByLanguageAndUser(url: string, params: getS3FilesByLanguageAndUserRequest): Promise<string[]> {
	try {
		const { data: { files } } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return files;
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IVoiceGeneratorAudioFilesCountRequest { user: string; language?: string; model: string; voiceName: string; folder: string; }

async function voiceGeneratorAudioFilesCount(url: string, params: IVoiceGeneratorAudioFilesCountRequest): Promise<number> {
	try {
		const { data: { total } } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return total;
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IVoiceGeneratorFilesKeysRequest { folder: string, user: string; language: string; model: string; voiceName: string; }
interface IVoiceGeneratorFilesKeysResponse { audio: number[], text: number[]; }

async function voiceGeneratorFilesKeys(url: string, params: IVoiceGeneratorFilesKeysRequest): Promise<IVoiceGeneratorFilesKeysResponse> {
	try {
		const { data: { audio, text } } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return { audio, text };
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IVoiceGeneratorDownloadFileRequest { file: string; path: string; }

async function voiceGeneratorDownloadFile(url: string, params: IVoiceGeneratorDownloadFileRequest): Promise<any> {
	try {
		const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

async function voiceGeneratorUploadFile(url: string, fd: FormData) {
	try {
		const contentType = 'multipart/form-data';
		const { data: { path } } = await axios.post(url, fd, {
			headers: { 'Content-Type': contentType, 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` }
		});
		return Promise.resolve(path);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IVoiceGeneratorEditTextFileRequest { path: string, text: string; name: string; }

async function voiceGeneratorEditTextFile(url: string, params: IVoiceGeneratorEditTextFileRequest): Promise<any> {
	try {
		const { data } = await axios.put(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IVoiceGeneratorCreateVoiceNameRequest { user: string, language: string }
async function voiceGeneratorCreateVoiceName(url: string, params: IVoiceGeneratorCreateVoiceNameRequest): Promise<any> {
  try {
    const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(_errorHandler(error));
  }
}

interface IVoiceGeneratorCreateVoiceRequest { reference_audio_url: string, speaker: string }
async function voiceGeneratorCreateVoice(url: string, params: IVoiceGeneratorCreateVoiceRequest) {
  try {
    const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(_errorHandler(error));
  }
}

interface IVoiceGeneratorDownloadAudiosRequest { path: string; voiceName: string; model: string; }
async function voiceGeneratorMergeAllAudios(url: string, params: IVoiceGeneratorDownloadAudiosRequest): Promise<any> {
  try {
    const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(_errorHandler(error));
  }
}

interface ILabelingMoveRequest { user: string, language: string, file: string; };

async function labelingMoveFile(url: string, params: ILabelingMoveRequest): Promise<any> {
	try {
		const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ILabelingRequest { path: string; language: string; }

async function labelingProcess(url: string, params: ILabelingRequest): Promise<any> {
	try {
		const { data } = await axios.post(url, params, {
			headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` },
			timeout: 60 * 2 * 1000
		});
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ILabelginRequest { name: string; }

async function labelingGetSegments(url: string, params: ILabelginRequest): Promise<any> {
	try {
		const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

async function labelingCheckSegments(url: string, params: ILabelginRequest): Promise<any> {
	try {
		const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ILabelingDownloadFileRequest { path: string; file: string; };

async function labelingDownloadFile(url: string, params: ILabelingDownloadFileRequest): Promise<any> {
	try {
		const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ILabelingSaveFileRequest { user: string, language: string, file: string; segments: ISegment[]; }

async function labelingSaveFile(url: string, params: ILabelingSaveFileRequest): Promise<any> {
	try {
		const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ILabelingLoadFileRequest { user: string, language: string, file: string; }

async function labelingLoadFile(url: string, params: ILabelingLoadFileRequest): Promise<any> {
	try {
		const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ILabelingDeleteFileRequest { file: string; language: string; }

async function labelingDeleteFile(url: string, params: ILabelingDeleteFileRequest) {
	try {
		const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface ISendMessage { id: string, text: string; }

async function sendMessage(url: string, params: ISendMessage) {
	try {
		const { data } = await axios.post(url, params, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
};

async function mimicListVideos(url: string, name?: string) {
	try {
		const { data } = name ? await axios.post(url, { user: name }, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } }) : await axios.get(url, { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } });
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(_errorHandler(error));
	}
}

interface IAxiosError { response: IAxiosErrorResponse; }
interface IAxiosErrorResponse { data: IAxiosErrorData; }
interface IAxiosErrorData { message: string; };

function _errorHandler(axiosError: IAxiosError | unknown): string {
	const { response } = axiosError as IAxiosError;
	if (!response) return (typeof axiosError === 'string' ? axiosError : JSON.stringify(axiosError));
	const { data: { message } } = response;
	return message || 'Undefined error';
}

export {
	getLanguages,
	asrConnect,
	asrCheckLoad,
	getTranscriptions,
	getVoices,
	ttsConnect,
	ttsDownloadAudio,
	translate,
	getTranslatorLanguages,
	checkKey,
	register,
	sendMail,
	faceRecognitionConnect,
	getS3Languages,
	voiceGeneratorAudioFilesCount,
	getS3FilesByLanguage,
	getS3FilesByLanguageAndUser,
	voiceGeneratorFilesKeys,
	voiceGeneratorDownloadFile,
	voiceGeneratorUploadFile,
	voiceGeneratorEditTextFile,
  voiceGeneratorCreateVoiceName,
  voiceGeneratorCreateVoice,
  voiceGeneratorMergeAllAudios,
	labelingMoveFile,
	labelingProcess,
	labelingGetSegments,
	labelingCheckSegments,
	labelingDownloadFile,
	labelingSaveFile,
	labelingLoadFile,
	labelingDeleteFile,
	sendMessage,
	avatarUploadAudio,
	mimicListVideos
};