import React, { createContext } from 'react';

// environment context
type Env = 'dev' | 'test' | 'prod';
type Role = 'visitor' | 'publisher' | 'admin';

const { REACT_APP_ENV } = process.env;

const env = REACT_APP_ENV ? REACT_APP_ENV as Env : 'prod';
const domain = env.includes('prod') ? 'apps.utopia.ai' : `apps-${env}.utopia.ai`;

console.log(`DOMAIN: ${domain} / ENVIRONMENT: ${env}`);

const EnvContext = createContext<Env>(env);

// login context
export interface ILoginContext {
	logged: boolean | string;
	setLogged: React.Dispatch<React.SetStateAction<boolean | string>>;
}

export interface IRoleContext {
	role: Role;
	setRole: React.Dispatch<React.SetStateAction<Role>>;
}

const LoginContext = createContext<ILoginContext>({
	logged: false,
	setLogged: () => { },
});

const RoleContext = createContext<IRoleContext>({
	role: 'visitor',
	setRole: () => { }
});

// domain context
const DomainContext = createContext<string>(domain);

// size context
interface ISize { size: Size; }
export type Size = 'small' | 'large';

const SizeContext = createContext<ISize>({
	size: 'small'
});

export {
	EnvContext,
	LoginContext,
	RoleContext,
	DomainContext,
	SizeContext
};
