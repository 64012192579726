import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IProps {
  name: string;
  centered?: boolean;
  hidden?: boolean;
}

const Subtitle = ({ name, centered, hidden }: IProps) => {
  const pathname = window.location.pathname;
  const className = pathname.split('/')[1];

  return (
    <Container fluid className={`${hidden ? 'd-none' : ''}`}>
      <Row>
        <Col xs={12} className={centered ? 'text-center' : 'text-left'}>
          <h3 className={`utopia-home-subtitle ${className}`}>{name}</h3>
        </Col>
      </Row>
    </Container>
  );
};

export default Subtitle;
