import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as helpers from '../utils/helpers';

interface IProps {
  title: string;
  centered?: boolean;
}

const Title = ({ title, centered }: IProps) => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} className={centered ? 'text-center' : 'text-left'}>
          <h2 className='utopia-home-title'>{helpers.capitalizeFirstLetter(title)}</h2>
        </Col>
      </Row>
    </Container>
  );
};

export default Title;
