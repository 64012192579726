import React from 'react';

import Form from 'react-bootstrap/Form';
import { IOption } from '../utopia/loquista/asr/SpeechToTextForm';

type CheckBoxType = 'checkbox' | 'radio' | undefined;

interface IProps {
  options: IOption[];
  inline?: boolean;
  boxType?: CheckBoxType;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const CustomCheckBox = ({
  options,
  boxType,
  inline,
  disabled,
  onChange,
}: IProps) => {
  const box = boxType ? boxType : 'checkbox';

  return (
    <div className="mb-3">
      {options.map((item: IOption, key: number) => {
        if (item.name === 'use_provisional_transcription') {
          return (
            <Form.Check
              key={key + 1}
              type={box}
              inline={inline ? true : false}
              label={getLabelByOption(item.name)}
              id={`${box}-${item.name}-${key + 1}`}
              disabled={disabled}
              name={item.name}
              checked={item.value}
              onChange={(event: any) => (onChange ? onChange(item.name) : null)}
            />
          );
        } else return null;
      })}
    </div>
  );
};

function getLabelByOption(option: string): string {
  switch (option) {
    case 'use_nlp':
      return 'Data format';
    case 'use_enhanced':
      return 'Noise cancelling';
    case 'use_gpu':
      return 'High quality';
    case 'use_provisional_transcription':
      return 'Provisionals';
    default:
      break;
  }
  return option;
}

export default CustomCheckBox;
