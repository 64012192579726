import { useState, useEffect, useContext } from 'react';
import { DomainContext } from '../context/Context';

import axios from 'axios';

type Binary = 1 | 0;
type Env = 'dev' | 'test' | 'prod';

interface IApp {
	id: number;
	section: string;
	app: string;
	status: Binary;
	path: string;
	description?: string;
	env: Env;
	logged_required: Binary;
}

const useGetApps = () => {
	const domain = useContext(DomainContext);
	const url = `https://${domain}/admin/apps`;

	const [apps, setApps] = useState<IApp[]>([]);

	useEffect(() => {
		const getSections = async () => {
			try {
				const { data: { results } } = await axios.get(url);
				setApps(results);
			} catch (error) {
				console.error('An error occurred while getting Apps from database');
				setApps([]);
			}
		};

		getSections();
		// eslint-disable-next-line
	}, []);

	return apps;
};

export default useGetApps;