import React, { useState, useEffect, useContext, useRef } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Select from '../../../forms/Select';
import TextArea from '../../../forms/TextArea';
import CustomRange from '../../../forms/CustomRange';

import { useTextToSpeechForm, IUseTextToSpeechForm, ITextToSpeechOptions, Language, getText, Model } from './useTextToSpeechForm';

import { EnvContext } from '../../../../context/Context';

export interface IParams {
	host: string;
	language: string;
	voice: string;
  model: string;
	text: string;
	sampleRate: number;
	speakingRate: number;
}

interface IProps {
	hidden: boolean;
	audioRef: any,
	disabled: boolean;
	onConnect: (audioContext: AudioContext) => void;
	onDisconnect: () => void;
	gotParams: (params: IParams) => void;
	onError?: (error: Error) => void;
}

const selects = ['host', 'language', 'voice', 'model'];

const TextToSpeechForm = (props: IProps) => {
	const { hidden, audioRef, disabled, ...rest } = props; // props
	const { onConnect, onDisconnect, gotParams, onError } = rest; // events

	const env = useContext(EnvContext) || process.env.REACT_APP_ENV;
	const isMounted = useRef<boolean>(false);

	const { options, setOptions, formError }: IUseTextToSpeechForm = useTextToSpeechForm({});

	const [speakingRate, setSpeakingRate] = useState<number>(1.0);
	const [sampleRate, setSampleRate] = useState<number>(22050);

	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false; };
	}, []);

	useEffect(() => {
		if (options) {
			let isFilled = true;

			Object.entries(options).forEach(([key, value]) => {
				if (typeof value === 'string' && value === '') isFilled = false;
				if (typeof value === 'object' && value[0] === '') isFilled = false;
			});

			if (isFilled) {
				const { host, language, voice, model, text } = options;
				if (isMounted.current) gotParams({ host, language, voice, model, text, speakingRate, sampleRate });
			}
		}
		// eslint-disable-next-line
	}, [options, speakingRate, sampleRate]);

	useEffect(() => {
		if (formError && onError && isMounted.current) onError(formError);
		// eslint-disable-next-line
	}, [formError]);

	const form = (
		<Form>
			<Form.Row>
				{
					selects.map((item, key) => (
						<Col key={`${item}-${key}`} xs={12} md={item === 'host' ? 12 : 6} lg={env.includes('prod') ? 6 : 4}
							className={env.includes('prod') && item === 'host' ? 'd-none' : ''}
						>
							<Select
                name={`loquista-tts-${item}`}
                label={item}
                disabled={disabled}
								options={
                  item === 'host'
                    ? options.hosts
                    : item === 'language'
                        ? options.languages
                        : item === 'voice'
                            ? options.voices
                            : options.models
                }
								selected={
                  item === 'host'
                    ? options.host
                    : item === 'language'
                        ? options.language
                        : item === 'voice'
                            ? options.voice
                            : options.model
                }
								onChange={(value) => {
									const { ...props } = options;
									if (isMounted.current && item === 'host') setOptions({ ...props, host: value });
									if (isMounted.current && item === 'language') setOptions({ ...props, language: value as Language });
									if (isMounted.current && item === 'voice') setOptions({ ...props, voice: value });
                  if (isMounted.current && item === 'model') setOptions({ ...props, model: value as Model });
								}}
              />
						</Col>
					))
				}
			</Form.Row>
			<Form.Row>
				<Col>
					<TextArea name="loquista-tts-text" label="text"
						disabled={disabled} rows={6}
						selected={options.text} onChange={(value) => {
							if (isMounted.current) {
								const { ...props } = options;
								setOptions({ ...props, text: value });
							}
						}} />
				</Col>
			</Form.Row>
			<Form.Row>
				<Col xs={12} md={2} className="text-right order-xs-first order-md-last">
					<Button onClick={() => {
						if (isMounted.current) {
							const { language, text, ...props } = options;
							setOptions({ ...props, language, text: getText(language) });
						}
					}} size="sm" variant="light">Example text</Button>
				</Col>
				<Col xs={6} md={5} className={options.model !== 'TTS' ? 'd-none' : ''}>
					<CustomRange name="loquista-tts-samplerate" label="sample rate"
						disabled={disabled} min={8000} max={22050} step={14050} value={sampleRate}
						onChange={(value) => isMounted.current && setSampleRate(value)} />
				</Col>
				<Col xs={6} md={5}>
					<CustomRange name="loquista-tts-speakingrate" label="speaking rate"
						disabled={disabled} min={0.7} max={1.3} step={0.1} value={speakingRate}
						onChange={(value) => isMounted.current && setSpeakingRate(value)} />
				</Col>
			</Form.Row>
		</Form>
	);


	const control = disabled ? (
		<Button className="m-2 utopia-tts-button" block onClick={() => isMounted.current && onDisconnect()}>
			Stop
		</Button>
	) : (
		<Button className="m-2 utopia-tts-button" block
			onClick={() => {
				const audioContext: AudioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
				if (audioContext.state === 'suspended') audioContext.resume();
				(isMounted.current) && onConnect(audioContext);
			}}>
			Start
		</Button>
	);

	const modal = (
		<Row className="text-center">
			<Col xs={12} md={{ order: 12 }} className='p-0 '>
				{control}
			</Col>
			<Col xs={12} md={{ order: 1 }} className='p-0 text-center'>
				{audioRef && <audio ref={audioRef} className='utopia-tts-audio' controls playsInline />}
			</Col>
		</Row>
	);

	return (
		<Container fluid>
			<Row>
				<Col className={hidden ? 'd-none' : ''}>
					{form}
					<hr />
					{modal}
				</Col>
			</Row>
		</Container>
	);
};

export default TextToSpeechForm;

function checkSpeakingRateByVoice(options: ITextToSpeechOptions): boolean {
    return true;
	// return options.host.includes('78') ? false : true;
}
