import React from 'react';

import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Loading = () => {
  return (
    <Container>
      <Row>
        <Col>
          <div className='text-center py-4 my-4'>
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Loading;
