import React, { useEffect, useState, useContext } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { DomainContext, EnvContext } from '../../../../context/Context';
import { Top } from './VoiceIdConn';
import { checkVoicePrint, IMetaData } from '../../admin/AdminSesame';
import { truncateNoRounding } from '../../../../utils/helpers';

interface IProps {
	top: Top;
	isUser: boolean;
}

const TopTable = ({ top, isUser }: IProps) => {
	const domain = useContext(DomainContext);
	const env = useContext(EnvContext);

	const [metadata, setMetadata] = useState<IMetaData[]>();

	useEffect(() => {
		const _getMetadata = async () => {
			try {
				const keys = top.uids;
				const _metadata: IMetaData[] = await Promise.all(keys.map((item: string) => checkVoicePrint(domain, env, item)));
				setMetadata(_metadata);
			} catch (error) {
				console.error(error);
			}
		};

		top && _getMetadata();
		// eslint-disable-next-line
	}, [top]);

	return (
		<Container fluid className="mt-4">
			<Row>
				<Col className="text-center">
					<h5>Top five scores</h5>
				</Col>
			</Row>
			<Row>
				<Col>
					<Table striped bordered hover size="sm">
						<thead>
							<tr className="text-capitalize">
								<th>#</th>
								<th>key</th>
								<th>name</th>
								<th>email</th>
								<th>score</th>
							</tr>
						</thead>
						<tbody>
							{
								top.uids.map((id, index) => (
									<tr key={id}>
										<td className="text-secondary">
											<small>
												{index + 1}
											</small>
										</td>
										<td>
											<small>
												<pre>
													<code>
														{id}
													</code>
												</pre>
											</small>
										</td>
										<td className="font-weight-light">
											{metadata ? metadata[index].name : ''}
										</td>
										<td className="font-italic">
											<small>
												{metadata ? metadata[index].email : ''}
											</small>
										</td>
										<td className={`font-weight-bold text-${index === 0 && isUser ? 'success' : 'danger'}`}>
											{truncateNoRounding(top.scores[index], 3)}
										</td>
									</tr>
								))
							}
						</tbody>
					</Table>
				</Col>
			</Row>
		</Container>

	);
};

export default TopTable;