import React, { useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "../contexts/appContext";

const DrivingVideo = () => {
  const { setRequestData, responseData, start, setStart, drivingVideo, setDrivingVideo } = AppContext();
  const { drivingVideoEl } = AppContext();
  const canvas = useRef(undefined);

  const started = useRef(false);

  const PlayEvent = () => {
  };

  const ClickEvent = () => {
  };

  useEffect(() => {
    // videoEl.current.crossOrigin = "anonymous";
  }, []);

  useEffect(() => {
      if(drivingVideo) {
        drivingVideoEl.current.src = `https://dialoga-machine-learning.s3.amazonaws.com/${drivingVideo}`;
      }
  }, [drivingVideo]);

  return (
          <video
            ref={drivingVideoEl}
            onClick={ClickEvent}
            onPlay={PlayEvent}
            width="400px" height="400px"
            style={{ backgroundColor: "#fff" }}
          />
  );
};

            // onEnded={() => drivingVideoEl.current.play()}

export default DrivingVideo;
