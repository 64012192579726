import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { IAssistant, useGetAssistants } from './utopia/brain/useGetAssistants';
import { capitalizeFirstLetter } from '../utils/helpers';
import { IApp } from '../App';

import ListGroupItem from 'react-bootstrap/ListGroupItem';
import classNames from 'classnames';

interface IProps {
	title: string;
	group: string;
	apps: IApp[];
	selected: string;
	onSelected: (item: string) => void;
}

const SubMenuItem = ({ title, group, apps, selected, onSelected }: IProps) => {
	const assistants = useGetAssistants();

	const [loaded, setLoaded] = useState<boolean>(false);
	const [bots, setBots] = useState<IAssistant[]>();

	useEffect(() => {
		if (assistants) {
			setLoaded(true);
			const availableBots = assistants.filter(bot => bot.typename === group);
			setBots(availableBots);
		}
		// eslint-disable-next-line
	}, [assistants]);

	const header = (
		<div className="card-header-submenu ml-3">
			<div className="utopia-menu-card-description">
				{capitalizeFirstLetter(title)}
			</div>
		</div>
	);

	const body = bots && bots.map((bot, key) => {
		const botName = bot.name.replace(/ /g, '-');
		const botLang = bot.language;
		const patron = `${group}/${botName}/${botLang}`;
		const el = apps.filter(item => item.path.includes(patron))[0];

		if (!el) return null;
		const selectedItem = el.app.toLowerCase().replace(/ /g, '-');

		const className = classNames('utopia-menu-child', `utopia-menu-child-${selectedItem}`, el.section, selected === selectedItem ? 'selected' : '');

		return (
			<Link key={key} to={`/${el.section}/${el.path}`}>
				<ListGroupItem
					className={className}
					onClick={() => onSelected(selectedItem)}>
					<span className='ml-1-rem-2'>
						{el.app}
					</span>
				</ListGroupItem>
			</Link>
		);
	});

	const content = (
		<Fragment>
			{header}
			{body}
		</Fragment>
	);

	return (
		<Fragment>
			{loaded && content}
		</Fragment>
	);
};

export default SubMenuItem;