import React from 'react';
import { Link } from 'react-router-dom';

import lgImg from '../../resources/imagen-home-grande.jpg';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';


import Title from '../Title';

const NotFound = () => {
	const title = 'Page not found';
	return (
		<Container fluid>
			<Title title={title} centered={true} />
			<Container fluid className="text-center">
				<Row>
					<Col>
						<h4>Code 404</h4>
					</Col>
				</Row>
				<Row>
					<Col>
						<p>The page you are looking for doesn't exist.</p>
						<p className="font-weight-light">
							You may have mistyped the address or the page may have moved
						</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<Button variant="link">
							<Link to="/">Go back home page</Link>
						</Button>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className='w-50 p-3 mx-auto'>
							<Image src={lgImg} alt='utopia.ai' thumbnail className='border-0' />
						</div>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default NotFound;