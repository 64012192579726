import React from 'react';

import * as helpers from '../../utils/helpers';
import Form from 'react-bootstrap/Form';

interface IProps {
  name: string;
  label: string;
  disabled?: boolean;
  rows?: number;
  value?: string;
  onChange?: (text: string) => void;
}

const CustomTextArea = ({
  name,
  label,
  disabled,
  rows,
  value,
  onChange,
}: IProps) => {
  return (
    <>
      <Form.Group controlId={name}>
        <Form.Label>{helpers.capitalizeFirstLetter(label)}</Form.Label>
        <Form.Control
          as="textarea"
          rows={rows ? rows : 4}
          size="sm"
          disabled={disabled}
          value={value}
          onChange={(event) => (onChange ? onChange(event.target.value) : null)}
        />
      </Form.Group>
    </>
  );
};

export default CustomTextArea;
