import React, { useState, useRef, useEffect, useContext } from 'react';

import visionConf from '../vision.config.json';

import { EnvContext, DomainContext } from '../../../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';

import * as videoTools from '../../../../utils/video';

import CustomSocket, { socketStates } from '../../../utilities/CustomSocket';
import CustomVideo from '../../../utilities/CustomVideo';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export interface IFaceRecogMessage {
  key: string;
  score: number;
  callId: string;
}

interface Ibb {
  [index: string]: number;
}

interface IProps {
  hiddenCamera?: boolean;
  onResult: (message: IFaceRecogMessage) => void;
  onDisconnect: () => void;
  onError: (error: string) => void;
  onRecording: (state: boolean) => void;
}

const FaceRecogConn = ({ hiddenCamera, onResult, onDisconnect, onError, onRecording }: IProps) => {
  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);
  const faceRecogWsPort = env.includes('prod') ? 8004 : 4004;
  const faceRecogWsUrl = `wss://${domain}/ws${faceRecogWsPort}`;

  const isMounted = useRef<boolean>(false);
  const wsRef = useRef<WebSocket | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const wsIdRef = useRef<string>('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const askForNewImg = useRef<boolean>(true);
  const bbParams = useRef<Ibb>({ top: 0, left: 0, width: 0, height: 0 });
  const imageCountRef = useRef<number>(0);

  const [imageCount, setImageCount] = useState<number>(0);

  useEffect(() => {
    isMounted.current = true;

    const videoRefCurrent = videoRef.current;
    const canvasRefCurrent = canvasRef.current;
    return () => {
      if (timeoutRef && timeoutRef.current) clearTimeout(timeoutRef.current);
      wsRef.current && wsRef.current.close();
      if (videoRefCurrent) videoRefCurrent.remove();
      if (canvasRefCurrent) canvasRefCurrent.remove();
      askForNewImg.current = false;
      isMounted.current = false;
    };
  }, []);

  const getFrame = () => {
    if (canvasRef.current && videoRef.current) {
      canvasRef.current.width = videoRef.current.videoWidth || 400;
      canvasRef.current.height = videoRef.current.videoHeight || 300;

      const context = canvasRef.current.getContext('2d');
      context?.drawImage(videoRef.current, 0, 0);

      if (canvasRef.current) {
        const data: string = canvasRef.current.toDataURL('image/jpeg');

        if (
          wsRef.current &&
          wsRef.current.readyState === socketStates.OPEN &&
          data
        ) {
          if (askForNewImg.current) {
            imageCountRef.current = imageCountRef.current + 1;
            setImageCount(imageCountRef.current);
            wsRef.current.send(data);
            askForNewImg.current = false;
          }
        }
      }

      if (bbParams.current) {
        const left = bbParams.current.left;
        const top = bbParams.current.top;
        const width = bbParams.current.width;
        const height = bbParams.current.height;

        if (context) {
          videoTools.rounded_rect(context, left, top, width, height, 10);
        }
      }

      timeoutRef.current = setTimeout(
        getFrame,
        1000 / visionConf.frames_per_second
      );
    }
  };

  const socket = (
    <CustomSocket
      url={faceRecogWsUrl}
      onOpen={(ws: WebSocket | null) => {
        if (ws) wsRef.current = ws;
      }}
      onClose={() => {
        onDisconnect();
      }}
      onMessage={({ data }) => {
        const { uuid, keep_alive, ...rest } = JSON.parse(data);
        if (isMounted.current) {
          if (keep_alive) {
            //console.log('connected...');
          } else if (uuid) {
            wsIdRef.current = uuid;
            console.log('uuid:', uuid);
          } else {
            const { userID, score, bb, error } = rest;

            if (userID || error) {
              wsRef.current?.close();
              if (timeoutRef.current) clearTimeout(timeoutRef.current);
              askForNewImg.current = false;

              if (error) onError(error);
              else onResult({ key: userID, callId: wsIdRef.current, score });
            } else {
              askForNewImg.current = true;
            }

            if (bb && bb[0]) {
              for (let param in bb[0]) {
                bbParams.current[param] = bb[0][param];
              }
            }
          }
        }
      }}
      onError={(error) => {
        const description =
          typeof error === 'string' ? error : JSON.stringify(error);
        if (isMounted.current) onError(description);
      }}
    />
  );

  const recorder = (
    <Row className="pt-4 pb-2">
      <Col className={hiddenCamera ? 'd-none' : 'text-center'}>
        <CustomVideo
          forwardRef={videoRef}
          hidden={hiddenCamera}
          onStreaming={(stream: MediaStream) => {
            if (isMounted.current) {
              onRecording(true);
              getFrame();
            }
          }}
        />
      </Col>
      <Col className="text-center">
        <canvas ref={canvasRef}></canvas>
      </Col>
    </Row>
  );

  const count = (
    <Row>
      <Col>
        <div className="text-center">
          <p className="text-muted">
            <FontAwesomeIcon icon={faCameraRetro} />
            <span className="ml-2">{imageCount}</span>
            {' requested images'}
          </p>
        </div>
      </Col>
    </Row>
  );

  return (
    <Container fluid>
      {socket}
      {recorder}
      {count}
    </Container>
  );
};

export default FaceRecogConn;
