import React, { useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "../contexts/appContext";

const OutputVideo = () => {
  const { responseData } = AppContext();


  return (
          responseData.image ? (
            <img
              src={`data:image/jpeg;base64,${responseData.image}`}
              alt="response-image"
            />
          ) : (
            <img
              src={'/img/empty.png'}
              style={{ backgroundColor: "#f7f7f7", width: "256px", height:"256px" }}
              alt=""
            />
          )

  );
};

export default OutputVideo;
