import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { DomainContext } from '../../../../context/Context';

import * as requests from '../../../../requests/requests';
import { roundPercentage } from '../../../../utils/helpers';

import SubTitle from '../../../SubTitle';
import Description from '../../../Description';
import RegisterForm from '../../../utilities/RegisterForm';
import IdentifiedComponent from '../../../utilities/IdentifiedComponent';
import ErrorBoundary, { IErrorProps } from '../../../ErrorBoundary';

import VoiceIdForm from './VoiceIdForm';
import VoiceIdConn, { IVoiceIdMessage, Top } from './VoiceIdConn';
import TopTable from './TopTable';

interface IUserData {
  name: string;
  email: string;
  score: number;
}

interface IProps {
  title: string;
  description?: string;
}

type IdentifyResult = 'success' | 'fail' | 'no-data' | 'no-result';

const VoiceId = ({ title, description }: IProps) => {
  const domain = useContext(DomainContext);
  const isMounted = useRef<boolean>(false);
  const userKeyRef = useRef<string>('');

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [identifiedResult, setIdentifiedResult] = useState<IdentifyResult>(
    'no-result'
  );
  const [userData, setUserData] = useState<IUserData | null>(null);
  const [boundError, setBoundError] = useState<IErrorProps | null>(null);
  const [top, setTop] = useState<Top | null>(null);
  const [isUser, setIsUser] = useState<boolean>(false);
  const [params, setParams] = useState<IParams | null>(null);
  const [connUuid, setConnUuid] = useState<string>(domain);

  const [threshold, setThreshold] = useState<string>(0.72);

  const [defaultThreshold, setDefaultThreshold] = useState<string>(0.72);

  const [defaultThresholdEnabled, setDefaultThresholdEnabled] = useState<boolean>(false);

  const checkUrl = `https://${domain}/api/sesame/voiceid/check`;

  const connectUrl = `https://${domain}/api/sesame/voiceid/connect`;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const gotResult = (result: IVoiceIdMessage) => {
    const { isUser: _isUser, key, callId, score, top } = result;
    const readableScore = roundPercentage(score);

    userKeyRef.current = _isUser ? key : callId;
    if (key && callId) {
      if (_isUser && key !== callId) {
        console.log('%cIdentified', 'color: green');
        requests
          .checkKey(checkUrl, { key })
          .then(({ error, name, email }) => {
            if (isMounted.current) {
              if (error) {
                console.warn(error);
                setIdentifiedResult('no-data');
              } else {
                setUserData({
                  name,
                  email,
                  score: readableScore,
                });
                setIdentifiedResult('success');
              }
            }
          })
          .catch((error) => {
            console.warn('Identified', readableScore, 'but... without data');
            console.error(error);
            setIdentifiedResult('no-data');
            // setBoundError({title: 'Connection', description: error})
          });
      } else {
        console.log('%cNo Identified', 'color: red');
        if (isMounted.current) {
          setIdentifiedResult('fail');
        }
      }

      if (top) {
        const { scores, uids } = top;
        setTop({ scores, uids });
        setIsUser(_isUser ?? false);
      }
    } else {
      console.error('something went wrong');
      setBoundError({
        title: 'Voice Identifier',
        description: 'Something went wrong',
      });
      if (isMounted.current) {
        setIsConnected(false);
        setUserData(null);
        setIsWorking(false);
        setIdentifiedResult('no-result');
        setTop(null);
      }
    }
  };

  const onConnect = () => {
        if (isMounted.current) {
          setIsConnected(!isConnected);
          setUserData(null);
          setBoundError(null);
          if (!isConnected) setTop(null);
        }
  }

  const onDefaultThresholdEnabledChanged = (status) => {
      console.log('onDefaultThresholdEnabledChanged', status);
      setDefaultThresholdEnabled(status);
      if(status === true) {
          setThreshold(defaultThreshold.current);
      }
  }


  const form = (
    <VoiceIdForm
      uuid={connUuid}
      threshold={threshold}
      defaultThreshold={defaultThreshold}
      status={isConnected}
      disabled={identifiedResult !== 'no-result' ? true : false}
      onConnect={onConnect}
      onDefaultThresholdEnabledChanged={onDefaultThresholdEnabledChanged}
      gotParams={(params) => {
        if (isMounted.current) {
          // if (isLoading) setIsLoading(false); // TODO
          setParams(params);
          setThreshold(params.threshold);
        }
      }}
    />
  );

  const conn = isConnected ? (
    <VoiceIdConn
      uuid={connUuid}
      threshold={threshold}
      defaultThresholdEnabled={defaultThresholdEnabled}
      onResult={gotResult}
      onDisconnect={() => {
        if (isMounted.current) {
          setIsConnected(false);
          setIsWorking(false);
        }
      }}
      onError={(error: string) => {
        console.error(error);
        setBoundError({ title: 'Connection', description: error });
      }}
      onRecording={(status) => {
        if (isMounted.current) setIsWorking(status);
      }}
    />
  ) : null;

  const register =
    (identifiedResult === 'no-data' || identifiedResult === 'fail') &&
      !isWorking ? (
      <RegisterForm
        uuid={userKeyRef.current}
        details={identifiedResult}
        onRegistered={() => {
          if (isMounted.current) setIdentifiedResult('no-result');
        }}
        onError={(error) => console.log(error)}
      />
    ) : null;

  const identified =
    identifiedResult === 'success' && userData && !isWorking ? (
      <IdentifiedComponent
        name={userData?.name}
        email={userData?.email}
        score={userData?.score}
        done={() => {
          if (isMounted.current) setIdentifiedResult('no-result');
        }}
      />
    ) : null;

  const topFive = top && !isWorking && (
    <TopTable top={top} isUser={isUser} />
  );

  const errorBoundary = boundError ? (
    <ErrorBoundary
      title={boundError?.title || 'Error'}
      description={boundError?.description ? boundError.description : ''}
      reason={boundError?.reason ? boundError.reason : ''}
    />
  ) : null;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <SubTitle name={title} />
      <Description text={description ? description : 'Utopia.AI'} />
      {form}
      {conn}
      {register}
      {identified}
      {topFive}
      {errorBoundary}

    </Fragment>
  );
};

export default VoiceId;
