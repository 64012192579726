import React from 'react';

import Pagination from 'react-bootstrap/Pagination';

interface IProps {
  disabled?: boolean;
  pages: number;
  index: number;
  onChange: (value: number) => void;
}

const CustomPagination = ({ disabled, pages, index, onChange }: IProps) => {
  const first =
    index > 1 ? (
      <Pagination.First disabled={disabled} onClick={() => onChange(1)} />
    ) : null;
  const prev =
    index > 1 ? (
      <Pagination.Prev
        disabled={disabled}
        onClick={() => onChange(index - 1)}
      />
    ) : null;

  const prevNumber =
    index > 1 ? (
      <Pagination.Item disabled={disabled} onClick={() => onChange(index - 1)}>
        {index - 1}
      </Pagination.Item>
    ) : null;
  const activeItem = <Pagination.Item active>{index}</Pagination.Item>;
  const nextNumber =
    index < pages ? (
      <Pagination.Item disabled={disabled} onClick={() => onChange(index + 1)}>
        {index + 1}
      </Pagination.Item>
    ) : null;

  const next =
    index < pages ? (
      <Pagination.Next
        disabled={disabled}
        onClick={() => onChange(index + 1)}
      />
    ) : null;
  const last =
    index < pages ? (
      <Pagination.Last disabled={disabled} onClick={() => onChange(pages)} />
    ) : null;

  return (
    <Pagination className="justify-content-center" size="sm">
      {first}
      {prev}

      {prevNumber}
      {activeItem}
      {nextNumber}

      {next}
      {last}
    </Pagination>
  );
};

export default CustomPagination;
