/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

export type Variant = 'up' | 'select' | 'cancel';
export type Position = 'first' | 'second' | 'third';

interface IProps {
	variant: Variant;
	position: Position;
	disabled?: boolean;
	onClick: () => void;
}

const style = css`
	.aside-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		color: #945151;
		right: 1.25em;
		position: fixed;
		width: 3em;
		height: 3em;
		border-radius: 100%;
		background-color: #eeeeee;
		z-index: 9999;
		transition: color 0.25s ease, background-color 0.25s ease;

		&:hover, &:focus {
			background-color: #945151;
			color: #eeeeee;
		}
	}

	.aside-icon.first {
		top: 60%;
	}

	.aside-icon.second {
		top: 67%;
	}

	.aside-icon.third {
		bottom: 8%;
	}

	.aside-icon.disabled {
		opacity: 0.5;
		cursor: default;
	}

	.aside-icon.disabled:hover,
	.aside-icon.disabled:focus {
		background-color: unset;
		color: unset;
	}
`;

const AsideButton = ({ variant, position, disabled, onClick }: IProps) => {
	let icon = faTimes;
	icon = variant === 'up' ? faArrowUp : icon;
	icon = variant === 'select' ? faCheck : icon;

	return (
		<div css={style}>
			<div
				className={`aside-icon ${variant} ${position} ${disabled ? 'disabled' : ''}`}
				onClick={() => !disabled && onClick()}
			>
				<FontAwesomeIcon icon={icon} />
			</div>
		</div>
	);
};

export default AsideButton;