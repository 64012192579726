import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IProps {
  text: string;
  centered?: boolean;
  hidden?: boolean;
}

const Description = ({ text, centered, hidden }: IProps) => {
  return (
    <Container fluid className={hidden ? 'd-none' : ''}>
      <Row>
        <Col xs={12} className={centered ? 'text-center' : 'text-left'}>
          <p className='utopia-home-description'>{text}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Description;
