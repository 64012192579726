import { useEffect, useContext, useRef, useState } from 'react';
import axios from 'axios';

import { DomainContext, EnvContext } from '../../../context/Context';
import { IAudioContent } from '../loquista/tts/TextToSpeechConn';
import { getLinear16, toArrayBuffer, withWaveHeader } from '../../../utils/audio';
// import { displayName } from './digital_assistants/VideoAssistantLegend';
import { useBuffer } from './useBuffer';

export interface IResult {
	image: Buffer | null;
	audio_contents: IAudioContent | null;
}

interface IProps {
	audio: IAudioContent;
	avatar: string;
	background: string | undefined,
	watermark: string | undefined;
	// shown: boolean;
	// onResult: (result: IResult) => void;
}

const targetSampleRate = 16000;

export const usePlayAvatar = ({ audio, avatar, background, watermark }: IProps) => {
	const env = useContext(EnvContext);
	const domain = useContext(DomainContext);

	const isMounted = useRef<boolean>(false);
	const wsRef = useRef<WebSocket>();
	const audioCtxRef = useRef<AudioContext>();
	const idRef = useRef<string>('');

	// const [wsConn, setWsConn] = useState<boolean>(false);
	const [result, setResult] = useState<IResult>({ image: null, audio_contents: null });

    const fps = 20;
    const { setItem, getItem, state, frame } = useBuffer({ itemsSize: fps } );

    const bufferState = useRef();

	useEffect(() => {
        console.log(`STATE_ ${state}`);
        bufferState.current = state;
    }, [state]);

	// useEffect(() => {
    //     console.log(`FRAME`, new Date().getTime());
    //     frame && setResult(frame);
    // }, [frame]);

	useEffect(() => {
		isMounted.current = true;

        connectSocket();
		return () => {
            console.log('usePlayAvatar DISMOUNTED');
			isMounted.current = false;
			disconnectFromAvatar(env, domain, idRef.current);
			// wsRef.current?.close();
			audioCtxRef.current?.suspend();
			audioCtxRef.current?.close();
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const connectHandler = async () => {
			try {
				// !isConnected && await connectToAvatar(env, domain, idRef.current, avatar);
				// !isConnected && setIsConnected(true);
				sendAudio();
			} catch (error) {
				console.error(error);
			}
		};
		isMounted.current && audio && connectHandler();
		// eslint-disable-next-line
	}, [audio]);

    const start = () => {
		connectSocket();
    };

    const stop = () => {
        disconnectFromAvatar(env, domain, idRef.current);
    };

	const sendAudio = async () => {
        console.log('sendAudio');
		const rate = 22050;
        if(!audio) {
            return;
        }
		const buffer: Buffer = audio.data;

		const arrayBuffer: ArrayBuffer = toArrayBuffer(buffer);
		const audioWithWaveHeader: ArrayBuffer = withWaveHeader(arrayBuffer, 1, rate);

		if (audioCtxRef.current && wsRef.current) {
			audioCtxRef.current.decodeAudioData(audioWithWaveHeader,
				async (chunk: AudioBuffer) => {
					// console.log('chunk', chunk);
					if (audioCtxRef.current) {
						// const source: AudioBufferSourceNode = audioCtxRef.current.createBufferSource();
						// source.buffer = chunk;
						// source.connect(audioCtxRef.current?.destination);
						// source.start();

						const channelData = chunk.getChannelData(0);
						const raw = await getLinear16(channelData);
						const bytes = raw.buffer;

						wsRef.current?.readyState && wsRef.current.send(bytes);
					}
				},
				(error) => console.error(error));
		}
	};

	const connectSocket = () => {
        console.log('connectSocket ');
		const wsPort = env.includes('prod') ? 8007 : 4007; // connection to avatar server
		const wsUrl = `wss://${domain}/ws${wsPort}`;

		wsRef.current = new WebSocket(wsUrl);
		audioCtxRef.current = new (window.AudioContext || (window as any).webkitAudioContext)(
			{ sampleRate: targetSampleRate }
		);

		wsRef.current.onopen = async () => {
			console.log('ws opened');
			// setWsConn(true);
		};

		wsRef.current.onclose = () => {
			console.log('ws closed');
		};

		wsRef.current.onerror = (err) => {
			console.error(err);
			wsRef.current?.close();
		};

		wsRef.current.onmessage = async (message) => {
            console.log('WS MSG');
			const { data } = message;
			const { keep_alive, uuid, error, ...rest } = JSON.parse(data);

			if (keep_alive) console.log('Socket is connected');
			else if (!keep_alive && uuid) {
				if (!idRef.current && avatar) await connectToAvatar(env, domain, uuid, avatar, background, watermark);
				idRef.current = uuid;
				sendAudio();

				// !isConnected && await connectToAvatar(env, domain, idRef.current, avatar);
				// !isConnected && setIsConnected(true);
				// !isConnected && sendAudio();
			} else if (error) {
				console.error(error);
				const defaultImage = 'default Image';
				isMounted.current && setResult({ image: Buffer.from(defaultImage, 'utf-8'), audio_contents: null });
			} else {
				const { image, audio_contents } = rest;
                // isMounted.current && setResult({ image, audio_contents });
                isMounted.current && setItem({ image, audio_contents });
                // isMounted.current && setResult(getItem());
			}
		};

        console.log('set interval');
        const interval = setInterval(() => {
            // console.log(`INTERVAL:${new Date().getTime()}`);
            // console.log(`STATE_get ${bufferState.current}`);
            const item = isMounted.current && getItem(bufferState.current);
            item && setResult(item);
        }, 1000 / fps);
	};

	// const disconnect = async () => await disconnectFromAvatar(env, domain, idRef.current);

	return { result, setResult, start, stop, state };
};

function connectToAvatar(env: string, domain: string, id: string, name: string, background: string | undefined, watermark: string | undefined): Promise<void> {
	const avatarVideoName = _getNameByUrl(name);
	console.log('Connect to Avatar', avatarVideoName);
	return new Promise(async (resolve, reject) => {
		try {
			const api = `https://${domain}/api/vision/mimic/connect`;
			const params = { id, env, name: avatarVideoName, background, watermark };
			const options = { headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` } };

			await axios.post(api, params, options);
			resolve();
		} catch (error) {
			reject('An error occurred while trying to connect.');
		}
	});
}

function disconnectFromAvatar(env: string, domain: string, id: string): Promise<void> {
	console.log('Disconect from Avatar');
	return new Promise(async (resolve, reject) => {
		try {
			const api = `https://${domain}/api/vision/mimic/disconnect`;
			const params = { id };
			const options = { headers: { 'Authorization': `Bearer ${localStorage.getItem(`${env}-token`)}` } };

			await axios.post(api, params, options);
			resolve();
		} catch (error) {
			reject('An error occurred while trying to disconnect.');
		}
	});
}

function _getNameByUrl(url: string): string {
	return displayName(url);
}

export function displayName(url: string): string {
	const _arr = url.split('/');
	return _arr[_arr.length - 1].replace(/.mp4|.jpeg|.jpg|.png/, '');
}
