import { useContext, useState, useEffect, useMemo } from 'react';
import { DomainContext } from '../context/Context';

import axios from 'axios';

export type Role = 'visitor' | 'publisher' | 'admin';

const useRole = (user?: string | boolean) => {
	const domain = useContext(DomainContext);

	const [role, setRole] = useState<Role>('visitor');

	const providerValue = useMemo(() => ({
		role,
		setRole
	}), [role, setRole]);

	useEffect(() => {
		const checkAuth = async () => {
			try {
				const url = `https://${domain}/admin/roles`;
				const { data: { results } } = await axios.post(url, { email: user });
				const { role } = results[0];
				providerValue.setRole(role);
			} catch (error) {
				console.error(error);
			}
		};
		if (user) checkAuth();
		// eslint-disable-next-line
	}, [user]);

	return providerValue;
};

export default useRole;