import React from 'react';
import { Helmet } from 'react-helmet';

import lgImg from '../../resources/imagen-home-grande.jpg';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../Title';
import features from '../../text/features.json';

const Home = () => {
  const title = 'Artificial intelligence for your calls';

  return (
    <Container fluid>
      <Helmet>
        <title>Utopia apps</title>
      </Helmet>
      <Title title={title} centered={true} />
      <Row>
        <Col sm={12} md={7} lg={7} xl={5}>
          <div className='utopia-home-image-wrap'>
            <img src={lgImg} alt='utopia.ai' />
          </div>
        </Col>
        <Col sm={12} md={5} lg={5} xl={7}>
          <ul className='utopia-home-features list-unstyled'>
            {features.map((feature: string, key: number) => (
              <li key={key}>{feature}</li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
