import React, { useRef, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

interface IProps {
	status: boolean;
	disabled?: boolean;
	onConnect: () => void;
}

const instructions = 'Please, check you camera and press Connect. After few seconds, results will be displayed.';

const NIFReaderForm = ({ status, disabled, onConnect }: IProps) => {
	const isMounted = useRef<boolean>(false);

	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false; };
		// eslint-disable-next-line
	}, []);

	const form = (
		<Form>
			<Form.Row>
				<Col className="py-2 px-0">
					<Button block disabled={disabled} onClick={onConnect}>
						{status ? 'Disconnect' : 'Connect'}
					</Button>
				</Col>
			</Form.Row>
		</Form>
	);

	const main = (
		<Container fluid>
			<Row>
				<Col>
					<div className="text-muted">
						<p>{instructions}</p>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>{form}</Col>
			</Row>
		</Container>
	);

	return main;
};

export default NIFReaderForm;
