import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';

import { EnvContext, DomainContext } from '../../../../context/Context';
import { orderLanguages, orderVoices, defaultSelectedVoiceByLanguage } from '../../../../utils/utils';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import CustomSelect from '../../../forms/CustomSelect';
import CustomSwitch from '../../../forms/CustomSwitch';

interface IHost {
  env: string;
  host: string;
  port?: number;
  state?: string;
}

interface ICombination {
  input: string;
  output: string;
}

export interface IData {
  asrHost: string;
  nmtHost: string;
  ttsHost: string;
  inputLanguage: string;
  outputLanguage: string;
  voice: string;
  formatted: boolean;
  combinations: ICombination[];
}

interface IProps {
  className: string;
  disabled: boolean;
  onError: (error: string | null) => void;
  gotParams: () => void;
  onConnect: (data: IData) => void;
}

const VoiceTranslatorForm = ({
  className,
  disabled,
  onError,
  gotParams,
  onConnect,
}: IProps) => {
  const domain = useContext(DomainContext);
  const env = useContext(EnvContext);
  const isMounted = useRef<boolean>(false);

  const [asrHosts, setASRHosts] = useState<string[]>(['']);
  const [nmtHosts, setNMTHosts] = useState<string[]>(['']);
  const [ttsHosts, setTTSHosts] = useState<string[]>(['']);

  const [selectedASRHost, setSelectedASRHost] = useState<string>('');
  const [selectedNMTHost, setSelectedNMTHost] = useState<string>('');
  const [selectedTTSHost, setSelectedTTSHost] = useState<string>('');

  const [asrLanguages, setASRLanguages] = useState<string[]>(['']);
  const [selectedASRLang, setASRLang] = useState<string>('');
  const [ttsLanguages, setTTSLanguages] = useState<string[]>(['']);
  const [selectedTTSLang, setTTSLang] = useState<string>('');
  const [combinations, setCombinations] = useState<ICombination[]>([]);

  const [voices, setVoices] = useState<string[]>(['']);
  const [selecetedVoice, setSelectedVoice] = useState<string>('');

  const [formatted, setFormatted] = useState<boolean>(true);

  const uuid = 'apps.utopia.ai';

  useEffect(() => {
    isMounted.current = true;

    const asrUrl = `https://${domain}/api/loquista/asr/hosts`;
    const nmtUrl = `https://${domain}/api/loquista/translate-text/hosts`;
    const ttsUrl = `https://${domain}/api/loquista/tts/hosts`;
    const params = { frontEnv: env };
    const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };

    const getHosts = async () => {
      try {
        let asrData = await axios.post(asrUrl, params, options);
        const _asrHosts = asrData.data.availableHosts.map((item: IHost) => item.host);
        let nmtData = await axios.post(nmtUrl, params, options);
        const _nmtHosts = nmtData.data.availableHosts.map((item: IHost) => item.host);
        let ttsData = await axios.post(ttsUrl, params, options);
        const _ttsHosts = ttsData.data.availableHosts.map((item: IHost) => item.host);

        // hosts[Math.floor(Math.random() * hosts.length)]
        if (isMounted.current) {
          setASRHosts(_asrHosts);
          setNMTHosts(_nmtHosts);
          setTTSHosts(_ttsHosts);
          setSelectedASRHost(_asrHosts[Math.floor(Math.random() * _asrHosts.length)]);
          setSelectedNMTHost(_nmtHosts[Math.floor(Math.random() * _nmtHosts.length)]);
          setSelectedTTSHost(_ttsHosts[Math.floor(Math.random() * _ttsHosts.length)]);
          gotParams();
        }
      } catch (error) {
        onError('Service unavailable');
      }
    };

    getHosts();

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getASRLanguagesByASRHost = async () => {
      try {
        const url = `https://${domain}/api/loquista/asr/languages`;
        const params = { host: selectedASRHost, uuid };
        const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
        const { data: { availableLanguages } } = await axios.post(url, params, options);
        const sortedLanguages = orderLanguages(availableLanguages);
        if (isMounted.current) {
          setASRLanguages(sortedLanguages);
          setASRLang(sortedLanguages[2]);
        }
      } catch (error) {
        onError('Service unavailable');
      }
    };

    if (selectedASRHost) getASRLanguagesByASRHost();
    // eslint-disable-next-line
  }, [selectedASRHost]);

  useEffect(() => {
    const getNMTLanguagesByNMTHost = async () => {
      try {
        const url = `https://${domain}/api/loquista/translate-text/languages`;
        const params = { host: selectedNMTHost, uuid };
        const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
        const { data: { languages } } = await axios.post(url, params, options);

        const inputs: string[] = languages.map(((lang: ICombination) => lang.input));
        const _uniqueLanguages = [...new Set(inputs)];

        if (_uniqueLanguages.length > 0) {
          const sortedLanguages = orderLanguages(_uniqueLanguages);
          if (isMounted.current) {
            setASRLang(sortedLanguages[2]);
            setTTSLang(sortedLanguages[0]);
            setCombinations(languages);
          }
        } else console.warn('Impossible combination');
      } catch (error) {
        onError('Service unavailable');
      }
    };

    if (selectedNMTHost) getNMTLanguagesByNMTHost();
    // eslint-disable-next-line
  }, [selectedNMTHost]);

  useEffect(() => {
    const getTTSLanguagesAndVoicesByTTSHost = async () => {
      try {
        const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };

        const languagesUrl = `https://${domain}/api/loquista/tts/languages`;
        const languageParams = { host: selectedTTSHost, uuid };

        const { data: { availableLanguages } } = await axios.post(languagesUrl, languageParams, options);
        const sortedLanguages = orderLanguages(availableLanguages);

        const voicesUrl = `https://${domain}/api/loquista/tts/voices`;
        const voicesParams = { host: selectedTTSHost, uuid, language: sortedLanguages[0] };
        const { data: { availableVoices } } = await axios.post(voicesUrl, voicesParams, options);
        const sortedVoices: string[] = orderVoices(availableVoices);
        const _voice = defaultSelectedVoiceByLanguage(sortedLanguages[0], availableVoices);

        if (isMounted.current) {
          setTTSLanguages(sortedLanguages);
          setTTSLang(sortedLanguages[0]);
          setVoices(sortedVoices);
          setSelectedVoice(_voice);
        }
      } catch (error) {
        onError('Service unavailable');
      }
    };

    if (selectedASRHost) getTTSLanguagesAndVoicesByTTSHost();
    // eslint-disable-next-line
  }, [selectedTTSHost]);

  useEffect(() => {
    const getTTSVoicesByTTSLanguage = async () => {
      try {
        const url = `https://${domain}/api/loquista/tts/voices`;
        const params = { host: selectedTTSHost, uuid, language: selectedTTSLang };
        const options = { headers: { "Authorization": `Bearer ${localStorage.getItem(`${env}-token`)}` } };
        const { data: { availableVoices } } = await axios.post(url, params, options);
        const sortedVoices: string[] = orderVoices(availableVoices);
        const _voice = defaultSelectedVoiceByLanguage(selectedTTSLang, availableVoices);

        if (isMounted.current) {
          setVoices(sortedVoices);
          setSelectedVoice(_voice);
        }
      } catch (error) {
        onError('Unavailable service');
      }
    };

    if (selectedTTSLang && selectedTTSHost) getTTSVoicesByTTSLanguage();
    // eslint-disable-next-line
  }, [selectedTTSLang]);

  return (
    <Container fluid className={className}>
      <Row>
        <Col>
          <Form>
            <Form.Row className={env.includes('prod') ? 'd-none' : ''}>
              <Col xs={12} sm={4}>
                <CustomSelect
                  name="vocie-translator-host"
                  label="asr host"
                  options={asrHosts}
                  disabled={disabled}
                  onChange={(value) => {
                    if (isMounted.current) setSelectedASRHost(value);
                  }}
                />
              </Col>
              <Col xs={12} sm={4}>
                <CustomSelect
                  name="vocie-translator-host"
                  label="nmt host"
                  options={nmtHosts}
                  disabled={disabled}
                  onChange={(value) => {
                    if (isMounted.current) setSelectedNMTHost(value);
                  }}
                />
              </Col>
              <Col xs={12} sm={4}>
                <CustomSelect
                  name="vocie-translator-host"
                  label="tts host"
                  options={ttsHosts}
                  disabled={disabled}
                  onChange={(value) => {
                    if (isMounted.current) setSelectedTTSHost(value);
                  }}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={12} sm={6} md={4}>
                <CustomSelect
                  name="voice-translator-input-language"
                  label="Input language"
                  options={asrLanguages}
                  disabled={disabled}
                  selected={selectedASRLang}
                  onChange={(value) => {
                    if (isMounted.current) setASRLang(value);
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4}>
                <CustomSelect
                  name="voice-translator-output-language"
                  label="Output language"
                  options={ttsLanguages}
                  disabled={disabled}
                  selected={selectedTTSLang}
                  onChange={(value) => {
                    if (isMounted.current) setTTSLang(value);
                  }}
                />
              </Col>
              <Col xs={12} sm={12} md={4}>
                <CustomSelect
                  name="vocie-translator-voices"
                  label="voice"
                  options={voices}
                  disabled={disabled}
                  onChange={(value) => {
                    if (isMounted.current) setSelectedVoice(value);
                  }}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={12} sm={4}>
                <CustomSwitch
                  name="voice-translator-switch"
                  label="Format"
                  note={{
                    on: 'Formatted text',
                    off: 'Plain text',
                  }}
                  initial={formatted}
                  disabled={disabled}
                  onChange={(value) => {
                    if (isMounted.current) setFormatted(value);
                  }}
                />
              </Col>
              <Col xs={12} sm={8}>
                <div className="my-2 mt-sm-3">
                  <Button
                    block
                    onClick={() => {
                      onConnect({
                        asrHost: selectedASRHost,
                        nmtHost: selectedNMTHost,
                        ttsHost: selectedTTSHost,
                        inputLanguage: selectedASRLang,
                        outputLanguage: selectedTTSLang,
                        voice: selecetedVoice,
                        formatted,
                        combinations,
                      });
                    }}
                  >
                    {disabled ? 'Disconnect' : 'Connect'}
                  </Button>
                </div>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    </Container >
  );
};

export default VoiceTranslatorForm;