import React, { useRef, useState, useEffect } from "react";
import { Row, FormControl, InputGroup, Button, Form } from "react-bootstrap";
import { AppContext } from "../contexts/appContext";

const SelectVideo = () => {
  const { drivingVideo, setDrivingVideo, drivingVideos, setDrivingVideos, start, setShowVideosPanel } = AppContext();

  const defaultValue = useRef(undefined);

  const handleChange = (e) => {
    console.log('handleChange',  e.target.value);
    setDrivingVideo(e.target.value);
  };

  // const videos = [
  //     { value: null, name: '' },
  //     { value: 'video_01.mp4', name: 'Video 01' },
  // ];

  function getVideoOptions(drivingVideos) {
      return drivingVideos.map((video) => {
          return <option value={video.key} key={video.key}>{video.name}</option>;
      });
  }

  const clickHandler = () => {
      console.log('SELECT');
      if(!start) {
        setShowVideosPanel(true);
      }
  };

  return (

	<Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Control disabled={start} as="button" onClick={ () => clickHandler() }>Select a driving video</Form.Control>
	  </Form.Group>
  );
};

		// <Form.Label>Select a driving video</Form.Label>
		// <Form.Control as="select" onChange={handleChange} value={drivingVideo} disabled={start} >
        //     {getVideoOptions(drivingVideos)}
		// </Form.Control>

        // <button onClick={ () => clickHandler() }>SELECT</button>

export default SelectVideo;
