import React, { useEffect, useState, useContext, useRef } from 'react';

import { EnvContext } from '../../../../context/Context';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Select from '../../../forms/Select';
import NlpSelect from '../../../forms/NlpSelect';
import CustomCheckBox from '../../../forms/CustomCheckBox';

import { useSpeechToTextForm, IUseSpeechToTextForm, Language } from './useSpeechToTextForm';

export interface IOption {
  name: string;
  value: boolean;
}

export interface IParams {
  host: string;
  language: string;
  nlp: string;
  options: IOption[];
}

interface IProps {
  uuid: string;
  disabled: boolean;
  className?: string;
  gotParams: (params: IParams) => void;
  onConnect: () => void;
  onError: (error: string) => void;
}



const SpeechToTextForm = ({ uuid, disabled, className, onConnect, onError, gotParams }: IProps) => {
  const isMounted = useRef<boolean>(false);
  const env = useContext(EnvContext);

  const selects = ['host', 'language'];

  const { params, setParams }: IUseSpeechToTextForm = useSpeechToTextForm(uuid);
  const [nlp, setNlp] = useState<string>(localStorage.getItem('loquista-asr-nlp') || '');
  const [options, setOptions] = useState<IOption[]>([
    // { name: 'use_nlp', value: true },
    { name: 'use_enhanced', value: true },
    { name: 'use_gpu', value: true },
    { name: 'use_provisional_transcription', value: false },
  ]);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  useEffect(() => {
    if (params) {
      let isFilled = true;

      Object.entries(params).forEach(([key, value]) => {
        if (typeof value === 'string' && value === '') isFilled = false;
        if (typeof value === 'object' && value[0] === '') isFilled = false;
      });

      if (isFilled) {
        const { host, language } = params;
        gotParams({ host, language, nlp, options });
      }
    }
    // eslint-disable-next-line
  }, [params, nlp, options]);


  const nlpSelect = (
    <NlpSelect name={window.location.pathname} label="format" disabled={disabled}
      onChange={(option) => isMounted.current && setNlp(option)} />
  );

  const optionsCheckBoxes = (
    <>
      <label className="mt-1">Options</label>
      <CustomCheckBox
        options={options}
        inline={true}
        disabled={disabled}
        onChange={(currentOpt) => {
          const newOptions = options.map((item) => {
            if (item.name === currentOpt) item.value = !item.value;
            return item;
          });
          isMounted.current && setOptions(newOptions);
        }}
      />
    </>
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <Form className={className}>
            <Form.Row>
              {
                selects.map((item, key) => (
                  <Col key={`${item}-${key}`} xs={12} md={item === 'host' ? 12 : 6} lg={env.includes('prod') ? 6 : 4}
                    className={env.includes('prod') && item === 'host' ? 'd-none' : ''}
                  >
                    <Select name={`loquista-asr-${item}`} label={item} disabled={disabled}
                      options={item === 'host' ? params.hosts : params.languages}
                      selected={item === 'host' ? params.host : params.language}
                      onChange={(value) => {
                        const { ...props } = params;
                        if (isMounted.current && item === 'host') setParams({ ...props, host: value });
                        if (isMounted.current && item === 'language') setParams({ ...props, language: value as Language });
                      }} />
                  </Col>
                ))
              }
              <Col xs={12} md={6} lg={env.includes('prod') ? 6 : 4}>
                {nlpSelect}
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={12} lg={4}>
                {optionsCheckBoxes}
              </Col>
              <Col xs={12} lg={8}>
                <Button className="py-2 mt-1 mt-lg-3 mb-4 mb-lg-0" block onClick={onConnect}>
                  {disabled ? 'Disconnect' : 'Connect'}
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SpeechToTextForm;
