import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

interface IProps {
  name: string;
  email?: string;
  score: number;
  done?: () => void;
}

const IdentifiedComponent = ({ name, email, score, done }: IProps) => {
  const unknown = <span className="text-muted font-italic">{'unknown'}</span>;

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex justify-content-center my-4 text-center">
            <Card style={{ width: '25rem' }}>
              <Card.Header className="bg-gradient-success text-light">
                Identified
              </Card.Header>
              <Card.Body className="text-left">
                <Card.Title>User</Card.Title>
                <div>
                  <ul className="list-unstyled">
                    <li>
                      <span className="font-weight-bolder">Name: </span>
                      {name ? name : unknown}
                    </li>
                    <li>
                      <span className="font-weight-bolder">Email: </span>
                      {email ? email : unknown}
                    </li>
                  </ul>
                </div>
              </Card.Body>
              <Card.Footer className="bg-light">
                <ProgressBar
                  striped
                  variant="success"
                  now={score * 100}
                  label={`${score * 100}%`}
                />
              </Card.Footer>
            </Card>
          </div>
          <div className="text-center">
            <Button
              variant="light"
              size="sm"
              onClick={done}
              style={{ width: '25rem' }}
            >
              Ok
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default IdentifiedComponent;
